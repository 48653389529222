import React, { useContext, useState, createContext } from 'react'

export const AlertContext = createContext()

const AlertBoxProvider = (props) => {
  const [options, setOptions] = useState({
    open: false,
    type: 'success',
    time: 2000
  })

  return (
    <AlertContext.Provider
      value={{
        options,
        setOptions
      }}
    >
      {props.children}
    </AlertContext.Provider>
  )
}

export const useAlertBox = () => {
  const context = useContext(AlertContext)
  if (!context) { throw new Error('useModal must be used within a provider AlertBoxProvider') }

  return { ...context }
}

export default AlertBoxProvider
