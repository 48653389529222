import React from 'react'
import { Route as ReactRoute, Switch } from 'react-router-dom'
import { GuardedRoute } from 'react-router-guards'

const Route = ({ data }) => {
  return (
    <Switch>
      {data.map(({ layout: Layout, data: item }, index) => (
        <ReactRoute key={index} path={item.map((i) => i.path)}>
          <Layout>
            <Switch>
              {item.map(({ path, component, ...route }, idx) => (
                <GuardedRoute
                  key={`${index}-${idx}`}
                  path={path}
                  component={component}
                  {...route}
                  exact={route.exact || true}
                />
              ))}
            </Switch>
          </Layout>
        </ReactRoute>
      ))}
    </Switch>
  )
}

export default Route
