import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import { Col } from 'react-bootstrap'
import { formatMoney, phoneMask } from '../../../../Utils/format'
import { Rating } from '@mui/material'
import moment from 'moment'
import ButtonPrimary from '../../../components/Button'
import { Link } from 'react-router-dom'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { customTheme } from '../../../../styles/theme'
import * as Yup from 'yup'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import MailOutlineOutlined from '@mui/icons-material/MailOutlineOutlined'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import country from '../../../../Utils/estados_cidades.json'
import { AuthService } from '../../../../services/auth/auth'
import DataTableActionColumn from '../../../components/DataTableActionColumn'

export const filterManageDoctors = (
  especilities,
  doctors,
  filter,
  setFilter
) => {
  const states = []
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })
  return [
    {
      name: 'Adicionar novo médico',
      fields: [
        {
          label: 'Especialidade',
          type: 'autocomplete',
          required: true,
          disabled: false,
          hideField: false,
          name: 'doctors_with_specialties',
          options: especilities?.map((item) => ({
            label: item.name,
            value: item.id
          })),
          onChange: (e) => {
            setFilter((prev) => ({
              ...prev,
              doctors_with_specialties: e
            }))
          },
          columns: {
            xs: 3
          }
        },
        {
          label: 'Estado',
          type: 'autocomplete',
          required: true,
          disabled: false,
          hideField: false,
          name: 'state',
          onChange: (data) => {
            setFilter((prev) => ({
              ...prev,
              state: data
            }))
          },
          options: states,
          columns: {
            xs: 3
          },
          validation: Yup.object().required('Campo obrigatório')
        },
        {
          label: 'Médicos',
          type: 'autocomplete',
          required: true,
          disabled: !filter?.state,
          hideField: false,
          name: 'doctor',
          options: doctors
            ?.filter((item) => {
              if (filter?.doctors_with_specialties && item) {
                return !!(
                  item?.doctors_with_specialties?.find((el) => {
                    return (
                      filter?.doctors_with_specialties?.value ===
                      el.medical_specialty.id
                    )
                  }) || item.main_specialty === filter.value
                )
              }
              return item
            })
            ?.map((item) => ({
              label: item.full_name,
              value: item.id
            })),
          columns: {
            xs: 3
          },
          validation: Yup.object().required('Obrigatório Selecionar um médico')
        }
      ]
    }
  ]
}

export const columns = (
  handleRemove,
  especilities,
  actions
) => {
  return [
    {
      field: 'photo',
      headerName: 'Foto',
      width: 60,
      editable: true,
      renderCell: (params) => {
        return (
          <Col className="d-flex justify-content-center">
            {params?.row?.profile_image
              ? (
                <Avatar src={params.row.profile_image} />
                )
              : (
                <Avatar>{params?.row?.full_name[0]}</Avatar>
                )}
          </Col>
        )
      }
    },
    {
      field: 'full_name',
      headerName: 'Nome',
      width: 300,
      editable: true
    },
    {
      field: 'average_cost_of_medical_consultation',
      headerName: 'Custo Médio',
      width: 100,
      type: 'number',
      editable: true,
      valueGetter: (params) =>
        `${formatMoney(params?.row?.average_cost_of_medical_consultation)} `
    },
    {
      field: 'average_of_evaluations',
      headerName: 'Avaliações',
      width: 150,
      editable: true,
      renderCell: ({ row }) => {
        return (
          <Rating
            name="read-only"
            value={row?.average_of_evaluations}
            readOnly
          />
        )
      }
    },
    {
      field: 'main_specialty',
      headerName: 'Especialidade Principal',
      width: 240,
      editable: true,
      valueGetter: (params) => {
        return `${
          especilities?.find((item) => item?.id === params?.row?.main_specialty)
            ?.name || '--'
        }`
      }
    },
    {
      field: 'created_at',
      headerName: 'Data de Cadastro',
      sortable: true,
      width: 150,
      valueGetter: (params) =>
        `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
      valueFormatter: (params) =>
        moment(params.value).format('DD/MM/YYYY - HH:MM')
    },
    {
      field: 'id',
      headerName: '',
      sortable: false,
      width: 180,
      renderCell: (params) => {
        return (
          <DataTableActionColumn
            handleEdit={() => {
              if (actions.handleEdit) {
                actions.handleEdit(params)
              }
            }}
            handleDelete={async () => {
              if (handleRemove) {
                await handleRemove(params.row.id)
              }
            }}
            handleSchedules={() => {
              if (actions.handleSchedules) {
                actions.handleSchedules(params)
              }
            }}
            handleSendEmail={() => {
              if (actions.handleSendEmail) {
                actions.handleSendEmail(params)
              }
            }}
          />
        )
      }
    }
  ]
}
