import React, { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import { columns } from './columns'
import {
  updateSchedule,
  finishSchedule
} from '../../../../services/schedules'
import { useModal } from '../../../../contexts/modal'
import CardModal from '../../../components/CardModal'
import ButtonPrimary from '../../../components/Button'
import { ConfirmModal, FilterContainer } from './styled'
import { customTheme } from '../../../../styles/theme'
import { useAlertBox } from '../../../../contexts/alert_box'
import { updateScheduleUseCase } from '../../../../main/usecases/schedules'
import GenerateForm from '../../../components/layout/GenerateForm'
import { getScheduleStatus, statusList } from '../../../../Utils/schedules'
import { fetchDoctorById, shedulesByDoctorStatusClinicHospital } from '../../../../services/doctor'
import { createNotificationFireFunction } from '../../../../services/Notifications'
import { useLoading } from '../../../../contexts/loading'
import DataTable from '../../../components/DataTable'
import Header from '../../../components/Header'
import MainContainer from '../../../components/MainContainer'

function SchedulesByDoctors (props) {
  const [id] = useState(props.user_id)
  const [offset, setOffset] = useState(0)
  const [items, setItems] = useState([])
  const [selectedFilters, setSelectedFilter] = useState({ status: '' })
  const doctorById = useQuery(fetchDoctorById(), {
    variables: {
      limit: 10,
      offset: 0,
      id: id
    },
    skip: id === null || id === undefined
  })
  const { data, refetch, loading } = useQuery(
    shedulesByDoctorStatusClinicHospital({
      clinic_id: selectedFilters?.clinic?.value?.id || null,
      hospital_id: selectedFilters?.hospital?.value?.id || null
    }),
    {
      variables: {
        status: `%${selectedFilters?.status !== ''
          ? selectedFilters?.status + '%'
          : ''
          }`,
        limit: 10,
        offset: offset * 10,
        doctor_id: id
      },
      pollInterval: 300000,
      skip: id === null || id === undefined,
      onError: () => {
        alertBox.setOptions({
          open: true,
          message: 'Erro ao buscar os dados!',
          type: 'error',
          time: 3000
        })
      }
    }
  )

  const alertBox = useAlertBox()
  const modal = useModal()
  const { setActive } = useLoading()
  const [updateScheduleMutation] = useMutation(updateSchedule())
  const [finishScheduleMutation] = useMutation(finishSchedule())
  const [clinicList, setClinicList] = useState([])
  const [hospitalList, setHospitalList] = useState([])

  useEffect(() => {
    if (data?.schedules) {
      setItems(data?.schedules)
    }
  }, [data])

  useEffect(() => {
    if (id !== null) {
      // If id is not null, execute the query
      refetch()
    }
  }, [])

  useEffect(() => {
    if (doctorById?.data?.doctors?.length) {
      const clinicTemp = [{ label: 'Todas', value: '' }]
      const hospitalTemp = [{ label: 'Todos', value: '' }]

      doctorById?.data?.doctors[0].doctors_working_in_clinics.forEach(
        (clinic) => {
          clinicTemp.push({
            label: clinic?.clinic?.company_name,
            value: {
              ...clinic?.clinic,
              type: 'clinic'
            }
          })
        }
      )

      doctorById?.data?.doctors[0].doctors_working_in_hospitals.forEach(
        (hospital) => {
          hospitalTemp.push({
            label: hospital?.hospital?.company_name,
            value: {
              ...hospital?.hospital,
              type: 'hospital'
            }
          })
        }
      )

      setClinicList(clinicTemp)
      setHospitalList(hospitalTemp)
      setSelectedFilter({ status: '', clinic: null, hospital: null })
    }
  }, [doctorById?.data])

  const onChangePage = (page) => {
    setOffset(page)
  }

  const mutationUpdateSchedule = async (data) => {
    return await updateScheduleUseCase(updateScheduleMutation, data, {
      alertBox
    })
  }

  const mutationFinishSchedule = async (data) => {
    return await updateScheduleUseCase(finishScheduleMutation, data, {
      alertBox
    })
  }

  const handleCancelSchedule = (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '500px' }}>
          <ConfirmModal>
            <h2>Deseja cancelar este agendamento?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  setActive(true)
                  await mutationUpdateSchedule({
                    id: id,
                    status: 'canceled',
                    description:
                      'Seu agendamento foi cancelado, compareça a clinica para ter seu reembolso'
                  })

                  await createNotificationFireFunction({
                    title: 'Seu agendamento não foi aceito ❌',
                    description:
                      'Por favor, confirmar dia e hora com a recepção da clínica via WhatsApp',
                    user_id: params.user_id,
                    payload: {
                      route: '/my_shedules/my_shedule',
                      schedule_id: params.id
                    }
                  })

                  modal.setOptions({ open: false })
                  setActive(false)
                }}
              >
                Cancelar agendamento
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: customTheme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const handleConfirmSchedule = (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '40%' }}>
          <ConfirmModal>
            <h2>Deseja confirmar este agendamento?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  setActive(true)

                  await mutationUpdateSchedule({
                    id: id,
                    status: 'pending_payment',
                    description: 'Agendamento confirmado!'
                  })

                  await createNotificationFireFunction({
                    title: 'Seu agendamento foi aceito 🔔✅',
                    description:
                      'Entre no aplicativo e faça o pagamento para continuar',
                    user_id: params.user_id,
                    payload: {
                      route: '/my_shedules/my_shedule',
                      schedule_id: params.id
                    }
                  })

                  modal.setOptions({ open: false })
                  setActive(false)
                }}
              >
                Confirmar agendamento
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: customTheme.colors.error }}
                onClick={
                  () => {
                    modal.setOptions({ open: false })
                  }
                }
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const handleFinishSchedule = (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '40%' }}>
          <ConfirmModal>
            <h2>Deseja finalizar este agendamento?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  setActive(true)

                  await mutationFinishSchedule({
                    id: id
                  })

                  await createNotificationFireFunction({
                    title: 'Sua solicitação foi finalizada com sucesso ✅',
                    description:
                      'Aproveite e nos conte como foi, faça uma avaliação da consulta ⭐',
                    user_id: params.user_id,
                    payload: {
                      route: '/my_shedules/my_shedule',
                      schedule_id: params.id
                    }
                  })

                  modal.setOptions({ open: false })
                  setActive(false)
                }}
              >
                Finalizar agendamento
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: customTheme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const columnsActions = {
    handleCancelSchedule,
    handleConfirmSchedule,
    handleFinishSchedule
  }

  const handleDataFilter = async (e, actions) => {
    console.log(e)
    actions.setSubmitting(true)
    setSelectedFilter({
      status: e.status?.value,
      clinic: e.clinic || null,
      hospital: e.hospital || null
    })
    actions.setSubmitting(false)

    await refetch()
  }

  return (
    <MainContainer>
      <Header
        title="Agendamentos solicitados (Médicos)"
      />

      <Col xs={12}>
        <FilterContainer>
          <GenerateForm
            onSubmit={handleDataFilter}
            groupFields={[
              {
                fields: [
                  {
                    label: 'Clínica:',
                    type: 'autocomplete',
                    required: true,
                    hideField: false,
                    name: 'clinic',
                    currentValue: {
                      label: selectedFilters?.clinic?.company_name,
                      value: selectedFilters?.clinic?.id
                    },
                    options: clinicList.map((i) => ({
                      label: i.label,
                      value: i.value
                    })),
                    columns: { xs: 3 }
                  },
                  {
                    label: 'Hospital:',
                    type: 'autocomplete',
                    required: true,
                    hideField: false,
                    name: 'hospital',
                    currentValue: {
                      label: selectedFilters?.hospital?.company_name,
                      value: selectedFilters?.hospital?.id
                    },
                    options: hospitalList.map((i) => ({
                      label: i.label,
                      value: i.value
                    })),
                    columns: { xs: 3 }
                  },
                  {
                    label: 'Status:',
                    type: 'autocomplete',
                    required: true,
                    hideField: false,
                    name: 'status',
                    currentValue: {
                      label: getScheduleStatus(selectedFilters?.status),
                      value: selectedFilters?.status
                    },
                    options: statusList.map((item) => ({
                      label: getScheduleStatus(item),
                      value: item
                    })),
                    columns: { xs: 2 }
                  }
                ]
              }
            ]}
            button={{ submit: 'Filtrar', submitting: 'Filtrando' }}
          />

          <ButtonPrimary
            style={{
              boxShadow: 'none',
              fontSize: '14px',
              padding: '5px',
              backgroundColor: customTheme.colors.secondary,
              lineHeight: '2',
              display: 'flex',
              maxWidth: 'calc(25% - 18px)',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              bottom: 0,
              left: 'calc(25% + 6px)'
            }}
            onClick={() => {
              setItems(data?.schedules)
              setSelectedFilter(null)
            }}
          >
            Limpar filtros
          </ButtonPrimary>
        </FilterContainer>
      </Col>

      <br />

      <DataTable
        data={items || []}
        onChangePage={onChangePage}
        loading={loading}
        columns={columns(modal, columnsActions)}
      />
    </MainContainer>
  )
}

export default SchedulesByDoctors
