import * as S from './styled'
import { useQuery } from '@apollo/client'
import { fetchAnamnese } from '../../../../../services/anamnese'
import DataTable from '../../../../components/DataTable'
import { columns } from './columns'
import { useModal } from '../../../../../contexts/modal'

export default function AnamneseList () {
  const { data } = useQuery(fetchAnamnese(), {
    variables: {
      user_id: 1,
      limit: 10,
      offset: 10
    }
  })
  const modal = useModal()

  return (
    <S.Container>
      {data && (
        <DataTable
          data={data.medical_history}
          columns={columns(modal)}
        />
      )}
    </S.Container>
  )
};
