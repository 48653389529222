import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import Avatar from '@material-ui/core/Avatar'
import country from '../../../../Utils/estados_cidades.json'
import moment from 'moment'
import { phoneMask } from '../../../../Utils/format'
import ButtonPrimary from '../../../components/Button'
import { customTheme } from '../../../../styles/theme'
import CardModal from '../../../components/CardModal'
import { ModalConfirm } from '../../hospital/schedules-hospital/styled'
import DataTableActionColumn from '../../../components/DataTableActionColumn'

export const groupFields = (content, setContent) => {
  const cities = []
  const states = []
  const [selectdState, setSelectedState] = useState(null)
  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })
  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e?.target?.value || e }))
    }
  }

  const handleInputChangeAddress = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        adress: { ...prev.adress, [key]: e.target.value }
      }))
    }
  }
  return [
    {
      name: 'Farmácia',
      fields: [
        {
          label: 'Avatar',
          type: 'file',
          required: true,
          disabled: false,
          hideField: false,
          name: 'photo',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido'),
          component: (data) => {
            return (
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <Avatar
                    src={data || content?.photo}
                    style={{ width: 100, height: 100 }}
                  />
                </Col>
              </Row>
            )
          }
        },
        {
          label: 'Nome',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.company_name,
          name: 'company_name',
          onChange: (e) => {
            handleInputChange(e, 'company_name')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },

        {
          label: 'Telefone',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'phone_number')
          },
          currentValue: content?.phone_number,
          hideField: false,
          mask: '+ 99 (99) 9 9999-9999',
          name: 'phone_number',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório').test('phoneNotComplete', 'O campo precisa ser preenchido totalmente!', () => !content?.phone_number?.includes('_'))
        },
        {
          label: 'E-mail',
          type: 'email',
          required: true,
          disabled: false,
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'email')
          },
          currentValue: content?.email || '',
          name: 'email',
          columns: {
            xs: 6
          }
        },
        {
          label: 'Descrição',
          type: 'textarea',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'description')
          },
          currentValue: content?.description,
          hideField: false,
          name: 'description',
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Facebook URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'facebook')
          },
          currentValue: content?.facebook || 'https://',
          hideField: false,
          name: 'facebook',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Instagram URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'instagram')
          },
          currentValue: content?.instagram || 'https://',
          hideField: false,
          name: 'instagram',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Linkedin URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'linkedin')
          },
          currentValue: content?.linkedin || 'https://',
          hideField: false,
          name: 'linkedin',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Link do site',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'website')
          },
          currentValue: content?.website || 'https://',
          hideField: false,
          name: 'website',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Catálogo',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'menu')
          },
          currentValue: content?.menu || 'https://',
          hideField: false,
          name: 'menu',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        }
      ]
    },
    {
      name: 'Endereço',
      fields: [
        {
          label: 'CEP',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'zipcode')
          },
          currentValue: content?.adress?.zipcode,
          hideField: false,
          mask: '99999-999',
          name: 'zipcode',
          columns: {
            xs: 4
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório').test('cepNotComplete', 'O campo precisa ser preenchido totalmente!', () => !content?.adress?.zipcode?.includes('_'))
        },
        {
          label: 'Logradouro',
          type: 'text',
          required: true,
          disabled: false,
          currentValue: content?.adress?.address,
          hideField: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'address')
          },
          name: 'address',
          columns: {
            xs: 8
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Bairro',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'neighborhood')
          },
          currentValue: content?.adress?.neighborhood,
          hideField: false,
          name: 'neighborhood',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Número',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'number')
          },
          currentValue: content?.adress?.number,
          hideField: false,
          name: 'number',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Latitude',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'latitude')
          },
          currentValue: content?.adress?.latitude || '',
          hideField: false,
          name: 'latitude',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Longitude',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'longitude')
          },
          currentValue: content?.adress?.longitude || '',
          hideField: false,
          name: 'longitude',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Estado',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content?.adress?.state
            ? {
                label: content?.adress?.state,
                value: content?.adress?.state_code
              }
            : undefined,
          hideField: false,
          name: 'state',
          onChange: (data) => {
            setSelectedState(data)
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  state: data.label,
                  state_code: data.value
                }
              }))
            }
          },
          options: states,
          columns: {
            xs: 6
          },
          validation: Yup.object().required('Campo obrigatório')
        },
        {
          label: 'Cidade',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content?.adress?.city
            ? {
                label: content?.adress?.city,
                value: content?.adress?.city
              }
            : undefined,
          hideField: false,
          name: 'city',
          columns: {
            xs: 6
          },
          onChange: (data) => {
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  city: data.label
                }
              }))
            }
          },
          options: cities.filter(
            (item) =>
              item.value === selectdState?.value ||
              item.value === content?.adress?.state_code
          ),
          validation: Yup.object().required('Campo obrigatório')
        },

        {
          label: 'Complemento',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'complement')
          },
          currentValue: content?.adress?.complement || '',
          hideField: false,
          name: 'complement',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        }
      ]
    }
  ]
}

const handleDelete = (modal, alertBox, remove, refetch, params) => {
  modal.setOptions({
    open: true,
    component: (
      <CardModal style={{ width: '400px' }}>
        <ModalConfirm>
          <h3>Tem certeza que deseja apagar?</h3>
          <div className="buttons-area">
            <ButtonPrimary
              id="delete-button"
              onClick={async () => {
                try {
                  document.getElementById('delete-button').disabled = true

                  await remove({
                    variables: {
                      id: params.row.id
                    }
                  })
                  await refetch()
                  alertBox.setOptions({
                    open: true,
                    message: 'Sucesso ao apagar!',
                    type: 'success',
                    time: 3000
                  })
                  modal.setOptions({ open: false })
                } catch (error) {
                  alertBox.setOptions({
                    open: true,
                    message: 'Erro Ao apagar Clinica',
                    type: 'error',
                    time: 3000
                  })
                  console.error(error)
                  modal.setzOptions({ open: false })
                }
              }}
            >
              Sim
            </ButtonPrimary>

            <ButtonPrimary
              onClick={() => modal.setOptions({ open: false })}
              style={{
                backgroundColor: customTheme.colors.error,
                boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`
              }}
            >
              Não
            </ButtonPrimary>
          </div>
        </ModalConfirm>
      </CardModal>
    )
  })
}

export const columns = (modal, alertBox, remove, refetch, history) => {
  return [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: 'photo',
      headerName: 'Foto',
      width: 60,
      editable: true,
      renderCell: (params) => {
        return (
          <Col className="d-flex justify-content-center">
            {params.row.photo ? (
              <Avatar src={params.row.photo} />
            ) : (
              <Avatar>{params?.row?.user?.full_name[0]}</Avatar>
            )}
          </Col>
        )
      }
    },
    {
      field: 'phone_number',
      headerName: 'Telefone',
      width: 200,
      editable: true,
      valueGetter: (params) => `${phoneMask(params.row.phone_number) || ''} `
    },
    {
      field: 'company_name',
      headerName: 'Nome',
      width: 200,
      editable: true
    },
    {
      field: 'description',
      headerName: 'Descrição',
      width: 250,
      editable: true
    },
    {
      field: 'created_at',
      headerName: 'Data de Cadastro',
      sortable: true,
      width: 150,
      valueGetter: (params) =>
        `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
      valueFormatter: (params) =>
        moment(params.value).format('DD/MM/YYYY - HH:MM')
    },
    {
      field: 'id',
      headerName: '',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <DataTableActionColumn
            handleEdit={() => history.push(`/pharmacies/${params.row.id}`)}
            handleDelete={() => handleDelete(modal, alertBox, remove, refetch, params)}
          />
        )
      }
    }
  ]
}

export const filterUsers = (filter, setFilter) => {
  const cities = []
  const states = []
  country.estados
    .filter((item) => item.sigla === filter.state.value)
    .forEach((item) => {
      item.cidades.forEach((city) =>
        cities.push({ label: city, value: item.sigla })
      )
    })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })
  return [
    {
      name: 'Filtrar os Resultados',
      fields: [
        {
          label: 'Estado',
          type: 'autocomplete',
          required: true,
          disabled: false,
          hideField: false,
          name: 'state',
          onChange: (data) => {
            setFilter((prev) => ({
              ...prev,
              state: data
            }))
          },
          options: states,
          columns: {
            xs: 3
          },
          validation: Yup.object()
        },
        {
          label: 'Cidade',
          type: 'autocomplete',
          required: true,
          disabled: false,
          hideField: false,
          name: 'city',
          onChange: (data) => {
            setFilter((prev) => ({
              ...prev,
              city: data
            }))
          },
          options: cities,
          columns: {
            xs: 3
          },
          validation: Yup.object()
        }
      ]
    }
  ]
}
