import { hourAndMinuteClear } from '../../../../../Utils/format'
import { getScheduleStatus } from '../../../../../Utils/schedules'

export const groupFields = (values) => {
  const formatedStartTime = hourAndMinuteClear(values.start_time)
  const formatedEndTime = hourAndMinuteClear(values.end_time)
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
  const appointmentTime = new Date(values?.day)
  const formatedDate = `${appointmentTime.toLocaleString('pt-BR', options)}`
  const status = getScheduleStatus(values.status)

  return [
    {
      fields: [
        {
          label: 'Tipo de atendimento',
          type: 'text',
          disabled: true,
          currentValue:
            values.type_of_medical_appointment.toLowerCase() === 'personal'
              ? 'Presencial'
              : 'Online',
          columns: {
            xs: 3
          }
        },
        {
          label: 'Dia do atendimento',
          type: 'text',
          disabled: true,
          currentValue: formatedDate,
          columns: {
            xs: 3
          }
        },
        {
          label: 'Horário de Início',
          type: 'text',
          disabled: true,
          currentValue: formatedStartTime,
          columns: {
            xs: 3
          }
        },
        {
          label: 'Horário de Término',
          type: 'text',
          disabled: true,
          currentValue: formatedEndTime,
          columns: {
            xs: 3
          }
        },
        {
          label: 'Descrição',
          type: 'text',
          disabled: true,
          currentValue: values.description,
          columns: {
            xs: 6
          }
        },
        {
          label: 'Status',
          type: 'text',
          disabled: true,
          currentValue: status,
          columns: {
            xs: 6
          }
        }
      ]
    }
  ]
}
