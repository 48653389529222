// import api from "../api/api";
import { gql } from '@apollo/client'

export const fetchAssessmentCalls = () => {
  return gql`
  query getShedules {
    schedules(order_by: {created_at: desc}, limit: 1000) {
      id
      user {
        full_name
        receita_digital_id
        cpf
      }
      doctor{
        full_name
        receita_digital_id
        receita_digital_infos
        cpf
      }
      doctors_appointment {
        doctor {
          full_name
          receita_digital_id
          receita_digital_infos
          cpf
        }
        clinic {
          company_name
          adress {
            city
            state_code
          }
        }
        hospital {
          company_name
          adress {
            city
            state_code
          }
        }
      }
      susAppoitments {
        su {
          name
          adress {
            city
            state_code
          }
        }
      }
      status
      created_at
      description
    }
  }
  `
}
