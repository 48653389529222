import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { columns } from './columns'
import {
  updateSchedule,
  fetchSchedulesByClinic,
  finishSchedule
} from '../../../../services/schedules'
import { useModal } from '../../../../contexts/modal'
import CardModal from '../../../components/CardModal'
import ButtonPrimary from '../../../components/Button'
import { ConfirmModal, FilterContainer } from './styled'
import { customTheme } from '../../../../styles/theme'
import { useAlertBox } from '../../../../contexts/alert_box'
import { updateScheduleUseCase } from '../../../../main/usecases/schedules'
import GenerateForm from '../../../components/layout/GenerateForm'
import { getScheduleStatus, onlyUnique, statusList } from '../../../../Utils/schedules'
import { createNotificationFireFunction } from '../../../../services/Notifications'
import { useLoading } from '../../../../contexts/loading'
import DataTable from '../../../components/DataTable'
import Header from '../../../components/Header'
import MainContainer from '../../../components/MainContainer'

function SchedulesByClinics (props) {
  const [id] = useState(props.user_id)
  const [myList, setMyList] = useState([])
  const [pageSize] = useState(10)
  const [offset, setOffset] = useState(0)
  const [selectedFilters, setSelectedFilter] = useState({ status: '' })
  const { loading, data, refetch } = useQuery(fetchSchedulesByClinic(), {
    variables: {
      status: `%${selectedFilters?.status !== ''
        ? selectedFilters?.status + '%'
        : ''
        }`,
      clinic_id: id,
      limit: pageSize,
      offset: (offset) * pageSize
    },
    skip: !id,
    onError: () => {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar os dados!',
        type: 'error',
        time: 3000
      })
    }
  })

  const alertBox = useAlertBox()
  const modal = useModal()
  const [updateScheduleMutation] = useMutation(updateSchedule())
  const [finishScheduleMutation] = useMutation(finishSchedule())

  const [filterDoctors] = useState([])

  const { setActive } = useLoading()

  useEffect(() => {
    console.log('data', data)
    if (data?.schedules) {
      setMyList(data.schedules)
    }
  }, [data])

  const mutationUpdateSchedule = async (data) => {
    return await updateScheduleUseCase(updateScheduleMutation, data, {
      alertBox
    })
  }

  const mutationFinishSchedule = async (data) => {
    return await updateScheduleUseCase(finishScheduleMutation, data, {
      alertBox
    })
  }

  const handleCancelSchedule = (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '500px' }}>
          <ConfirmModal>
            <h2>Deseja cancelar este agendamento?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  setActive(true)
                  await mutationUpdateSchedule({
                    id: id,
                    status: 'canceled',
                    description:
                      'Seu agendamento foi cancelado, compareça a clinica para ter seu reembolso'
                  })

                  await createNotificationFireFunction({
                    title: 'Seu agendamento não foi aceito ❌',
                    description:
                      'Por favor, confirmar dia e hora com a recepção da clínica via WhatsApp',
                    user_id: params.user_id,
                    payload: {
                      route: '/my_shedules/my_shedule',
                      shedule_id: params.id
                    }
                  })

                  modal.setOptions({ open: false })
                  refetch()
                  setActive(false)
                }}
              >
                Cancelar agendamento
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: customTheme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const handleConfirmSchedule = (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '40%' }}>
          <ConfirmModal>
            <h2>Deseja confirmar este agendamento?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  setActive(true)

                  await mutationUpdateSchedule({
                    id: id,
                    status: 'pending_payment',
                    description: 'Agendamento confirmado!'
                  })

                  modal.setOptions({ open: false })

                  await createNotificationFireFunction({
                    title: 'Seu agendamento foi aceito 🔔✅',
                    description:
                      'Entre no aplicativo e faça o pagamento para continuar',
                    user_id: params.user_id,
                    payload: {
                      route: '/my_shedules/my_shedule',
                      shedule_id: params.id
                    }
                  })
                  refetch()
                  setActive(false)
                }}
              >
                Confirmar agendamento
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: customTheme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const handleFinishSchedule = (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '40%' }}>
          <ConfirmModal>
            <h2>Deseja finalizar este agendamento?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  setActive(true)
                  await mutationFinishSchedule({
                    id: id
                  })

                  await createNotificationFireFunction({
                    title: 'Sua solicitação foi finalizada com sucesso ✅',
                    description:
                      'Aproveite e nos conte como foi, faça uma avaliação da consulta ⭐',
                    user_id: params.user_id,
                    payload: {
                      route: '/my_shedules/my_shedule',
                      shedule_id: params.id
                    }
                  })

                  modal.setOptions({ open: false })
                  refetch()
                  setActive(false)
                }}
              >
                Finalizar agendamento
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: customTheme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const columnsActions = {
    handleCancelSchedule,
    handleConfirmSchedule,
    handleFinishSchedule
  }

  const handleFilter = async (e, actions) => {
    actions.setSubmitting(true)
    setSelectedFilter((prev) => ({ ...prev, status: e.status.value }))
    actions.setSubmitting(false)
    await refetch()
  }

  const onChangePage = (page) => {
    setOffset(page)
  }

  return (
    <MainContainer>
      <Header
        title="Agendamentos solicitados (Clínicas)"
      />

      <FilterContainer>
        <GenerateForm
          onSubmit={handleFilter}
          groupFields={[
            {
              fields: [
                {
                  label: 'Paciente:',
                  type: 'autocomplete',
                  required: true,
                  hideField: false,
                  name: 'patient',
                  currentValue: {
                    label: selectedFilters?.patient?.full_name,
                    value: selectedFilters?.patient?.id
                  },
                  options: selectedFilters?.patient
                    ?.filter(onlyUnique)
                    ?.map((item) => ({
                      label: item.full_name,
                      value: item
                    })),
                  columns: { xs: 3 }
                },
                {
                  label: 'Médico:',
                  type: 'autocomplete',
                  required: true,
                  hideField: false,
                  name: 'doctor',
                  currentValue: {
                    label: selectedFilters?.doctor?.full_name,
                    value: selectedFilters?.doctor?.id
                  },
                  options: filterDoctors
                    .filter(onlyUnique)
                    ?.map((item) => ({
                      label: item.full_name,
                      value: item
                    })),
                  columns: { xs: 3 }
                },
                {
                  label: 'Status:',
                  type: 'autocomplete',
                  required: true,
                  hideField: false,
                  name: 'status',
                  currentValue: {
                    label: getScheduleStatus(selectedFilters?.status),
                    value: selectedFilters?.status
                  },
                  options: statusList.map((item) => ({
                    label: getScheduleStatus(item),
                    value: item
                  })),
                  columns: { xs: 3 }
                }
              ]
            }
          ]}
          button={{ submit: 'Filtrar', submitting: 'Filtrando' }}
        />
        <ButtonPrimary
          style={{
            boxShadow: 'none',
            fontSize: '14px',
            padding: '5px',
            backgroundColor: customTheme.colors.secondary,
            lineHeight: '2',
            display: 'flex',
            maxWidth: 'calc(25% - 18px)',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: 0,
            left: 'calc(25% + 6px)'
          }}
          onClick={() => {
            setMyList(data?.schedules)
            setSelectedFilter(null)
          }}
        >
          Limpar filtros
        </ButtonPrimary>
      </FilterContainer>

      <br />

      <DataTable
        data={myList}
        onChangePage={onChangePage}
        loading={loading}
        columns={columns(modal, columnsActions)}
        totalItems={data?.payments_aggregate?.aggregate?.count || 0}
      />
    </MainContainer>
  )
}

export default SchedulesByClinics
