import React from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Card, Col, Container, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import logo from '../../../../assets/images/logos/vertical_logo.png'
import GenerateForm from '../../../components/layout/GenerateForm'
import { customTheme } from '../../../../styles/theme'
import { AuthService } from '../../../../services/auth/auth'
import { useAlertBox } from '../../../../contexts/alert_box'
import translate from '../../../../translate'

const auth = new AuthService()

function Login () {
  const alertBox = useAlertBox()
  const history = useHistory()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const groupFields = [
    {
      name: 'Login',
      fields: [
        {
          label: 'Login',
          type: 'email',
          required: true,
          disabled: false,
          currentValue: '',
          hideField: false,
          name: 'username',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido')
            .email()
            .required('E-mail é um campo obrigatório')
        },
        {
          label: 'Senha',
          type: 'password',
          required: true,
          currentValue: '',
          disabled: false,
          hideField: false,
          name: 'password',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido')
            .min(6, 'Mínimo de 6 caracteres')
            .required('Senha é um campo obrigatório')
        }
      ]
    }
  ]

  const handleLogin = async (e, actions) => {
    try {
      await auth.signIn(e.username, e.password) // dispatch the context of authetication automatically
      actions.setSubmitting(false)

      const redirectSearch = searchParams.get('redirect')
      const redirect = redirectSearch ? atob(redirectSearch) : '/'

      history.push(redirect)
    } catch (error) {
      console.error('ERROR ', error?.message)
      alertBox.setOptions({
        open: true,
        message: translate(error?.message),
        type: 'error',
        time: 3000
      })
      actions.setSubmitting(false)
    }
  }

  return (
    <Container className="h-100">
      <Row className="justify-content-center align-items-center h-100">
        <Col xs={12} md={8}>
          <Card
            className="p-3"
            style={{ backgroundColor: customTheme.colors.backgroudForm }}
          >
            <Row className="d-flex justify-content-center">
              <Col
                md={6}
                xs={5}
                className="align-items-center d-flex justify-content-center"
              >
                <img width="80%" alt="smart-life" src={logo} />
              </Col>
              <Col md={6}>
                <GenerateForm
                  onSubmit={handleLogin}
                  groupFields={groupFields}
                  button={{ submit: 'Entrar', submitting: 'Entrando' }}
                />
                <p className="justify-content-center d-flex w-100 mb-0">
                  Seu primeiro acesso ou esqueceu sua senha?
                </p>

                <Link
                  className="justify-content-center d-flex w-100"
                  to="/reset-password"
                >
                  Clique aqui!
                </Link>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
