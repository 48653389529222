import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  min-width: 250px;
  padding: 15px;
  gap: 5%;
  border-radius: 18px;
  box-shadow: rgba(13, 105, 198, 0.06) 4px 14px 10px 0px, rgba(13, 105, 198, 0.06) -2px 0px 4px 0px, rgba(13, 105, 198, 0.06) 4px -4px 10px 0px;
`

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.$color + '40'};
  color: ${props => props.$color};
  width: 52px;
  height: 52px;
  font-size: 2rem;

  border-radius: 50%;
`

export const Content = styled.label`
  display: flex;
  flex-direction: column;
`

export const Title = styled.label`
  font-size: 12px;
  color: rgb(36, 143, 125);
`

export const Number = styled.h3`
  font-weight: bold;
`

export const SubTitle = styled.label`
  font-weight: 500;
  font-size: 11px;
  text-align: center;
`
