import React, { useContext, useState, createContext } from 'react'

const ModalContext = createContext()

const ModalProvider = (props) => {
  const [options, setOptions] = useState({
    open: false,
    type: 'success',
    time: 2000
  })

  return (
    <ModalContext.Provider
      value={{
        options,
        setOptions
      }}
    >
      {props.children}
    </ModalContext.Provider>
  )
}

export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) { throw new Error('useModal must be used within a provider ModalProvider') }

  return { ...context }
}

export default ModalProvider
