import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { useQuery } from '@apollo/client'
import { fetchClinicsFull } from '../../../../services/clinics'
import { columns } from './columns'
import { useModal } from '../../../../contexts/modal'
import Loading from '../../../components/layout/Loading'

function SchedulesClinic () {
  const [perPage, setPerPage] = useState(5)
  const { loading, data } = useQuery(fetchClinicsFull())
  const modal = useModal()

  if (loading) return <Loading />
  return (
    <>
      <Row>
        <Col xs={12}>
          <h2>Horários disponíveis - Clínicas</h2>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          {data?.clinics && (
            <div style={{ height: 700, width: '100%' }}>
              <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={data.clinics}
                columns={columns(modal)}
                pageSize={perPage}
                onPageSizeChange={(e) => {
                  setPerPage(e)
                }}
                rowsPerPageOptions={[10]}
                pagination
                disableSelectionOnClick={false}
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

export default SchedulesClinic
