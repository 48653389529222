import { AuthService } from '../../../services/auth/auth'
import { createBrowserHistory } from 'history'

export const createDoctorUseCase = async (
  { email, ...params },
  extras,
  mutationAddDoctorClinic,
  paramEntityParent
) => {
  const auth = new AuthService()
  const history = createBrowserHistory({ forceRefresh: true })
  try {
    const cepWithUnderscore = params.zipcode
    const formattedCep = cepWithUnderscore.replace(/_/g, '')

    const data = {
      account_entity_id: params.account_entity_id,
      account_info: params.account_info,
      about: params.about,
      average_cost_of_medical_consultation:
      params.average_cost_of_medical_consultation,
      crm: params.crm,
      full_name: params.full_name,
      linkedin: params.linkedin,
      main_specialty: params.main_specialty,
      profile_image: params.profile_image,
      email: email,
      zipcode: formattedCep,
      address: params.address,
      city: params.city,
      complement: params.complement,
      neighborhood: params.neighborhood,
      number: params.number,
      state: params.state,
      state_code: params.state_code,
      rg_uf: params.rg_uf,
      rg_number: params.rg_number,
      sex: params.sex,
      birthdate: params.birthdate,
      type_of_account: 'doctor',
      cpf: params.cpf,
      phone_number: params.phone_number,
      specialties: params.specialties
    }

    console.log(data)

    function replaceUndefinedWithNull (obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === undefined) {
          obj[key] = null
        }
      }
    }

    replaceUndefinedWithNull(data)
    console.log(JSON.stringify(data, null, 2))

    const res = await auth.createAccount(data)
    console.log(res)

    await auth.recoveryPassword(email)

    history.goBack()
  } catch (error) {
    const message = error?.response?.data?.error ?? 'Erro ao criar médico!'
    extras.actions.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: message,
      type: 'error',
      time: 3000
    })
  } finally {
    await auth.recoveryPassword(email)
  }
}

export const updateDoctorUseCase = async (
  { email, ...params },
  extras,
  mutationAddDoctorClinic,
  paramEntityParent
) => {
  const auth = new AuthService()
  try {
    const data = params

    function replaceUndefinedWithNull (obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === undefined) {
          obj[key] = null
        }
      }
    }

    replaceUndefinedWithNull(data)

    const res = await auth.updateDoctor(data)

    return { sucess: true, data: res.data.data }
  } catch (error) {
    const message = error?.response?.data?.error ?? 'Erro ao atualizar médico!'
    extras.actions.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: message,
      type: 'error',
      time: 3000
    })
    return { sucess: false }
  }
}

export const updateMedicalEspeciality = async (mutation, params, extras) => {
  try {
    await mutation({
      variables: params
    })
    extras.actions.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: 'Atualizado com Sucesso!',
      type: 'success',
      time: 3000
    })
    extras.modal.setOptions({ open: false })
  } catch (error) {
    console.error(error)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro Ao atualizar!',
      type: 'error',
      time: 3000
    })
  }
}

export const createMEdialEspecialist = async (mutation, params, extras) => {
  try {
    await mutation({
      variables: params
    })
    extras.alertBox.setOptions({
      open: true,
      message: 'Atualizado com Sucesso!',
      type: 'success',
      time: 3000
    })
    extras.actions.setSubmitting(false)
    extras.modal.setOptions({ open: false })
  } catch (error) {
    console.error(error)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro Ao atualizar!',
      type: 'error',
      time: 3000
    })
  }
}
