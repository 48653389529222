import axios from 'axios'
import { AuthService } from '../auth/auth'

export const createNotificationFireFunction = async (body) => {
  const authService = new AuthService()
  const res = await axios.post(
    process.env.REACT_APP_FUNCTIONS_URL + '/createNotification',
    body,
    {
      headers: {
        Authorization: `Bearer ${await authService.getToken()}`
      }
    }
  )

  return res
}
