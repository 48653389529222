import { useMutation, useQuery } from '@apollo/client'
import GenerateForm from '../../../components/layout/GenerateForm'
import { fecthEspecialities } from '../../../../services/medicalSpecialty'
import { createSusWaitingRoom, fetchSusWaitingRoomById, updateConsutationRoom, insertMedicalSpecialty, removeMedicalSpecialty } from '../../../../services/sus'
import Header from '../../../components/Header'
import Loading from '../../../components/layout/Loading'
import { genericCreateFetch, genericFetch } from '../../../../main/usecases/generic'
import { useAlertBox } from '../../../../contexts/alert_box'
import { groupFields } from './fields'
import { useModal } from '../../../../contexts/modal'
import * as S from './styled'

const EditWaitingRoom = ({ id, susId }) => {
  const alertBox = useAlertBox()
  const modal = useModal()

  const [mutationAddWaitingRoom] = useMutation(createSusWaitingRoom())
  const [mutationUpdateConsutationRoom] = useMutation(updateConsutationRoom())
  const [mutationInsertMedicalSpecialty] = useMutation(insertMedicalSpecialty())
  const [mutationRemoveMedicalSpecialty] = useMutation(removeMedicalSpecialty())
  const { loading: loadingEspecialities, data: especialities } = useQuery(fecthEspecialities(), {})
  const { loading, data } = useQuery(fetchSusWaitingRoomById(), {
    variables: {
      id
    }
  })

  const mergeSpecialties = (newsSpecialties) => {
    const especialties = data.consultation_room[0].consultation_room_medical_specialties

    // filter all to remove -> filter in consultation_room all that isn't in newsSpecialties
    const toRemove = especialties
      .filter(item => !newsSpecialties.find(news => news.value === item.medical_specialty.id))

    // filter all to add -> filter in newsSpecialties all that isn't in consultation_room
    const toAdd = newsSpecialties.filter(item => !especialties.find(olds => olds.medical_specialty.id === item.value))

    if (toRemove.length > 0) {
      toRemove.forEach(item => {
        mutationRemoveMedicalSpecialty({
          variables: { id: item.id }
        })
      })
    }

    if (toAdd.length > 0) {
      toAdd.forEach(item => {
        mutationInsertMedicalSpecialty({
          variables: {
            consultation_room_id: id,
            medical_specialty_id: item.value
          }
        })
      })
    }
  }

  const handleSubmit = async (values, actions) => {
    if (!id) { // create
      await genericCreateFetch(
        mutationAddWaitingRoom,
        {
          sus_id: susId,
          available_vacancies: values.available_vacancies,
          end_time: values.end_time,
          medical_specialties: values.consultation_room_medical_specialties.map(item => ({ medical_specialty_id: item.value })),
          tolerance_to_enter_the_room_in_minutes: values.tolerance_to_enter_the_room_in_minutes
        },
        {
          actions,
          alertBox
        }
      )
    } else { // edit
      mergeSpecialties(values.consultation_room_medical_specialties)

      await genericFetch(
        mutationUpdateConsutationRoom,
        {
          id: id,
          available_vacancies: values.available_vacancies,
          end_time: values.end_time,
          tolerance_to_enter_the_room_in_minutes: values.tolerance_to_enter_the_room_in_minutes
        },
        {
          actions,
          alertBox
        }
      )
    }

    modal.setOptions({ open: false })
  }

  return (
    <S.Wrapper>
      <Header title={!id ? 'Adicionar Sala' : 'Editar Sala'} />

      {loading || loadingEspecialities
        ? (
          <Loading />
          )
        : (
          <GenerateForm
            onSubmit={handleSubmit}
            groupFields={groupFields(
              data?.consultation_room[0],
              especialities?.medical_specialties
            )}
            button={{
              submit: !id ? 'Adicionar' : 'Atualizar',
              submitting: !id ? 'Adicionando...' : 'Atualizando...'
            }}
          />
          )}

    </S.Wrapper>
  )
}

export default EditWaitingRoom
