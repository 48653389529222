import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Header from '../../../components/Header'
import ListWaitingRooms from '../../master/list-waiting-rooms'
import * as S from './styled'

const WaitingRoom = ({ match, location }) => {
  const susId = match.params.id
  const doctorId = location.state.doctorId
  const history = useHistory()

  const handleShowRoom = (params) => {
    history.push(`/doctor/waiting-room/${params.row.id}`)
  }

  return (
    <S.Wrapper>
      <Header title="Salas de atendimento" />

      <ListWaitingRooms
        susId={susId}
        doctorId={doctorId}
        actions={{
          handleView: handleShowRoom
        }}
      />
    </S.Wrapper>
  )
}

export default WaitingRoom
