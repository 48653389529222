import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 5px;
  gap: 5px;
`

export const ModalContainer = styled.div`
  .button-submit-generate-form-container {
    display: none;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;

    button {
      width: 30%;
    }
  }
`
