import 'leaflet/dist/leaflet.css'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import { useHistory } from 'react-router'
import EditIcon from '@mui/icons-material/Edit'
import { Col, Row } from 'react-bootstrap'
import Leaflet from 'leaflet'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { useAlertBox } from '../../../../contexts/alert_box'
import {
  fetchClinicsById,
  inserDoctorInClinic,
  removeDoctorInClinic,
  updateClinics
} from '../../../../services/clinics'
import InternalBody from '../../../components/InternalBody'
import GenerateForm from '../../../components/layout/GenerateForm'
import { groupFields } from './columns'
import { updateAddress } from '../../../../services/address'
import { createClinicUseCase, updateClinicUseCase } from '../../../../main/usecases/clinics'
import { updateAddressUseCase } from '../../../../main/usecases/addressess'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../services/firebase/firebase-config'
import ButtonPrimary from '../../../components/Button'
import { customTheme } from '../../../../styles/theme'
import CardModal from '../../../components/CardModal'
import { useModal } from '../../../../contexts/modal'
import ManageDoctors from '../manageDoctors/ManageDoctors'
import {
  createCompanyPlan,
  updateCompanyPlan
} from '../../../../services/companyPlans'
import { genericFetch } from '../../../../main/usecases/generic'
import mapPin from '../../../../assets/svg/pin.svg'
import country from '../../../../Utils/estados_cidades.json'
import { AuthService } from '../../../../services/auth/auth'
import { CustomStyle } from './styled'
import { coordIsValid } from '../../../../Utils/coordValidate'
import { updateDoctorUseCase } from '../../../../main/usecases/doctors'
import { fetchAddressByZipCode } from '../../../../services/searchZip/searchZipcode'

function InternalClinic ({ match }) {
  const history = useHistory()
  const alertBox = useAlertBox()
  const modal = useModal()

  const [mutationAddDoctor] = useMutation(inserDoctorInClinic())
  const [mutationRemoveDoctor] = useMutation(removeDoctorInClinic())
  const [mutateFunction] = useMutation(updateClinics())
  const [createCompanymutation] = useMutation(createCompanyPlan())
  const [updateCompanymutation] = useMutation(updateCompanyPlan())
  const [mutateFunctionAdress] = useMutation(updateAddress())

  const [content, setContent] = useState({})
  const [isAdmin, setIsAdmin] = useState(match.path === '/clinics/:id')
  const [isFirstRun, setIsFirtsRun] = useState(true)

  const { data } = useQuery(fetchClinicsById(), {
    variables: { limit: 10, offset: 0, id: match.params.id }
  })

  const states = []
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  useEffect(() => {
    setIsAdmin(match.path === '/clinics/:id')
  }, [match])

  useEffect(() => {
    if (data?.clinics[0]) {
      setContent(data?.clinics[0])
    }
  }, [data])

  useEffect(() => {
    if (content?.adress?.zipcode && !isFirstRun) {
      fetchAddressByZipCode(
        content?.adress?.zipcode,
        content,
        setContent,
        states,
        alertBox
      ).then(() => { })
    }

    if (data?.clinics[0].adress?.zipecode !== content?.adress?.zipcode) {
      setIsFirtsRun(false)
    }
  }, [content?.adress?.zipcode])

  const mutationCreateClinic = async (e, url, actions) => {
    const res = await createCompanymutation({
      variables: {
        account: '',
        agency: '',
        chave_pix: '',
        cpf: '',
        transfer_of_interest_to_the_customer:
          e?.transfer_of_interest_to_the_customer || false,
        plan_id: e.plan_id.value,
        bank_id: e.bank_id,
        codigo_split: e.codigo_split
      }
    })

    await createClinicUseCase(
      {
        email: e.email,
        address: e.address,
        city: e.city.label,
        state: e.state.label,
        state_code: e.state.value,
        complement: e.complement,
        neighborhood: e.neighborhood,
        number: e.number,
        zipcode: e.zipcode,
        latitude: e.latitude || content.latitude,
        longitude: e.longitude || content.longitude,
        cnpj: e.cnpj,
        company_name: e.company_name,
        description: e.description,
        facebook: e.facebook,
        website: e.website,
        instagram: e.instagram,
        linkedin: e.linkedin,
        phone_number: e.phone_number,
        photo: url || '',
        company_plan_id: res.data.insert_company_plan_one.id
      },
      {
        actions,
        alertBox
      }
    )
  }

  const mutationUpdateClinic = async (e, url, actions) => {
    if (isAdmin) {
      await genericFetch(
        updateCompanymutation,
        {
          id: content.company_plan.id,
          account: '',
          agency: '',
          chave_pix: '',
          cpf: '',
          transfer_of_interest_to_the_customer:
            e?.transfer_of_interest_to_the_customer || false,
          plan_id: e.plan_id.value,
          bank_id: e.bank_id,
          codigo_split: e.codigo_split
        },
        {
          actions,
          alertBox
        }
      )
    }

    const addressData = {
      id: content.adress.id,
      address: e.address,
      complement: e.complement,
      latitude: e.latitude,
      longitude: e.longitude,
      neighborhood: e.neighborhood,
      number: e.number,
      city: e.city.label,
      state: e.state.label,
      state_code: e.state.value,
      zipcode: e.zipcode
    }

    await updateAddressUseCase(
      mutateFunctionAdress,
      addressData,
      {
        actions,
        alertBox
      }
    )
    await updateClinicUseCase(
      mutateFunction,
      {
        id: match.params.id,
        cnpj: e.cnpj,
        email: e.email,
        company_name: e.company_name,
        description: e.description,
        facebook: e.facebook,
        website: e.website,
        instagram: e.instagram,
        linkedin: e.linkedin,
        phone_number: e.phone_number,
        photo: url || content.photo,
        company_plan_id: content.company_plan.id
      },
      {
        actions,
        alertBox
      }
    )
  }

  const mapPinIcon = Leaflet.icon({
    iconUrl: mapPin,
    iconSize: [58, 68],
    iconAnchor: [29, 68],
    popupAnchor: [170, 2]
  })

  const onSubmit = async (e, actions) => {
    if (window.location.href.includes('new')) {
      if (e.file) {
        const storageRef = ref(storage, `clinics/${new Date().getTime()}`)
        const uploadTask = uploadBytesResumable(storageRef, e.file)

        uploadTask.on('state_changed', null, console.error, () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            await mutationCreateClinic(e, url, actions)
          })
        })
      } else {
        await mutationCreateClinic(e, null, actions)
      }
    } else {
      if (e.file) {
        const storageRef = ref(storage, `clinics/${new Date().getTime()}`)
        const uploadTask = uploadBytesResumable(storageRef, e.file)

        uploadTask.on('state_changed', null, console.error, () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            await mutationUpdateClinic(e, url, actions)
          })
        })
      } else {
        await mutationUpdateClinic(e, null, actions)
      }
    }

    history.goBack()
  }

  const handleSubmit = async (e, actions) => {
    await genericFetch(
      mutationAddDoctor,
      {
        clinic_id: match.params.id,
        doctor_id: e.doctor.value
      },
      {
        alertBox,
        modal,
        actions
      }
    )
    await updateDoctorUseCase({ id: e.doctor.value })
  }

  const handleRemove = async (doctorId) => {
    await genericFetch(
      mutationRemoveDoctor,
      {
        clinic_id: match.params.id,
        doctor_id: doctorId
      },
      {
        alertBox,
        modal
      }
    )
  }

  const handleNewDoctor = () => {
    history.push('/doctors/new', { account_info: 'clinic', account_entity_id: match.params.id })
    modal.setOptions({
      open: false
    })
  }

  const handleEdit = (paramsRow) => {
    modal.setOptions({ open: false })
    history.push(`/doctors/${paramsRow.row.id}`)
  }

  const handleSchedules = (paramsRow) => {
    modal.setOptions({ open: false })
    history.push(
      `/doctor-available-schedules/${match.params.id}/${paramsRow.row.id}`
    )
  }

  const handleSendEmail = async (paramsRow) => {
    try {
      const auth = new AuthService()
      await auth.recoveryPassword(paramsRow?.row?.email)
      alertBox.setOptions({
        open: true,
        message: 'Enviado com Sucesso!',
        type: 'success',
        time: 3000
      })
    } catch (error) {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao enviar',
        type: 'error',
        time: 3000
      })
      console.error(error)
    }
  }

  const getResponse = (data) => {
    if (!data) return null

    if (!data?.clinics?.length) return null

    return data?.clinics[0].doctors_working_in_clinics
  }

  return (
    <>
      <CustomStyle>
        <InternalBody>
          <p>
            Criado em:{' '}
            {moment(content?.created_at).format('DD/MM/YYYY HH:MM') || ''}
          </p>
          {match.params.id !== 'new' && (
            <Row>
              <Col xs={12}>
                <ButtonPrimary
                  onClick={() => {
                    modal.setOptions({
                      open: true,
                      component: (
                        <CardModal style={{ width: '90%' }}>
                          <ManageDoctors
                            query={fetchClinicsById()}
                            params={{
                              clinic_id: match.params.id
                            }}
                            queryKey="id"
                            objectKey="clinic_id"
                            onSubmit={handleSubmit}
                            onRemove={handleRemove}
                            newDoctor={{
                              title: 'Adicionar Novo Médico á essa clínica',
                              action: handleNewDoctor
                            }}
                            actions={{
                              handleEdit,
                              handleSchedules,
                              handleSendEmail
                            }}
                            getResponse={getResponse}
                          />
                        </CardModal>
                      )
                    })
                  }}
                  style={{
                    marginBottom: '15px',
                    marginTop: '10px',
                    boxShadow: 'none',
                    fontSize: '12px',
                    padding: '5px',
                    backgroundColor: customTheme.colors.primary,
                    lineHeight: '2',
                    maxWidth: 150,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  Gerenciar médicos
                  <EditIcon
                    style={{ color: customTheme.colors.white, marginLeft: 5 }}
                  />
                </ButtonPrimary>
              </Col>
            </Row>
          )}
          <GenerateForm
            onSubmit={onSubmit}
            groupFields={groupFields(content, setContent, isAdmin)}
            button={{
              submit: match.params.id === 'new' ? 'Adicionar' : 'Atualizar',
              submitting:
                match.params.id === 'new' ? 'Adicionando...' : 'Atualizando...'
            }}
          />
          {coordIsValid(content.adress?.latitude) && coordIsValid(content.adress?.longitude) && (
            <Col xs={12} style={{ height: '250px' }}>
              <MapContainer
                center={[content.adress?.latitude, content.adress?.longitude]}
                style={{ width: '100%', height: '100%', maxHeight: '250px' }}
                zoom={15}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  icon={mapPinIcon}
                  position={[
                    content.adress?.latitude || -14.8567487,
                    content.adress?.longitude || -40.8414804
                  ]}
                >
                  <Popup>
                    A pretty CSS3 popup. <br /> Easily customizable.
                  </Popup>
                </Marker>
              </MapContainer>
            </Col>
          )}
        </InternalBody>
      </CustomStyle>
    </>
  )
}

export default InternalClinic
