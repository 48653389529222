import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { doctorCounts } from '../../../../services/doctor/index'
import Loading from '../../../components/layout/Loading'
import DashboardBox from '../../../components/DashboardBox'
import * as S from './styled'
import ScheduleIcon from '@mui/icons-material/Schedule'
import MoneyIcon from '@mui/icons-material/Money'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined'
import GiteOutlinedIcon from '@mui/icons-material/GiteOutlined'
import HealingOutlinedIcon from '@mui/icons-material/HealingOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'

const DoctorDashboard = (props) => {
  const [id] = useState(props.user_id)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const { data: doctorCountsData, loading: doctorCountsLoading, error: doctorCountsError, refetch } = useQuery(
    doctorCounts(),
    { variables: { doctor_id: id }, skip: id === null || id === undefined }
  )

  useEffect(() => {
    if (id !== null) {
      refetch()
    }
  }, [])

  useEffect(() => {
    if (doctorCountsData) {
      setData(doctorCountsData)
      setLoading(false)
    }

    if (doctorCountsLoading) {
      setLoading(doctorCountsLoading)
    }

    if (doctorCountsError) {
      setError('Erro ao pegar seus dados')
      setLoading(false)
    }
  }, [doctorCountsData, doctorCountsLoading, doctorCountsError])

  if (!id) return <Loading />

  if (loading) return <Loading />

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
      <h1>Dados de agendamentos</h1>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <S.Grid>
        <DashboardBox
          title="Agendamentos confirmados"
          number={data?.confirmed?.aggregate?.count || 0}
          color="#00ff00"
          icon={<PersonOutlineIcon />}
        />

        <DashboardBox
          title="Agendamentos Requisitados"
          number={data?.requested?.aggregate?.count || 0}
          color="#12005e"
          icon={<ScheduleIcon />}
        />

        <DashboardBox
          title="Pagamentos Pendentes"
          number={data?.pendingPayment?.aggregate?.count || 0}
          color="#006db3"
          icon={<CurrencyExchangeIcon />}
        />

        <DashboardBox
          title="Agendamentos Cancelados"
          number={data?.canceled?.aggregate?.count || 0}
          color="#c43e00"
          icon={<CancelOutlinedIcon />}
        />
        <DashboardBox
          title="Agendamentos Finalizados"
          number={data?.finished?.aggregate?.count || 0}
          color="#10c500"
          icon={<CheckCircleOutlineOutlinedIcon />}
        />
        <DashboardBox
          title="Agendamentos Por Clínicas"
          number={data?.by_clinics?.aggregate?.count || 0}
          color="#9a179a"
          icon={<GiteOutlinedIcon />}
        />
        <DashboardBox
          title="Agendamentos Por Sus"
          number={data?.by_sus?.aggregate?.count || 0}
          color="#4834b8"
          icon={<LocalHospitalOutlinedIcon />}
        />
        <DashboardBox
          title="Agendamentos Por Hospitais"
          number={data?.by_hospitals?.aggregate?.count || 0}
          color="#ff6a25"
          icon={<HealingOutlinedIcon />}
        />
        <DashboardBox
          title="Pacientes"
          number={data?.pacients?.aggregate?.count || 0}
          color="#d0d618"
          icon={<GroupsOutlinedIcon />}
        />
      </S.Grid>
    </div>
  )
}

export default DoctorDashboard
