export const updateAddressUseCase = async (mutation, params, extras) => {
  try {
    await mutation({
      variables: params
    })
    extras.actions.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: 'Atualizado com Sucesso!',
      type: 'success',
      time: 3000
    })
  } catch (error) {
    extras.actions.setSubmitting(false)
    console.error(error)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro Ao atualizar!',
      type: 'error',
      time: 3000
    })
  }
}
