import React from 'react'
import InternalOdontology from '../../master/odontology/Internal'
import { useAuth } from '../../../../contexts/authenticator'

function OdontologyProfile () {
  const { id } = useAuth()
  return (
    <div>{id && <InternalOdontology match={{ params: { id: id } }} />}</div>
  )
}

export default OdontologyProfile
