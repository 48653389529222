import React from 'react'
import { Card, Typography } from '@material-ui/core'
import GenerateForm from '../../../../components/layout/GenerateForm'
import { groupFields } from './detailsFields'

const Detalhes = ({ data }) => {
  return (
    <Card style={{ width: '100%' }}>
      <GenerateForm
        groupFields={groupFields(data)}
      />
      {data.status?.includes('requested') && (
        <Typography variant="h6">
          O paciente solicitou atendimento através do aplicativo. Por favor, confirme o agendamento para que ele possa realizar o pagamento e prosseguir com a confirmação da consulta.
        </Typography>
      )}
    </Card>
  )
}

export default Detalhes
