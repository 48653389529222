import * as Yup from 'yup'

export const phoneValidator = () => {
  return Yup.string('Valor inválido')
    .nullable() // Permitir valores nulos
    .transform((value, originalValue) => originalValue === '' ? null : value) // Transformar valores vazios em nulos
    .test('phoneComplete', 'O telefone precisa ser preenchido corretamente!', (value) => {
      if (!value) return false // Se o valor for nulo ou vazio, retornar falso

      // Remover caracteres não numéricos
      const phone = value.replace(/[^\d]/g, '')

      // Verificar se o telefone tem o comprimento esperado
      return phone.length === 13 || phone.length === 14
    })
}
