import styled from 'styled-components'

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .left {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & > .right {
    display: flex;
    flex-direction: column;
    text-align: end;
  }

  & > .right span {
    font-size: 13px;
  }

  & img {
    width: 100px;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
  }
`

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const Title = styled.span`
  font-size: 1.25rem;
`

export const Text = styled.span``

export const Panel = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  flex: 1;

  span {
    display: flex;
    flex: 1;
    align-self: center;
  }
`

export const HealthInsurance = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  margin-left: 10px;
`

export const Back = styled.div`
  text-decoration: underline;
  cursor: pointer;
`
