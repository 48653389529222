import React from 'react'
import DashboardAdmin from './dashboard-admin'
import DashboardClinic from './dashboard-clinic'
import DashboardSus from './dashboard-sus'
import DoctorDashboard from './../doctor/dashboard/index'
import { useAuth } from '../../../contexts/authenticator'

function Homepage () {
  const { id, type } = useAuth()

  return (
    <>
      {type === 'sus' && <DashboardSus data={{ type, id }} />}
      {type === 'hospital' && <DashboardClinic data={{ type, id }} />}
      {type === 'hospitals' && <DashboardClinic data={{ type, id }} />}
      {type === 'clinics' && <DashboardClinic data={{ type, id }} />}
      {type === 'admin' && <DashboardAdmin data={{ type, id }} />}
      {type === 'doctor' && <DoctorDashboard user_id={id} />}
      {type === 'doctors' && <DoctorDashboard user_id={id} />}
    </>
  )
}

export default Homepage
