import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { useQuery } from '@apollo/client'
import { fetchUsersByFilter } from '../../../services/users'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { customTheme } from '../../../styles/theme'
import { Avatar, CustomStyle } from './styled'
import moment from 'moment'
import { Link } from 'react-router-dom'
import ButtonPrimary from '../../components/Button'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import GenerateForm from '../../components/layout/GenerateForm'
import { filterUsers } from './columns'
import DataTable from '../../components/DataTable'
import Header from '../../components/Header'
import MainContainer from '../../components/MainContainer'
import DataTableActionColumn from '../../components/DataTableActionColumn'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function UsersPage () {
  const [filter, setFilter] = useState({ state: { value: '%' } })
  const { loading, error, data } = useQuery(fetchUsersByFilter(), {
    variables: {
      limit: 1000000,
      offset: 0,
      state_code: filter?.state?.value,
      city: filter?.city?.label || '%'
    }
  })
  const history = useHistory()
  const columns = [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: 'photo',
      headerName: 'Foto',
      width: 60,
      editable: true,
      renderCell: (params) => {
        return (
          <Col className="d-flex justify-content-center">
            {params.row.photo ? (
              <Avatar src={params.row.photo} />
            ) : (
              <AccountCircleIcon
                style={{ color: customTheme.colors.primary }}
                edge="start"
                sx={{
                  marginRight: '36px'
                  // ...(open && { display: "none" }),
                }}
              />
            )}
          </Col>
        )
      }
    },
    {
      field: 'full_name',
      headerName: 'Nome Completo',
      width: 200,
      editable: true
    },
    {
      field: 'email',
      headerName: 'E-mail',
      type: 'number',
      width: 250,
      editable: true
    },
    {
      field: 'created_at',
      headerName: 'Data de Cadastro',
      sortable: false,
      width: 160,
      valueGetter: (params) => `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
      valueFormatter: params => moment(params?.value).format('DD/MM/YYYY - HH:MM')
    },
    {
      field: 'id',
      headerName: '',
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <DataTableActionColumn
            handleEdit={() => history.push(`/users/${params.row.id}`)}
          />
        )
      }
    }
  ]

  return (
    <MainContainer>
      <Header title="Usuários" />

      <Col xs={12}>
        <CustomStyle>
          <GenerateForm
            onSubmit={null}
            groupFields={filterUsers(filter, setFilter)}
            button={{
              submit: 'Adicionar médico existente na base de dados',
              submitting: 'Adicionando...'
            }}
          />
        </CustomStyle>
      </Col>

      {loading && (<span>Carregando...</span>)}

      {data?.users && (
        <DataTable
          data={data.users}
          columns={columns}
        />
      )}
    </MainContainer>
  )
}

export default UsersPage
