/* eslint-disable react/jsx-handler-names */
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { useQuery } from '@apollo/client'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useTheme } from 'styled-components'

import { fecthEspecialities } from '../../../../services/medicalSpecialty'
import GenerateForm from '../../../components/layout/GenerateForm'
import { fetchDoctorsByState } from '../../../../services/doctor'
import { columns, filterManageDoctors } from './manageDoctorColumns'
import { AddDoctor, Button } from './styled'

function ManageDoctors ({
  query,
  params,
  objectKey,
  queryKey,
  onRemove,
  onSubmit,
  newDoctor,
  actions,
  getResponse
}) {
  const theme = useTheme()

  const [perPage, setPerPage] = useState(5)
  const [filter, setFilter] = useState(null)

  const { loading, data, refetch } = useQuery(query, {
    variables: { limit: 10, offset: 0, [queryKey]: params[objectKey] }
  })

  const especialities = useQuery(fecthEspecialities(), {})
  const doctors = useQuery(fetchDoctorsByState(), {
    variables: {
      limit: 1000000,
      offset: 0,
      state_code: filter?.state?.value || 'AC'
    }
  })

  useEffect(() => {
    doctors.refetch()
  }, [filter?.state])

  useEffect(() => {
    if (objectKey && queryKey) {
      refetch()
    }
  }, [])

  const submit = async (e, action) => {
    await onSubmit(e, action)

    await refetch()
  }

  const handleRemove = async (doctorId) => {
    if (onRemove) {
      await onRemove(doctorId)
      await refetch()
    }
  }

  if (loading) return <span>Carregando...</span>

  if (!getResponse(data)) {
    // redirect -> nao encontrou
    return null
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <h2>Gerenciar médicos</h2>
        </Col>
        <AddDoctor>
          <Col xs={12}>
            <GenerateForm
              onSubmit={submit}
              groupFields={filterManageDoctors(
                especialities?.data?.medical_specialties,
                doctors?.data?.doctors.filter(
                  (item) =>
                    !getResponse(data).find(
                      (el) => el.doctor.id === item.id
                    )
                ),
                filter,
                setFilter
              )}
              button={{
                submit: 'Adicionar médico existente na base de dados',
                submitting: 'Adicionando...'
              }}
            />
          </Col>
        </AddDoctor>
        <Col xs={12}>
          <Button onClick={newDoctor.action}>
            {newDoctor.title}
            <AddCircleIcon
              style={{ color: theme.colors.white, marginLeft: 5 }}
            />
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          {!!getResponse(data) && (
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                disableColumnSelector
                rows={getResponse(data).map(
                  (item) => item.doctor
                )}
                columns={columns(
                  handleRemove,
                  especialities?.data?.medical_specialties,
                  actions
                )}
                pageSize={perPage}
                onPageSizeChange={(e) => {
                  setPerPage(e)
                }}
                rowsPerPageOptions={[10]}
                pagination
                disableSelectionOnClick
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

export default ManageDoctors
