import moment from 'moment'
import { formatMoney } from '../../../../Utils/format'
import { discriminatorPaymentType } from '../../master/payments/columns'

const statusList = [
  { value: 'all', label: 'Todos' },
  { value: 'canceled', label: 'Cancelado' },
  { value: 'paid', label: 'Pago' },
  { value: 'reversed', label: 'Estornado' },
  { value: 'processing', label: 'Processando' },
  { value: 'error', label: 'Falha' }
]

export const columns = (modal, checkTransfer) => [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: 'description',
    headerName: 'Descrição',
    width: 250,
    editable: true
  },
  {
    field: 'method',
    headerName: 'Met. de Pag.',
    width: 110,
    editable: true,
    valueGetter: (params) => `${discriminatorPaymentType(params.row.method)} `
  },
  {
    field: 'nameUser',
    headerName: 'Nome do Paciente',
    width: 200,
    editable: true,
    valueGetter: (params) => `${params.row.user.full_name} `
  },
  {
    field: 'nameClinic',
    headerName: 'Clínica',
    width: 200,
    editable: true,
    valueGetter: (params) =>
      `${params?.row?.doctors_appointment?.clinic?.company_name || '--'} `
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    editable: true,
    valueGetter: (params) =>
      `${
        statusList.find((item) => item.value === params.row.status).label || ''
      } `
  },
  {
    field: 'value_of_transaction',
    headerName: 'Valor',
    width: 100,
    editable: true,
    valueGetter: (params) => `${formatMoney(params.row.value_of_transaction)} `
  },

  {
    field: 'created_at',
    headerName: 'Horário',
    sortable: true,
    width: 150,
    valueGetter: (params) => `${moment(params.row.created_at).format('YYYY-MM-DD hh:mm') || ''} `,
    valueFormatter: params => moment(params.value).format('DD/MM/YYYY - hh:mm')
  }
  // {
  //   field: "id",
  //   headerName: "Ações",
  //   sortable: false,
  //   width: 100,
  //   renderCell: (params) => {
  //     return (
  //       <Col className="d-flex justify-content-sm-between">
  //         <ButtonPrimary
  //           disabled={params.row.transfer_made}
  //           onClick={() => {
  //             modal.setOptions({
  //               open: true,
  //               component: (
  //                 <CardModal style={{ width: "40%" }}>
  //                   <h4>
  //                     Deseja marcar o repasse desse pagamento como concluído ?
  //                   </h4>
  //                   <div style={{ display: "flex", marginTop: 20 }}>
  //                     <ButtonPrimary
  //                       onClick={async () => {
  //                         await checkTransfer(params.row.id);
  //                         modal.setOptions({ open: false });
  //                       }}
  //                       style={{
  //                         backgroundColor: customTheme.colors.secondary,
  //                         boxShadow: "none",
  //                         marginRight: 10,
  //                       }}
  //                     >
  //                       Marcar como concluído
  //                     </ButtonPrimary>
  //                     <ButtonPrimary
  //                       onClick={() => {
  //                         modal.setOptions({ open: false });
  //                       }}
  //                       style={{
  //                         backgroundColor: customTheme.colors.error,
  //                         boxShadow: "none",
  //                         marginLeft: 10,
  //                       }}
  //                     >
  //                       Cancelar
  //                     </ButtonPrimary>
  //                   </div>
  //                 </CardModal>
  //               ),
  //             });
  //           }}
  //           style={{
  //             width: "35px",
  //             height: "35px",
  //             padding: "5px",
  //             borderRadius: "50%",
  //           }}
  //         >
  //           <PriceCheckIcon
  //             style={{ color: customTheme.colors.white }}
  //             edge="start"
  //             fontSize="small"
  //             sx={{
  //               marginBottom: "2px",
  //             }}
  //           />
  //         </ButtonPrimary>
  //       </Col>
  //     );
  //   },
  // },
]

export const filterForm = (values) => {
  return [
    {
      name: '',
      fields: [
        {
          label: 'Status',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: values?.status || '',
          hideField: false,
          name: 'status',
          // onChange: (data) => {
          //   if (content && setContent) {
          //     setContent((prev) => ({
          //       ...prev,
          //       main_specialty: data.value,
          //     }));
          //   }
          // },
          options: statusList,
          columns: {
            xs: 3
          }
        }
      ]
    }
  ]
}
