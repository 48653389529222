import React, { useState } from 'react'
import { IframeContainer } from '../../../../components/Iframe'
import { useAlertBox } from '../../../../../contexts/alert_box'
import PrescriptionList from './prescriptionList'
import ButtonPrimary from '../../../../components/Button'
import Loading from '../../../../components/layout/Loading'
import GenerateForm from '../../../../components/layout/GenerateForm'
import { useMutation } from '@apollo/client'
import { addSheduleFile, removeSheduleFile } from '../../../../../services/schedules/shedule_files'
import { AuthService } from '../../../../../services/auth/auth'

export const MedicalPrescription = (props) => {
  const { data, handleRefresh } = props
  const [addPrescription, setAddPrescription] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [description, setDescription] = useState('')
  const [addScheduleFileMutation] = useMutation(addSheduleFile())
  const [deleteScheduleFileMutation] = useMutation(removeSheduleFile())
  const alertBox = useAlertBox()
  const auth = new AuthService()

  const getMatchingLocalId = () => {
    /**
     * @type {import('./types').Local[]} locals
     */
    const locals = data.doctor?.receita_digital_infos?.locals ?? data.doctors_appointment?.doctor?.receita_digital_infos?.locals

    /**
     * @type {import('./types').Hospital} hospital
     */
    const selectedLocal = data.su ?? data.doctors_appointment?.hospital ?? data.doctors_appointment?.clinic

    const local = locals.find((local) => {
      if (selectedLocal.name) {
        return local.name === selectedLocal.name
      }

      return local.name === selectedLocal?.company_name
    })

    if (!local) {
      alertBox.setOptions({
        open: true,
        message: 'Local não encontrado',
        type: 'error',
        time: 3000
      })

      return
    }

    return local.id
  }

  const openPrescription = (prescriptionURL) => {
    window.open(prescriptionURL)
  }

  const handleAddPrescription = () => {
    setAddPrescription(true)
    setIsLoading(true)
  }

  const handleSubmit = async () => {
    try {
      const prescriptionDescription = `Prescrição criada por ${data.doctors_appointment?.doctor?.full_name}}`

      setIsLoading(true)
      await addScheduleFileMutation({
        variables: {
          description: prescriptionDescription,
          url: description,
          shedule_id: data.id
        }
      })

      auth.createNotification({
        title: 'Prescrição adicionada',
        description: prescriptionDescription + ' , clique aqui para visualizar',
        user_id: data.user_id,
        payload: {
          route: '/my_shedules/my_shedule',
          shedule_id: data.id
        }
      })
      handleRefresh()
      setAddPrescription(false)
    } catch (err) {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao adicionar a prescrição',
        type: 'error',
        time: 3000
      })
    } finally {
      setIsLoading(false)
    }
  }

  const deletePrescription = async (prescriptionId) => {
    try {
      setIsLoading(true)
      await deleteScheduleFileMutation({
        variables: {
          id: prescriptionId
        }
      })
      handleRefresh()
    } catch (err) {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao deletar a prescrição',
        type: 'error',
        time: 3000
      })
    } finally {
      setIsLoading(false)
    }
  }

  const doctorIdReceitaDigital = data.doctors_appointment?.doctor?.receita_digital_id || data.doctor?.receita_digital_id
  const doctorCpfReceitaDigital = (data.doctors_appointment?.doctor?.cpf || data.doctor?.cpf)?.replace(/\D/g, '')

  const final = JSON.stringify({
    callbackUrl: `https://smartlifecreditosaude.com/prescription-succeded/${data.id}`,
    documentType: 'prescription',
    patient: {
      id: data.user && data.user?.receita_digital_id,
      cpf: data.user && data.user?.cpf.replace(/\D/g, '')
    },
    prescriber: {
      id: doctorIdReceitaDigital,
      cpf: doctorCpfReceitaDigital,
      type: 'doctor',
      localId: getMatchingLocalId()
    },
    colorSetting: {
      primary: '#1F47A3',
      secondary: '#fff',
      footer: 'transparent',
      inputBorder: '#4062B1',
      inputBackground: '#F1FCFF'
    },
    ui: {
      showLocal: true,
      showPatientName: true,
      showSendDocumentOnCallback: true,
      sendDocumentRequired: false
    }
  })

  return (
    <>
      {addPrescription ? (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 10 }}>
          {isLoading && <Loading />}

          <IframeContainer
            payload={final}
            hidden={isLoading}
            onLoad={() => setIsLoading(false)}
          />

          <GenerateForm
            onSubmit={handleSubmit}
            groupFields={[
              {
                fields: [
                  {
                    label: 'Cole o link da prescrição aqui',
                    type: 'text',
                    required: true,
                    hideField: false,
                    name: 'description',
                    currentValue: description,
                    onChange: (event) => setDescription(event.target.value),
                    columns: { xs: 6 }
                  }
                ]
              }
            ]}
            button={{ submit: 'Enviar', submitting: 'Enviando...' }}
          />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 10 }}>
          <PrescriptionList
            data={data}
            openPrescription={openPrescription}
            deletePrescription={deletePrescription}
            loading={isLoading}
          />

          <ButtonPrimary onClick={handleAddPrescription}>
            Adicionar Prescrição
          </ButtonPrimary>
        </div>
      )}
    </>
  )
}
