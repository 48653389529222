import { useEffect, useState } from 'react'
import ButtonPrimary from '../../../../components/Button'
import DraftTextArea from '../../../../components/DraftTextArea'
import * as S from './styled'
import { useMutation } from '@apollo/client'
import { addAnamnese, updateAmamneses } from '../../../../../services/anamnese'
import { addAnamneseUseCase, editAnamneseUseCase } from '../../../../../main/usecases/anamnese'
import { useAlertBox } from '../../../../../contexts/alert_box'
import { useAuth } from '../../../../../contexts/authenticator'
import { getEntityId } from '../../../../../services/decode_jwt/get_entity_id'
import { decodeToken } from 'react-jwt'
const Anamnese = ({ data }) => {
  const { user } = useAuth()
  const [content, setContent] = useState(data.medical_histories?.length > 0 ? data.medical_histories[0].description : '')
  const [addAnamneseMutation] = useMutation(addAnamnese())
  const [updateAmamneseMutation] = useMutation(updateAmamneses())
  const alertBox = useAlertBox()

  const [medicalHistories, setMedicalHistories] = useState(data.medical_histories)

  useEffect(() => {
    if (medicalHistories && medicalHistories.length > 0) {
      console.log(medicalHistories[0].description)
      setContent(medicalHistories[0].description)
    }
  }, [medicalHistories])

  const handleAddAnamnese = async (e, actions) => {
    e.preventDefault()

    const decoded = decodeToken(
      (user?.stsTokenManager?.accessToken || user?.accessToken) || ''
    )

    const newAnamnese = await addAnamneseUseCase(addAnamneseMutation,
      {
        doctor_id: getEntityId(decoded),
        user_id: data.user_id,
        shedule_id: data.id,
        description: content
      },
      {
        alertBox
      }
    )

    setMedicalHistories([newAnamnese])

    console.log(newAnamnese)
  }

  const handleEditAnamnese = async (e, actions) => {
    e.preventDefault()

    const edit = await editAnamneseUseCase(updateAmamneseMutation,
      {
        id: medicalHistories[0].id,
        description: content
      },
      {
        alertBox
      }
    )

    if (edit.success) {
      setMedicalHistories([edit.data])
    }
  }

  return (
    <S.FormContainer onSubmit={medicalHistories && medicalHistories.length > 0 ? handleEditAnamnese : handleAddAnamnese}>
      <DraftTextArea style={{ height: 400 }} value={content} onChange={setContent} />

      <S.ButtonContainer>
        <ButtonPrimary>
          {medicalHistories && medicalHistories.length > 0 ? 'Editar Anamnese' : 'Adicionar Anamnese'}
        </ButtonPrimary>
      </S.ButtonContainer>
    </S.FormContainer>

  )
}

export default Anamnese
