import moment from 'moment'
import { formatMoney } from '../../../../Utils/format'
import { discriminatorPaymentType } from '../../master/payments/columns'

function getStatusLabel (statusValue) {
  const statusObj = statusList.find(item => item.value.includes(statusValue))
  return statusObj ? statusObj.label : 'Desconhecido'
}

const statusList = [
  { value: 'all', label: 'Todos' },
  { value: 'canceled', label: 'Cancelado' },
  { value: 'paid', label: 'Pago' },
  { value: 'reversed', label: 'Estornado' },
  { value: 'processing', label: 'Processando' },
  { value: 'failed', label: 'Falha' }
]

export const columns = (modal, checkTransfer) => [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: 'description',
    headerName: 'Descrição',
    width: 250,
    editable: true
  },
  {
    field: 'method',
    headerName: 'Met. de Pag.',
    width: 110,
    editable: true,
    valueGetter: (params) => `${discriminatorPaymentType(params.row.method)} `
  },
  {
    field: 'nameUser',
    headerName: 'Nome do Paciente',
    width: 200,
    editable: true,
    valueGetter: (params) => `${params.row.user.full_name} `
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    editable: true,
    valueGetter: params => getStatusLabel(params.row.status)
  },
  {
    field: 'value_of_transaction',
    headerName: 'Valor',
    width: 100,
    editable: true,
    valueGetter: (params) => `${formatMoney(params.row.value_of_transaction)} `
  },

  {
    field: 'created_at',
    headerName: 'Horário',
    sortable: true,
    width: 150,
    valueGetter: (params) => `${moment(params.row.created_at).format('YYYY-MM-DD hh:mm') || ''} `,
    valueFormatter: params => moment(params.value).format('DD/MM/YYYY - hh:mm')
  }
]

export const filterForm = (values) => {
  return [
    {
      name: '',
      fields: [
        {
          label: 'Status',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: values?.status || '',
          hideField: false,
          name: 'status',
          options: statusList,
          columns: {
            xs: 3
          }
        }
      ]
    }
  ]
}
