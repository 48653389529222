import React from 'react'
import { Card, Container } from 'react-bootstrap'

const PageNotFound = () => {
  return (
    <Container>
      <Card>
        <h1>Página não encontrada</h1>
      </Card>
    </Container>
  )
}

export default PageNotFound
