export const createScheduleAvailableUseCase = async (
  mutation,
  params,
  extras
) => {
  try {
    const res = await mutation({
      variables: params
    })
    extras.alertBox.setOptions({
      open: true,
      message: 'Criado com Sucesso!',
      type: 'success',
      time: 3000
    })
    return res
  } catch (error) {
    console.error(error)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro ao Salvar!',
      type: 'error',
      time: 3000
    })
  }
}

export const deleteScheduleAvailableUseCase = async (
  mutation,
  params,
  extras
) => {
  try {
    const res = await mutation({
      variables: params
    })
    extras.alertBox.setOptions({
      open: true,
      message: 'Apagado com Sucesso!',
      type: 'success',
      time: 3000
    })
    return res
  } catch (error) {
    console.error(error)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro ao Salvar!',
      type: 'error',
      time: 3000
    })
  }
}

export const updateScheduleAvailableUseCase = async (
  mutation,
  params,
  extras
) => {
  try {
    const res = await mutation({
      variables: params
    })
    extras?.actions?.setSubmitting(false)
    extras?.alertBox?.setOptions({
      open: true,
      message: 'Atualizado com Sucesso!',
      type: 'success',
      time: 3000
    })
    return res
  } catch (error) {
    extras.actions.setSubmitting(false)
    console.error(error)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro Ao atualizar!',
      type: 'error',
      time: 3000
    })
  }
}

export const updateScheduleUseCase = async (mutation, params, extras) => {
  try {
    const res = await mutation({
      variables: params
    })
    extras?.actions?.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: 'Alterado com Sucesso!',
      type: 'success',
      time: 3000
    })
    return res
  } catch (error) {
    extras?.actions?.setSubmitting(false)
    console.error(error)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro ao alterar!',
      type: 'error',
      time: 3000
    })
  }
}
