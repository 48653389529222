import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { columns, groupFields } from './columns'
import { useModal } from '../../../../contexts/modal'
import {
  createSpecialty,
  deleteSpecialty,
  fecthEspecialities,
  updateSpecialty
} from '../../../../services/medicalSpecialty'
import { useAlertBox } from '../../../../contexts/alert_box'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../services/firebase/firebase-config'
import GenerateForm from '../../../components/layout/GenerateForm'
import CardModal from '../../../components/CardModal'
import DataTable from '../../../components/DataTable'
import MainContainer from '../../../components/MainContainer'
import Header from '../../../components/Header'

function Specialties () {
  const { loading, data, refetch } = useQuery(fecthEspecialities())
  const [mutateUpdateSpecialt] = useMutation(updateSpecialty())
  const [mutateCreateSpecialt] = useMutation(createSpecialty())
  const [mutationDelete] = useMutation(deleteSpecialty())
  const [content, setContent] = useState(null)
  const alertBox = useAlertBox()
  const modal = useModal()

  useEffect(() => {
    if (data?.medical_specialties) {
      setContent(data?.medical_specialties)
    }
  }, [data])

  const updateEsp = async (id, e, actions) => {
    if (e.file) {
      const storageRef = ref(storage, `especialties/${e.file.name}`)
      const uploadTask = uploadBytesResumable(storageRef, e.file)

      uploadTask.on('state_changed', null, console.error, () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          await modelMutation(mutateUpdateSpecialt, id, e, actions, url)
        })
      })
    } else {
      await modelMutation(mutateUpdateSpecialt, id, e, actions, null)
    }
  }

  const createEsp = async (id, e, actions) => {
    if (e.file) {
      const storageRef = ref(storage, `especialties/${new Date().getTime()}`)
      const uploadTask = uploadBytesResumable(storageRef, e.file)

      uploadTask.on('state_changed', null, console.error, () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          await modelMutation(mutateCreateSpecialt, id, e, actions, url)
        })
      })
    }
  }

  const modelMutation = async (mutation, id, e, actions, url) => {
    try {
      await mutation({
        variables: {
          ...(id && { id: id }),
          name: e.name,
          description: e.description,
          icon: url || content.find((el) => el.id === id)?.icon || ''
        }
      })
      await refetch()
      actions.setSubmitting(false)
      alertBox.setOptions({
        open: true,
        message: 'Registrado com Sucesso!',
        type: 'success',
        time: 3000
      })
      modal.setOptions({ open: false })
    } catch (error) {
      console.error(error)
      alertBox.setOptions({
        open: true,
        message: 'Erro Ao Registrar!',
        type: 'error',
        time: 3000
      })
    }
  }

  const handleAdd = () => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal>
          <GenerateForm
            onSubmit={(e, actions) => createEsp(null, e, actions)}
            groupFields={groupFields(null)}
            button={{
              submit: 'Atualizar',
              submitting: 'Atualizando'
            }}
          />
        </CardModal>
      )
    })
  }

  return (
    <MainContainer>
      <Header title="Especialidades" labelAdd="Adicionar Especilidade" handleAdd={handleAdd} />

      {content && (
        <DataTable
          loading={loading}
          data={content}
          columns={columns(
            modal,
            updateEsp,
            alertBox,
            mutationDelete,
            refetch
          )}
        />
      )}
    </MainContainer>
  )
}

export default Specialties
