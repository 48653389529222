import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { getHospitalsByDoctor } from '../../../../services/doctor'
import { useAlertBox } from '../../../../contexts/alert_box'
import DataTable from '../../../components/DataTable'
import Header from '../../../components/Header'
import { columns } from './columns'

function HospitalsbyDoctor (props) {
  const id = props.user_id

  const history = useHistory()
  const alertBox = useAlertBox()

  const [offset, setOffset] = useState(0)
  const { data, loading } = useQuery(getHospitalsByDoctor(), {
    variables: {
      limit: 10,
      offset: offset * 10,
      doctor_id: id
    },
    skip: !id,
    onError: () => {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar os dados!',
        type: 'error',
        time: 3000
      })
    }
  })

  const onChangePage = (page) => {
    setOffset(page)
  }

  return (
    <div>
      <Header title="Hospitais" />

      <DataTable
        data={data?.hospitals ?? []}
        onChangePage={onChangePage}
        columns={columns(history)}
        loading={loading}
      />
    </div>
  )
}

export default HospitalsbyDoctor
