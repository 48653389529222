import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useModal } from '../../../../contexts/modal'
import { genericFetch } from '../../../../main/usecases/generic'
import { useAlertBox } from '../../../../contexts/alert_box'
import {
  fetchHospitalsById,
  inserDoctorInHospital,
  removeDoctorInHospital
} from '../../../../services/hospitals'
import ManageDoctors from '../manageDoctors/ManageDoctors'
import { AuthService } from '../../../../services/auth/auth'
import { updateDoctorUseCase } from '../../../../main/usecases/doctors'
import CardModal from '../../../components/CardModal'
import ConfirmModal from '../../../components/ConfirmModal'

function ManageDoctorsHospitals (props) {
  const history = useHistory()
  const modal = useModal()
  const alertBox = useAlertBox()

  const [mutationAddDoctor] = useMutation(inserDoctorInHospital())
  const [mutationRemoveDoctor] = useMutation(removeDoctorInHospital())

  const handleSubmit = async (e, actions) => {
    await genericFetch(
      mutationAddDoctor,
      {
        hospital_id: props.hospital_id,
        doctor_id: e.doctor.value
      },
      {
        alertBox,
        modal,
        actions
      }
    )

    await updateDoctorUseCase({ id: e.doctor.value })
  }

  const handleRemove = async (doctorId) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '500px' }}>
          <ConfirmModal
            title="Deseja remover esse médico da lista desse Hospital?"
            description="Fazendo isso ele não vai mais realizar atendimentos com esse Hospital"
            onCancel={async () => {

            }}
            onConfirm={async () => handleDelete(doctorId)}
          />
        </CardModal>
      )
    })
  }

  const handleDelete = async (doctorId) => {
    await genericFetch(
      mutationRemoveDoctor,
      {
        hospital_id: props.hospital_id,
        doctor_id: doctorId
      },
      {
        alertBox,
        modal
      }
    )

    await updateDoctorUseCase({ id: doctorId })
  }

  const handleNewDoctor = () => {
    history.push('/doctors/new', { account_info: 'hospital', account_entity_id: props.hospital_id })
    modal.setOptions({
      open: false
    })
  }

  const handleEdit = (paramsRow) => {
    modal.setOptions({ open: false })
    history.push(`/doctors/${paramsRow.row.id}`)
  }

  const handleSchedules = (paramsRow) => {
    modal.setOptions({ open: false })
    history.push(
      `/doctor-available-schedules/${props.hospital_id}/${paramsRow.row.id}`
    )
  }

  const handleSendEmail = async (paramsRow) => {
    if (!paramsRow.row.email) return false

    try {
      const auth = new AuthService()
      await auth.recoveryPassword(paramsRow?.row?.email)
      alertBox.setOptions({
        open: true,
        message: 'Enviado com Sucesso!',
        type: 'success',
        time: 3000
      })
    } catch (error) {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao enviar',
        type: 'error',
        time: 3000
      })
      console.error(error)
    }
  }

  const getResponse = (data) => {
    if (!data) return null

    return data.hospitals_by_pk.doctors_working_in_hospitals
  }

  return (
    <>
      <ManageDoctors
        query={fetchHospitalsById()}
        params={{
          hospital_id: props.hospital_id
        }}
        queryKey="id"
        objectKey="hospital_id"
        onSubmit={handleSubmit}
        onRemove={handleRemove}
        newDoctor={{
          title: 'Adicionar Novo Médico á esse Hospital',
          action: handleNewDoctor
        }}
        actions={{
          handleEdit,
          handleSchedules,
          handleSendEmail
        }}
        getResponse={getResponse}
      />
    </>
  )
}

export default ManageDoctorsHospitals
