import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'

export const Error = styled.span`
  color: #f74040;
  font-size: 0.7rem;
  position: absolute;
  bottom: -2px;
  left: 23px;
`

export const GroupSubTitle = styled.label`
  font-size: 12px;
  color: gray;
`

export const LabelFloat = styled.div`
  position: relative;
  padding-top: 13px;
  margin-bottom: 15px;

  & input {
    background-color: ${customTheme.colors.white};
    border: 0;
    outline: none;
    border-radius: 6px;
    min-width: 160px;
    font-size: 16px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    height: 50px;
    padding: 20px 10px 5px;
    box-shadow: 0px 1px 8px 0px #a1a1a126;
    border: 1px solid #ccc;
  }

  & input::placeholder {
    color: transparent;
  }

  & label {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 26px;
    margin-left: 10px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    font-weight: 600;
    color: #505050;
  }

  & input:disabled {
    background-color: #f5f5f5;
  }

  & input:required:invalid + label {
    color: red;
  }
  & input:focus:required:invalid {
    border-bottom: 2px solid red;
  }
  & input:required:invalid + label:before {
    content: "*";
  }

  & input:focus + label,
  & input:not(:placeholder-shown) + label,
  label.active {
    font-size: 10px;
    margin-top: 20px;
    color: #505050;
  }
  label.active {
    margin-top: 17px;
  }

  .custom-select__input-container input {
    height: 35px;
  }
  .custom-select__single-value {
    padding-top: 10px;
  }
  .custom-select__value-container--is-multi {
    padding-top: 15px;
  }
`

export const CheckBokField = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  input {
    margin-right: 5px;
  }

  label {
    font-size: 12px;
    font-weight: 600;
    color: "#505050";
  }
`

export const LabelInput = styled.label`
  margin-top: 13px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px 10px;
  color: #505050;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`
