import React from 'react'
import InternalSus from '../../master/sus/Internal'
import { useAuth } from '../../../../contexts/authenticator'

function SusProfile () {
  const { id } = useAuth()

  return <div>{id && <InternalSus match={{ params: { id: id } }} />}</div>
}

export default SusProfile
