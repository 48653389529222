// import api from "../api/api";
import { gql } from '@apollo/client'
import { fragmentAddresses } from '../address'
import { fragmentCompanyPlan } from '../companyPlans'
import { fragmentDoctors } from '../doctor'

export const removeHospital = () => {
  return gql`
    mutation deleteHospitalByPk($id: Int!) {
      delete_hospitals_by_pk(id: $id) {
        created_at
      }
    }
  `
}

/**
 * Retorna uma consulta GraphQL para recuperar informações detalhadas dos hospitais.
 *
 * @param {Object} options - Opções para filtrar os resultados da consulta.
 * @param {string} [options.name=null] - Opcional. O nome do hospital para filtrar os resultados.
 * @param {string} [options.city=null] - Opcional. O nome da cidade para filtrar os resultados.
 * @param {string} [options.stateCode=null] - Opcional. O código do estado para filtrar os resultados.
 * @returns {string} - Uma consulta GraphQL para recuperar informações detalhadas dos hospitais.
 *
 * @example
 * // Exemplo de utilização:
 * fetchHospitals({
 *   name: "%hospital%",         // Nome do hospital (filtro opcional)
 *   city: "Itajaí",             // Nome da cidade (filtro opcional)
 *   stateCode: "SC"             // Código do estado (filtro opcional)
 * })
 */
export const fetchHospitals = (data) => {
  let query
  if (!data?.name) {
    if (data?.city && data?.stateCode) {
      query = gql`
        query getHospitals($limit: Int, $offset: Int, $city: String, $state_code: String) {
          hospitals(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            ...fullHospitalFields
          }
          hospitals_aggregate(where: { adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentHospitals}
      `
    } else {
      query = gql`
        query getHospitals($limit: Int, $offset: Int) {
          hospitals(limit: $limit, offset: $offset, order_by: { updated_at: desc }) {
            ...fullHospitalFields
          }
          hospitals_aggregate {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentHospitals}
      `
    }
  } else {
    if (data?.city && data?.stateCode) {
      query = gql`
        query getHospitals($limit: Int, $offset: Int, $name: String, $city: String, $state_code: String) {
          hospitals(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { company_name: { _ilike: $name }, adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            ...fullHospitalFields
          }
          hospitals_aggregate(where: { company_name: { _ilike: $name }, adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentHospitals}
      `
    } else {
      query = gql`
        query getHospitals($limit: Int, $offset: Int, $name: String) {
          hospitals(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { company_name: { _ilike: $name } }) {
            ...fullHospitalFields
          }
          hospitals_aggregate(where: { company_name: { _ilike: $name } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentHospitals}
      `
    }
  }
  return query
}

export const updateHospitals = () => {
  return gql`
    ${fragmentHospitalFields}
    mutation updateHospitalByPk(
      $id: Int!
      $cnpj: String
      $company_name: String
      $description: String
      $email: String
      $facebook: String
      $instagram: String
      $linkedin: String
      $online_service: Boolean
      $personal_assistance: Boolean
      $phone_number: String
      $photo: String
      $website: String
    ) {
      update_hospitals_by_pk(
        pk_columns: { id: $id }
        _set: {
          cnpj: $cnpj
          company_name: $company_name
          description: $description
          email: $email
          facebook: $facebook
          instagram: $instagram
          linkedin: $linkedin
          online_service: $online_service
          personal_assistance: $personal_assistance
          phone_number: $phone_number
          photo: $photo
          website: $website
        }
      ) {
        ...hospitalFields
      }
    }
  `
}

export const createHospital = () => {
  return gql`
    ${fragmentHospitalFields}
    mutation insertHospitalOne(
      $address: String
      $city: String
      $complement: String
      $latitude: numeric
      $longitude: numeric
      $neighborhood: String
      $number: String
      $state: String
      $state_code: String
      $zipcode: String
      $cnpj: String
      $company_name: String
      $description: String
      $facebook: String
      $instagram: String
      $linkedin: String
      $phone_number: String
      $photo: String
      $company_plan_id: Int
      $email: String
      $website: String
      $online_service: Boolean
      $personal_assistance: Boolean
    ) {
      insert_hospitals_one(
        object: {
          adress: {
            data: {
              address: $address
              city: $city
              complement: $complement
              latitude: $latitude
              longitude: $longitude
              neighborhood: $neighborhood
              zipcode: $zipcode
              state: $state
              number: $number
              state_code: $state_code
            }
          }
          cnpj: $cnpj
          company_name: $company_name
          description: $description
          facebook: $facebook
          instagram: $instagram
          linkedin: $linkedin
          phone_number: $phone_number
          photo: $photo
          company_plan_id: $company_plan_id
          email: $email
          website: $website
          online_service: $online_service
          personal_assistance: $personal_assistance
        }
      ) {
        ...hospitalFields
      }
    }
  `
}

export const fetchHospitalsById = () => {
  return gql`
    query getHospitalByPk($id: Int!) {
      hospitals_by_pk(id: $id) {
        ...fullHospitalFields
      }
    }
    ${fragmentHospitals}
  `
}

export const fetchHospitalsFull = () => {
  return gql`
    query getHospitals($limit: Int, $offset: Int) {
      clinics(limit: $limit, offset: $offset, order_by: {updated_at: desc}) {
        ...clinicFields
      }
    }
    ${fragmentHospitals}
  `
}

export const inserDoctorInHospital = () => {
  return gql`
    ${fragmentHospitals}
    mutation addDoctorToHospital($doctor_id: Int, $hospital_id: Int) {
      insert_doctors_working_in_hospitals_one(
        object: { doctor_id: $doctor_id, hospital_id: $hospital_id }
      ) {
        hospital {
          ...fullHospitalFields
        }
      }
    }
  `
}

export const removeDoctorInHospital = () => {
  return gql`
    ${fragmentHospitals}
    mutation deleteDoctorToHospital(
      $doctor_id: Int
      $hospital_id: Int
    ) {
      delete_doctors_working_in_hospitals(
        where: {
          doctor_id: { _eq: $doctor_id }
          hospital_id: { _eq: $hospital_id }
        }
      ) {
        affected_rows
        returning {
          hospital {
            ...fullHospitalFields
          }
        }
      }
    }
  `
}

export const fragmentHospitalHoursAndDaysAvailables = gql`
  fragment hospitalHoursAndDaysAvalables on hospital_hours_and_days_available {
    id
    days_of_week {
      id
      full_text
      abbreviation
    }
    start_time
    end_time
  }
`

export const fragmentHospitalFields = gql`
  fragment hospitalFields on hospitals {
    photo
    cnpj
    company_name
    created_at
    description
    facebook
    id
    instagram
    linkedin
    phone_number
    photo
    updated_at
    website
    personal_assistance
    online_service
    adress {
      ...addressFields
    }
    doctors_working_in_hospitals {
      doctor {
        ...doctorFields
      }
    }
    hospital_hours_and_days_availables {
      ...hospitalHoursAndDaysAvalables
    }
  }
  ${fragmentAddresses}
  ${fragmentDoctors}
  ${fragmentHospitalHoursAndDaysAvailables}
  ${fragmentCompanyPlan}
`

export const fragmentHospitals = gql`
  fragment fullHospitalFields on hospitals {
    photo
    cnpj
    company_name
    created_at
    description
    facebook
    id
    instagram
    linkedin
    phone_number
    photo
    email
    updated_at
    website
    personal_assistance
    online_service
    adress {
      ...addressFields
    }
    doctors_working_in_hospitals {
      doctor {
        ...doctorFields
      }
    }
    hospital_hours_and_days_availables {
      ...hospitalHoursAndDaysAvalables
    }
    company_plan {
      ...companyPlanFields
    }
  }
  ${fragmentAddresses}
  ${fragmentDoctors}
  ${fragmentHospitalHoursAndDaysAvailables}
  ${fragmentCompanyPlan}
`

export const fragmentResumedHospitalFields = gql`
  fragment resumedHospitalFields on clinics {
    id
    company_name
    photo
    adress {
      ...addressFields
    }
  }
`

export const createHospitalHoursAndDaysAvailable = () => {
  return gql`
    mutation addHoursAndDaysAvailable(
      $objects: [hospital_hours_and_days_available_insert_input!]!
    ) {
      insert_hospital_hours_and_days_available(objects: $objects) {
        affected_rows
        returning {
          hospital {
            ...fullHospitalFields
          }
        }
      }
    }
    ${fragmentHospitals}
  `
}

export const updateHospitalHoursAndDaysAvailable = () => {
  return gql`
    mutation update(
      $id: Int!
      $day_of_week_id: Int
      $end_time: timetz
      $start_time: timetz
    ) {
      update_hospital_hours_and_days_available_by_pk(
        pk_columns: { id: $id }
        _set: {
          day_of_week_id: $day_of_week_id
          end_time: $end_time
          start_time: $start_time
        }
      ) {
        hospital {
          ...fullHospitalFields
        }
      }
    }
    ${fragmentHospitals}
  `
}

export const removeHospitalHoursAndDaysAvailable = () => {
  return gql`
    mutation delete($id: Int!) {
      delete_hospital_hours_and_days_available_by_pk(id: $id) {
        hospital {
          ...fullHospitalFields
        }
      }
    }
    ${fragmentHospitals}
  `
}
