// import api from "../api/api";
import { gql } from '@apollo/client'
import { fragmentAddresses } from '../address'
import { fragmentCompanyPlan } from '../companyPlans'
import { fragmentMedicalSpecialty } from '../medicalSpecialty'
import { fragmentSchedules } from '../schedules'

export const fetchPetshopById = () => {
  return gql`
    query getPetshopByPk($id: Int!) {
      pet_shops_by_pk(id: $id) {
        ...petshopFields
      }
    }
    ${fragmentPetshop}
  `
}
export const deletePetshop = () => {
  return gql`
    mutation deletePetShopByPk($id: Int!) {
      delete_pet_shops_by_pk(id: $id) {
        id
      }
    }
    ${fragmentPetshop}
  `
}
export const fetchPetshopByFilter = () => {
  return gql`
    query getPetShopsByCity(
      $limit: Int
      $offset: Int
      $city: String
      $state_code: String
    ) {
      pet_shops(
        order_by: {updated_at: desc},
        limit: $limit,
        offset: $offset,
        where: {
          adress: { city: { _like: $city }, state_code: { _like: $state_code } }
        }
      ) {
        ...petshopFields
      }
    }
    ${fragmentPetshop}
  `
}
export const createPetshop = () => {
  return gql`
    mutation insertPetOne(
      $company_name: String
      $description: String
      $email: String
      $facebook: String
      $instagram: String
      $linkedin: String
      $menu: String
      $phone_number: String
      $photo: String
      $website: String
      $address: String
      $city: String
      $complement: String
      $latitude: numeric
      $longitude: numeric
      $neighborhood: String
      $number: String
      $state: String
      $state_code: String
      $zipcode: String
      $company_plan_id: Int
    ) {
      insert_pet_shops_one(
        object: {
          company_name: $company_name
          description: $description
          email: $email
          facebook: $facebook
          instagram: $instagram
          linkedin: $linkedin
          menu: $menu
          phone_number: $phone_number
          photo: $photo
          website: $website
          adress: {
            data: {
              address: $address
              city: $city
              complement: $complement
              latitude: $latitude
              longitude: $longitude
              neighborhood: $neighborhood
              number: $number
              state: $state
              state_code: $state_code
              zipcode: $zipcode
            }
          }
          company_plan_id: $company_plan_id
        }
      ) {
        ...petshopFields
      }
    }
    ${fragmentPetshop}
  `
}
export const updatePetshop = () => {
  return gql`
    mutation updatePetShopByPk(
      $id: Int!
      $company_name: String
      $description: String
      $email: String
      $facebook: String
      $instagram: String
      $linkedin: String
      $menu: String
      $phone_number: String
      $photo: String
      $website: String
    ) {
      update_pet_shops_by_pk(
        pk_columns: { id: $id }
        _set: {
          company_name: $company_name
          description: $description
          email: $email
          facebook: $facebook
          instagram: $instagram
          linkedin: $linkedin
          menu: $menu
          phone_number: $phone_number
          photo: $photo
          website: $website
        }
      ) {
        ...petshopFields
      }
    }
    ${fragmentPetshop}
  `
}
export const fetchPetshop = () => {
  return gql`
    query getPharmacies($limit: Int, $offset: Int) {
      pharmacies(order_by: {updated_at: desc}, limit: $limit, offset: $offset) {
        ...pharmacyFields
      }
    }
    ${fragmentPetshop}
  `
}

export const fragmentPetshop = gql`
  fragment petshopFields on pet_shops {
    adress {
      ...addressFields
    }
    company_name
    created_at
    description
    email
    facebook
    id
    instagram
    linkedin
    menu
    phone_number
    photo
    updated_at
    website
    company_plan {
      ...companyPlanFields
    }
  }
  ${fragmentAddresses}
  ${fragmentCompanyPlan}
`

export const fragmentResumedUserFields = gql`
  fragment resumedUserFields on users {
    id
    full_name
    email
    phone
    photo
  }
`
export const fragmentResumedPetshopFields = gql`
  ${fragmentAddresses}

  fragment resumedPetshopFields on petshop {
    id
    name
    photo
    adress {
      ...addressFields
    }
  }
`
export const fragmentPetshopMedicalSpecialty = gql`
  ${fragmentMedicalSpecialty}

  fragment petshopMedicalSpecialty on petshop_with_medical_specialty {
    created_at
    id
    medical_specialty_id
    petshop_id
    updated_at
    medical_specialty {
      ...medicalSpecialtyFields
    }
  }
`
export const fragmentPetshopAppointmentFields = gql`
  ${fragmentResumedPetshopFields}
  ${fragmentPetshopMedicalSpecialty}

  fragment petshopAppointmentFields on petshop_appointment {
    created_at
    days_of_week {
      id
      abbreviation
      full_text
    }
    end_time
    id
    personal_assistance
    cost_of_medical_consultation_personal_assistance
    start_time
    petshop_id
    updated_at
    number_of_vacancies
    unavailable_days {
      date
      id
    }
    pet_shop {
      ...resumedPetshopFields
    }
  }
`
export const fetchPetshopAppointment = () => {
  return gql`
    query getPetshopAppointments($petshop_id: Int) {
      petshop_appointment(order_by: {updated_at: desc}, where: { petshop_id: { _eq: $petshop_id } }) {
        ...petshopAppointmentFields
      }
    }
    ${fragmentPetshopAppointmentFields}
  `
}

export const createPetshopAppointment = () => {
  return gql`
    ${fragmentPetshopAppointmentFields}

    mutation insertPetshopAppointments(
      $objects: [petshop_appointment_insert_input!]!
    ) {
      insert_petshop_appointment(objects: $objects) {
        affected_rows
        returning {
          ...petshopAppointmentFields
        }
      }
    }
  `
}

export const addUnavailableDaysPetshopAppointment = () => {
  return gql`
    ${fragmentPetshopAppointmentFields}
    mutation addUnavailableDays($objects: [unavailable_days_insert_input!]!) {
      insert_unavailable_days(objects: $objects) {
        affected_rows
        returning {
          petshop_appointment {
            ...petshopAppointmentFields
          }
        }
      }
    }
  `
}

export const removePetshopAppointment = () => {
  return gql`
    ${fragmentPetshopAppointmentFields}

    mutation deletePetshoAppointmentByIds($_in: [Int!] = 10) {
      delete_petshop_appointment(where: { id: { _in: $_in } }) {
        affected_rows
        returning {
          ...petshopAppointmentFields
        }
      }
    }
  `
}

export const removeUnavailableDaysPetshopAppointment = () => {
  return gql`
    mutation deleteUnavailableDays($unavalaible_days_id: [Int!]) {
      delete_unavailable_days(where: { id: { _in: $unavalaible_days_id } }) {
        affected_rows
        returning {
          id
          date
        }
      }
    }
  `
}

export const updatePetshopAppointment = () => {
  return gql`
    ${fragmentPetshopAppointmentFields}

    mutation updatePetshopAppointment(
      $id: Int!
      $day_of_week_id: Int
      $start_time: time
      $end_time: timetz
      $number_of_vacancies: Int
      $personal_assistance: Boolean
      $cost_of_medical_consultation_personal_assistance: numeric
    ) {
      update_petshop_appointment_by_pk(
        pk_columns: { id: $id }
        _set: {
          day_of_week_id: $day_of_week_id
          start_time: $start_time
          end_time: $end_time
          personal_assistance: $personal_assistance
          number_of_vacancies: $number_of_vacancies
          cost_of_medical_consultation_personal_assistance: $cost_of_medical_consultation_personal_assistance
        }
      ) {
        ...petshopAppointmentFields
      }
    }
  `
}

export const fetchPetshopSchedules = () => {
  return gql`
    query getShedulesByPetshop($petshop_id: Int, $status: String) {
      schedules(
        order_by: {updated_at: desc},
        where: {
          petshop_appointment: { petshop_id: { _eq: $petshop_id } }
          status: { _like: $status }
        }
      ) {
        ...sheduleFields
      }
    }
    ${fragmentSchedules}
  `
}

export const confirmPetshopShedule = () => {
  return gql`
    mutation confirmPetshopShedule(
      $id: Int!
      $status: String = "confirmed"
      $description: String
      $room_id: uuid
    ) {
      update_schedules_by_pk(
        pk_columns: { id: $id }
        _set: { status: $status, description: $description, room_id: $room_id }
      ) {
        ...sheduleFields
      }
    }
    ${fragmentSchedules}
  `
}

export const cancelPetshopShedule = () => {
  return gql`
    mutation cancelPetshopShedule(
      $id: Int!
      $status: String = "cancelled"
      $description: String
    ) {
      update_schedules_by_pk(
        pk_columns: { id: $id }
        _set: { status: $status, description: $description }
      ) {
        ...sheduleFields
      }
    }
    ${fragmentSchedules}
  `
}
