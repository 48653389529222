import DataTableActionColumn from '../../../../components/DataTableActionColumn'
import DataTable from '../../../../components/DataTable'
import moment from 'moment'
import CardModal from '../../../../components/CardModal'
import { ModalConfirm } from '../../../hospital/schedules-hospital/styled'
import ButtonPrimary from '../../../../components/Button'
import { customTheme } from '../../../../../styles/theme'
import { useAlertBox } from '../../../../../contexts/alert_box'
import { useModal } from '../../../../../contexts/modal'

export default function PrescriptionList ({ data, openPrescription, deletePrescription, loading }) {
  const modal = useModal()
  const alertBox = useAlertBox()

  return (
    <DataTable
      data={data?.files || []}
      columns={columns(openPrescription, deletePrescription, modal, alertBox)}
      loading={loading}
    />
  )
}

const columns = (openPrescription, deletePrescription, modal, alertBox) => {
  return [
    {
      field: 'prescription_id',
      headerName: 'Id',
      width: 100,
      editable: false,
      valueGetter: (params) => `${params.row.id || ''} `
    },
    {
      field: 'description',
      headerName: 'Descrição',
      width: 350,
      editable: false,
      valueGetter: (params) => `${params.row.description || ''} `
    },
    {
      field: 'created_at',
      headerName: 'Criado em',
      width: 200,
      editable: false,
      valueGetter: (params) => params.row.created_at,
      valueFormatter: params => params.value ? moment(params.value).format('DD/MM/YYYY - HH:mm') : 'Sem horário'
    },
    {
      field: 'id',
      headerName: '',
      renderCell: (params) => (
        <DataTableActionColumn
          handleVisibility={params.row.url ? () => openPrescription(params.row.url) : undefined}
          handleDelete={() => handleDelete(modal, alertBox, () => deletePrescription(params.row.id))}
        />
      )
    }
  ]
}

const handleDelete = async (modal, alertBox, remove) => {
  modal.setOptions({
    open: true,
    component: (
      <CardModal style={{ width: '400px' }}>
        <ModalConfirm>
          <h3>Tem certeza que deseja apagar?</h3>
          <div className="buttons-area">
            <ButtonPrimary
              id="delete-button"
              onClick={async () => {
                try {
                  document.getElementById('delete-button').disabled = true
                  remove()
                  alertBox.setOptions({
                    open: true,
                    message: 'Sucesso ao apagar!',
                    type: 'success',
                    time: 3000
                  })
                  modal.setOptions({ open: false })
                } catch (error) {
                  alertBox.setOptions({
                    open: true,
                    message: 'Erro Ao apagar Clinica',
                    type: 'error',
                    time: 3000
                  })
                  console.error(error)
                  modal.setOptions({ open: false })
                }
              }}
            >
              Sim
            </ButtonPrimary>

            <ButtonPrimary
              onClick={() => modal.setOptions({ open: false })}
              style={{
                backgroundColor: customTheme.colors.error,
                boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`
              }}
            >
              Não
            </ButtonPrimary>
          </div>
        </ModalConfirm>
      </CardModal>
    )
  })
}
