import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail
} from 'firebase/auth'
import { clearLocalStorage } from '../../Utils/localStorage'
import { createBrowserHistory } from 'history'
import axios from 'axios'

const auth = getAuth()
const history = createBrowserHistory({ forceRefresh: true })

export class AuthService {
  async signIn (email, password) {
    try {
      const res = await signInWithEmailAndPassword(auth, email, password)
      return res
    } catch (error) {
      console.error('Erro ao fazer login:', error.message)
      throw error
    }
  }

  async getToken () {
    const user = auth.currentUser

    if (user) {
      try {
        const token = await user?.getIdToken()
        return token
      } catch (error) {
        console.error('Erro ao obter token de autenticação:', error)
        return null
      }
    } else {
      console.error('Nenhum usuário atualmente autenticado.')
      return null
    }
  }

  async createAccount (body) {
    try {
      const res = await axios.post(
        process.env.REACT_APP_FUNCTIONS_URL + '/createCompanyAccount',
        body,
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`
          }
        }
      )

      return res
    } catch (error) {
      console.error('Erro ao criar conta:', error.message)
      throw error
    }
  }

  async updateDoctor (body) {
    try {
      const res = await axios.post(
        process.env.REACT_APP_FUNCTIONS_URL + '/updateDoctor',
        body,
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`
          }
        }
      )

      return res
    } catch (error) {
      console.error('Erro ao atualizar a conta:', error.message)
      throw error
    }
  }

  async removeUser (body) {
    try {
      const res = await axios.post(
        process.env.REACT_APP_FUNCTIONS_URL + '/remove-user',
        {
          data: body
        },
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`
          }
        }
      )

      return res
    } catch (error) {
      console.error('Erro ao remover usuário:', error.message)
      throw error
    }
  }

  async signOut () {
    try {
      const res = await signOut(auth)
      clearLocalStorage(res)
      history.push('/login')
    } catch (error) {
      console.error('Erro ao fazer logout:', error.message)
      throw error
    }
  }

  async recoveryPassword (email) {
    try {
      await sendPasswordResetEmail(auth, email)
    } catch (error) {
      console.error('Erro ao recuperar senha:', error.message)
    }
  }

  async checkUser () {
    try {
      const res = auth.currentUser
      return res
    } catch (error) {
      console.error('Erro ao verificar usuário:', error.message)
      throw error
    }
  }

  async deleteAccount (body) {
    try {
      const res = await axios.post(
        process.env.REACT_APP_FUNCTIONS_URL + '/deleteEntityInHasura',
        body,
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`
          }
        }
      )

      return res
    } catch (error) {
      console.error('Erro ao remover usuário:', error.message)
      throw error
    }
  }

  async createNotification (body) {
    try {
      const res = await axios.post(
        process.env.REACT_APP_FUNCTIONS_URL + '/createNotification',
        body,
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`
          }
        }
      )

      return res
    } catch (error) {
      console.error('Erro ao remover usuário:', error.message)
      throw error
    }
  }

  async acceptRoom (body) {
    try {
      const res = await axios.post(
        process.env.REACT_APP_FUNCTIONS_URL + '/acceptRoom',
        body,
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`
          }
        }
      )

      return { sucess: true, data: res.data }
    } catch (error) {
      const message = error?.response?.data?.error ?? 'Erro ao aceitar a solicitação!'
      return { sucess: false, message: message }
    }
  }
}
