import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import { red } from '@material-ui/core/colors'
import MoreVertIcon from '@material-ui/icons/MoreVert'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '90%',
    position: 'relative',
    maxHeight: '90%',
    overflow: 'auto'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500]
  }
}))

export default function CardModal ({
  title,
  subtitle,
  avatar,
  children,
  ...props
}) {
  const classes = useStyles()

  return (
    <Card className={classes.root} style={{ maxWidth: '100%' }} {...props}>
      {title && (
        <CardHeader
          avatar={
            avatar && (
              <Avatar
                src={avatar}
                aria-label="recipe"
                className={classes.avatar}
              >
                {title[0]}
              </Avatar>
            )
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={title}
          subheader={subtitle}
        />
      )}

      <CardContent>{children}</CardContent>
    </Card>
  )
}
