import GenerateProfile from '../../../components/layout/GenerateProfile'
import { fetchClinicsById } from '../../../../services/clinics'
import country from '../../../../Utils/estados_cidades.json'
import { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'

export default function InternalMyClinics ({ match }) {
  const [content, setContent] = useState({})
  const history = useHistory()

  const { data } = useQuery(fetchClinicsById(), {
    variables: { limit: 10, offset: 0, id: match.params.id }
  })

  const states = []
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  useEffect(() => {
    if (data?.clinics[0]) {
      console.log(data)
      setContent(data?.clinics[0])
    }
  }, [data])

  const handleBack = () => {
    if (history.length > 0) {
      history.goBack()
    } else {
      history.push('/my-clinics')
    }
  }

  return (
    <div>
      <Header
        title="Detalhes"
        labelAdd="Voltar"
        handleAdd={handleBack}
      />
      <GenerateProfile data={content} />
    </div>
  )
}
