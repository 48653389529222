import { decodeToken } from 'react-jwt'
import * as Auth from 'firebase/auth'

export const useUser = () => {
  const user = Auth.getAuth().currentUser

  return {
    user
  }
}

export const useUserType = () => {
  const { user } = useUser()
  const decoded = decodeToken(
    user?.stsTokenManager?.accessToken || user?.accessToken
  )
  const type = decoded['https://hasura.io/jwt/claims'].entity

  return {
    type
  }
}

export const useUserClaims = () => {
  const { user } = useUser()
  const decoded = decodeToken(
    user?.stsTokenManager?.accessToken || user?.accessToken
  )

  const deco = decoded['https://hasura.io/jwt/claims']

  return {
    ...deco
  }
}

export const useUserEntityId = () => {
  const { user } = useUser()
  const decoded = decodeToken(
    user?.stsTokenManager?.accessToken || user?.accessToken
  )

  const deco = decoded['https://hasura.io/jwt/claims']

  if (!deco) return null

  const entityId = deco['entity-id'] ??
  deco.entity_id

  return entityId
}
