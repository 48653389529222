import 'bootstrap/dist/css/bootstrap.css'
import 'leaflet/dist/leaflet.css'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from 'styled-components'
import Routes from './routes'
import { GlobalStyle } from './styles/global'
import UserProvider from './contexts/authenticator'
import { LoadingProvider } from './contexts/loading'
import { customTheme } from './styles/theme'

ReactDOM.render(
  <App>
    <ThemeProvider theme={customTheme}>
      <LoadingProvider>
        <UserProvider>
          <GlobalStyle />
          <Routes />
        </UserProvider>
      </LoadingProvider>
    </ThemeProvider>
  </App>,
  document.getElementById('root')
)
