import LocationOnIcon from '@mui/icons-material/LocationOn'
import mapPin from '../../../../assets/svg/pin.svg'
import * as S from './styled'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import Leaflet from 'leaflet'
import 'leaflet/dist/leaflet.css'
import GenerateForm from '../GenerateForm'
import { groupAddressFields, groupFields } from './profileField'

export default function GenerateProfile ({ data, columns, hideHeader, typeScheduling }) {
  const renderServices = () => {
    const services = []

    if (data?.online_service) {
      services.push({ name: 'Atendimento online' })
    }

    if (data?.personal_assistance) {
      services.push({
        name: 'Atendimento presencial'
      })
    }

    return (
      <S.Group>
        {services.length > 0 && (
          <>
            <S.Subtitle>Serviços</S.Subtitle>
            <S.FlexRow>
              {services.map((s, idx) => (
                <S.Chip key={idx}>{s.name}</S.Chip>
              ))}
            </S.FlexRow>
          </>
        )}
      </S.Group>
    )
  }

  const renderSpecialties = () => {
    if (data?.sus_with_medical_specialties) {
      return (
        <S.Group>
          <S.Subtitle>Especialidades</S.Subtitle>
          <S.FlexRow>
            {
              data?.sus_with_medical_specialties?.map((s, idx) => (
                <S.Chip key={idx}>
                  {s.medical_specialty.name}
                </S.Chip>
              ))
            }
          </S.FlexRow>
        </S.Group>
      )
    } else {
      return ''
    }
  }

  const mapPinIcon = Leaflet.icon({
    iconUrl: mapPin,
    iconSize: [58, 68],
    iconAnchor: [29, 68],
    popupAnchor: [170, 2]
  })

  return (
    <S.Container>
      {!hideHeader && (
        <S.Header>
          <div className="left">
            <img src={data?.photo} />
            <S.FlexColumn style={{ gap: 10 }}>
              <S.Title>{data?.company_name || data?.email}</S.Title>
              <S.Text>
                Criado em:{' '}
                {new Date(data?.created_at).toLocaleDateString('pt-BR')}
              </S.Text>
              <S.FlexRow style={{ gap: 3 }}>
                <LocationOnIcon />
                <S.Text>
                  {data?.adress?.city + ' - ' + data?.adress?.state_code}
                </S.Text>
              </S.FlexRow>
            </S.FlexColumn>
          </div>
          <div className="right" />
        </S.Header>
      )}

      <S.Main>
        {
          renderSpecialties()
        }
        {data?.description && (
          <S.Group style={{ maxWidth: '60%' }}>
            <S.Subtitle>Descrição</S.Subtitle>
            <S.Text>{data?.description}</S.Text>
          </S.Group>
        )}

        <S.Subtitle>Informações Principais</S.Subtitle>

        <GenerateForm
          groupFields={groupFields(data)}
        />

        <S.Subtitle>Endereço</S.Subtitle>

        {data.adress && (
          <GenerateForm
            groupFields={groupAddressFields(data.adress)}
          />
        )}

        {renderServices()}
        {console.log(data?.adress?.longitude, data?.adress?.latitude)}
        {(data?.adress && data?.adress.longitude && data?.adress.latitude) && (
          <S.Group>
            <S.Subtitle>Localização no mapa</S.Subtitle>
            <S.FlexRow style={{ height: 250, width: '100%' }}>
              <MapContainer
                center={[data.adress.latitude, data.adress.longitude]}
                style={{ width: '100%', height: '100%' }}
                zoom={15}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  icon={mapPinIcon}
                  position={[
                    data.adress.latitude,
                    data.adress.longitude
                  ]}
                >
                  <Popup>
                    {data?.company_name}, em: {data.adress.city} - {' '}
                    {data.adress?.state_code}
                  </Popup>
                </Marker>
              </MapContainer>
            </S.FlexRow>
          </S.Group>
        )}
      </S.Main>
    </S.Container>
  )
}
