import styled from 'styled-components'
import ButtonPrimary from '../../../components/Button'

export const AddDoctor = styled.div`
  position: relative;
  .mt-4.col-12 {
    position: absolute;
    max-width: 300px;
    right: 0;
    top: 15px;
  }
`

export const Button = styled(ButtonPrimary)`
  margin-bottom: 1rem;
  margin-top: 3rem;
  box-shadow: none;
  font-size: 0.750rem;
  padding: 0.3rem;
  background-color: ${props => props.theme.colors.primary};
  line-height: 2;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`
