import { gql } from '@apollo/client'

export const removeDoctorFromSus = () => {
  return gql`
    mutation deleteDoctorWorkingInSus($sus_id: Int!, $doctor_id: Int!) {
        delete_doctors_working_in_sus(where: {sus_id: {_eq: $sus_id}, doctor_id: {_eq: $doctor_id}}) {
          affected_rows
          returning {
            created_at
            doctor_id
            id
            sus_id
          }
        }
      }
    `
}
