// import api from "../api/api";
import { gql } from '@apollo/client'
import { fragmentAddresses } from '../address'

export const fetchUsersById = () => {
  return gql`
    query getUser($id: Int) {
      users(order_by: {created_at: desc}, where: { id: { _eq: $id } }) {
        ...userFields
      }
    }
    ${fragmentUser}
  `
}
export const fetchUsersByFilter = () => {
  return gql`
    query getUser($state_code: String, $city: String) {
      users(
        order_by: {created_at: desc},
        where: {
          adress: { state_code: { _like: $state_code }, city: { _like: $city } }
        }
      ) {
        ...userFields
      }
    }
    ${fragmentUser}
  `
}

/**
 * Retorna uma consulta GraphQL para recuperar informações detalhadas dos usuários, opcionalmente filtradas por nome, cidade e estado.
 *
 * @param {Object} options - Opções para filtrar os resultados da consulta.
 * @param {string} [options.name=null] - Opcional. O nome do usuário para filtrar os resultados.
 * @param {string} [options.city=null] - Opcional. O nome da cidade para filtrar os resultados.
 * @param {string} [options.stateCode=null] - Opcional. O código do estado para filtrar os resultados.
 * @returns {string} - Uma consulta GraphQL para recuperar informações detalhadas dos usuários.
 *
 * @example
 * // Exemplo de utilização:
 * fetchUsers({
 *   name: "%João%",        // Nome do usuário (filtro opcional)
 *   city: "São Paulo",     // Nome da cidade (filtro opcional)
 *   stateCode: "SP",       // Código do estado (filtro opcional)
 *   limit: 10,             // Limite de usuários a serem retornados (opcional)
 *   offset: 0              // Deslocamento para paginação (opcional)
 * })
 */
export const fetchUsers = (data) => {
  let query

  if (!data?.name) {
    if (data?.city && data?.stateCode) {
      query = gql`
      query GetUsers($limit: Int, $offset: Int, $city: String, $stateCode: String) {
        users(order_by: {created_at: desc}, limit: $limit, offset: $offset, where: {adress:{ city: { _eq: $city }, state_code: { _eq: $stateCode }} }) {
          ...userFields
        }
        users_aggregate(where: {adress:{ city: { _eq: $city }, state_code: { _eq: $stateCode }} }) {
          aggregate {
            count(columns: id)
          }
        }
      }
        ${fragmentUser}
      `
    } else {
      query = gql`
        query GetUsers($limit: Int, $offset: Int) {
          users(order_by: {created_at: desc}, limit: $limit, offset: $offset) {
            ...userFields
          }
          users_aggregate {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentUser}
      `
    }
  } else {
    if (data?.city && data?.stateCode) {
      query = gql`
      query GetUsers($limit: Int, $offset: Int, $name: String, $city: String, $stateCode: String) {
        users(order_by: {created_at: desc}, limit: $limit, offset: $offset, where: { full_name: { _ilike: $name },adress:{ city: { _eq: $city }, state_code: { _eq: $stateCode } }}) {
          ...userFields
        }
        users_aggregate(where: { full_name: { _ilike: $name }, adress:{ city: { _eq: $city }, state_code: { _eq: $stateCode } }}) {
          aggregate {
            count(columns: id)
          }
        }
      }
        ${fragmentUser}
      `
    } else {
      query = gql`
        query GetUsers($limit: Int, $offset: Int, $name: String) {
          users(order_by: {created_at: desc}, limit: $limit, offset: $offset, where: { full_name: { _ilike: $name } }) {
            ...userFields
          }
          users_aggregate(where: { full_name: { _ilike: $name } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentUser}
      `
    }
  }
  return query
}

export const fragmentUser = gql`
  fragment userFields on users {
    id
    created_at
    updated_at
    birth_date
    firebase_uid
    full_name
    email
    photo
    cnpj
    cpf
    kind_of_person
    phone
    genderByGender {
      id
      value
    }
    adress {
      ...addressFields
    }
    user_marital_status {
      spouse_document
      marital_status {
        have_a_spouse
        label
        id
      }
    }
    notifications_aggregate(where: { read: { _eq: false } }) {
      aggregate {
        count
      }
    }
  }
  ${fragmentAddresses}
`

export const fragmentResumedUserFields = gql`
  fragment resumedUserFields on users {
    id
    full_name
    email
    phone
    photo
    birth_date
    cnpj
    cpf
    kind_of_person
  }
`
