import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ToggleButtonGroup } from '@mui/material'
import Header from '../../../../components/Header'
import { MainContainer } from '../../pacients/styled'
import { tabsData } from './tabsData'
import Loading from '../../../../components/layout/Loading'
import Actions from './actions'
import { fetchSheduleById } from '../../../../../services/schedules'
import { useQuery } from '@apollo/client'
import { useAlertBox } from '../../../../../contexts/alert_box'
import ButtonPrimary from '../../../../components/Button'
import { useModal } from '../../../../../contexts/modal'
import GenerateForm from '../../../../components/layout/GenerateForm'
import * as Yup from 'yup'
import * as Dates from '../../../../../Utils/dates'
import * as Sp from '../../pacients/styled'
import * as S from './styled'
import { createNotificationFireFunction } from '../../../../../services/Notifications'

function InternalScheduleDoctorDetails ({ location }) {
  const history = useHistory()
  const [id, setId] = useState()
  const [loading, changeLoading] = useState(false)
  const [data, changeData] = useState()
  const [currentMainTab] = useState(0)
  const [currentSubTab, setCurrentSubTab] = useState(0)
  const alertBox = useAlertBox()
  const modal = useModal()

  const groupFields = [
    {
      name: 'Notificar paciente',
      fields: [
        {
          label: 'Título',
          required: true,
          disabled: false,
          currentValue: '',
          hideField: false,
          name: 'title',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido')
            .required('Título é um campo obrigatório')
        },
        {
          label: 'Descrição',
          required: true,
          currentValue: '',
          disabled: false,
          hideField: false,
          name: 'description',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido')
            .required('Descrição é um campo obrigatório')
        }
      ]
    }
  ]

  const { loading: sheduleDetailsLoading, data: sheduleDetails, refetch } = useQuery(
    fetchSheduleById(),
    {
      variables: {
        id: id
      },
      onError: () => {
        alertBox.setOptions({
          open: true,
          message: 'Erro ao buscar os dados!',
          type: 'error',
          time: 3000
        })
      }
    }
  )

  useEffect(() => {
    const path = location.pathname.substring(
      location.pathname.lastIndexOf('/') + 1
    )

    const id = parseInt(path)
    if (!isNaN(id)) {
      setId(id)
    }
  }, [location.pathname])

  useEffect(() => {
    handleRefresh()
  }, [sheduleDetails])

  const handleBack = () => {
    if (history.length > 0) {
      history.goBack()
    } else {
      history.push('/doctor-requests-schedules')
    }
  }

  const handleRefresh = () => {
    changeLoading(true)
    refetch().then(() => {
      if (sheduleDetails?.schedules_by_pk) {
        changeData(sheduleDetails.schedules_by_pk)
      }
      changeLoading(false)
    }).catch((_) => {
      changeLoading(false)
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar agendamento',
        type: 'error',
        time: 3000
      })
    })
  }

  const onModalNotification = () => {
    modal.setOptions({
      open: true,
      component: (
        <S.ContainerModal>
          <GenerateForm
            onSubmit={handleNotify}
            groupFields={groupFields}
            button={{ submit: 'Notificar', submitting: 'Enviando' }}
          />
        </S.ContainerModal>
      )
    })
  }

  const handleNotify = async (e) => {
    await createNotificationFireFunction({
      title: `${e.title}🔔`,
      description: e.description,
      user_id: data.user_id,
      payload: {
        route: '/my_shedules/my_shedule',
        shedule_id: data.id
      }
    })
    modal.setOptions({ open: false })
  }

  if (loading || sheduleDetailsLoading) return <Loading />

  return (
    <MainContainer>
      <Header
        title="Detalhes do atendimento"
        labelAdd="Voltar"
        handleAdd={handleBack}
        handleRefresh={handleRefresh}
      />

      <Sp.Header>
        <div className="left">
          <img src={data?.user?.photo ?? null} />
          <Sp.ProfileInfo>
            <Sp.Title>{data?.user?.full_name}</Sp.Title>
            <Sp.Text>
              {data?.user?.birth_date
                ? Dates.age(data?.user?.birth_date, true)
                : ''}
            </Sp.Text>
          </Sp.ProfileInfo>
        </div>
      </Sp.Header>

      <Sp.FlexContainer style={{ flexDirection: 'row', flex: 1, marginTop: '2rem' }}>
        <Sp.FlexContainer>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={currentSubTab}
            onChange={(e, newSubTab) => {
              if (newSubTab !== null) setCurrentSubTab(newSubTab)
            }}
            exclusive
          >
            {tabsData[currentMainTab].tabs.map((t, idx) => {
              return <S.ToggleButton key={t.name} value={idx}>{t.name}</S.ToggleButton>
            })}
          </ToggleButtonGroup>
          <Sp.Panel>
            {(data && tabsData[currentMainTab]?.tabs[currentSubTab]?.component(
              { data, handleRefresh }
            )) || <div>Nada há para mostrar...</div>}
          </Sp.Panel>
        </Sp.FlexContainer>
      </Sp.FlexContainer>

      {data && <Actions data={data} handleRefresh={handleRefresh} />}
    </MainContainer>
  )
}

export default InternalScheduleDoctorDetails
