import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlertBox } from '../../../../contexts/alert_box'
import InternalBody from '../../../components/InternalBody'
import GenerateForm from '../../../components/layout/GenerateForm'
import { groupFields } from './columns'
import { Col } from 'react-bootstrap'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import mapPin from '../../../../assets/svg/pin.svg'
import Leaflet from 'leaflet'
import { CustomStyle } from './styled'
import country from '../../../../Utils/estados_cidades.json'

import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../services/firebase/firebase-config'

import {
  fetchDentalClinicsById,
  updateDentalClinics,
  addressDentalClinic
} from '../../../../services/odontology'
import {
  createDentalClinicsUseCase,
  updateDentalClinicsUseCase,
  updateAddressUseCase
} from '../../../../main/usecases/odontology'
import {
  createCompanyPlan,
  updateCompanyPlan
} from '../../../../services/companyPlans'
import { genericFetch } from '../../../../main/usecases/generic'
import { coordIsValid } from '../../../../Utils/coordValidate'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { fetchAddressByZipCode } from '../../../../services/searchZip/searchZipcode'

function InternalOdontology ({ match }) {
  const { data, error } = useQuery(fetchDentalClinicsById(), {
    variables: { id: match.params.id }, skip: !match.params.id
  })

  const [createCompanymutation] = useMutation(createCompanyPlan())
  const [updateCompanymutation] = useMutation(updateCompanyPlan())
  const [mutateFunction] = useMutation(updateDentalClinics())
  const [mutateFunctionAdress] = useMutation(addressDentalClinic())
  const [content, setContent] = useState({})
  const alertBox = useAlertBox()
  const history = useHistory()
  const [isFirstRun, setIsFirtsRun] = useState(true)
  const states = []
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  useEffect(() => {
    if (data?.dental_clinics_by_pk) {
      setContent(data?.dental_clinics_by_pk)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar odontologia',
        type: 'error',
        time: 3000
      })
    }
  }, [error])

  useEffect(() => {
    if (content?.adress?.zipcode && !isFirstRun) {
      fetchAddressByZipCode(
        content?.adress?.zipcode,
        content,
        setContent,
        states,
        alertBox
      ).then(() => { })
    }

    if (data?.dental_clinics_by_pk.adress?.zipecode !== content?.adress?.zipcode) {
      setIsFirtsRun(false)
    }
  }, [content?.adress?.zipcode])

  const mutationCreateEntity = async (e, url, actions) => {
    const res = await createCompanymutation({
      variables: {
        account: '',
        agency: '',
        chave_pix: '',
        cpf: '',
        transfer_of_interest_to_the_customer:
          e?.transfer_of_interest_to_the_customer || false,
        plan_id: e.plan_id.value,
        bank_id: e.bank_id,
        codigo_split: e.codigo_split
      }
    })
    return await createDentalClinicsUseCase(
      {
        email: e.email,
        address: e.address,
        city: e.city.label,
        complement: e.complement,
        neighborhood: e.neighborhood,
        number: e.number,
        state: e.state.label,
        state_code: content?.adress?.state_code,
        zipcode: e.zipcode,
        latitude: e.latitude || content.latitude,
        longitude: e.longitude || content.longitude,
        company_name: e.company_name,
        description: e.description,
        website: e.website,
        online_service: e.online_service,
        personal_assistance: e.personal_assistance,
        phone_number: e.phone_number,
        photo: url || '',
        company_plan_id: res.data.insert_company_plan_one.id
      },
      {
        actions,
        alertBox
      }
    )
  }

  const mutationUpdateEntity = async (e, url, actions) => {
    await genericFetch(
      updateCompanymutation,
      {
        id: content.company_plan.plan_id,
        account: '',
        agency: '',
        chave_pix: '',
        cpf: '',
        transfer_of_interest_to_the_customer:
          e?.transfer_of_interest_to_the_customer || false,
        plan_id: e.plan_id.value,
        bank_id: e.bank_id,
        codigo_split: e.codigo_split
      },
      {
        actions,
        alertBox
      }
    )
    await updateAddressUseCase(
      mutateFunctionAdress,
      {
        address_id: content.adress.id,
        address: e.address,
        city: e.city.label,
        state: e.state.label,
        state_code: e.state.value,
        complement: e.complement,
        latitude: e.latitude || content.latitude,
        longitude: e.longitude || content.longitude,
        neighborhood: e.neighborhood,
        number: e.number,
        zipcode: e.zipcode
      },
      {
        actions,
        alertBox
      }
    )
    await updateDentalClinicsUseCase(
      mutateFunction,
      {
        id: match.params.id,
        email: e.email,
        company_name: e.company_name,
        website: e.website,
        phone_number: e.phone_number,
        online_service: e.online_service,
        personal_assistance: e.personal_assistance,
        description: e.description,
        photo: url || content.photo
      },
      {
        actions,
        alertBox
      }
    )
  }

  const mapPinIcon = Leaflet.icon({
    iconUrl: mapPin,
    iconSize: [58, 68],
    iconAnchor: [29, 68],
    popupAnchor: [170, 2]
  })

  const onSubmit = async (e, actions) => {
    try {
      if (window.location.pathname.includes('new')) {
        if (e.file) {
          const storageRef = ref(storage, `odontology/${new Date().getTime()}`)
          const uploadTask = uploadBytesResumable(storageRef, e.file)

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Handle progress, if needed
              // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              // console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.error('Error during file upload:', error)
              actions.setSubmitting(false) // Allow further submissions
            },
            async () => {
              try {
                const url = await getDownloadURL(uploadTask.snapshot.ref)
                await mutationCreateEntity(e, url, actions)
              } catch (urlError) {
                console.error('Error getting download URL:', urlError)
                actions.setSubmitting(false) // Allow further submissions
              }
            }
          )
        } else {
          await mutationCreateEntity(e, null, actions)
        }
      } else {
        console.warn(e)
        if (e.file) {
          const storageRef = ref(storage, `odontology/${new Date().getTime()}`)
          const uploadTask = uploadBytesResumable(storageRef, e.file)

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              // Handle progress, if needed
              // const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              // console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.error('Error during file upload:', error)
              actions.setSubmitting(false) // Allow further submissions
            },
            async () => {
              try {
                const url = await getDownloadURL(uploadTask.snapshot.ref)
                await mutationUpdateEntity(e, url, actions)
              } catch (urlError) {
                console.error('Error getting download URL:', urlError)
                actions.setSubmitting(false) // Allow further submissions
              }
            }
          )
        } else {
          await mutationUpdateEntity(e, null, actions)
        }
      }
    } catch (error) {
      console.error('Error in onSubmit:', error)
      actions.setSubmitting(false) // Allow further submissions
    } finally {
      handleGoBack()
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <>
      <CustomStyle>
        <InternalBody style={{ position: 'relative', height: '100%' }}>
          {content?.created_at && (
            <>
              <p>
                Criado em:{' '}
                {moment(content?.created_at).format('DD/MM/YYYY HH:MM') || ''}
              </p>
            </>
          )}

          <GenerateForm
            onSubmit={onSubmit}
            groupFields={groupFields(content, setContent)}
            button={{
              submit: window.location.pathname.includes('new')
                ? 'Adicionar'
                : 'Atualizar',
              submitting: window.location.pathname.includes('new')
                ? 'Adicionando...'
                : 'Atualizando...'
            }}
          />
          {coordIsValid(content.adress?.latitude) && coordIsValid(content.adress?.longitude) && (
            <Col xs={12} style={{ height: '250px' }}>
              <MapContainer
                center={[content.adress?.latitude, content.adress?.longitude]}
                style={{ width: '100%', height: '100%' }}
                zoom={15}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  icon={mapPinIcon}
                  position={[
                    content.adress?.latitude || -14.8567487,
                    content.adress?.longitude || -40.8414804
                  ]}
                />
              </MapContainer>
            </Col>
          )}
        </InternalBody>
      </CustomStyle>
    </>
  )
}

export default InternalOdontology
