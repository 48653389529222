import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { columns } from './columns'
import { useAlertBox } from '../../../../contexts/alert_box'
import DataTable from '../../../components/DataTable'
import Header from '../../../components/Header'
import MainContainer from '../../../components/MainContainer'
import { fetchMySUSList } from '../../../../services/sus'

function DoctorSUS (props) {
  const id = props.user_id

  const alertBox = useAlertBox()
  const history = useHistory()

  const [offset, setOffset] = useState(0)
  const { data, loading } = useQuery(fetchMySUSList(), {
    variables: { doctor_id: id, limit: 10, offset: offset * 10 },
    skip: !id,
    onError: () => {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar os dados!',
        type: 'error',
        time: 3000
      })
    }
  })

  const onChangePage = (page) => {
    setOffset(page)
  }

  return (
    <MainContainer>
      <Header
        title="Sua Lista SUS"
      />

      <DataTable
        data={data?.sus ?? []}
        onChangePage={onChangePage}
        loading={loading}
        columns={columns(history, id)}
      />
    </MainContainer>
  )
}

export default DoctorSUS
