import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { removeClinic } from '../../../../services/clinics'
import { columns } from './columns'
import { useModal } from '../../../../contexts/modal'
import { useAlertBox } from '../../../../contexts/alert_box'
import DataTable from '../../../components/DataTable'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Header from '../../../components/Header'
import MainContainer from '../../../components/MainContainer'
import { fetchPacientsByDoctorId } from '../../../../services/pacients'

function Pacients (props) {
  const [id] = useState(props.user_id)
  const [items, setItems] = useState([])
  const [offset, setOffset] = useState(0)
  const { refetch } = useQuery(fetchPacientsByDoctorId(), {
    variables: { doctor_id: id, limit: 10, offset: 0 },
    skip: id === null || id === undefined
  })
  const [loading, setLoading] = useState(false)

  const [mutateDelete] = useMutation(removeClinic())
  const modal = useModal()
  const alertBox = useAlertBox()
  const history = useHistory()

  useEffect(() => {
    updateData()
  }, [offset])

  const updateData = async () => {
    if ((offset * 10) >= items.length) {
      try {
        setLoading(true)
        const result = await refetch({ doctor_id: id, limit: 10, offset: 0 })
        setItems(items.concat(result.data.users))
      } catch (e) {
        alertBox.setOptions({
          open: true,
          message: 'Erro ao buscar os dados!',
          type: 'error',
          time: 3000
        })
      } finally {
        setLoading(false)
      }
    }
  }

  const onChangePage = (page) => {
    setOffset(page)
  }

  return (
    <MainContainer>
      <Header
        title="Pacientes"
      />

      <DataTable
        data={items}
        onChangePage={onChangePage}
        loading={loading}
        columns={columns(modal, alertBox, mutateDelete, refetch, history)}
      />
    </MainContainer>
  )
}

export default Pacients
