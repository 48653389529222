import * as Yup from 'yup'

export const groupFields = (
  content,
  especialities
) => {
  especialities = especialities?.map((item) => ({ ...item, value: item.id }))
  const especialitiesValues = content?.consultation_room_medical_specialties.map(item => ({
    label: item.medical_specialty.name,
    value: item.medical_specialty.id
  }))

  return [
    {
      name: '',
      fields: [
        {
          label: 'Especialidades',
          type: 'autocomplete',
          required: true,
          disabled: false,
          multiple: true,
          currentValue: especialitiesValues ?? null,
          name: 'consultation_room_medical_specialties',
          options: especialities?.map((item) => ({
            label: item.name,
            value: item.value
          })),
          columns: {
            xs: 12
          },
          validation: Yup.array().required('Campo obrigatório').nullable()
        },
        {
          label: 'Vagas',
          type: 'number',
          required: true,
          currentValue: content?.available_vacancies || '',
          name: 'available_vacancies',
          columns: {
            xs: 4
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Horário de término',
          type: 'time',
          required: true,
          currentValue: content?.end_time?.slice(0, 5) || '',
          name: 'end_time',
          columns: {
            xs: 4
          },
          validation: Yup.string('Date inválida').required('Campo obrigatório')
        },
        {
          label: 'Tolerância (em minutos)',
          type: 'number',
          required: true,
          currentValue: content?.tolerance_to_enter_the_room_in_minutes || '',
          name: 'tolerance_to_enter_the_room_in_minutes',
          columns: {
            xs: 4
          },
          validation: Yup.string('Date inválida').required('Campo obrigatório')
        }
      ]
    }
  ]
}
