import React from 'react'
import { Col } from 'react-bootstrap'
import moment from 'moment'
import Avatar from '@material-ui/core/Avatar'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { customTheme } from '../../../../styles/theme'
import { phoneMask } from '../../../../Utils/format'
import DataTableActionColumn from '../../../components/DataTableActionColumn'

export const columns = (history) => [
  {
    field: 'photo',
    headerName: 'Foto',
    width: 60,
    editable: true,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          {params.row.photo ? (
            <Avatar src={params.row.photo} />
          ) : (
            <AccountBalanceIcon
              style={{ color: customTheme.colors.primary }}
              edge="start"
              sx={{
                marginRight: '36px'
              }}
            />
          )}
        </Col>
      )
    }
  },
  {
    field: 'phone_number',
    headerName: 'Telefone',
    width: 200,
    editable: true,
    valueGetter: (params) => `${phoneMask(params.row.phone_number) || ''} `
  },
  {
    field: 'company_name',
    headerName: 'Nome',
    width: 200,
    editable: true
  },
  {
    field: 'cnpj',
    headerName: 'CNPJ',
    width: 200,
    editable: true
  },
  {
    field: 'created_at',
    headerName: 'Data de Cadastro',
    sortable: true,
    width: 150,
    valueGetter: (params) =>
        `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
    valueFormatter: (params) =>
      moment(params.value).format('DD/MM/YYYY - HH:MM')
  },
  {
    field: 'id',
    headerName: '',
    sortable: false,
    width: 180,
    renderCell: (params) => {
      return (
        <DataTableActionColumn
          handleVisibility={() => history.push(`my-hospitals/${params.row.id}`)}
        />
      )
    }
  }
]
