import DataTableActionColumn from '../../../components/DataTableActionColumn'

export const columns = (alertBox, actions, listValues, doctorSpecialitiesId) => [
  {
    field: 'available_vacancies',
    headerName: 'Vagas disponíveis',
    description: 'Vagas disponíveis',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (params) => {
      const count = listValues?.find(item => item.id === params.id)?.count || 0
      return params.value - count
    }
  },
  {
    field: 'consultation_room_user_requests_aggregate',
    headerName: 'Solicitações',
    description: 'Solicitações de pacientes',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (value) => {
      return `${value.value.aggregate.count}`
    }
  },
  {
    field: 'consultation_room_medical_specialties',
    headerName: 'Especialidades',
    description: 'Especialidades',
    flex: 2,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (value) => value.value.map(item => item.medical_specialty.name).join(', ')
  },
  {
    field: 'end_time',
    headerName: 'Hora de término',
    description: 'Horário de término das consultas',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (value) => {
      return `${value.value?.slice(0, 5)}`
    }
  },
  {
    field: 'tolerance_to_enter_the_room_in_minutes',
    headerName: 'Tolerância',
    description: 'Tolerância em minutos',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (value) => {
      return `${value.value} minutos`
    }
  },
  {
    field: 'action',
    flex: 1,
    headerName: '',
    align: 'center',
    renderCell: (params) => {
      const specialities = params.row.consultation_room_medical_specialties
        .filter(speciality => {
          return doctorSpecialitiesId?.includes(speciality.medical_specialty.id)
        })

      const hasFunc = (func) => {
        if (func && doctorSpecialitiesId && specialities.length === 0) {
          return () => alertBox.setOptions({
            open: true,
            message: 'Não pode aceitar solicitações dessa sala, pois a especialidade dela não está atribuida a você',
            type: 'warning',
            time: 3000
          })
        }

        if (func) return () => func(params)
        return undefined
      }

      return (
        <DataTableActionColumn
          handleVisibility={hasFunc(actions?.handleView)}
          handleEdit={hasFunc(actions?.handleEdit)}
          handleDelete={hasFunc(actions?.handleDelete)}
        />
      )
    }
  }
]
