export const customTheme = {
  breakpoints: ['576px', '768px', '992px', '1200px', '1400px'],
  fonts: {
    body: 'Barlow, sans-serif',
    heading: 'Poppins, sans-serif',
    mono: 'monospace'
  },
  fontSizes: {
    xs: '0.750rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '2.375rem' // 38px
  },
  gutters: '10px',
  colors: {
    white: '#fff',
    black: '#000',
    primary: 'rgb(31, 71, 163)',
    secondary: '#003641',
    backgroudForm: '#f3f8fe',
    primaryButton: 'rgb(31, 71, 163)',
    validate: '#8ce5cd',
    error: '#f46868',
    success: '#4caf50'
  },
  modal: {
    padding: '15px'
  }
}
