import React from 'react'
import InternalDiagnosticImage from '../../master/diagnostic-image/Internal'
import { useAuth } from '../../../../contexts/authenticator'

function DiagnosticimageProfile () {
  const { id } = useAuth()

  return (
    <div>
      {id && <InternalDiagnosticImage match={{ params: { id: id } }} />}
    </div>
  )
}

export default DiagnosticimageProfile
