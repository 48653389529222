import { Col } from 'react-bootstrap'
import { useTheme } from 'styled-components'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useMutation } from '@apollo/client'
import Header from '../../../components/Header'
import CardModal from '../../../components/CardModal'
import ListWaitingRooms from '../../master/list-waiting-rooms'
import { useModal } from '../../../../contexts/modal'
import EditWaitingRoom from '../edit-waiting-room'
import { useAuth } from '../../../../contexts/authenticator'
import { removeSusWaitingRoom } from '../../../../services/sus'
import { genericDeleteFetch } from '../../../../main/usecases/generic'
import { useAlertBox } from '../../../../contexts/alert_box'
import ConfirmModal from '../../../components/ConfirmModal'
import * as S from './styled'

const WaitingRoom = () => {
  const theme = useTheme()
  const { id } = useAuth()
  const modal = useModal()
  const alertBox = useAlertBox()
  const [mutationDelete] = useMutation(removeSusWaitingRoom())

  const handleNewRoom = () => {
    // open modal with component that create or edit a waiting room
    modal.setOptions({
      open: true,
      component: (
        <S.ContainerModal>
          <EditWaitingRoom
            susId={id}
          />
        </S.ContainerModal>
      )
    })
  }

  const handleViewRoom = (params) => {
    // open modal with component that create or edit a waiting room
    modal.setOptions({
      open: true,
      component: (
        <S.ContainerModal>
          <EditWaitingRoom
            susId={id}
            id={params.row.id}
          />
        </S.ContainerModal>
      )
    })
  }

  const handleEditRoom = (params) => {
    // open modal with component that create or edit a waiting room
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '90%', minHeight: '60vh', padding: '2rem' }}>
          <EditWaitingRoom
            susId={id}
            id={params.row.id}
          />
        </CardModal>
      )
    })
  }

  const deleteRoom = (id) => {
    genericDeleteFetch(
      mutationDelete, {
        id: id
      },
      {
        alertBox
      })
  }

  const handleDeleteRoom = (params) => {
    // open modal with component that create or edit a waiting room
    modal.setOptions({
      open: true,
      component: (
        <CardModal>
          <ConfirmModal
            title="Deseja deletar esta sala?"
            description="Esta sala será deletada em definitivo."
            onConfirm={() => deleteRoom(params.row.id)}
          />
        </CardModal>
      )
    })
  }

  return (
    <S.Wrapper>
      <Header title="Salas de atendimento" />

      <Col xs={12} className="mb-4">
        <S.Button onClick={handleNewRoom}>
          Adicionar Nova Sala
          <AddCircleIcon
            style={{ color: theme.colors.white, marginLeft: 5 }}
          />
        </S.Button>
      </Col>

      <ListWaitingRooms
        susId={id}
        actions={{
          handleView: handleViewRoom,
          handleEdit: handleEditRoom,
          handleDelete: handleDeleteRoom
        }}
      />
    </S.Wrapper>
  )
}

export default WaitingRoom
