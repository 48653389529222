import React from 'react'
import { customTheme } from '../../../../styles/theme'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import { formatMoney, phoneMask } from '../../../../Utils/format'
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityIcon from '@mui/icons-material/Visibility'
import CancelIcon from '@mui/icons-material/Cancel'
import ButtonPrimary from '../../../components/Button'
import { Col } from 'react-bootstrap'
import CardModal from '../../../components/CardModal'
import GenerateForm from '../../../components/layout/GenerateForm'
import { ModalContainer } from './styled'
import { getScheduleStatus } from '../../../../Utils/schedules'
import { useHistory } from 'react-router-dom'
import DataTableActionColumn from '../../../components/DataTableActionColumn'
// import * as Yup from "yup";

export const schedulesFieldsView = (val) => {
  const day = moment(val?.day).add(1, 'hours').add(1, 'days').format('YYYY-MM-DD ') || ''
  const startTime = val?.start_time.replace(
    '+00',
    '-03:00'
  )

  return [
    {
      fields: [
        {
          label: 'Nome do paciente:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'name',
          currentValue: val.user?.full_name,
          columns: { xs: 4 }
        },
        {
          label: 'E-mail:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'email',
          currentValue: val.user?.email,
          columns: { xs: 4 }
        },
        {
          label: 'Telefone:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'phone',
          currentValue: val?.user?.phone && val?.user?.phone,
          columns: { xs: 4 }
        },
        {
          label: 'Horário:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'time',
          currentValue:
            moment(day + startTime).format('DD/MM/YYYY - HH:mm') || '',
          columns: { xs: 4 }
        },
        {
          label: 'Status:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'status',
          currentValue: getScheduleStatus(val.status),
          columns: { xs: 4 }
        },
        {
          label: 'Tipo de consulta:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'type_of_medical_appointment',
          currentValue:
            val?.type_of_medical_appointment === 'online'
              ? 'Online'
              : 'Presencial',
          columns: { xs: 4 }
        },
        {
          label: 'Nome do médico:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'fullname_doctor',
          currentValue: val?.doctors_appointment?.doctor?.full_name,
          columns: { xs: 4 }
        },
        {
          label: 'Custo:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'average_cost_of_medical_consultation',
          currentValue: formatMoney(
            val?.type_of_medical_appointment === 'online'
              ? val?.doctors_appointment
                ?.cost_of_medical_consultation_online_service
              : val?.doctors_appointment
                ?.cost_of_medical_consultation_personal_assistance
          ),
          columns: { xs: 4 }
        },
        {
          label: 'Status de pagamento:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'payment_status',
          currentValue: getScheduleStatus(val?.payment?.status),
          columns: { xs: 4 }
        },

        {
          label: 'Descrição:',
          type: 'textarea',
          required: true,
          hideField: false,
          disabled: true,
          name: 'description',
          currentValue: val.description,
          columns: { xs: 12 }
        }
      ]
    }
  ]
}

export const columns = (modal, columnsActions) => {
  const history = useHistory()
  return [
    {
      field: 'photo',
      headerName: 'Foto',
      width: 60,
      editable: false,
      renderCell: (params) => {
        return (
          <Col className="d-flex justify-content-center">
            {params?.row?.user?.profile_image
              ? (
                <Avatar src={params.row.user?.photo} />
                )
              : (
                <Avatar>{params?.row?.user?.full_name ? params.row.user.full_name[0] : ''}</Avatar>
                )}
          </Col>
        )
      }
    },
    {
      field: 'type_of_medical_appointment',
      headerName: 'Tipo de Consulta',
      width: 140,
      editable: false,
      valueGetter: (params) =>
        `${
          params.row.type_of_medical_appointment === 'online'
            ? 'Online'
            : 'Presencial'
        } `
    },
    {
      field: 'full_name',
      headerName: 'Nome do paciente',
      width: 170,
      editable: false,
      valueGetter: (params) => `${params.row.user?.full_name || ''} `
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      valueGetter: (params) => `${getScheduleStatus(params.row.status) || ''}`
    },
    {
      field: 'average_cost_of_medical_consultation',
      headerName: 'Custo',
      width: 100,
      type: 'number',
      editable: false,
      valueGetter: (params) => {
        const value =
          params?.row?.type_of_medical_appointment === 'online'
            ? params?.row?.doctors_appointment
              ?.cost_of_medical_consultation_online_service
            : params?.row?.doctors_appointment
              ?.cost_of_medical_consultation_personal_assistance
        return `${formatMoney(value || 0)} `
      }
    },
    {
      field: 'fullname_doctor',
      headerName: 'Nome do Médico',
      width: 150,
      editable: false,
      valueGetter: (params) =>
      `${params?.row?.doctor?.full_name ?? params?.row?.doctors_appointment?.doctor?.full_name} `
    },
    {
      field: 'created_at',
      headerName: 'Horário',
      sortable: true,
      width: 150,
      valueGetter: (params) => {
        const day = moment(params.row.day).add(3, 'hours').format('YYYY-MM-DD ') || ''

        if (!params.row.start_time) {
          return ''
        }

        const startTime = params.row.start_time.replace(
          '+00',
          '-03:00'
        )
        return `${moment(day + startTime).format('YYYY-MM-DD HH:mm') || ''}`
      },
      valueFormatter: params => params.value ? moment(params.value).format('DD/MM/YYYY - HH:mm') : 'Sem horário'
    },
    {
      field: 'id',
      headerName: '',
      renderCell: (params) => (
        <DataTableActionColumn
          handleVisibility={() => history.push('/doctor-requests-schedules/' + params.row.id, { data: params.row })}
          handleDelete={() => columnsActions?.handleCancelSchedule(params?.id, params?.row)}
        />
      )
    }
  ]
}
