import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import * as Yup from 'yup'
import { Col, Row } from 'react-bootstrap'
import InternalBody from '../../../components/InternalBody'
import GenerateForm from '../../../components/layout/GenerateForm'
import { useAlertBox } from '../../../../contexts/alert_box'
import { useModal } from '../../../../contexts/modal'
import ButtonPrimary from '../../../components/Button'
import { customTheme } from '../../../../styles/theme'
import CardModal from '../../../components/CardModal'
import {
  createScheduleAvailableUseCase,
  deleteScheduleAvailableUseCase,
  updateScheduleUseCase
} from '../../../../main/usecases/schedules'
import AppointmentForm from './appointment-form'
import {
  currentWeek,
  getIdDay,
  translateCalendar,
  verifyEventIsAvailable,
  weekDays
} from '../../../../Utils/dates'
import { CalendarContainer, EditModalContent, WeekDays } from './styled'
import {
  addUnavailableDaysSusAppointment,
  createSusAppointment,
  fetchSUSAppointment,
  removeSusAppointment,
  removeUnavailableDaysSUSAppointment,
  updateSusAppointment
} from '../../../../services/sus'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { columns } from './columns'
import { useAuth } from '../../../../contexts/authenticator'
require('react-big-calendar/lib/css/react-big-calendar.css')
const localizer = momentLocalizer(moment)

function InternalSchedulesSus ({ match }) {
  const [perPage, setPerPage] = useState(5)
  const docAvailableColor = customTheme.colors.primary
  const alertBox = useAlertBox()
  const modal = useModal()

  const { id } = useAuth()

  let susId = id

  if (match.params.id) {
    susId = match.params.id
  }

  const susAppointments = useQuery(fetchSUSAppointment(), {
    variables: { sus_id: susId }
  })

  const [schedulesAvailable, setSchedulesAvailable] = useState([])
  const [unavailableDays, setUnavailableDays] = useState([])

  const [createSusAppointmentMutation] = useMutation(createSusAppointment())

  const mutationCreateSusAppointment = async (data) => {
    return await createScheduleAvailableUseCase(
      createSusAppointmentMutation,
      data,
      { alertBox }
    )
  }

  const [removeSusAppointmentMutation] = useMutation(removeSusAppointment())

  const mutationRemoveSusAppointment = async (data) => {
    return await deleteScheduleAvailableUseCase(
      removeSusAppointmentMutation,
      data,
      { alertBox }
    )
  }

  const [updateSusAppointmentMutation] = useMutation(updateSusAppointment())

  const mutationUpdateSusAppointment = async (data) => {
    return await updateScheduleUseCase(updateSusAppointmentMutation, data, {
      alertBox
    })
  }

  const [addUnavailableDaysSusAppointmentMutation] = useMutation(
    addUnavailableDaysSusAppointment()
  )

  const mutationAddUnavailableDaysSusAppointment = async (data) => {
    return await createScheduleAvailableUseCase(
      addUnavailableDaysSusAppointmentMutation,
      data,
      { alertBox }
    )
  }

  const [removeUnavailableDaysSUSAppointmentMutation] = useMutation(
    removeUnavailableDaysSUSAppointment()
  )

  const mutationRemoveUnavailableDaysSUSAppointment = async (data) => {
    const res = await deleteScheduleAvailableUseCase(
      removeUnavailableDaysSUSAppointmentMutation,
      data,
      { alertBox }
    )
    setUnavailableDays((prev) =>
      prev.filter((item) => item.id !== data.unavalaible_days_id[0])
    )
    susAppointments.refetch()
    return res
  }

  const handleCreateSusAppointment = async (e, createdSchedule, schedule) => {
    try {
      const resp = await mutationCreateSusAppointment({
        objects: [
          {
            sus_id: susId,
            day_of_week_id: createdSchedule.day_of_week_id,
            start_time: e.start_time,
            end_time: e.end_time,
            online_service: !!e.online_service,
            personal_assistance: !!e.personal_assistance
          }
        ]
      })

      setSchedulesAvailable((prev) => [
        ...prev,
        {
          start: schedule.start,
          end: schedule.end,
          title: 'Disponível',
          color: docAvailableColor,
          id: resp?.data?.insert_sus_appointment?.returning[0].id,
          full_data: resp?.data?.insert_sus_appointment?.returning[0]
        }
      ])

      modal.setOptions({ open: false })
    } catch (error) {
      console.error(error)
    }
  }

  const handleRemoveSusAppointment = async (id) => {
    try {
      const resp = await mutationRemoveSusAppointment({ _in: [id] })
      setSchedulesAvailable((prev) => prev.filter((i) => i.id !== id))
      modal.setOptions({ open: false })
    } catch (error) {
      console.error(error)
    }
  }

  const handleUpdateSusAppointment = async (e, event) => {
    try {
      await mutationUpdateSusAppointment({
        id: e.id,
        number_of_vacancies: event?.number_of_vacancies,
        day_of_week_id: getIdDay(e?.full_data?.days_of_week?.full_text),
        start_time: event?.start_time,
        end_time: event?.end_time,
        online_service: !!event.online_service,
        personal_assistance: !!event.personal_assistance
      })
      modal.setOptions({ open: false })
    } catch (error) {
      console.error(error)
    }
  }

  const unavailableForm = (id) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '400px' }}>
          <GenerateForm
            onSubmit={async (e) => {
              await mutationAddUnavailableDaysSusAppointment({
                objects: [
                  {
                    sus_appoitment_id: id,
                    date: e?.date
                  }
                ]
              })
              modal.setOptions({ open: false })
            }}
            groupFields={[
              {
                name: 'Adicionar horário indisponível',
                fields: [
                  {
                    label: 'Data:',
                    type: 'date',
                    required: true,
                    disabled: false,
                    hideField: false,
                    name: 'date',
                    columns: { xs: 12 },
                    validation: Yup.string().required('Campo obrigatório')
                  }
                ]
              }
            ]}
            button={{
              submit: 'Adicionar',
              submitting: 'Adicionando'
            }}
          />
        </CardModal>
      )
    })
  }

  const handleSelectTime = ({ start, end }) => {
    let canAdd = true
    schedulesAvailable.forEach((event) => {
      if (canAdd && verifyEventIsAvailable(start, end, event)) {
        canAdd = false
      }
    })

    if (canAdd) {
      const schedule = { start, end }
      const createdScheduleTemp = {
        day_of_week_id: Number(moment(start).format('d')) + 1,
        start_time: moment(start).format('HH:mm:ss'),
        end_time: moment(end).format('HH:mm:ss')
      }

      setSchedulesAvailable((prev) => [...prev, createdScheduleTemp])

      modal.setOptions({
        open: true,
        component: (
          <CardModal style={{ width: '50%' }}>
            <AppointmentForm
              type="create"
              values={createdScheduleTemp}
              submitEvent={handleCreateSusAppointment}
              button={{
                submit: 'Adicionar',
                submitting: 'Adicionando'
              }}
              complements={[createdScheduleTemp, schedule]}
              closeEvent={() => {
                modal.setOptions({ open: false })
              }}
            />
          </CardModal>
        )
      })
    }
  }

  useEffect(() => {
    if (susAppointments?.data && susAppointments?.data?.sus_appointment?.length) {
      const unvailableDaysTemp = []
      susAppointments?.data?.sus_appointment.forEach((item) => {
        item?.unavailable_days.forEach((i) => {
          unvailableDaysTemp.push(i)
        })
      })

      setUnavailableDays(unvailableDaysTemp)

      const events = []
      susAppointments?.data.sus_appointment?.forEach((item) => {
        const currentDay = moment(
          currentWeek()[getIdDay(item?.days_of_week?.full_text) - 1]
        ).format('YYYY, M, D ')

        events.push({
          id: item?.id,
          title: 'Disponível',
          color: docAvailableColor,
          start: new Date(
            currentDay +
              moment(`11/11/1111 ${item.start_time.replace('+00', '')}`).format(
                'HH:mm:ss'
              )
          ),
          end: new Date(
            currentDay +
              moment(`11/11/1111 ${item.end_time.replace('+00', '')}`).format(
                'HH:mm:ss'
              )
          ),
          full_data: item
        })
      })

      setSchedulesAvailable(events)
    }
  }, [susAppointments?.data])

  return (
    <InternalBody>
      <Row>
        <Col xs={12}>
          <h2>Horários disponíveis para agendamento (SUS)</h2>
        </Col>

        <Col xs={12}>
          <CalendarContainer>
            <WeekDays>
              <div>Hora</div>
              {weekDays.map((item) => (
                <div key={item.value}>{item.label}</div>
              ))}
            </WeekDays>
            <Calendar
              selectable
              localizer={localizer}
              events={schedulesAvailable}
              style={{ height: 2000, paddingBottoms: 200 }}
              defaultView={Views.WEEK}
              scrollToTime={new Date(1970, 1, 1, 6)}
              defaultDate={new Date()}
              onSelectEvent={(e) => {
                modal.setOptions({
                  open: true,
                  component: (
                    <CardModal style={{ width: '50%' }}>
                      <EditModalContent>
                        <ButtonPrimary
                          onClick={() => unavailableForm(e?.id)}
                          style={{
                            boxShadow: 'none',
                            fontSize: '12px',
                            padding: '5px 15px',
                            backgroundColor: customTheme.colors.primary,
                            lineHeight: '2',
                            display: 'flex',
                            width: 'fit-content',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '15px',
                            position: 'absolute',
                            right: 16
                          }}
                        >
                          Adicionar data indisponível
                          <AddCircleIcon
                            style={{
                              color: customTheme.colors.white,
                              marginLeft: 5
                            }}
                          />
                        </ButtonPrimary>

                        <AppointmentForm
                          type="update"
                          values={e.full_data}
                          submitEvent={handleUpdateSusAppointment}
                          button={{
                            submit: 'Salvar',
                            submitting: 'Salvando'
                          }}
                          complements={[e]}
                          closeEvent={() => {
                            modal.setOptions({ open: false })
                          }}
                        />

                        <div style={{ display: 'flex', marginTop: 30 }}>
                          <ButtonPrimary
                            id="delete-button"
                            onClick={() => {
                              document.getElementById(
                                'delete-button'
                              ).textContent = 'Apagando'
                              handleRemoveSusAppointment(e.id)
                            }}
                            style={{
                              boxShadow: 'none',
                              marginRight: 10
                            }}
                          >
                            Apagar
                          </ButtonPrimary>
                          <ButtonPrimary
                            onClick={() => {
                              modal.setOptions({ open: false })
                            }}
                            style={{
                              backgroundColor: customTheme.colors.error,
                              boxShadow: 'none',
                              marginLeft: 10
                            }}
                          >
                            Fechar
                          </ButtonPrimary>
                        </div>
                      </EditModalContent>
                    </CardModal>
                  )
                })
              }}
              onSelectSlot={handleSelectTime}
              messages={translateCalendar}
              eventPropGetter={(event) => {
                return {
                  style: {
                    fontSize: 12,
                    backgroundColor: event?.color,
                    border: 'none'
                  }
                }
              }}
            />
          </CalendarContainer>
        </Col>
      </Row>
      {!!unavailableDays?.length && (
        <>
          <Row>
            <Col xs={12} style={{ marginTop: 20 }}>
              <h4>Datas Indisponíveis</h4>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                  rows={unavailableDays}
                  columns={columns(mutationRemoveUnavailableDaysSUSAppointment)}
                  pageSize={perPage}
                  onPageSizeChange={(e) => {
                    setPerPage(e)
                  }}
                  rowsPerPageOptions={[10]}
                  pagination
                  disableSelectionOnClick
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </InternalBody>
  )
}

export default InternalSchedulesSus
