import React from 'react'
import ManageDoctorsHospitals from '../../master/manageDoctorsHospitals/ManageDoctors'
import { useAuth } from '../../../../contexts/authenticator'

function MyDoctorsHospital () {
  const { id } = useAuth()

  return <div>{id && <ManageDoctorsHospitals hospital_id={id} />}</div>
}

export default MyDoctorsHospital
