import { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { EditorState } from 'draft-js'
import { convertFromHTML, convertToHTML } from 'draft-convert'
import * as S from './styled'

function DraftTextArea ({ value = '', onChange, disabled, style }) {
  const _contentState = convertFromHTML(value)
  const [content, setContent] = useState(EditorState.createWithContent(_contentState))

  useEffect(() => {
    if (!disabled) {
      onChange(convertToHTML(content.getCurrentContent()))
    }
  }, [content])

  return (
    <S.Container style={style}>
      <Editor
        editorStyle={{ maxWidth: '100%' }}
        editorState={content}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={disabled ? undefined : setContent}
      />
    </S.Container>
  )
}

export default DraftTextArea
