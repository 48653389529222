import React from 'react'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { customTheme } from '../../../../styles/theme'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import { Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import CardModal from '.././../../components/CardModal'
import GenerateForm from '../../../components/layout/GenerateForm'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import DataTableActionColumn from '../../../components/DataTableActionColumn'

const handleEdit = (modal, updateEsp, params) => {
  modal.setOptions({
    open: true,
    component: (
      <CardModal>
        <DeleteOutlinedIcon
          style={{
            position: 'absolute',
            right: 15,
            cursor: 'pointer'
          }}
        />
        <GenerateForm
          onSubmit={(e, actions) => updateEsp(params.row.id, e, actions)}
          groupFields={groupFields(params.row)}
          button={{
            submit: 'Atualizar',
            submitting: 'Atualizando'
          }}
        />
      </CardModal>
    )
  })
}

export const columns = (modal, updateEsp, remove) => [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: 'photo_url',
    headerName: 'Imagem',
    width: 200,
    editable: true,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          {params.row.photo_url ? (
            <Avatar
              src={params.row.photo_url}
              style={{ width: 40, height: 40 }}
            />
          ) : (
            <AccountBalanceIcon
              style={{ color: customTheme.colors.primary }}
              edge="start"
              sx={{
                marginRight: '36px'
                // ...(open && { display: "none" }),
              }}
            />
          )}
        </Col>
      )
    }
  },
  {
    field: 'relevance',
    headerName: 'Relevância',
    sortable: false,
    width: 200
  },
  {
    field: 'expiration_date',
    headerName: 'Expira em',
    sortable: true,
    width: 200,
    valueGetter: (params) =>
      `${moment(params.row.expiration_date).format('YYYY-MM-DD') || ''} `,
    valueFormatter: (params) => moment(params.value).format('DD/MM/YYYY')
  },
  {
    field: 'created_at',
    headerName: 'Data de Cadastro',
    sortable: true,
    width: 200,
    valueGetter: (params) =>
      `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
    valueFormatter: (params) =>
      moment(params.value).format('DD/MM/YYYY - HH:MM')
  },
  {
    field: 'id',
    headerName: '',
    sortable: false,
    width: 60,
    renderCell: (params) => {
      return (
        <DataTableActionColumn handleEdit={() => handleEdit(modal, updateEsp, params)} />
      )
    }
  }
]

export const groupFields = (content, setContent) => {
  return [
    {
      name: 'Gerenciar Banner',
      fields: [
        {
          label: 'Imagem',
          type: 'file',
          required: true,
          disabled: false,
          hideField: false,
          name: 'icon',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido'),
          component: (data) => {
            return (
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  {data || content?.photo_url
                    ? (
                      <img
                        src={data || content?.photo_url}
                        style={{ maxWidth: '55%' }}
                      />
                      )
                    : (
                      <Col>
                        <p
                          style={{
                            width: '100%',
                            height: 400,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          Clique para adicionar uma imagem
                        </p>
                      </Col>
                      )}
                </Col>
              </Row>
            )
          }
        },
        {
          label: 'Link do Banner (Iphone)',
          type: 'textarea',
          required: true,
          disabled: false,
          currentValue: content?.link_url_iphone || '',
          hideField: false,
          name: 'link_url_iphone',
          height: 20,
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Link do Banner (Android)',
          type: 'textarea',
          required: true,
          disabled: false,
          currentValue: content?.link_url_android || '',
          hideField: false,
          name: 'link_url_android',
          height: 20,
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Relevância',
          type: 'number',
          required: true,
          disabled: false,
          currentValue: content?.relevance || '',
          hideField: false,
          name: 'relevance',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Expira em',
          type: 'date',
          required: true,
          disabled: false,
          currentValue:
            moment(content?.expiration_date).format('YYYY-MM-DD') || '',
          hideField: false,
          name: 'expiration_date',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]
}
