import React, { useState } from 'react'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { customTheme } from '../../../../styles/theme'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import {
  refactorLabelPlan
} from '../../../../Utils/format'
import ButtonPrimary from '../../../components/Button'
import { Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import country from '../../../../Utils/estados_cidades.json'
import { fecthPlans } from '../../../../services/plans'
import { useQuery } from '@apollo/client'
import { ModalConfirm } from '../../hospital/schedules-hospital/styled'
import CardModal from '../../../components/CardModal'
import { AuthService } from '../../../../services/auth/auth'
import DataTableActionColumn from '../../../components/DataTableActionColumn'
import { cpfValidator } from '../../../../services/validators/cpf_validator'
import { cepValidator } from '../../../../services/validators/cep_validator'
import { phoneValidator } from '../../../../services/validators/phone_validator'

// const handleEdit = (modal, history, params) => {
//   history.push(`clinics/${params.row.id}`)
// }

const handleDelete = async (modal, alertBox, remove, refetch, params) => {
  modal.setOptions({
    open: true,
    component: (
      <CardModal style={{ width: '400px' }}>
        <ModalConfirm>
          <h3>Tem certeza que deseja apagar?</h3>
          <div className="buttons-area">
            <ButtonPrimary
              id="delete-button"
              onClick={async () => {
                try {
                  document.getElementById('delete-button').disabled = true
                  const auth = new AuthService()

                  await auth.deleteAccount({
                    email: params.row.email,
                    type_of_account: 'clinic'
                  })

                  await refetch()
                  alertBox.setOptions({
                    open: true,
                    message: 'Sucesso ao apagar!',
                    type: 'success',
                    time: 3000
                  })
                  modal.setOptions({ open: false })
                } catch (error) {
                  const message = error?.response?.data?.error ?? 'Erro ao apagar clínica'
                  alertBox.setOptions({
                    open: true,
                    message: message,
                    type: 'error',
                    time: 3000
                  })
                  console.error(error)
                  modal.setOptions({ open: false })
                }
              }}
            >
              Sim
            </ButtonPrimary>

            <ButtonPrimary
              onClick={() => modal.setOptions({ open: false })}
              style={{
                backgroundColor: customTheme.colors.error,
                boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`
              }}
            >
              Não
            </ButtonPrimary>
          </div>
        </ModalConfirm>
      </CardModal>
    )
  })
}

const handleSendEmail = async (alertBox, params) => {
  try {
    const auth = new AuthService()
    await auth.recoveryPassword(params?.row?.email)
    alertBox.setOptions({
      open: true,
      message: 'Enviado com Sucesso!',
      type: 'success',
      time: 3000
    })
  } catch (error) {
    alertBox.setOptions({
      open: true,
      message: 'Erro ao enviar',
      type: 'error',
      time: 3000
    })
    console.error(error)
  }
}

export const columns = (modal, alertBox, remove, refetch, history) => [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: 'photo',
    headerName: 'Foto',
    width: 60,
    editable: true,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          {params.row.photo ? (
            <Avatar src={params.row.photo} />
          ) : (
            <AccountBalanceIcon
              style={{ color: customTheme.colors.primary }}
              edge="start"
              sx={{
                marginRight: '36px'
                // ...(open && { display: "none" }),
              }}
            />
          )}
        </Col>
      )
    }
  },
  {
    field: 'phone_number',
    headerName: 'Telefone',
    width: 200,
    editable: true,
    valueGetter: (params) => `${params.row.phone_number || ''} `
  },
  {
    field: 'company_name',
    headerName: 'Nome',
    width: 200,
    editable: true
  },
  {
    field: 'cnpj',
    headerName: 'CNPJ',
    width: 200,
    editable: true
  },
  {
    field: 'created_at',
    headerName: 'Data de Cadastro',
    sortable: true,
    width: 150,
    valueGetter: (params) =>
      `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
    valueFormatter: (params) =>
      moment(params.value).format('DD/MM/YYYY - HH:MM')
  },
  {
    field: 'id',
    headerName: '',
    sortable: false,
    width: 180,
    renderCell: (params) => {
      return (
        <DataTableActionColumn
          handleEdit={() => history.push(`clinics/${params.row.id}`)}
          handleDelete={() => handleDelete(modal, alertBox, remove, refetch, params)}
          handleSchedules={() =>
            history.push(`clinics/available-schedules/${params.row.id}`)}
          handleSendEmail={() => handleSendEmail(alertBox, params)}
        />
      )
    }
  }
]

export const groupFields = (content, setContent, isAdmin) => {
  const cities = []
  const states = []
  const [selectdState, setSelectedState] = useState(null)
  const { data } = useQuery(fecthPlans())
  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e.target.value }))
    }
  }
  const handleInputChangeAddress = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        adress: { ...prev.adress, [key]: e.target.value }
      }))
    }
  }
  const handleInputChangeCompanyPlan = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        company_plan: { ...prev.company_plan, [key]: e.target.value }
      }))
    }
  }

  return [
    {
      name: 'Sobre a Clínica',
      fields: [
        {
          label: 'Avatar',
          type: 'file',
          required: true,
          disabled: false,
          hideField: false,
          name: 'photo',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido'),
          component: (data) => {
            return (
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <Avatar
                    src={data || content?.photo}
                    style={{ width: 100, height: 100 }}
                  />
                </Col>
              </Row>
            )
          }
        },
        {
          label: 'Nome da Clínica',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.company_name,
          name: 'company_name',
          onChange: (e) => {
            handleInputChange(e, 'company_name')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'E-mail',
          type: 'email',
          required: true,
          disabled: !window.location.pathname.includes('/new'),
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'email')
          },
          currentValue: content?.email || '',
          name: 'email',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'CNPJ',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'cnpj')
          },
          currentValue: content?.cnpj,
          hideField: false,
          name: 'cnpj',
          mask: '99.999.999/9999-99',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório').test('cnpjNotComplete', 'O campo precisa ser preenchido totalmente!', () => !content?.cnpj?.includes('_'))
        },
        {
          label: 'Descrição',
          type: 'textarea',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'description')
          },
          currentValue: content?.description,
          hideField: false,
          name: 'description',
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Telefone',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'phone_number')
          },
          currentValue: content?.phone_number,
          hideField: false,
          mask: '+ 99 (99) 9 9999-9999',
          name: 'phone_number',
          columns: {
            xs: 6
          },
          validation:
          phoneValidator()
        },
        {
          label: 'Website URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'website')
          },
          currentValue: content?.website || 'https://',
          hideField: false,
          name: 'website',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Facebook URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'facebook')
          },
          currentValue: content?.facebook || 'https://',
          hideField: false,
          name: 'facebook',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Instagram URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'instagram')
          },
          currentValue: content?.instagram || 'https://',
          hideField: false,
          name: 'instagram',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Linkedin URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'linkedin')
          },
          currentValue: content?.linkedin || 'https://',
          hideField: false,
          name: 'linkedin',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        }
      ]
    },
    {
      name: 'Dados bancários e Tarifa',
      subtitle: !isAdmin && 'Para alterar esses dados, solicite ao Administrador',
      fields: [
        {
          label: 'Código do Banco Split',
          type: 'text',
          required: true,
          disabled: !isAdmin,
          onChange: (e) => {
            handleInputChangeCompanyPlan(e, 'codigo_split')
          },
          currentValue: content?.company_plan?.codigo_split || '',
          hideField: false,
          name: 'codigo_split',
          columns: {
            xs: 6
          }
        },
        {
          label: 'Tarifa',
          type: 'autocomplete',
          required: true,
          disabled: !isAdmin,
          currentValue: content?.company_plan?.plan_id
            ? {
                value: content?.company_plan?.plan_id,
                label: data?.plan
                  ?.filter((el) => el.id === content?.company_plan?.plan_id)
                  .map((item) => ({
                    label: refactorLabelPlan(item),
                    value: item.id
                  }))[0]?.label
              }
            : undefined,
          hideField: false,
          name: 'plan_id',
          onChange: (data) => {
            // setSelectedState(data);
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                company_plan: {
                  ...prev?.company_plan,
                  plan_id: data.value
                }
              }))
            }
          },
          options: data?.plan?.map((item) => ({
            label: refactorLabelPlan(item),
            value: item.id
          })),
          columns: {
            xs: 12
          },
          validation: Yup.object().required('Campo Obrigatório')
        }
      ]
    },
    {
      name: 'Endereço',
      fields: [
        {
          label: 'CEP',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'zipcode')
          },
          currentValue: content?.adress?.zipcode,
          hideField: false,
          mask: '99999-999',
          name: 'zipcode',
          columns: {
            xs: 4
          },
          validation:
          cepValidator()
        },
        {
          label: 'Logradouro',
          type: 'text',
          required: true,
          disabled: false,
          currentValue: content?.adress?.address || '',
          hideField: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'address')
          },
          name: 'address',
          columns: {
            xs: 8
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Bairro',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'neighborhood')
          },
          currentValue: content?.adress?.neighborhood || '',
          hideField: false,
          name: 'neighborhood',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Número',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'number')
          },
          currentValue: content?.adress?.number || '',
          hideField: false,
          name: 'number',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Latitude',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'latitude')
          },
          currentValue: content?.adress?.latitude || '',
          hideField: false,
          name: 'latitude',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Longitude',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'longitude')
          },
          currentValue: content?.adress?.longitude || '',
          hideField: false,
          name: 'longitude',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Estado',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content?.adress?.state
            ? {
                label: content?.adress?.state,
                value: content?.adress?.state_code
              }
            : undefined,
          hideField: false,
          name: 'state',
          onChange: (data) => {
            setSelectedState(data)
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  state: data.label,
                  state_code: data.value
                }
              }))
            }
          },
          options: states,
          columns: {
            xs: 6
          },
          validation: Yup.object().required('Campo obrigatório')
        },
        {
          label: 'Cidade',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content?.adress?.city
            ? {
                label: content?.adress?.city,
                value: content?.adress?.city
              }
            : undefined,
          hideField: false,
          name: 'city',
          columns: {
            xs: 6
          },
          onChange: (data) => {
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  city: data.label
                }
              }))
            }
          },
          options: cities.filter(
            (item) =>
              item.value === selectdState?.value ||
              item.value === content?.adress?.state_code
          ),
          validation: Yup.object().required('Campo obrigatório')
        },

        {
          label: 'Complemento',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'complement')
          },
          currentValue: content?.adress?.complement || '',
          hideField: false,
          name: 'complement',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        }
      ]
    }
  ]
}
