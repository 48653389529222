import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: 250px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px 8px;

  .rdw-image-modal, .rdw-embedded-modal, .rdw-emoji-modal, .rdw-link-modal {
    left: -230px;
  }

  .wrapperClassName {
    display: flex;
    flex-direction: column;
  }

  .editorClassName {
    max-width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: unset;
  }
`
