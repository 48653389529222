import { gql } from '@apollo/client'

export const createCompanyPlan = () => {
  return gql`
    ${fragmentCompanyPlan}
    mutation addCompanyPlan(
      $account: String
      $agency: String
      $bank_id: String
      $chave_pix: String
      $cnpj_account: String
      $cpf: String
      $plan_id: Int
      $transfer_of_interest_to_the_customer: Boolean = false
      $codigo_split: String
    ) {
      insert_company_plan_one(
        object: {
          account: $account
          agency: $agency
          bank_id: $bank_id
          chave_pix: $chave_pix
          cnpj_account: $cnpj_account
          cpf: $cpf
          plan_id: $plan_id
          transfer_of_interest_to_the_customer: $transfer_of_interest_to_the_customer
          codigo_split: $codigo_split
        }
      ) {
        ...companyPlanFields
      }
    }
  `
}

export const updateCompanyPlan = () => {
  return gql`
    ${fragmentCompanyPlan}
    mutation updateCompanyPlan(
      $id: Int!
      $account: String = ""
      $agency: String = ""
      $bank_id: String = ""
      $chave_pix: String = ""
      $cnpj_account: String = ""
      $cpf: String = ""
      $plan_id: Int
      $transfer_of_interest_to_the_customer: Boolean = false
      $codigo_split: String = ""
    ) {
      update_company_plan_by_pk(
        pk_columns: { id: $id }
        _set: {
          account: $account
          agency: $agency
          bank_id: $bank_id
          chave_pix: $chave_pix
          cnpj_account: $cnpj_account
          cpf: $cpf
          plan_id: $plan_id
          transfer_of_interest_to_the_customer: $transfer_of_interest_to_the_customer
          codigo_split: $codigo_split
        }
      ) {
        ...companyPlanFields
      }
    }
  `
}

export const fragmentCompanyPlan = gql`
  fragment companyPlanFields on company_plan {
    account
    agency
    bank_id
    chave_pix
    cnpj_account
    cpf
    created_at
    id
    plan_id
    transfer_of_interest_to_the_customer
    codigo_split
    updated_at
  }
`
