import { gql } from '@apollo/client'
import { fragmentCooperative } from '../cooperative'

export const fragmentcooperativeInstallment = gql`
  fragment cooperativeInstallmentPlans on cooperative_installment_plan {
    created_at
    fees
    id
    minimum_value
    updated_at
    number_of_installments
  }
`

export const removeCooperativeInstallmentPlan = () => {
  return gql`
    ${fragmentCooperative}
    ${fragmentcooperativeInstallment}
    mutation deleteCooperativeInstallmentPlanByPks($_in: [Int!] = 10) {
      delete_cooperative_installment_plan(where: { id: { _in: $_in } }) {
        affected_rows
        returning {
          cooperative {
            ...cooperativeFields
          }
        }
      }
    }
  `
}

export const createCooperativeInstallmentPlan = () => {
  return gql`
    ${fragmentCooperative}
    ${fragmentcooperativeInstallment}
    mutation insertCooperativeInstallmentPlan(
      $objects: [cooperative_installment_plan_insert_input!]!
    ) {
      insert_cooperative_installment_plan(objects: $objects) {
        affected_rows
        returning {
          cooperative {
            ...cooperativeFields
          }
        }
      }
    }
  `
}
export const updateCooperativeInstallmentPlan = () => {
  return gql`
    ${fragmentcooperativeInstallment}
    mutation updateCooperativeInstallmentPlanByPk(
      $id: Int!
      $fees: numeric = ""
      $minimum_value: numeric = ""
      $number_of_installments: Int = 10
    ) {
      update_cooperative_installment_plan_by_pk(
        pk_columns: { id: $id }
        _set: {
          fees: $fees
          minimum_value: $minimum_value
          number_of_installments: $number_of_installments
        }
      ) {
        ...cooperativeInstallmentPlans
      }
    }
  `
}
