import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { columns, groupFields } from './columns'
import InternalBody from '../../components/InternalBody'
import GenerateForm from '../../components/layout/GenerateForm'
import { fetchUsersById } from '../../../services/users'
import { CustomStyle } from './styled'
import { fetchSchedulesByUser } from '../../../services/schedules'
import { useAlertBox } from '../../../contexts/alert_box'
import { useModal } from '../../../contexts/modal'
import { DataGrid, ptBR } from '@mui/x-data-grid'

function InternalUser ({ match }) {
  const [perPage, setPerPage] = useState(5)
  const { data } = useQuery(fetchUsersById(), {
    variables: { id: match.params.id }
  })
  const shedulesData = useQuery(fetchSchedulesByUser(), {
    variables: { user_id: match.params.id }
  })
  const [content, setContent] = useState(null)
  const alertBox = useAlertBox()
  const modal = useModal()
  useEffect(() => {
    if (data?.users[0]) {
      setContent(data?.users[0])
    }
  }, [data])

  return (
    <CustomStyle>
      <InternalBody>
        <p>
          Criado em:{' '}
          {moment(content?.created_at).format('DD/MM/YYYY HH:MM') || ''}
        </p>

        <GenerateForm
          onSubmit={null}
          groupFields={groupFields(content, setContent)}
          button={{
            submit: match.params.id === 'new' ? 'Adicionar' : 'Atualizar',
            submitting:
              match.params.id === 'new' ? 'Adicionando...' : 'Atualizando...'
          }}
        />
        {shedulesData?.data?.schedules && (
          <div style={{ height: 400, width: '100%', marginTop: '30px' }}>
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={shedulesData?.data?.schedules}
              columns={columns(modal, null)}
              pageSize={perPage}
              onPageSizeChange={(e) => {
                setPerPage(e)
              }}
              rowsPerPageOptions={[10]}
              pagination
              disableSelectionOnClick
            />
          </div>
        )}
      </InternalBody>
    </CustomStyle>
  )
}

export default InternalUser
