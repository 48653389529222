import {
  ToggleButtonGroup,
  ToggleButton as MUIToggleButton,
  styled
} from '@mui/material'
import { fetchCurrentUserById } from '../../../../services/pacients'
import { customTheme } from '../../../../styles/theme'
import Header from '../../../components/Header'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { tabsData } from './tabsData'
import * as S from './styled'
import { ageCalculator } from '../../../../Utils/ageCalculator'

const primaryColor = customTheme.colors.primary

const ToggleButton = styled(MUIToggleButton)(() => {
  return {
    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'white',
      backgroundColor: primaryColor
    }
  }
})

function InternalPacients ({ match, isMaster }) {
  const [currentMainTab] = useState(0)
  const [currentSubTab, setCurrentSubTab] = useState(0)
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)

  const { data } = useQuery(fetchCurrentUserById(), {
    variables: { id: match.params.id }
  })

  const handleBack = () => {
    history.goBack()
  }

  return (
    <S.MainContainer>
      <Header
        title="Detalhes"
        labelAdd="Voltar"
        handleAdd={handleBack}
      />
      {data && (
        <>
          <S.Header>
            <div className="left">
              <img src={data.users_by_pk?.photo || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJQAAACUCAMAAABC4vDmAAAAMFBMVEXk5ueutLff4uOnrrHn6eqrsbTZ3N25vsHHy82zuLvr7e7c3+DV2NnQ09W8wcPMz9FRQ01gAAADeUlEQVR4nO2b23LjIAxAuYibMfj//3ZxkqZJmtggWyKzw3no9PGMEAJLRIjBYDAYDAaDwWAwGAwGg4EQACBUjFFd/vsGANScXbiS/Sy6awFYH7SRd4zWLqmuXmCdfDC6eRmZ+2kVJf1q9BOvTlog/N8o/WrJ1MEKbPistKIzv1PaNLoGKzI7LR+y6RnLuYTgt5fuHixGK0hVcbrEis1prlUqseJyEnVrd5Xi2oOuQUoaz5FWsLQ4lXrFUturk/yGo1eqrQa/mEQuZRuVVqjXrz1Qa6iorVozaoU4q1q33i1UxCezQzhR16qIcSpMlFIJs3qlgFKuH2SclFkI12/COZVjmVAKMAVhhbIoWGSkZFBkTi23uxfoMh1XOlcIyyfm4LtJ0d3V/zep+RulCCP1lYmOPPokZUkQ8xcWT9y9c4XymJmwUpQH8hRwTqRXT+z2I6wIAn1N0LTfWLgPh0x6RwePChTdIXORipj9F0idcPuPvkWFKOqaWEkgUp2hlwe2NatIz70bU1PLsywe5YfoHdWU6yaQ1qgfGlr7K0wDmqmho6BntkFItRVPF/3GzqzvHidOJ6GqtiBrnFYqVpDdaf8bsMsUGebNxDIu9pi3b43bjVw6GF2YbNbvtIz0PR9xgPLhOVzGyLD0fu8Cwi7ZaK2NWf+GvNjveBlULOycUppjcfwKo6I0PdI3TtenXDYtPjt3uc6E4HL2yxzV+ryrg9Gk5iJTsnzlMc9Lahnpsk8RJkYxEDF5qZ9k/paF4paXqFgiBlP0Tm76PBaskBO1VomRN2/r5YaYzpawlAKkN6/dKrRM8ESVAkqQ0C3PEi6Ce/H2Y7caLZlPfrdUjrjGTHqHdmdqQaq8k+9QonVWboEN2P7rXy1zToXA9ck+a7nj9QHiOSv3YHX4lQmkY3vuvZY/5uRPy6YnK3ekO4R8hrBvJfGJ1diJagJbss5O8ScM7rOQMk4riFgBtZM0zdmOHxfX09rzx0+LGzCuaQFx0452q7ZeEXLY2GzVkOwTQ0JdCfVSzUMFNPULCJnLqVBZF2BmC1T90BQ7UkdaVYUK0M80cFJ1WUV1X/lA1eRNsSqVD6+a2zH+5Q8St5/qE+rlwREqUh24naTc7zJgfi5wjP39x3JneZHa/b0Px+Xulf2iwHnu/bAnpYLmZ3f2rTqw5zQYDAaDwaAD/wANKir9WY4qAQAAAABJRU5ErkJggg=='} />
              <S.ProfileInfo>
                <S.Title>{data.users_by_pk?.full_name || data.users_by_pk?.email}</S.Title>
                <S.Text>{ageCalculator(data.users_by_pk?.birth_date)}</S.Text>
              </S.ProfileInfo>
            </div>
          </S.Header>

          <S.FlexContainer>
            <S.Header>
              <ToggleButtonGroup
                size="small"
                color="primary"
                value={currentSubTab}
                onChange={(e, newSubTab) => {
                  setIsLoading(true)
                  if (newSubTab !== null) {
                    setCurrentSubTab(newSubTab)
                  }
                  setTimeout(() => {
                    setIsLoading(false)
                  }, 100)
                }}
                exclusive
              >
                {tabsData.doctor[currentMainTab].tabs.map((t, idx) => {
                  return <ToggleButton key={idx} value={idx}>{t.name}</ToggleButton>
                })}
              </ToggleButtonGroup>
            </S.Header>
            {!isLoading && (
              <S.Panel>
                {tabsData.doctor[currentMainTab]?.tabs[currentSubTab]?.component(
                  data.users_by_pk
                ) || <div>Nada para mostrar...</div>}
              </S.Panel>
            )}
          </S.FlexContainer>
        </>
      )}
    </S.MainContainer>
  )
}

export default InternalPacients
