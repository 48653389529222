import React, { useEffect, useState } from 'react'
import MainContainer from '../../../components/MainContainer'
import Header from '../../../components/Header'
import Loading from '../../../components/layout/Loading'
import * as S from './styled'
import { fetchTermsOfUse } from '../../../../services/terms-of-use'
import { useQuery } from '@apollo/client'

function TermosOfUse ({ list }) {
  const [content, setContent] = useState('')
  const { data, loading } = useQuery(fetchTermsOfUse())

  useEffect(() => {
    if (data?.legal_documents_by_pk?.terms_of_use) {
      setContent(data?.legal_documents_by_pk?.terms_of_use)
    }
  }, [data])

  return (
    <MainContainer container>

      {loading && (<Loading />)}

      {!loading && <S.Paragraph dangerouslySetInnerHTML={{ __html: content }} />}
    </MainContainer>
  )
}

export default TermosOfUse
