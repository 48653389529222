import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { Rating } from '@mui/material'
import Avatar from '@material-ui/core/Avatar'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { useAlertBox } from '../../../../contexts/alert_box'
import {
  fetchDoctorById
} from '../../../../services/doctor'
import InternalBody from '../../../components/InternalBody'
import GenerateForm from '../../../components/layout/GenerateForm'
import { fecthEspecialities } from '../../../../services/medicalSpecialty'
import ButtonPrimary from '../../../components/Button'
import { customTheme } from '../../../../styles/theme'
import { useModal } from '../../../../contexts/modal'
import CardModal from '../../../components/CardModal'
import { Map } from '../../../components/Map'
import {
  createDoctorUseCase,
  updateDoctorUseCase
} from '../../../../main/usecases/doctors'
import { storage } from '../../../../services/firebase/firebase-config'
import { updateAddress } from '../../../../services/address'
import { updateAddressUseCase } from '../../../../main/usecases/addressess'
import { inserDoctorInClinic } from '../../../../services/clinics'
import { inserDoctorInHospital } from '../../../../services/hospitals'
import { stringToFloat } from '../../../../Utils/format'
import { useUserClaims } from '../../../../hooks/user.hook'
import { especiality, groupFields } from './columns'
import { ItemRate, Ratings, CustomStyle } from './styled'
import { inserDoctorInSus } from '../../../../services/sus'
import { getEntityId } from '../../../../services/decode_jwt/get_entity_id'
import { coordIsValid } from '../../../../Utils/coordValidate'
// import { getDataByZipCode } from '../../../../services/coordinates'
import country from '../../../../Utils/estados_cidades.json'
// import { useSearchZip } from '../../../../hooks/useSearchZip'
// import { getDataByZipCode } from '../../../../services/coordinates'
import { fetchAddressByZipCode } from '../../../../services/searchZip/searchZipcode'
import * as S from './styled'

function InternalDoctor ({ match, ...props }) {
  const [selectdState, setSelectedState] = useState(null)
  const especialities = useQuery(fecthEspecialities(), {})
  const { data, refetch, loading } = useQuery(fetchDoctorById(), {
    variables: { id: match.params.id }
  })
  const [mutationAddDoctor] = useMutation(inserDoctorInClinic())
  const [mutationAddDoctorHospital] = useMutation(inserDoctorInHospital())
  const [mutationAddDoctorSus] = useMutation(inserDoctorInSus())
  const [mutateFunctionAdress] = useMutation(updateAddress())
  const [content, setContent] = useState({})
  const alertBox = useAlertBox()
  const modal = useModal()
  const { location } = useHistory()
  const { account_info, account_entity_id } = location?.state ?? {}
  const [clinicId, setClinicId] = useState()
  const [entity, setEntity] = useState()
  const claims = useUserClaims()
  const [doctorSpecialties, setDoctorSpecialties] = useState([])
  const [isFirstRun, setIsFirtsRun] = useState(true)
  const states = []
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  useEffect(() => {
    setClinicId(
      getEntityId(claims) || props?.location?.state?.entity_id
    )

    getEntity()
  }, [])

  useEffect(() => {
    if (data) {
      if (data?.doctors_by_pk) {
        setContent(
          (prevContent) => ({
            ...prevContent,
            ...data?.doctors_by_pk,
            birthdate: data?.doctors_by_pk.birthdate
              ? moment(data?.doctors_by_pk.birthdate, 'YYYY-MM-DD')
                .format('YYYY-MM-DD')
              : ''
          })
        )

        const specialties = data?.doctors_by_pk?.doctors_with_specialties

        if (specialties && specialties.length > 0) {
          setDoctorSpecialties(specialties.map(e => e.medical_specialty))
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (content?.adress?.zipcode && !isFirstRun) {
      fetchAddressByZipCode(
        content?.adress?.zipcode,
        content,
        setContent,
        states,
        alertBox
      ).then(() => { })
    }

    if (data?.doctors_by_pk.adress?.zipecode !== content?.adress?.zipcode) {
      setIsFirtsRun(false)
    }
  }, [content?.adress?.zipcode])

  const getEntity = () => {
    switch (location?.hash) {
      case '#hospitals':
        setEntity('hospitals')
        break
      case '#sus':
        setEntity('sus')
        break

      default:
        setEntity('clinics')
    }
  }

  const getMutation = () => {
    switch (entity) {
      case 'hospitals':
        return mutationAddDoctorHospital
      case 'sus':
        return mutationAddDoctorSus
      default:
        return mutationAddDoctor
    }
  }

  const getKey = () => {
    switch (entity) {
      case 'hospitals':
        return 'hospital_id'
      case 'sus':
        return 'sus_id'
      default:
        return 'clinic_id'
    }
  }

  const filterEspecialities = (all, used) => {
    return all.filter(
      (item) => !used?.find((el) => el.medical_specialty.id === item.id)
    )
  }

  const viewEspecialisties = async (esp) => {
    const submitEspecialty = async (e, actions) => {
      setDoctorSpecialties((prevDoctorSpecialties) => [
        ...prevDoctorSpecialties,
        e.doctors_with_specialties
      ])
      modal.setOptions({ open: false })
    }

    modal.setOptions({
      open: true,
      component: (
        <CardModal
          style={{ width: 500 }}
          title={`${esp?.name ?? 'Especialidade'}`}
          avatar={false}
          subtitle={!esp ? 'Adicionar nova especialidade' : 'Remover especialidade'}
        >
          {!esp && (<GenerateForm
            onSubmit={submitEspecialty}
            groupFields={especiality(
              esp,
              esp
                ? especialities?.data?.medical_specialties
                : filterEspecialities(
                  especialities?.data?.medical_specialties,
                  content.doctors_with_specialties
                )
            )}
            button={{
              submit: location.pathname.includes('new')
                ? 'Novo'
                : 'Novo',
              submitting: location.pathname.includes('new')
                ? 'Criando...'
                : 'Atualizando'
            }}
                    />)}
          {esp && (
            <div style={{ display: 'flex', gap: '10px' }}>
              <ButtonPrimary
                style={{
                  padding: '10px',
                  backgroundColor: '#b61827'
                }}
                onClick={async () => {
                  setDoctorSpecialties((prevDoctorSpecialties) => {
                    // Suponha que você queira remover um item específico da lista, por exemplo, o item com ID igual a "idToRemove"
                    const updatedSpecialties = prevDoctorSpecialties.filter(
                      specialty => specialty.id !== esp.id
                    )
                    return updatedSpecialties
                  })
                  modal.setOptions({ open: false })
                }}
              >
                Remover
              </ButtonPrimary>

              <ButtonPrimary
                style={{
                  padding: '10px',
                  backgroundColor: '#1565c0' // Change color for "Voltar" button
                }}
                onClick={() => {
                  modal.setOptions({ open: false })
                }}
              >
                Voltar
              </ButtonPrimary>
            </div>
          )}

        </CardModal>
      )
    })
  }

  const updateMutationRequest = async (e, url, actions) => {
    console.log(e)
    const profileImage = url || (e?.profile_image ?? '')
    await updateAddressUseCase(
      mutateFunctionAdress,
      {
        id: content.adress?.id,
        address: e.address,
        complement: e.complement,
        latitude: e.latitude,
        longitude: e.longitude,
        neighborhood: e.neighborhood,
        number: e.number,
        city: e.city.label,
        state: e.state.label,
        state_code: e.state.value,
        zipcode: e.zipcode
      },
      {
        actions,
        alertBox
      }
    )
    const res = await updateDoctorUseCase(
      {
        id: match.params.id,
        about: e.about,
        average_cost_of_medical_consultation:
          stringToFloat(e?.average_cost_of_medical_consultation) ||
          e?.average_cost_of_medical_consultation,
        crm: e?.crm.toString(),
        full_name: e?.full_name,
        linkedin: e?.linkedin,
        main_specialty: null,
        profile_image: profileImage,
        email: e?.email,
        rg_uf: e.rg_uf,
        rg_number: e.rg_number,
        sex: e.sex?.value,
        birthdate: e.birthdate,
        phone_number: e.phone_number,
        cpf: e.cpf,
        specialties: doctorSpecialties.map((e) => e.id)
      },
      {
        actions,
        alertBox
      }
    )

    if (res.sucess) {
      refetch()
    }
  }

  const createMutationRequest = async (e, url, actions) => {
    const mutation = getMutation()
    const key = getKey()
    const parsedAccountEntityId = account_entity_id ? parseInt(account_entity_id) : null

    await createDoctorUseCase(
      {
        account_entity_id: parsedAccountEntityId,
        account_info: account_info,
        full_name: e.full_name,
        about: e.about,
        crm: String(e.crm),
        main_specialty: null,
        average_cost_of_medical_consultation:
          stringToFloat(e.average_cost_of_medical_consultation) || 0,
        profile_image: url || '',
        email: e.email,
        address: e.address || '',
        city: e.city?.label || '',
        complement: e.complement || '',
        neighborhood: e.neighborhood || '',
        number: e.number || '',
        state: e.state?.label || '',
        state_code: e.city?.value || '',
        zipcode: e.zipcode || '',
        linkedin: e.linkedin || '',
        rg_uf: e.rg_uf || '',
        rg_number: e.rg_number || '',
        sex: e.sex?.value || '',
        cpf: e.cpf || '',
        birthdate: e.birthdate || '',
        specialties: doctorSpecialties.map((e) => e.id),
        phone_number: e.phone_number || ''
      },
      {
        actions,
        alertBox
      },
      mutation,
      {
        [key]: clinicId
      }
    )
  }

  const onSubmit = async (e, actions) => {
    if (location.pathname.includes('new')) {
      if (e.file) {
        const storageRef = ref(storage, `doctors/${new Date().getTime()}`)
        const uploadTask = uploadBytesResumable(storageRef, e.file)

        uploadTask.on('state_changed', null, console.error, () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            await createMutationRequest(e, url, actions)
          })
        })
      } else {
        await createMutationRequest(e, null, actions)
      }
    } else {
      if (e.file) {
        const storageRef = ref(storage, `doctors/${new Date().getTime()}`)
        const uploadTask = uploadBytesResumable(storageRef, e.file)

        uploadTask.on('state_changed', null, console.error, () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            await updateMutationRequest(e, url, actions)
          })
        })
      } else {
        await updateMutationRequest(e, null, actions)
      }
    }
  }

  if (loading) return <span>Carregando...</span>

  return (
    <>
      {especialities?.data?.medical_specialties && (
        <CustomStyle>
          <InternalBody>
            {content && (
              <>
                <p>
                  Criado em:{' '}
                  {moment(content?.created_at).format('DD/MM/YYYY HH:MM') || ''}
                </p>
                <Ratings>
                  <ItemRate>
                    <b>Avaliações:</b>

                    <Rating
                      name="read-only"
                      value={content?.average_of_evaluations}
                      readOnly
                    />
                  </ItemRate>
                </Ratings>

                <Row>
                  <S.SpecialitiesBox>
                    {doctorSpecialties.map((item) => (
                      <S.ButtonSpeciality onClick={() => viewEspecialisties(item)}>
                        <Avatar
                          alt={item.name}
                          src={item.icon}
                        />
                        {item.name}
                      </S.ButtonSpeciality>
                    ))}
                  </S.SpecialitiesBox>

                  <Col xs={2}>
                    <ButtonPrimary
                      onClick={() => viewEspecialisties(null)}
                      style={{
                        marginBottom: '30px',
                        boxShadow: 'none',
                        fontSize: '12px',
                        padding: '5px',
                        backgroundColor: 'transparent',
                        lineHeight: '2',
                        width: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <AddCircleIcon
                        style={{ color: customTheme.colors.primary }}
                      />
                    </ButtonPrimary>
                  </Col>
                </Row>
              </>
            )}
            <GenerateForm
              onSubmit={onSubmit}
              groupFields={groupFields(
                content,
                setContent,
                especialities?.data?.medical_specialties,
                selectdState,
                setSelectedState
              )}
              button={{
                submit: window.location.pathname.includes('new')
                  ? 'Adicionar'
                  : 'Atualizar',
                submitting: window.location.pathname.includes('new')
                  ? 'Adicionando...'
                  : 'Atualizando...'
              }}
            />
            {!!content.adress && coordIsValid(
              content.adress?.latitude
            ) && coordIsValid(
              content.adress?.longitude
            ) && (
              <Map content={content} />
            )}
          </InternalBody>
        </CustomStyle>
      )}
    </>
  )
}

export default InternalDoctor
