import { Container } from 'react-bootstrap'
import * as S from './styled'

function MainContainer ({ children, container }) {
  if (container) {
    return (
      <S.Container>
        <Container>
          {children}
        </Container>
      </S.Container>
    )
  }

  return (
    <S.Container>
      {children}
    </S.Container>
  )
}

export default MainContainer
