import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'

export const Avatar = styled.img`
  aspect-ratio: 1/1;
  max-width: 24px;
  border-radius: 50%;
  object-fit: cover;
`

export const WeekForm = styled.form`
  display: flex;
  justify-content: space-between;
`

export const WeekDayForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;

  h4 {
    font-size: 12px;
  }
`

export const ButtonAttach = styled.label`
  padding: 15px;
  background-color: #777;
  color: #fff;
  width: 100%;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  line-height: 0;
  margin-bottom: 15px;
  cursor: pointer;
  svg {
    margin: 0;
  }
`
export const WeekFormInput = styled.div`
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
  }
`

export const CalendarContainer = styled.div`
  width: 100%;
  height: 500px;
  margin: 25px 0 100px;

  .rbc-time-content {
    ::-webkit-scrollbar {
      width: 7px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }
  }

  .rbc-header,
  .rbc-toolbar,
  .rbc-time-header.rbc-overflowing,
  .rbc-time-header-content {
    display: none !important;
  }
  .rbc-today {
    background-color: unset;
  }
`

export const WeekDays = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fefefe;
  width: 100%;
  border: 0.5px #ccc solid;

  div {
    text-align: center;
    width: 100%;
    padding: 3px 9px;
    border-right: 1px #ddd solid;
    border-collapse: collapse;

    &:first-child {
      width: auto;
    }

    &:last-child {
      border-right: 9px #fff solid;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ModalContainer = styled.div`
  .button-submit-generate-form-container {
    display: none;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;

    button {
      width: 30%;
    }
  }
`

export const ConfirmModal = styled.div`
  h2 {
    font-size: 20px;
    text-align: center;
    line-height: 2;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
      width: 46%;
      box-shadow: none;
      padding: 10px 0;
    }
  }
`

export const FilterContainer = styled.div`
  position: relative;

  .button-submit-generate-form-container {
    width: 25%;
    margin-top: -5px !important;

    button.button-submit-generate-form {
      margin: 0 !important;
      box-shadow: none;
      font-size: 14px;
      padding: 5px;
      background: ${customTheme.colors.primary};
      line-height: 2;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
