import { useState } from 'react'
import country from '../../../Utils/estados_cidades.json'

export const groupFields = () => {
  const cities = [{ label: '-', value: '' }]
  const states = [{ label: '-', value: '' }]
  const [selectdState, setSelectedState] = useState(null)
  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  return [
    {
      fields: [
        {
          label: 'Nome',
          type: 'text',
          required: false,
          disabled: false,
          hideField: false,
          partialSubmit: true,
          name: 'name',
          columns: {
            xs: 4
          }
        },
        {
          label: 'Estado',
          type: 'autocomplete',
          required: false,
          disabled: false,
          name: 'state',
          onChange: (data) => {
            setSelectedState(data)
          },
          options: states,
          columns: {
            xs: 4
          }
        },
        {
          label: 'Cidade',
          type: 'autocomplete',
          required: false,
          disabled: false,
          hideField: false,
          name: 'city',
          columns: {
            xs: 4
          },
          options: cities.filter(
            (item) =>
              item.value === selectdState?.value
          )
        }
      ]
    }
  ]
}
