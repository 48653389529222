import * as S from './styled'

function DashboardBox ({ color, title, number, subtitle, icon }) {
  return (
    <S.Container>
      <S.IconBox $color={color}>
        {icon}
      </S.IconBox>
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.Number>{number}</S.Number>
        {subtitle && <S.SubTitle>{subtitle}</S.SubTitle>}
      </S.Content>
    </S.Container>
  )
}

export default DashboardBox
