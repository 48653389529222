import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { GuardProvider } from 'react-router-guards'
import { guardsRoutes } from './utils/functions'
import { AuthLayout, BaseLayout, PublicLayout } from '../presentation/components/layout'
import Route from './route'
import {
  Login,
  PageNotFound,
  Homepage,
  UsersPage,
  Clinics,
  InternalClinic,
  Specialties,
  Plans,
  InternalPlan,
  Cooperatives,
  InternalCooperatives,
  CreditRequest,
  InternalCreditRequest,
  SchedulesClinic,
  SchedulesDoctors,
  Payments,
  Hospitals,
  InternalHospital,
  Sus,
  InternalSus,
  Room,
  InternalUser,
  Suport
} from '../presentation/pages'
import AlertBoxProvider from '../contexts/alert_box'
import { ApolloProvider } from '@apollo/client'
import client from '../services/api/client'
import ModalProvider from '../contexts/modal'
import Alert from '../presentation/components/AlertBox'
import ModalBox from '../presentation/components/Modal'
import { useAuth } from '../contexts/authenticator'
import Doctors from '../presentation/pages/master/doctors'
import InternalDoctor from '../presentation/pages/master/doctors/Internal'
import Banners from '../presentation/pages/master/banners'
import {
  ClinicsByDoctor,
  DoctorProfile,
  SchedulesByDoctors,
  PacientsPage,
  InternalPacients,
  DoctorSUS,
  InternalDoctorSUS,
  DoctorDashboard,
  HospitalsbyDoctor
} from '../presentation/pages/doctor'
import {
  ClinicProfile,
  MyDoctors,
  PaymentsClinics,
  SchedulesByClinics
} from '../presentation/pages/clinics'
import InternalSchedulesDoctors from '../presentation/pages/doctor/schedules-doctors/Internal'
import InternalSchedulesClinic from '../presentation/pages/clinics/schedules-clinics/Internal'
import {
  HospitalProfile,
  MyDoctorsHospital,
  PaymentsHospitals
} from '../presentation/pages/hospital'
import { PaymentsSus, SusProfile } from '../presentation/pages/sus'
import InternalSchedulesHospital from '../presentation/pages/hospital/schedules-hospital/Internal'
import InternalSchedulesSus from '../presentation/pages/sus/schedules-sus/Internal'
import SchedulesBySUS from '../presentation/pages/sus/schedules'
import SchedulesByHospitals from '../presentation/pages/hospital/schedules'
import PharmaciePage from '../presentation/pages/master/pharmacies'
import AssessmentCalls from '../presentation/pages/master/assessment-calls'
import InternalPharmacie from '../presentation/pages/master/pharmacies/Internal'
import OpticsPage from '../presentation/pages/master/optics'
import InternalOptics from '../presentation/pages/master/optics/Internal'
import InternalPetshop from '../presentation/pages/master/petshops/Internal'
import PetshopPage from '../presentation/pages/master/petshops'
import Recovery from '../presentation/pages/auth/recoveryPass'
import PetshopProfile from '../presentation/pages/petshop/profile'
import PaymentsPetshop from '../presentation/pages/petshop/payments'
import InternalSchedulesPetshop from '../presentation/pages/petshop/schedules-petshop/Internal'
import SchedulesByPetshop from '../presentation/pages/petshop/schedules'
import LaboratoriesPage from '../presentation/pages/master/laboratories'
import InternalLaboratories from '../presentation/pages/master/laboratories/Internal'
import LaboratoriesProfile from '../presentation/pages/laboratories/profile'
import RequestLaboratories from '../presentation/pages/laboratories/schedules'
import Paymentslaboratories from '../presentation/pages/laboratories/payments'
import OdontologyPage from '../presentation/pages/master/odontology'
import InternalOdontology from '../presentation/pages/master/odontology/Internal'
import {
  OdontologyPayments,
  OdontologyProfile,
  OdontologyRequests
} from '../presentation/pages/odontology/index'
import DianosticImagePage from '../presentation/pages/master/diagnostic-image'
import InternalDiagnosticImage from '../presentation/pages/master/diagnostic-image/Internal'
import PaymentsDiagnosticImage from '../presentation/pages/diagnostico-image/payments'
import RequestDiagnosticImage from '../presentation/pages/diagnostico-image/schedules'
import DiagnosticimageProfile from '../presentation/pages/diagnostico-image/profile'
import SusMyDoctors from '../presentation/pages/sus/my-doctors/my-doctors'
import WaitingRooms from '../presentation/pages/sus/waiting-rooms'
import WaitingRoom from '../presentation/pages/doctor/waiting-rooms'
import WaitingRoomUsers from '../presentation/pages/master/waiting-room-users'
import InternalMyClinics from '../presentation/pages/doctor/myClinics/Internal'
import InternalMyHospitals from '../presentation/pages/doctor/my-hospitals/internal'
import InternalScheduleDoctorDetails from '../presentation/pages/doctor/schedules/schedulesDetails'
import AllPacientsPage from '../presentation/pages/master/pacients'
import AllInternalPacients from '../presentation/pages/master/pacients/InternalPacients'
import TermsOfUse from '../presentation/pages/public/terms-of-use'
import PrivacyPolicy from '../presentation/pages/public/privacy-policy'
import MasterTermsOfUse from '../presentation/pages/master/terms-of-use'
import MasterPrivacyPolicy from '../presentation/pages/master/privacy-policy'
import PrescriptionView from '../presentation/pages/doctor/schedules/schedulesDetails/prescriptionView'
import PrescriptionSucceded from '../presentation/pages/prescription/PrescriptionSucceded'

const Routes = () => {
  const { id, token } = useAuth()

  const routes = [
    {
      layout: PublicLayout,
      data: [
        { path: '/terms-of-use', component: TermsOfUse, meta: { auth: false } },
        { path: '/privacy-policy', component: PrivacyPolicy, meta: { auth: false } },
        { path: '/prescription-succeded/:scheduleId', component: PrescriptionSucceded, meta: { auth: true } }
      ]
    },
    {
      layout: AuthLayout,
      data: [
        { path: '/login', component: Login, meta: { auth: false } },
        { path: '/reset-password', component: Recovery, meta: { auth: false } }
        // { path: '/criar-nova-senha', component: CreateNewPassword },
        // { path: '/confirmar-usuario', component: ConfirmSignUp },
        // { path: '/esqueci-minha-senha', component: ForgotPassword },
        // { path: '/recuperar-senha', component: PasswordRecovery },
        // { path: '/sair', component: Logout },
        // { path: '/403', component: NoAuthorization },
        // { path: '/5:number', component: ServerErrors },
        // { path: '/formulario', component: Formulario }
      ]
    },
    {
      layout: BaseLayout,
      data: [
        {
          path: '/room/:id&callId=:callId',
          component: Room,
          meta: { auth: true }
        },
        {
          path: '/payments-diagnostic-image',
          component: PaymentsDiagnosticImage,
          meta: { auth: true }
        },
        {
          path: '/payments-laboratories',
          component: Paymentslaboratories,
          meta: { auth: true }
        },
        {
          path: '/payments-petshop',
          component: PaymentsPetshop,
          meta: { auth: true }
        },
        {
          path: '/payments-sus',
          component: PaymentsSus,
          meta: { auth: true }
        },
        {
          path: '/sus-profile',
          component: SusProfile,
          meta: { auth: true }
        },
        {
          path: '/petshop-profile',
          component: PetshopProfile,
          meta: { auth: true }
        },
        {
          path: '/diagnostic-image-requests',
          component: RequestDiagnosticImage,
          meta: { auth: true }
        },
        {
          path: '/laboratorie-requests',
          component: RequestLaboratories,
          meta: { auth: true }
        },
        {
          path: '/diagnostic-image-profile',
          component: DiagnosticimageProfile,
          meta: { auth: true }
        },
        {
          path: '/laboratories-profile',
          component: LaboratoriesProfile,
          meta: { auth: true }
        },
        {
          path: '/odontology-profile',
          component: OdontologyProfile,
          meta: { auth: true }
        },
        {
          path: '/odontology-payments',
          component: OdontologyPayments,
          meta: { auth: true }
        },
        {
          path: '/odontology-requests',
          component: OdontologyRequests,
          meta: { auth: true }
        },
        {
          path: '/sus/my-doctors',
          component: SusMyDoctors,
          meta: { auth: true }
        },
        { path: '/sus/:id', component: InternalSus, meta: { auth: true } },
        { path: '/sus', component: Sus, meta: { auth: true } },
        {
          path: '/payments-hospital',
          component: (props) => <PaymentsHospitals {...props} user_id={id} />,
          meta: { auth: true }
        },
        {
          path: '/payments-clinic',
          component: (props) => <PaymentsClinics {...props} user_id={id} />,
          meta: { auth: true }
        },
        {
          path: '/payments',
          component: Payments,
          meta: { auth: true }
        },
        {
          path: '/credit-request/:id',
          component: InternalCreditRequest,
          meta: { auth: true }
        },
        {
          path: '/credit-request',
          component: CreditRequest,
          meta: { auth: true }
        },
        {
          path: '/cooperatives/:id',
          component: InternalCooperatives,
          meta: { auth: true }
        },
        {
          path: '/cooperatives/new',
          component: InternalCooperatives,
          meta: { auth: true }
        },
        {
          path: '/cooperatives',
          component: Cooperatives,
          meta: { auth: true }
        },

        { path: '/hospital', component: Hospitals, meta: { auth: true } },
        {
          path: '/hospital/:id',
          component: InternalHospital,
          meta: { auth: true }
        },

        { path: '/plans/:id', component: InternalPlan, meta: { auth: true } },
        { path: '/plans/new', component: InternalPlan, meta: { auth: true } },
        { path: '/plans', component: Plans, meta: { auth: true } },

        { path: '/banners', component: Banners, meta: { auth: true } },

        { path: '/specialties', component: Specialties, meta: { auth: true } },

        { path: '/clinics', component: Clinics, meta: { auth: true } },
        {
          path: '/hospital-profile',
          component: HospitalProfile,
          meta: { auth: true }
        },
        {
          path: '/clinic-profile',
          component: (props) => <ClinicProfile {...props} user_id={id} />,
          meta: { auth: true }
        },
        {
          path: '/my-doctors',
          component: (props) => <MyDoctors {...props} user_id={id} />,
          meta: { auth: true }
        },
        {
          path: '/my-doctors-hospital',
          component: MyDoctorsHospital,
          meta: { auth: true }
        },
        {
          path: '/clinics/:id',
          component: InternalClinic,
          meta: { auth: true }
        },
        {
          path: '/clinics/new',
          component: InternalClinic,
          meta: { auth: true }
        },
        {
          path: '/my-clinics',
          component: (props) => <ClinicsByDoctor {...props} user_id={id} />,
          meta: { auth: true }
        },
        {
          path: '/my-clinics/:id',
          component: InternalMyClinics,
          meta: { auth: true }
        },
        {
          path: '/pacients',
          component: (props) => <AllPacientsPage {...props} user_id={id} />,
          meta: { auth: true }
        },
        {
          path: '/pacients/:id',
          component: AllInternalPacients,
          meta: { auth: true }
        },
        {
          path: '/my-pacients',
          component: (props) => <PacientsPage {...props} user_id={id} />,
          meta: { auth: true }
        },
        {
          path: '/my-pacients/:id',
          component: InternalPacients,
          meta: { auth: true }
        },
        {
          path: '/my-list-sus',
          component: (props) => <DoctorSUS {...props} user_id={id} />,
          meta: { auth: true }
        },
        {
          path: '/my-list-sus/:id',
          component: InternalDoctorSUS,
          meta: { auth: true }
        },
        {
          path: '/doctor/my-waiting-rooms/:id',
          component: WaitingRoom,
          meta: { auth: true }
        },
        {
          path: '/doctor/waiting-room/:id',
          component: WaitingRoomUsers,
          meta: { auth: true }
        },
        {
          path: '/doctor-profile',
          component: (props) => <DoctorProfile {...props} user_id={id} />,
          meta: { auth: true }
        },
        {
          path: '/doctor-dashboard',
          component: (props) => <DoctorDashboard {...props} user_id={id} />
        },
        {
          path: '/my-hospitals',
          component: (props) => <HospitalsbyDoctor {...props} user_id={id} />,
          meta: { auth: true }
        },
        {
          path: '/my-hospitals/:id',
          component: InternalMyHospitals,
          meta: { auth: true }
        },
        { path: '/doctors', component: Doctors, meta: { auth: true } },
        {
          path: '/doctors/new',
          component: (props) => <InternalDoctor {...props} />,
          meta: { auth: true }
        },
        {
          path: '/doctors/:id',
          component: InternalDoctor,
          meta: { auth: true }
        },
        {
          path: '/clinics-requests-schedules',
          component: (props) => <SchedulesByClinics {...props} user_id={id} />,
          meta: { auth: true }
        },
        {
          path: '/doctor-requests-schedules',
          component: (props) => <SchedulesByDoctors {...props} user_id={id} />,
          meta: { auth: true }
        },
        {
          path: '/doctor-requests-schedules/:id',
          component: InternalScheduleDoctorDetails,
          meta: { auth: true }
        },
        {
          path: '/hospital-requests-schedules',
          component: SchedulesByHospitals,
          meta: { auth: true }
        },
        {
          path: '/petshop-requests-schedules',
          component: SchedulesByPetshop,
          meta: { auth: true }
        },
        {
          path: '/sus-requests-schedules',
          component: SchedulesBySUS,
          meta: { auth: true }
        },
        {
          path: '/sus-waiting-rooms',
          component: WaitingRooms,
          meta: { auth: true }
        },
        {
          path: '/schedules-available',
          component: SchedulesClinic,
          meta: { auth: true }
        },

        {
          path: '/schedules-available-doctors',
          component: SchedulesDoctors,
          meta: { auth: true }
        },

        {
          path: '/doctor-available-schedules',
          component: InternalSchedulesDoctors,
          meta: { auth: true }
        },
        {
          path: '/doctor-available-schedules/:clinic_id/:id',
          component: InternalSchedulesDoctors,
          meta: { auth: true }
        },

        {
          path: '/clinics-available-schedules',
          component: InternalSchedulesClinic,
          meta: { auth: true }
        },
        {
          path: '/clinics-available-schedules/:id',
          component: InternalSchedulesClinic,
          meta: { auth: true }
        },

        {
          path: '/hospital-available-schedules',
          component: InternalSchedulesHospital,
          meta: { auth: true }
        },
        {
          path: '/hospital-available-schedules/:id',
          component: InternalSchedulesHospital,
          meta: { auth: true }
        },

        {
          path: '/sus-available-schedules',
          component: InternalSchedulesSus,
          meta: { auth: true }
        },
        {
          path: '/sus-available-schedules/:id',
          component: InternalSchedulesSus,
          meta: { auth: true }
        },
        {
          path: '/petshop/available-schedules',
          component: InternalSchedulesPetshop,
          meta: { auth: true }
        },
        {
          path: '/petshop/available-schedules/:id',
          component: InternalSchedulesPetshop,
          meta: { auth: true }
        },
        {
          path: '/diagnostic-images/:id',
          component: InternalDiagnosticImage,
          meta: { auth: true }
        },
        {
          path: '/diagnostic-images/new',
          component: InternalDiagnosticImage,
          meta: { auth: true }
        },
        {
          path: '/diagnostic-images',
          component: DianosticImagePage,
          meta: { auth: true }
        },
        {
          path: '/laboratories/:id',
          component: InternalLaboratories,
          meta: { auth: true }
        },
        {
          path: '/laboratories/new',
          component: InternalLaboratories,
          meta: { auth: true }
        },
        {
          path: '/laboratories',
          component: LaboratoriesPage,
          meta: { auth: true }
        },
        {
          path: '/odontology',
          component: OdontologyPage,
          meta: { auth: true }
        },
        {
          path: '/odontology/new',
          component: InternalOdontology,
          meta: { auth: true }
        },
        {
          path: '/odontology/:id',
          component: InternalOdontology,
          meta: { auth: true }
        },
        { path: '/petshops', component: PetshopPage, meta: { auth: true } },
        {
          path: '/petshops/new',
          component: InternalPetshop,
          meta: { auth: true }
        },
        {
          path: '/petshops/:id',
          component: InternalPetshop,
          meta: { auth: true }
        },
        { path: '/optics', component: OpticsPage, meta: { auth: true } },
        {
          path: '/optics/new',
          component: InternalOptics,
          meta: { auth: true }
        },
        {
          path: '/optics/:id',
          component: InternalOptics,
          meta: { auth: true }
        },
        { path: '/pharmacies', component: PharmaciePage, meta: { auth: true } },
        {
          path: '/pharmacies/new',
          component: InternalPharmacie,
          meta: { auth: true }
        },
        {
          path: '/pharmacies/:id',
          component: InternalPharmacie,
          meta: { auth: true }
        },
        {
          path: '/assessment-calls',
          component: AssessmentCalls,
          meta: { auth: true }
        },
        {
          path: '/prescription',
          component: PrescriptionView,
          meta: { auth: true }
        },
        { path: '/admin/terms-of-use', component: MasterTermsOfUse, meta: { auth: true } },
        { path: '/admin/privacy-policy', component: MasterPrivacyPolicy, meta: { auth: true } },
        { path: '/suport', component: Suport, meta: { auth: true } },
        { path: '/users/:id', component: InternalUser, meta: { auth: true } },
        { path: '/users', component: UsersPage, meta: { auth: true } },
        { path: '/', component: Homepage, meta: { auth: true } },
        { path: '/404', component: PageNotFound },
        { path: '*', component: PageNotFound }
      ]
    }
  ]

  return (
    <ApolloProvider client={client(token)}>
      <AlertBoxProvider>
        <ModalProvider>
          <BrowserRouter>
            <GuardProvider guards={[guardsRoutes]} error={PageNotFound}>
              <Route data={routes} />
            </GuardProvider>
            <Alert />
            <ModalBox />
          </BrowserRouter>
        </ModalProvider>
      </AlertBoxProvider>
    </ApolloProvider>
  )
}

export default Routes
