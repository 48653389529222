import styled from 'styled-components'

export const Avatar = styled.img`
  aspect-ratio: 1/1;
  max-width: 24px;
  border-radius: 50%;
  object-fit: cover;
`
export const CustomStyle = styled.div`
  .button-submit-generate-form {
    display: none;
  }
`
