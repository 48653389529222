import React, { useState } from 'react'
import { customTheme } from '../../../../styles/theme'
import moment from 'moment'
import ButtonPrimary from '../../../components/Button'
import { Col, Row } from 'react-bootstrap'
import CardModal from '../../../components/CardModal'
import GenerateForm from '../../../components/layout/GenerateForm'
import { ButtonAttach, ModalContainer } from './styled'
import { getScheduleStatus } from '../../../../Utils/schedules'
import VisibilityIcon from '@mui/icons-material/Visibility'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import AddIcon from '@mui/icons-material/Add'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../services/firebase/firebase-config'
import { useMutation } from '@apollo/client'
import { updateScheduleUseCase } from '../../../../main/usecases/schedules'
import { fetchLaboratoriesAddFiles } from '../../../../services/laboratories'
import { useAlertBox } from '../../../../contexts/alert_box'
import { createNotificationFireFunction } from '../../../../services/Notifications'

export const SendResultsFieldsForm = ({ exam_id, modal, params }) => {
  const [values, setValues] = useState({})
  const [sendFileMutation] = useMutation(fetchLaboratoriesAddFiles())
  const [qtdFiles, setQtdFiles] = useState(1)
  const [loading, setLoading] = useState(false)
  const alertBox = useAlertBox()

  function uploadAsPromise (imageFile, contentType) {
    return new Promise(function (resolve, reject) {
      const storageRef = ref(
        storage,
        `laboratories/results/${new Date().getTime()}`
      )

      // Upload file
      const task = uploadBytesResumable(storageRef, imageFile, {
        contentType: contentType
      })

      // Update progress bar
      task.on(
        'state_changed',
        function progress (snapshot) {},
        function error (err) {
          reject(err)
        },
        function complete () {
          getDownloadURL(task.snapshot.ref).then(async (url) => {
            resolve(url)
          })
        }
      )
    })
  }

  const mutationSendFile = async (params) => {
    return await updateScheduleUseCase(sendFileMutation, params, {
      alertBox
    })
  }

  return (
    <>
      {[...Array(qtdFiles).keys()].map((el) => (
        <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <ButtonAttach htmlFor={'file-' + el}>
              <AttachFileIcon
                style={{ color: customTheme.colors.white }}
                edge="start"
                fontSize="small"
                sx={{
                  marginBottom: '2px'
                }}
              />
              {(!!values[el] && values[el]?.name) || ' Anexar Documento'}
              <input
                type="file"
                hidden
                name={'file-' + el}
                id={'file-' + el}
                onChange={(e) => {
                  if (e?.target?.files[0]) {
                    setValues((prev) => ({
                      ...prev,
                      [el]: e?.target?.files[0]
                    }))
                  }
                }}
              />
            </ButtonAttach>
          </Col>
        </Row>
      ))}
      <Row>
        <Col xs={2} className="d-flex justify-content-center">
          <ButtonAttach
            onClick={() => setQtdFiles((prev) => prev + 1)}
            style={{
              background: customTheme.colors.primary,
              marginTop: 30,
              marginBottom: 30
            }}
          >
            <AddIcon
              style={{ color: customTheme.colors.white }}
              edge="start"
              fontSize="small"
              sx={{
                marginBottom: '2px'
              }}
            />
          </ButtonAttach>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="d-flex justify-content-center">
          <ButtonPrimary
            disabled={loading}
            onClick={async () => {
              setLoading(true)
              const arrPromises = []
              Object.values(values).forEach(async (el) => {
                arrPromises.push(uploadAsPromise(el, el?.type))
              })
              const resp = await Promise.all(arrPromises)

              await mutationSendFile({
                objects: Object.values(resp)?.map((el) => ({
                  url: el,
                  exam_id: exam_id
                }))
              })

              await createNotificationFireFunction({
                title: 'Você recebeu documentos relacionados ao seu Exame 🔔✅',
                description: 'Entre no aplicativo para visualizar',
                user_id: params.user_id,
                payload: {
                  route: '/exams/exam_id',
                  exam_id: exam_id
                }
              })

              setLoading(false)
              modal.setOptions({ open: false })
            }}
            style={{
              background: customTheme.colors.primary,
              marginTop: 30,
              marginBottom: 30,
              padding: 15
            }}
          >
            {loading ? 'Enviando, aguarde...' : 'Enviar Arquivos'}
          </ButtonPrimary>
        </Col>
      </Row>
    </>
  )
}
export const confrimRequest = () => {
  return [
    {
      fields: [
        {
          label: 'Dia de atendimento:',
          type: 'date',
          required: true,
          hideField: false,
          disabled: false,
          name: 'date',
          // currentValue: moment(startTime).format("DD/MM/YYYY - HH:mm") || "",
          columns: { xs: 4 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Horário de atendimento:',
          type: 'time',
          required: true,
          hideField: false,
          disabled: false,
          name: 'start_time',
          // currentValue: moment(startTime).format("DD/MM/YYYY - HH:mm") || "",
          columns: { xs: 6 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },

        {
          label: 'Descrição do exame:',
          type: 'textarea',
          required: true,
          hideField: false,
          disabled: false,
          name: 'exam_description',
          currentValue: 'Exame confirmado',
          columns: { xs: 12 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Valor do Exame:',
          required: true,
          hideField: false,
          disabled: false,
          name: 'value',
          // currentValue: formatMoney(val?.payment.value_of_transaction),
          columns: { xs: 4 },
          type: 'float',
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]
}
export const schedulesFieldsView = (val) => {
  return [
    {
      fields: [
        {
          label: 'Nome do paciente:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'name',
          currentValue: val.user?.full_name,
          columns: { xs: 8 }
        },
        {
          label: 'E-mail:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'email',
          currentValue: val.user?.email,
          columns: { xs: 4 }
        },
        {
          label: 'Telefone:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'phone',
          currentValue: val?.user?.phone && val?.user?.phone,
          columns: { xs: 4 }
        },
        {
          label: 'Horário da solicitação:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'time',
          currentValue:
            moment(val?.created_at).format('DD/MM/YYYY - HH:mm') || '',
          columns: { xs: 4 }
        },
        {
          label: 'Status:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'status',
          currentValue: getScheduleStatus(val.status),
          columns: { xs: 4 }
        },
        {
          label: 'Status de pagamento:',
          type: 'text',
          required: true,
          hideField: false,
          disabled: true,
          name: 'payment_status',
          currentValue: getScheduleStatus(val?.payment?.status),
          columns: { xs: 4 }
        },
        {
          label: 'Descrição do paciente:',
          type: 'textarea',
          required: true,
          hideField: false,
          disabled: true,
          name: 'user_description',
          currentValue: val.user_description,
          columns: { xs: 12 }
        }
      ]
    }
  ]
}

export const columns = (modal, columnsActions) => {
  const history = useHistory()
  return [
    {
      field: 'user_description',
      headerName: 'Descrição',
      width: 400,
      editable: false,
      valueGetter: (params) => `${params.row.user_description || ''} `
    },
    {
      width: 400,
      field: 'status',
      headerName: 'Status',
      editable: false,
      valueGetter: (params) => `${getScheduleStatus(params.row.status) || ''} `
    },
    // {
    //   field: "medical_specialty",
    //   headerName: "Especialidade",
    //   width: 150,
    //   editable: false,
    //   valueGetter: (params) =>
    //     `${params?.row?.sus_with_medical_specialty?.medical_specialty?.name} `,
    // },
    {
      field: 'created_at',
      headerName: 'Solicitado em',
      sortable: true,
      width: 250,
      valueGetter: (params) => `${moment(params.row.created_at).format('YYYY-MM-DD HH:mm') || ''} `,
      valueFormatter: params => moment(params.value).format('DD/MM/YYYY - HH:mm')
    },
    {
      field: 'id',
      headerName: 'Ações',
      sortable: false,
      renderCell: (params) => {
        return (
          <Col className="d-flex justify-content-sm-between">
            <ButtonPrimary
              onClick={() => {
                modal.setOptions({
                  open: true,
                  component: (
                    <CardModal style={{ width: '60%' }}>
                      <ModalContainer>
                        <GenerateForm
                          groupFields={schedulesFieldsView(params?.row)}
                        />
                        {!!params?.row?.files?.length && (
                          <div className="buttons-container d-flex flex-column mt-10 mb-5">
                            Download de anexos da solicitação
                            {params?.row?.files?.map((el, i) => (
                              <ButtonPrimary
                                style={{
                                  background: customTheme.colors.black,
                                  padding: 0,
                                  marginTop: 15
                                }}
                                // onClick={() =>
                                //   modal.setOptions({ open: false })
                                // }
                              >
                                <a
                                  style={{
                                    color: '#fff',
                                    padding: 5,
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center'
                                  }}
                                  href={el?.url}
                                  download={'Anexo -' + i + 1}
                                  name={'Anexo -' + i + 1}
                                  target="_blank" rel="noreferrer"
                                >
                                  Anexo - {i + 1}
                                </a>
                              </ButtonPrimary>
                            ))}
                          </div>
                        )}
                        <div className="buttons-container mt-5">
                          {params.row.status === 'requested' && (
                            <ButtonPrimary
                              onClick={() =>
                                columnsActions?.handleConfirmSchedule(
                                  params?.id,
                                  params.row
                                )}
                              style={{
                                backgroundColor: customTheme.colors.primary,
                                boxShadow: 'none'
                              }}
                            >
                              Confirmar solicitação
                            </ButtonPrimary>
                          )}
                          {params.row.status === 'requested' && (
                            <ButtonPrimary
                              onClick={() =>
                                columnsActions?.handleCancelSchedule(
                                  params?.id,
                                  params.row
                                )}
                              style={{
                                backgroundColor: customTheme.colors.secondary,
                                boxShadow: 'none'
                              }}
                            >
                              Cancelar solicitação
                            </ButtonPrimary>
                          )}
                          {params.row.status === 'confirmed' && (
                            <ButtonPrimary
                              onClick={() =>
                                columnsActions?.handleFinishRequest(
                                  params?.id,
                                  params.row
                                )}
                              style={{
                                backgroundColor: customTheme.colors.secondary,
                                boxShadow: 'none'
                              }}
                            >
                              Finalizar solicitação
                            </ButtonPrimary>
                          )}
                          {(params.row.status === 'confirmed' ||
                            params.row.status === 'finished') && (
                              <ButtonPrimary
                                onClick={() =>
                                  columnsActions?.sendResults(
                                    params?.id,
                                    params.row
                                  )}
                                style={{
                                  backgroundColor: customTheme.colors.black,
                                  boxShadow: 'none'
                                }}
                              >
                                Enviar resultados
                              </ButtonPrimary>
                          )}
                          <ButtonPrimary
                            onClick={() => modal.setOptions({ open: false })}
                            style={{
                              backgroundColor: customTheme.colors.error,
                              boxShadow: 'none'
                            }}
                          >
                            Fechar
                          </ButtonPrimary>
                        </div>
                      </ModalContainer>
                    </CardModal>
                  )
                })
              }}
              style={{
                width: '35px',
                height: '35px',
                padding: '5px',
                borderRadius: '50%'
              }}
            >
              <VisibilityIcon
                style={{ color: customTheme.colors.white }}
                edge="start"
                fontSize="small"
                sx={{
                  marginBottom: '2px'
                }}
              />
            </ButtonPrimary>
            {/* <ButtonPrimary
              onClick={() =>
                columnsActions?.handleCancelSchedule(params?.id, params.row)
              }
              style={{
                width: "35px",
                height: "35px",
                padding: "2.5px 0 0",
                borderRadius: "50%",
                background: customTheme.colors.error,
                boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`,
              }}
            >
              <CancelIcon
                style={{ color: customTheme.colors.white }}
                edge="start"
                fontSize="small"
                sx={{
                  marginBottom: "2px",
                }}
              />
            </ButtonPrimary> */}
          </Col>
        )
      }
    }
  ]
}
