import { useState } from 'react'
import { useSubscription } from '@apollo/client'
import { Col, Row } from 'react-bootstrap'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { fetchSusWaitingRoomUsers } from '../../../../services/sus'
import Header from '../../../components/Header'
import { columns } from './columns'
import * as S from './styled'
import ConfirmModal from '../../../components/ConfirmModal'
import { useModal } from '../../../../contexts/modal'
import { useAlertBox } from '../../../../contexts/alert_box'
import CardModal from '../../../components/CardModal'
import { acceptSusRoom } from '../../../../main/usecases/sus/accept_room'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const WaitingRoomUsers = ({ match }) => {
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(5)
  const modal = useModal()
  const alertBox = useAlertBox()
  const history = useHistory()

  const { loading, data } = useSubscription(fetchSusWaitingRoomUsers(), {
    fetchPolicy: 'no-cache',
    variables: {
      limit: perPage,
      offset: (page * perPage) ?? 0,
      consultation_room_id: match.params.id
    }
  })

  const handleConfirm = async (id) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '500px' }}>
          <ConfirmModal
            title="Deseja Aceitar essa solicitação?"
            description="Fazendo isso você será direcionado para a sala de atendimento"
            onCancel={async () => {
            // Alguma lógica de cancelamento se necessário
            }}
            onConfirm={async () => {
              try {
                const res = await acceptSusRoom({
                  id: id
                })

                if (res.sucess) {
                  alertBox.setOptions({
                    open: true,
                    type: 'success',
                    message: 'Solicitação aceita com sucesso!',
                    time: 1500
                  })
                  history.push('/doctor-requests-schedules/' + res?.data.shedule?.id)
                } else {
                  alertBox.setOptions({
                    open: true,
                    message: res?.message ?? 'Erro ao aceitar solicitação',
                    type: 'error',
                    time: 3000
                  })
                }
              } catch (error) {
                console.log('erro', error)
                alertBox.setOptions({
                  open: true,
                  message: error?.message ?? 'Erro',
                  type: 'error',
                  time: 3000
                })
              }
            }}
          />
        </CardModal>
      )
    })
  }

  return (
    <S.Wrapper>
      <Header title="Pacientes na Sala de espera" />

      <Row>
        <Col xs={12}>
          <div style={{ minHeight: 400, width: '100%' }}>
            <DataGrid
              localeText={{
                ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: 'Sem pacientes na sala de espera'
              }}
              disableColumnSelector
              rows={data?.consultation_room_user_request ?? []}
              columns={columns((id) => {
                handleConfirm(id)
              })}
              pageSize={perPage}
              onPageChange={e => setPage(e)}
              onPageSizeChange={(e) => setPerPage(e)}
              rowsPerPageOptions={[10]}
              pagination
              disableSelectionOnClick
              page={page}
              loading={loading}
            />
          </div>
        </Col>
      </Row>
    </S.Wrapper>
  )
}

export default WaitingRoomUsers
