import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { columns } from './columns'
import { useModal } from '../../../../contexts/modal'
import { fetchSUS } from '../../../../services/sus'
import { useAlertBox } from '../../../../contexts/alert_box'
import DataTable from '../../../components/DataTable'
import MainContainer from '../../../components/MainContainer'
import Header from '../../../components/Header'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Filter from '../../../components/Filter'

function Sus ({ list }) {
  const [offset, setOffset] = useState(0)
  const [pageSize] = useState(10)
  const [filter, setFilter] = useState()
  const [myList, setMyList] = useState([])
  const { data, loading, refetch } = useQuery(fetchSUS(filter), {
    variables: {
      limit: pageSize,
      offset: (offset) * pageSize,
      name: filter?.name,
      city: filter?.city,
      state_code: filter?.stateCode
    },
    onError: () => {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar os dados!',
        type: 'error',
        time: 3000
      })
    }
  })
  const modal = useModal()
  const alertBox = useAlertBox()
  const history = useHistory()

  useEffect(() => {
    if (data?.sus) {
      setMyList(data.sus)
    }
  }, [data])

  useEffect(() => {
    refetch()
  }, [filter])

  const onChangePage = (page) => {
    setOffset(page)
  }

  const handleAdd = () => {
    history.push('/sus/new')
  }

  return (
    <MainContainer>
      <Header title="SUS" labelAdd="Adicionar Entidade Credenciada SUS" handleAdd={handleAdd} />

      <Filter setFilter={setFilter} />

      <DataTable
        totalItems={data?.sus_aggregate?.aggregate?.count || 100}
        data={myList || list}
        loading={loading}
        onChangePage={onChangePage}
        columns={columns(modal, alertBox, () => {}, refetch, history)}
      />
    </MainContainer>
  )
}

export default Sus
