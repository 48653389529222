import { gql } from '@apollo/client'

export const fecthPlans = () => {
  return gql`
    ${fragmentPlan}
    query getPlans {
      plan(order_by: {updated_at: desc}) {
        ...planFields
      }
    }
  `
}

export const fetchPlanById = () => {
  return gql`
    query getPlan($limit: Int, $offset: Int, $id: Int) {
      plan(limit: $limit, offset: $offset, order_by: {updated_at: desc}, where: { id: { _eq: $id } }) {
        ...planFields
      }
    }
    ${fragmentPlan}
  `
}

export const createPlan = () => {
  return gql`
    ${fragmentPlan}
    mutation insertPlan(
      $credit_fees: numeric
      $debit_fees: numeric
      $fixed_amount_of_transfer_to_manager: numeric
      $fixed_percentage_of_transfer_to_manager: numeric
      $pix_fees: numeric
    ) {
      insert_plan_one(
        object: {
          credit_fees: $credit_fees
          debit_fees: $debit_fees
          fixed_amount_of_transfer_to_manager: $fixed_amount_of_transfer_to_manager
          fixed_percentage_of_transfer_to_manager: $fixed_percentage_of_transfer_to_manager
          pix_fees: $pix_fees
        }
      ) {
        ...planFields
      }
    }
  `
}
export const removePlan = () => {
  return gql`
    ${fragmentPlan}
    mutation deletePlanByPk($id: Int!) {
      delete_plan_by_pk(id: $id) {
        created_at
      }
    }
  `
}

export const updatePlan = () => {
  return gql`
    ${fragmentPlan}
    mutation updatePlan(
      $id: Int!
      $credit_fees: numeric
      $debit_fees: numeric
      $fixed_amount_of_transfer_to_manager: numeric
      $fixed_percentage_of_transfer_to_manager: numeric
      $pix_fees: numeric
    ) {
      update_plan_by_pk(
        pk_columns: { id: $id }
        _set: {
          credit_fees: $credit_fees
          debit_fees: $debit_fees
          fixed_amount_of_transfer_to_manager: $fixed_amount_of_transfer_to_manager
          fixed_percentage_of_transfer_to_manager: $fixed_percentage_of_transfer_to_manager
          pix_fees: $pix_fees
        }
      ) {
        ...planFields
      }
    }
  `
}

export const fragmentPlan = gql`
  fragment planFields on plan {
    fixed_amount_of_transfer_to_manager
    fixed_percentage_of_transfer_to_manager
    pix_fees
    credit_fees
    debit_fees
    id
    updated_at
    created_at
  }
`
