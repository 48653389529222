import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 15px;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  & > .left {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  & > .right {
    display: flex;
    flex-direction: column;
    text-align: end;
  }

  & > .right span {
    font-size: 13px;
  }

  & img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
  }
`

export const Text = styled.span`
    
`

export const FlexRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 15px;
`

export const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`

export const Title = styled.span`
    font-size: 1.2rem;
    text-transform: uppercase;
`

export const Photo = styled.img`
    width: 130px;
    height: 130px;
    border-radius: 50%;
`

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
`

export const Group = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export const Subtitle = styled.span`
    font-size: 1.2rem;
    font-weight: bold;
`

export const Chip = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    padding: 5px 8px;
    width: 190px;
    font-size: 14px;
    border: 1px solid #ccc;
`
