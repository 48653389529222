import React, { useEffect, useState } from 'react'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { useQuery } from '@apollo/client'
import { columns } from './columns'
import { useModal } from '../../../../contexts/modal'
import { fetchDoctors } from '../../../../services/doctor'
import { fecthEspecialities } from '../../../../services/medicalSpecialty'
import Filter from '../../../components/Filter'
import Header from '../../../components/Header'
import MainContainer from '../../../components/MainContainer'
import DataTable from '../../../components/DataTable'

function Doctors () {
  const [perPage, setPerPage] = useState(5)
  const [pageSize] = useState(10)
  const [offset, setOffset] = useState(0)
  const especialities = useQuery(fecthEspecialities(), {})
  const [filter, setFilter] = useState()
  const { loading, data, refetch } = useQuery(fetchDoctors(filter), {
    variables: {
      limit: pageSize,
      offset: (offset) * pageSize,
      name: filter?.name,
      city: filter?.city,
      state_code: filter?.stateCode
    }
  })
  const modal = useModal()

  useEffect(() => {
    refetch()
  }, [filter])

  const handleAddClinic = () => {
    history.push('/doctors/new')
  }

  const onChangePage = (page) => {
    setOffset(page)
  }

  if (loading) return <span>Carregando...</span>

  return (
    <MainContainer>
      <Header
        title="Médicos"
        labelAdd="Adicionar Médico"
        handleAdd={handleAddClinic}
      />

      <Filter setFilter={setFilter} />

      {data?.doctors && especialities?.data?.medical_specialties && (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={data.doctors}
            columns={columns(
              modal,
              especialities?.data?.medical_specialties
            )}
            pageSize={perPage}
            onPageSizeChange={(e) => {
              setPerPage(e)
            }}
            rowsPerPageOptions={[10]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}

      <DataTable
        data={data.doctors}
        loading={loading}
        onChangePage={onChangePage}
        columns={columns(modal, especialities?.data?.medical_specialties)}
      />
    </MainContainer>
  )
}

export default Doctors
