import { gql } from '@apollo/client'

export const fetchPrivacyPolicy = () => {
  return gql`
  query MyQuery($id: Int = 1) {
    legal_documents_by_pk(id: $id) {
      created_at
      id
      privacy_policy
      terms_of_use
      updated_at
    }
  }
  `
}

export const addPrivacyPolicy = () => {
  return gql`
  mutation MyMutation($privacy_policy: String) {
    update_legal_documents_by_pk(pk_columns: {id: 1}, _set: {privacy_policy: $privacy_policy}) {
      created_at
      id
      privacy_policy
      terms_of_use
      updated_at
    }
  }
  `
}
