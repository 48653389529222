import { columns } from './columns'
import { fetchClinics } from '../../../../../services/clinics'
import { useQuery } from '@apollo/client'
import DataTable from '../../../../components/DataTable'
import { fetchHospitals } from '../../../../../services/hospitals'
import Header from '../../../../components/Header'
import MainContainer from '../../../../components/MainContainer'
import { fetchDentailClinics } from '../../../../../services/odontology'
import { fetchLaboratories } from '../../../../../services/laboratories'

export default function Step2 ({ onSubmit, entity, onGoBack }) {
  let fetch

  switch (entity) {
    case 'laboratorio':
      fetch = fetchLaboratories
      break
    case 'hospital':
      fetch = fetchHospitals
      break
    case 'odontologia':
      fetch = fetchDentailClinics
      break
    default:
      fetch = fetchClinics
  }

  const { data, loading } = useQuery(fetch())

  let list
  let entityTitle

  switch (entity) {
    case 'laboratorio':
      list = data?.laboratories
      entityTitle = 'o laboratório'
      break
    case 'hospital':
      list = data?.hospitals
      entityTitle = 'o hospital'
      break
    case 'odontologia':
      list = data?.dental_clinics
      entityTitle = 'a clínica odontologica'
      break
    default:
      list = data?.clinics
      entityTitle = 'a clínica'
  }

  return (
    <MainContainer>
      <Header
        title={`Selecione ${entityTitle}`}
        onGoBack={onGoBack}
      />

      <DataTable
        data={list || []}
        loading={loading}
        columns={columns('company_name', onSubmit)}
      />
    </MainContainer>
  )
}
