import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;
  align-items: center;

  * {
    width: fit-content;
  }
`
