import { FlexContainer } from '../../pacients/styled'

export default function Screening ({ data }) {
  return (
    <FlexContainer>
      {
            data !== null ? (
              <div dangerouslySetInnerHTML={{ __html: data.description.replace('body', '') }} />
            ) : <span>O paciente não preencheu os dados de triagem</span>
        }
    </FlexContainer>
  )
}
