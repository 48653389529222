import { AuthService } from '../../../services/auth/auth'
import { createBrowserHistory } from 'history'

export const updatePetshopUseCase = async (mutation, params, extras) => {
  try {
    await mutation({
      variables: params
    })
    extras.actions.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: 'Atualizado com Sucesso!',
      type: 'success',
      time: 3000
    })
  } catch (error) {
    console.error(error)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro Ao atualizar!',
      type: 'error',
      time: 3000
    })
  }
}

export const createPetshopUseCase = async (
  mutation,
  { email, ...params },
  extras,
  entity
) => {
  const auth = new AuthService()
  const history = createBrowserHistory({ forceRefresh: true })
  try {
    const res = await mutation({
      variables: { ...params, email }
    })

    extras.actions.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: 'Criado com Sucesso!',
      type: 'success',
      time: 3000
    })
    history.push(`/petshops/${res?.data?.insert_pet_shops_one?.id}`)
  } catch (error) {
    console.error(error)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro Ao atualizar!',
      type: 'error',
      time: 3000
    })
  }
}
