import * as S from './styled'

export default function PacientData ({ data }) {
  if (!data) {
    return
  }

  const returnAddress = () => {
    if (!data.adress) {
      return <></>
    }

    const { address, number, neighborhood, city, state_code: stateCode, zipcode } = data.adress

    return (
      <>
        <Field
          title="Endereço"
          content={`${address}, ${number || 'S/N'} - ${neighborhood} - ${city}/${stateCode}`}
        />
        <Field
          title="CEP"
          content={zipcode}
        />
      </>
    )
  }

  return (
    <S.MainContainer>
      <Field title="Telefone" content={data.phone} />
      <Field title="Cartão SUS" content={data.sus_card} />
      <Field title="CPF" content={data.cpf} />
      <Field title="Email" content={data.email} />
      <Field title="Gênero" content={data.genderByGender?.value} />

      {returnAddress()}
    </S.MainContainer>
  )
}

function Field ({ title, content }) {
  return (
    <S.FieldBox>
      <strong>{title}:</strong>
      <p>{content || '(Vazio)'}</p>
    </S.FieldBox>
  )
}
