import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import MainContainer from '../../../components/MainContainer'
import Header from '../../../components/Header'
import DraftTextArea from '../../../components/DraftTextArea'
import Loading from '../../../components/layout/Loading'
import { addPrivacyPolicy, fetchPrivacyPolicy } from '../../../../services/privacy-policy'
import { useAlertBox } from '../../../../contexts/alert_box'
import { AuthService } from '../../../../services/auth/auth'
import * as S from './styled'

function PrivacyPolicy () {
  const alertBox = useAlertBox()
  const { data, loading } = useQuery(fetchPrivacyPolicy())
  const [content, setContent] = useState('')
  const [mutatePrivacyPolicy] = useMutation(addPrivacyPolicy())
  const auth = new AuthService()

  useEffect(() => {
    if (data?.legal_documents_by_pk?.privacy_policy) {
      setContent(data?.legal_documents_by_pk?.privacy_policy)
    }
  }, [data])

  const onSubmit = () => {
    mutatePrivacyPolicy({
      variables: {
        privacy_policy: content
      }
    })

    alertBox.setOptions({
      open: true,
      message: 'Salvo com sucesso!',
      type: 'success',
      time: 3000
    })
  }

  const onAddNotifications = () => {
    try {
      auth.createNotification({
        title: 'Atualizamos nossa política de privacidade',
        description: 'Clique aqui para ver',
        to_all_users: true,
        payload: {
          route: '/web_view',
          title: 'Política de privacidade',
          url: 'Env.privacyPolicy'
        }
      })
      alertBox.setOptions({
        open: true,
        message: 'Enviado com sucesso!',
        type: 'success',
        time: 3000
      })
    } catch (err) {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao enviar as notificações ao usuários',
        type: 'error',
        time: 3000
      })
    }
  }

  return (
    <MainContainer container>
      <Header title="Política de privacidade" />

      {loading && (<Loading />)}

      {!loading && (
        <DraftTextArea
          style={{ height: '70vh' }}
          value={content}
          onChange={setContent}
        />
      )}

      <S.Actions>
        <S.Button onClick={onAddNotifications}>
          Notificar usuários
        </S.Button>
        <Link to="/privacy-policy" target="_blank">
          Visualizar
        </Link>
        <S.Button onClick={onSubmit}>
          Salvar
        </S.Button>
      </S.Actions>
    </MainContainer>
  )
}

export default PrivacyPolicy
