import { gql } from '@apollo/client'
import { fragmentcooperativeInstallment } from '../cooperativeInstallment'

export const fecthCooperatives = () => {
  return gql`
    ${fragmentCooperative}
    query getCooperatives {
      cooperatives(order_by: {updated_at: desc}) {
        ...cooperativeFields
      }
    }
  `
}

export const fetchCooperativeById = () => {
  return gql`
    query getCooperatives($limit: Int, $offset: Int, $id: Int) {
      cooperatives(order_by: {updated_at: desc}, where: { id: { _eq: $id } }) {
        ...cooperativeFields
      }
    }
    ${fragmentCooperative}
  `
}

export const createCooperative = () => {
  return gql`
    ${fragmentCooperative}
    mutation insertCooperative(
      $name: String = ""
      $site: String = ""
      $terms_of_use: String = ""
      $terms_of_use_url_site: String = ""
    ) {
      insert_cooperatives_one(
        object: {
          name: $name
          site: $site
          terms_of_use: $terms_of_use
          terms_of_use_url_site: $terms_of_use_url_site
        }
      ) {
        ...cooperativeFields
      }
    }
  `
}
export const removeCooperative = () => {
  return gql`
    ${fragmentCooperative}
    mutation deleteCooperativeByPk($id: Int!) {
      delete_cooperatives_by_pk(id: $id) {
        ...cooperativeFields
      }
    }
  `
}

export const updateCooperative = () => {
  return gql`
    ${fragmentCooperative}
    mutation updateCooperative(
      $id: Int!
      $name: String
      $site: String = ""
      $terms_of_use: String = ""
      $terms_of_use_url_site: String = ""
    ) {
      update_cooperatives_by_pk(
        pk_columns: { id: $id }
        _set: {
          name: $name
          site: $site
          terms_of_use: $terms_of_use
          terms_of_use_url_site: $terms_of_use_url_site
        }
      ) {
        ...cooperativeFields
      }
    }
  `
}

export const fragmentCooperative = gql`
  ${fragmentcooperativeInstallment}
  fragment cooperativeFields on cooperatives {
    created_at
    id
    name
    site
    terms_of_use
    terms_of_use_url_site
    updated_at
    cooperative_installment_plans {
      ...cooperativeInstallmentPlans
    }
  }
`
