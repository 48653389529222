import React from 'react'
import { customTheme } from '../../../../styles/theme'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import { formatMoney, phoneMask } from '../../../../Utils/format'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ButtonPrimary from '../../../components/Button'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Rating } from '@mui/material'

export const especiality = (esp, especialities) => {
  especialities = especialities?.map((item) => ({ ...item, value: item.id }))
  return [
    {
      name: 'Sobre o médico',
      fields: [
        {
          label: 'Filtrar por especialidade',
          type: 'autocomplete',
          required: true,
          disabled: esp,
          ...(esp && {
            currentValue: {
              label: especialities?.find(
                (el) => el.value === esp?.medical_specialty?.id
              )?.name,
              value: especialities?.find(
                (el) => el.value === esp?.medical_specialty?.id
              )?.value
            }
          }),
          hideField: false,

          name: 'doctors_with_specialties',
          options: especialities?.map((item) => ({
            label: item.name,
            value: item.value
          })),
          columns: {
            xs: 12
          },
          validation: Yup.object().required('Campo obrigatório')
        },
        {
          label: 'Descrição',
          type: 'textarea',
          required: true,
          disabled: false,
          currentValue: esp?.description || '',
          hideField: false,

          name: 'description',
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Especialista desde:',
          type: 'date',
          required: true,
          disabled: false,
          currentValue: esp?.specialist_since,
          hideField: false,

          name: 'specialist_since',
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]
}

export const columns = (_, especilities) => [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: 'photo',
    headerName: 'Foto',
    width: 60,
    editable: true,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          {params?.row?.profile_image
            ? (
              <Avatar src={params.row.profile_image} />
              )
            : (
              <Avatar>{params?.row?.full_name[0]}</Avatar>
              )}
        </Col>
      )
    }
  },
  {
    field: 'full_name',
    headerName: 'Nome',
    width: 200,
    editable: true
  },
  {
    field: 'phone_number',
    headerName: 'Telefone',
    width: 200,
    editable: true,
    valueGetter: (params) => `${phoneMask(params.row.created_at) || ''} `
  },
  {
    field: 'average_cost_of_medical_consultation',
    headerName: 'Custo Médio',
    width: 100,
    type: 'number',
    editable: true,
    valueGetter: (params) =>
      `${formatMoney(params?.row?.average_cost_of_medical_consultation)} `
  },
  {
    field: 'average_of_evaluations',
    headerName: 'Avaliações',
    width: 150,
    editable: true,
    renderCell: ({ row }) => {
      return (
        <Rating name="read-only" value={row?.average_of_evaluations} readOnly />
      )
    }
  },
  {
    field: 'main_specialty',
    headerName: 'Especialidade Principal',
    width: 250,
    editable: true,
    valueGetter: (params) => {
      return `${
        especilities?.find((item) => item?.id === params?.row?.main_specialty)
          ?.name || '--'
      }`
    }
  },
  {
    field: 'created_at',
    headerName: 'Data de Cadastro',
    sortable: true,
    width: 150,
    valueGetter: (params) => `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
    valueFormatter: params => moment(params.value).format('DD/MM/YYYY - HH:MM')
  },
  {
    field: 'id',
    headerName: 'Ações',
    sortable: false,
    width: 80,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          <Link to={`/schedules-available-doctors/${params.row.id}`}>
            <ButtonPrimary
              style={{
                width: '35px',
                height: '35px',
                padding: '5px',
                borderRadius: '50%'
              }}
            >
              <EditOutlinedIcon
                style={{ color: customTheme.colors.white }}
                edge="start"
                fontSize="small"
                sx={{
                  marginBottom: '2px'
                }}
              />
            </ButtonPrimary>
          </Link>
        </Col>
      )
    }
  }
]

export const groupFields = (content, setContent, especialities) => {
  especialities = especialities?.map((item) => ({ ...item, value: item.id }))

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e.target.value }))
    }
  }
  return [
    {
      name: '',
      fields: [
        {
          label: 'Avatar',
          type: 'file',
          required: true,
          disabled: false,
          hideField: false,
          name: 'profile_image',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido'),
          component: (data) => {
            return (
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <Avatar
                    src={data || content?.profile_image}
                    style={{ width: 100, height: 100 }}
                  />
                </Col>
              </Row>
            )
          }
        },
        {
          label: 'Nome',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'full_name')
          },
          currentValue: content?.full_name || '',
          name: 'full_name',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'E-mail',
          type: 'email',
          required: true,
          disabled: !window.location.href.includes('new'),
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'email')
          },
          currentValue: content?.email || '',
          name: 'email',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'CRM',
          type: 'text',
          required: true,
          disabled: false,
          currentValue: content?.crm || '',
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'crm')
          },
          name: 'crm',
          mask: '9999999999/aa',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Sobre',
          type: 'about',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'about')
          },
          currentValue: content?.about || '',
          hideField: false,
          name: 'about',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },

        {
          label: 'Linkedin URL',
          type: 'textarea',
          required: true,
          disabled: false,
          currentValue: content?.linkedin || '',
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'linkedin')
          },
          name: 'linkedin',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Principal especialidade',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content
            ? {
                label: especialities?.find(
                  (el) => el.value === content?.main_specialty
                )?.name,
                value: especialities?.find(
                  (el) => el.value === content?.main_specialty
                )?.id
              }
            : null,
          hideField: false,
          name: 'main_specialty',
          onChange: (data) => {
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                main_specialty: data.value
              }))
            }
          },
          options: especialities?.map((item) => ({
            label: item.name,
            value: item.value
          })),
          columns: {
            xs: 6
          },
          validation: Yup.object().required('Campo obrigatório').nullable()
        }
      ]
    }
  ]
}

export const createDocAppointmentFields = (createdSchedule) => {
  return [
    {
      name: 'Horário disponível (Médico)',
      fields: [
        {
          label: 'Valor da consulta:',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          name: 'cost_of_medical_consultation_personal_assistance',
          columns: { xs: 6 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Valor da consulta (Online):',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          name: 'cost_of_medical_consultation_online_service',
          columns: { xs: 6 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Início',
          type: 'time',
          required: true,
          disabled: true,
          currentValue: createdSchedule?.start_time,
          hideField: false,
          name: 'start_time',
          columns: { xs: 6 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Final',
          type: 'time',
          required: true,
          disabled: true,
          currentValue: createdSchedule?.end_time,
          hideField: false,
          name: 'end_time',
          columns: { xs: 6 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Número de vagas:',
          type: 'text',
          required: true,
          disabled: false,
          currentValue: 1,
          hideField: false,
          name: 'number_of_vacancies',
          columns: { xs: 6 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Atendimento Presencial',
          type: 'checkbox',
          disabled: false,
          hideField: false,
          name: 'personal_assistance',
          columns: { xs: 12 }
        },
        {
          label: 'Atendimento Online',
          type: 'checkbox',
          disabled: false,
          hideField: false,
          name: 'online_service',
          columns: { xs: 12 }
        }
      ]
    }
  ]
}

export const unavailableDaysFields = () => {
  return [
    {
      name: 'Horário disponível (Médico)',
      fields: [
        {
          label: 'Data bloqueada:',
          type: 'date',
          required: true,
          disabled: false,
          hideField: false,
          name: 'date',
          columns: { xs: 6 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]
}
