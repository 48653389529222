// Importe o pacote dotenv para carregar as variáveis de ambiente do arquivo .env
// Importe as funções necessárias do Firebase
import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

// Configure o objeto de configuração do Firebase com as variáveis de ambiente
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
}

const firebase = initializeApp(firebaseConfig)

// Obtenha a instância de armazenamento do Firebase
const storage = getStorage(firebase)

// Exporte o armazenamento e o aplicativo Firebase
export { storage, firebase as default }
