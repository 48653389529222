export const getScheduleStatus = (status) => {
  if (status?.includes('processing')) return 'Processando'
  if (status?.includes('pending_payment')) return 'Pagamento pendente'
  if (status?.includes('confirmed')) return 'Confirmado'
  if (status?.includes('finished')) return 'Finalizado'
  if (status?.includes('canceled')) return 'Cancelado'
  if (status?.includes('requested')) return 'Solicitado'
  if (status?.includes('paid')) return 'Pago'
  if (status?.includes('error')) return 'Falha'
  if (status === '') return 'Todos'
  return 'Não Realizado'
}

export const statusList = [
  '', 'canceled', 'confirmed', 'pending', 'finished', 'requested', 'paid'
]

export function onlyUnique (value, index, self) {
  return self.indexOf(value) === index
}
