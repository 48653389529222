import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { columns } from './columns'
import { useModal } from '../../../../contexts/modal'
import { useAlertBox } from '../../../../contexts/alert_box'
import { deleteLaboratories } from '../../../../services/laboratories'
import { fetchDentailClinics } from '../../../../services/odontology'
import DataTable from '../../../components/DataTable'
import MainContainer from '../../../components/MainContainer'
import Header from '../../../components/Header'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Filter from '../../../components/Filter'

function OdontologyPage ({ list }) {
  const [offset, setOffset] = useState(0)
  const [pageSize] = useState(10)
  const [myList, setMyList] = useState([])
  const [filter, setFilter] = useState()
  const { data, loading, refetch } = useQuery(fetchDentailClinics(filter), {
    variables: {
      limit: pageSize,
      offset: (offset) * pageSize,
      name: filter?.name,
      city: filter?.city,
      state_code: filter?.stateCode
    },
    onError: () => {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar os dados!',
        type: 'error',
        time: 3000
      })
    }
  })

  useEffect(() => {
    if (data?.dental_clinics) {
      setMyList(data.dental_clinics)
    }
  }, [data])

  useEffect(() => {
    refetch()
  }, [filter])

  const onChangePage = (page) => {
    setOffset(page)
  }

  const [mutateDelete] = useMutation(deleteLaboratories())
  const modal = useModal()
  const alertBox = useAlertBox()
  const history = useHistory()

  const handleAdd = () => {
    history.push('/odontology/new')
  }

  return (
    <MainContainer>
      <Header
        title="Odontologia"
        labelAdd="Adicionar Clínica de Odontologia"
        handleAdd={handleAdd}
      />

      <Filter setFilter={setFilter} />

      <DataTable
        totalItems={data?.dental_clinics_aggregate?.aggregate?.count || 100}
        data={myList || list}
        loading={loading}
        onChangePage={onChangePage}
        columns={columns(modal, alertBox, mutateDelete, refetch, history)}
      />
    </MainContainer>
  )
}

export default OdontologyPage
