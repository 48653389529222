import styled from 'styled-components'

export const Avatar = styled.img`
  aspect-ratio: 1/1;
  max-width: 24px;
  border-radius: 50%;
  object-fit: cover;
`
export const CustomStyle = styled.div`
  &:not(.list) {
    position: relative;
    margin-bottom: 50px;
  }

  &:not(.list) .button-submit-generate-form {
    position: absolute;
    bottom: -50px;
    max-width: 300px;
    right: 15px;
  }
  
  &.list .button-submit-generate-form {
    display: none;
  }
`
