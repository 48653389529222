import { gql } from '@apollo/client'

export const fecthBanners = () => {
  return gql`
    ${fragmentmMedicalBanner}
    query getBanners {
      banners(order_by: {updated_at: desc}) {
        ...bannerFields
      }
    }
  `
}

export const deleteBanner = () => {
  return gql`
    mutation deleteBanner($id: Int) {
      delete_banners(where: { id: { _eq: $id } }) {
        affected_rows
      }
    }
  `
}
export const createBanner = () => {
  return gql`
    ${fragmentmMedicalBanner}

    mutation insertBannerOne(
      $expiration_date: timestamptz
      $link_url_iphone: String
      $link_url_android: String 
      $photo_url: String
      $relevance: Int
    ) {
      insert_banners_one(
        object: {
          expiration_date: $expiration_date
          link_url_iphone: $link_url_iphone
          link_url_android: $link_url_android
          photo_url: $photo_url
          relevance: $relevance
        }
      ) {
        ...bannerFields
      }
    }
  `
}

export const updateBanner = () => {
  return gql`
    ${fragmentmMedicalBanner}

    mutation updateBannerByPk(
      $id: Int!
      $expiration_date: timestamptz
      $link_url_iphone: String
      $link_url_android: String
      $photo_url: String
      $relevance: Int
    ) {
      update_banners_by_pk(
        pk_columns: { id: $id }
        _set: {
          expiration_date: $expiration_date
          link_url_iphone: $link_url_iphone
          link_url_android: $link_url_android
          photo_url: $photo_url
          relevance: $relevance
        }
      ) {
        ...bannerFields
      }
    }
  `
}

export const fragmentmMedicalBanner = gql`
  fragment bannerFields on banners {
    created_at
    updated_at
    id
    expiration_date
    link_url_iphone
    link_url_android
    photo_url
    relevance
  }
`
