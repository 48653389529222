import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'

export const Avatar = styled.img`
  aspect-ratio: 1/1;
  max-width: 24px;
  border-radius: 50%;
  object-fit: cover;
`
export const CustomStyle = styled.div`
  .button-submit-generate-form {
    position: absolute;
    bottom: -50px;
    max-width: 300px;
    right: 15px;
  }
  margin-bottom: 50px;
  position: relative;
`

export const buttonPrimaryStyle = {
  marginBottom: '15px',
  marginTop: '10px',
  boxShadow: 'none',
  fontSize: '12px',
  padding: '5px',
  backgroundColor: customTheme.colors.primary,
  lineHeight: '2',
  maxWidth: 150,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export const SpecialitiesBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  padding: 8px;
  gap: 10px;
  margin-bottom: 10px;
`

export const ButtonSpeciality = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4px 10px;
  border-radius: 8px;
  max-width: 300px;
  width: auto;
  
  font-size: 12px;
  line-height: 2;
  
  background-color: ${customTheme.colors.primary};
  box-shadow: none;
  color: white;

  & > * {
    width: 24px !important;
    height: 24px !important;
  }
`
