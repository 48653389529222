import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import 'moment/locale/pt-br'
import React, { useEffect, useState } from 'react'
import { useAlertBox } from '../../../../contexts/alert_box'
import InternalBody from '../../../components/InternalBody'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import {
  createScheduleAvailableUseCase,
  deleteScheduleAvailableUseCase,
  updateScheduleAvailableUseCase
} from '../../../../main/usecases/schedules'
import {
  currentWeek,
  translateCalendar,
  verifyEventIsAvailable,
  weekDays
} from '../../../../Utils/dates'
import {
  CalendarContainer,
  WeekDays,
  ModalConfirm
} from './styled'
import Loading from '../../../components/layout/Loading'
import { customTheme } from '../../../../styles/theme'
import ButtonPrimary from '../../../components/Button'
import GenerateForm from '../../../components/layout/GenerateForm'
import {
  fetchHospitalsById,
  createHospitalHoursAndDaysAvailable,
  removeHospitalHoursAndDaysAvailable,
  updateHospitalHoursAndDaysAvailable
} from '../../../../services/hospitals'
import CardModal from '../../../components/CardModal'
import { useModal } from '../../../../contexts/modal'
import { editFields } from './columns'
import { CloseButton } from 'react-bootstrap'
import { useLoading } from '../../../../contexts/loading'
import { useAuth } from '../../../../contexts/authenticator'
import { IframeContainer } from '../../../components/Iframe'

require('react-big-calendar/lib/css/react-big-calendar.css')

const localizer = momentLocalizer(moment)

function InternalSchedulesHospital ({ match }) {
  const { id } = useAuth()
  let docId = id

  if (match.params.id) {
    docId = match.params.id
  }

  const { data, loading } = useQuery(fetchHospitalsById(), {
    variables: { limit: 10, offset: 0, id: docId }
  })
  const alertBox = useAlertBox()
  const { setActive } = useLoading()

  const [createScheduleAvailableMutation] = useMutation(
    createHospitalHoursAndDaysAvailable()
  )

  const [deleteScheduleAvailableMutation] = useMutation(
    removeHospitalHoursAndDaysAvailable()
  )

  const [updateScheduleAvailableMutation] = useMutation(
    updateHospitalHoursAndDaysAvailable()
  )

  const [content, setContent] = useState(null)
  const [events, setEvents] = useState([])
  const [values, setValues] = useState([])

  const modal = useModal()

  const mutationCreateScheduleAvailable = async (data) => {
    return await createScheduleAvailableUseCase(
      createScheduleAvailableMutation,
      data,
      { alertBox }
    )
  }

  const mutationDeleteScheduleAvailable = async (data) => {
    return await deleteScheduleAvailableUseCase(
      deleteScheduleAvailableMutation,
      data,
      { alertBox }
    )
  }

  const mutationUpdateScheduleAvailable = async (data) => {
    return await updateScheduleAvailableUseCase(
      updateScheduleAvailableMutation,
      data,
      { alertBox }
    )
  }

  const handleSelect = ({ start, end }) => {
    const title = 'Disponível'

    let canAddEvent = true
    events.forEach((event) => {
      if (verifyEventIsAvailable(start, end, event)) {
        canAddEvent = false
      }
    })

    values.forEach((value) => {
      if (verifyEventIsAvailable(start, end, value)) {
        canAddEvent = false
      }
    })

    if (canAddEvent) {
      modal.setOptions({
        open: true,
        component: (
          <CardModal style={{ width: '500px' }}>
            <CloseButton
              style={{
                position: 'absolute',
                right: 13,
                top: 13
              }}
              onClick={() => modal.setOptions({ open: false })}
            />
            <GenerateForm
              onSubmit={(e) => handleCreateRequest(e)}
              groupFields={editFields({
                day_of_week_id: Number(moment(start).format('d')) + 1,
                start_time: moment(start).format('HH:mm:ss'),
                end_time: moment(end).format('HH:mm:ss')
              })}
              but
              button={{
                submit: 'Salvar',
                submitting: 'Salvando'
              }}
            />
            <ButtonPrimary
              onClick={() => {
                modal.setOptions({ open: false })
              }}
              style={{
                backgroundColor: customTheme.colors.error,
                boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`,
                marginBottom: 15
              }}
            >
              Cancelar
            </ButtonPrimary>
          </CardModal>
        )
      })
    }
  }

  const handleCreateRequest = async (e) => {
    setActive(true)
    try {
      const body = [
        {
          hospital_id: content.id,
          day_of_week_id: e.day_of_week_id.value,
          start_time: e.start_time,
          end_time: e.end_time
        }
      ]

      await mutationCreateScheduleAvailable({
        objects: body
      })
      setValues([])
    } catch (error) {
      console.error(error)
    }
    modal.setOptions({ open: false })
    setActive(false)
  }

  const handleUpdateRequest = async (event, e) => {
    setActive(true)
    try {
      await mutationUpdateScheduleAvailable({
        id: event.id,
        day_of_week_id: e.day_of_week_id.value,
        start_time: e.start_time,
        end_time: e.end_time
      })
    } catch (error) {
      console.error(error)
    }
    modal.setOptions({ open: false })
    setActive(false)
  }

  const handleDeleteRequest = async (id) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '400px' }}>
          <ModalConfirm>
            <h3>Tem certeza que deseja apagar?</h3>
            <div className="buttons-area">
              <ButtonPrimary
                id="delete-button"
                onClick={async () => {
                  setActive(true)
                  try {
                    document.getElementById('delete-button').textContent =
                      'Apagando'
                    await mutationDeleteScheduleAvailable({
                      id: id
                    })
                  } catch (error) {
                    console.error(error)
                  }
                  modal.setOptions({ open: false })
                  setActive(false)
                }}
              >
                Sim
              </ButtonPrimary>

              <ButtonPrimary
                onClick={() => modal.setOptions({ open: false })}
                style={{
                  backgroundColor: customTheme.colors.error,
                  boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`
                }}
              >
                Não
              </ButtonPrimary>
            </div>
          </ModalConfirm>
        </CardModal>
      )
    })
  }

  const handleCancelSchedule = () => {
    setEvents((prev) => prev.filter((item) => !isNaN(item.id)))
    setValues([])
  }

  useEffect(() => {
    if (data?.hospitals_by_pk) {
      setContent(data?.hospitals_by_pk)

      const evs = []
      data?.hospitals_by_pk?.hospital_hours_and_days_availables.forEach(
        (item) =>
          evs.push({
            id: item?.id,
            title: 'Disponível',
            color: '#3daf7d',
            start: new Date(
              moment(currentWeek()[Number(item?.days_of_week?.id) - 1]).format(
                'YYYY, M, D '
              ) +
                moment(
                  `11/11/1111 ${item.start_time.replace('+00', '')}`
                ).format('HH:mm:ss')
            ),
            end: new Date(
              moment(currentWeek()[Number(item?.days_of_week?.id) - 1]).format(
                'YYYY, M, D '
              ) +
                moment(`11/11/1111 ${item.end_time.replace('+00', '')}`).format(
                  'HH:mm:ss'
                )
            )
          })
      )

      setEvents(evs)
    }
  }, [data])

  if (loading) return <Loading />

  // ESSA LINHA EXISTE PARA FINS DE TESTE
  const payload = JSON.stringify({
    hospital_id: content?.id,
    doctor_id: docId
  })

  console.log(payload)

  // const payload = {
  //   id: '65f1f73444804e00136b6873',
  //   cpf: '07634247570',
  //   name: 'Gabriel Novais Cortes',
  //   fullname: 'Gabriel Novais Cortes',
  //   birthdate: '30/01/1970',
  //   cellphone: '61988887777',
  //   telephone: '6133222244',
  //   sex: 'M',
  //   address: {
  //     uf: 'RJ',
  //     city: 'Cidade',
  //     complement: 'Opcional',
  //     number: '1',
  //     street: 'Rua',
  //     neighborhood: 'Bairro',
  //     cep: '24330112'
  //   }
  // }

  // const final = JSON.stringify({
  //   callbackUrl: 'https://google.com',
  //   documentType: 'prescription',
  //   patient: {
  //     id: '65f1f73444804e00136b6873',
  //     cpf: '07634247570'
  //   },
  //   prescriber: {
  //     id: '65f0f49344804e00136b44af',
  //     cpf: '07634247570',
  //     type: 'doctor',
  //     localId: '65f0f49344804e00136b44af'
  //   },
  //   colorSetting: {
  //     primary: '#1C69C9',
  //     secondary: '#609EEA',
  //     footer: 'transparent',
  //     inputBorder: '#BCECFD',
  //     inputBackground: '#F1FCFF'
  //   },
  //   ui: {
  //     showLocal: true,
  //     showPatientName: true,
  //     showSendDocumentOnCallback: true,
  //     sendDocumentRequired: false
  //   }
  // })

  // console.log(final)

  return (
    <InternalBody>
      <h2>Horários disponíveis para atendimento (Hospital)</h2>

      {/* <div className="iframe">
        <IframeContainer payload={final} />
      </div> */}
      <CalendarContainer>
        <WeekDays>
          <div>Hora</div>
          {weekDays.map((item) => (
            <div key={item.value}>{item.label}</div>
          ))}
        </WeekDays>
        <Calendar
          selectable
          localizer={localizer}
          events={events}
          defaultView={Views.WEEK}
          style={{ height: 650, paddingBottoms: 200 }}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}
          onSelectEvent={(event) => {
            modal.setOptions({
              open: true,
              component: (
                <CardModal style={{ width: '500px' }}>
                  <CloseButton
                    style={{
                      position: 'absolute',
                      right: 13,
                      top: 13
                    }}
                    onClick={() => modal.setOptions({ open: false })}
                  />
                  <GenerateForm
                    onSubmit={(e) => handleUpdateRequest(event, e)}
                    groupFields={editFields({
                      id: event.id,
                      day_of_week_id:
                        Number(moment(event?.start).format('d')) + 1,
                      start_time: moment(event?.start).format('HH:mm:ss'),
                      end_time: moment(event?.end).format('HH:mm:ss')
                    })}
                    but
                    button={{
                      submit: 'Salvar',
                      submitting: 'Salvando'
                    }}
                  />
                  <ButtonPrimary
                    onClick={() => handleDeleteRequest(event.id)}
                    id="delete-button"
                    style={{
                      backgroundColor: customTheme.colors.error,
                      boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`,
                      marginBottom: 15
                    }}
                  >
                    Apagar
                  </ButtonPrimary>
                </CardModal>
              )
            })
          }}
          onSelectSlot={handleSelect}
          messages={translateCalendar}
          eventPropGetter={(event) => {
            return {
              style: { backgroundColor: event?.color, border: 'none' }
            }
          }}
        />
      </CalendarContainer>
    </InternalBody>
  )
}

export default InternalSchedulesHospital
