import React from 'react'
import LocalHospitalTwoToneIcon from '@material-ui/icons/LocalHospitalTwoTone'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import EventNoteIcon from '@mui/icons-material/EventNote'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import BarChartIcon from '@mui/icons-material/BarChart'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import PriceChangeIcon from '@mui/icons-material/PriceChange'
import ApartmentIcon from '@mui/icons-material/Apartment'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import PriceCheckIcon from '@mui/icons-material/PriceCheck'
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn'
import GroupIcon from '@mui/icons-material/Group'
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy'
import PreviewIcon from '@mui/icons-material/Preview'
import HelpIcon from '@mui/icons-material/Help'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import MedicalServicesIcon from '@mui/icons-material/MedicalServices'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import ScienceIcon from '@mui/icons-material/Science'
import ScheduleIcon from '@mui/icons-material/Schedule'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import GavelIcon from '@mui/icons-material/Gavel'

export const adminMenu = [
  {
    title: 'Dashboard',
    route: '/',
    category: 'general',
    icon: <BarChartIcon />
  },
  {
    title: 'Clínicas',
    route: '/clinics',
    category: 'general',
    icon: <LocalHospitalTwoToneIcon />
  },
  {
    title: 'SUS',
    route: '/sus',
    category: 'general',
    icon: <DataSaverOnIcon />
  },
  {
    title: 'Odontologia',
    route: '/odontology',
    category: 'general',
    icon: <MedicalServicesIcon />
  },
  {
    title: 'Hospitais',
    route: '/hospital',
    category: 'general',
    icon: <LocationCityIcon />
  },
  {
    title: 'Especialidades',
    route: '/specialties',
    category: 'settings',
    icon: <AssignmentTurnedInIcon />
  },
  {
    title: 'Pacientes',
    route: '/pacients',
    category: 'settings',
    icon: <GroupIcon />
  },
  {
    title: 'Banners',
    route: '/banners',
    category: 'settings',
    icon: <BurstModeIcon />
  },
  {
    title: 'Tarifas',
    route: '/plans',
    category: 'settings',
    icon: <PriceChangeIcon />
  },
  {
    title: 'Bancos',
    route: '/cooperatives',
    category: 'settings',
    icon: <ApartmentIcon />
  },
  {
    title: 'Crédito',
    route: '/credit-request',
    category: 'settings',
    icon: <PointOfSaleIcon />
  },
  {
    title: 'Pagamentos',
    route: '/payments',
    category: 'settings',
    icon: <PriceCheckIcon />
  },
  {
    title: 'Políticas de privacidade',
    route: '/admin/privacy-policy',
    category: 'settings',
    icon: <AdminPanelSettingsIcon />
  },
  {
    title: 'Termos de uso',
    route: '/admin/terms-of-use',
    category: 'settings',
    icon: <GavelIcon />
  },
  {
    title: 'Farmácias',
    route: '/pharmacies',
    category: 'services',
    icon: <LocalPharmacyIcon />
  },
  {
    title: 'Óticas',
    route: '/optics',
    category: 'services',
    icon: <PreviewIcon />
  },
  {
    title: 'Diagnóstico',
    route: '/diagnostic-images',
    category: 'services',
    icon: <QueryStatsIcon />
  },
  {
    title: 'Laboratórios',
    route: '/laboratories',
    category: 'services',
    icon: <ScienceIcon />
  },
  {
    title: 'Suporte',
    route: '/suport',
    category: 'services',
    icon: <HelpIcon />
  }
]

export const doctorMenu = [
  {
    title: 'Dashboard',
    route: '/',
    category: 'general',
    icon: <BarChartIcon />
  },
  {
    title: 'Minhas Clinicas',
    route: '/my-clinics',
    category: 'general',
    icon: <LocalHospitalTwoToneIcon />
  },
  {
    title: 'Meus Hospitais',
    route: '/my-hospitals',
    category: 'general',
    icon: <LocalHospitalTwoToneIcon />
  },
  {
    title: 'Meus SUS',
    route: '/my-list-sus',
    category: 'general',
    icon: <LocalHospitalTwoToneIcon />
  },
  {
    title: 'Pacientes',
    route: '/my-pacients',
    category: 'general',
    icon: <GroupIcon />
  },
  {
    title: 'Agendamentos',
    route: '/doctor-requests-schedules',
    category: 'general',
    icon: <CalendarTodayIcon />
  },
  {
    title: 'Meu Dados',
    route: '/doctor-profile',
    category: 'settings',
    icon: <SupervisedUserCircleIcon />
  },
  {
    title: 'Agenda',
    route: '/doctor-available-schedules',
    category: 'settings',
    icon: <EventNoteIcon />
  }
]

export const susMenu = [
  {
    title: 'Dashboard',
    route: '/',
    category: 'general',
    icon: <BarChartIcon />
  },
  {
    title: 'Meu Dados',
    route: '/sus-profile',
    category: 'general',
    icon: <DataSaverOnIcon />
  },
  {
    title: 'Meus Médicos',
    route: '/sus/my-doctors',
    category: 'general',
    icon: <SupervisedUserCircleIcon />
  },
  {
    title: 'Agenda',
    route: '/sus-available-schedules',
    category: 'general',
    icon: <EventNoteIcon />
  },
  {
    title: 'Agendamentos',
    route: '/sus-requests-schedules',
    category: 'general',
    icon: <CalendarTodayIcon />
  },
  {
    title: 'Salas de atendimento',
    route: '/sus-waiting-rooms',
    category: 'general',
    icon: <ScheduleIcon />
  }
]

export const petShopsMenus = [
  {
    title: 'Dashboard',
    route: '/',
    category: 'general',
    icon: <BarChartIcon />
  },
  {
    title: 'Meu Dados',
    route: '/petshop-profile',
    category: 'general',
    icon: <DataSaverOnIcon />
  },
  {
    title: 'Agenda',
    route: '/petshop-available-schedules',
    category: 'general',
    icon: <EventNoteIcon />
  },
  {
    title: 'Agendamentos',
    route: '/petshop-requests-schedules',
    category: 'general',
    icon: <CalendarTodayIcon />
  },
  {
    title: 'Pagamentos',
    route: '/payments-petshop',
    category: 'general',
    icon: <PriceCheckIcon />
  }
]

export const laboratoriesMenus = [
  {
    title: 'Dashboard',
    route: '/',
    category: 'general',
    icon: <BarChartIcon />
  },
  {
    title: 'Meus Dados',
    route: '/laboratories-profile',
    category: 'general',
    icon: <DataSaverOnIcon />
  },
  {
    title: 'Solicitações',
    route: '/laboratorie-requests',
    category: 'general',
    icon: <EventNoteIcon />
  },
  {
    title: 'Pagamentos',
    route: '/payments-laboratories',
    category: 'general',
    icon: <PriceCheckIcon />
  }
]

export const odontologyMenus = [
  {
    title: 'Dashboard',
    route: '/',
    category: 'general',
    icon: <BarChartIcon />
  },
  {
    title: 'Meu Dados',
    route: '/odontology-profile',
    category: 'general',
    icon: <DataSaverOnIcon />
  },
  {
    title: 'Solicitações',
    route: '/odontology-requests',
    category: 'general',
    icon: <EventNoteIcon />
  },
  {
    title: 'Pagamentos',
    route: '/odontology-payments',
    category: 'general',
    icon: <PriceCheckIcon />
  }
]

export const diagnosticImageMenus = [
  {
    title: 'Dashboard',
    route: '/',
    category: 'general',
    icon: <BarChartIcon />
  },
  {
    title: 'Meus Dados',
    route: '/diagnostic-image-profile',
    category: 'general',
    icon: <DataSaverOnIcon />
  },
  {
    route: '/diagnostic-image-requests',
    title: 'Solicitações',
    category: 'general',
    icon: <EventNoteIcon />
  },
  {
    title: 'Pagamentos',
    route: '/payments-diagnostic-image',
    category: 'general',
    icon: <PriceCheckIcon />
  }
]

export const hospitalMenu = [
  {
    title: 'Dashboard',
    route: '/',
    category: 'general',
    icon: <BarChartIcon />
  },
  {
    title: 'Meu Dados',
    route: '/hospital-profile',
    category: 'general',
    icon: <LocalHospitalTwoToneIcon />
  },
  {
    title: 'Meus Médicos',
    route: '/my-doctors-hospital',
    category: 'general',
    icon: <SupervisedUserCircleIcon />
  },
  {
    title: 'Agenda',
    route: '/hospital-available-schedules', // TODO VANUT
    category: 'general',
    icon: <EventNoteIcon />
  },
  {
    title: 'Agendamentos',
    route: '/hospital-requests-schedules', // TODO VANUT
    category: 'general',
    icon: <CalendarTodayIcon />
  },
  {
    title: 'Pagamentos',
    route: '/payments-hospital',
    category: 'general',
    icon: <PriceCheckIcon />
  }
]

export const clinicMenu = [
  {
    title: 'Dashboard',
    route: '/',
    category: 'general',
    icon: <BarChartIcon />
  },
  {
    title: 'Meu Dados',
    route: '/clinic-profile',
    category: 'general',
    icon: <LocalHospitalTwoToneIcon />
  },
  {
    title: 'Meus Médicos',
    route: '/my-doctors',
    category: 'general',
    icon: <SupervisedUserCircleIcon />
  },
  {
    title: 'Agenda',
    route: '/clinics-available-schedules',
    category: 'general',
    icon: <EventNoteIcon />
  },
  {
    title: 'Agendamentos',
    route: '/clinics-requests-schedules',
    category: 'general',
    icon: <CalendarTodayIcon />
  },
  {
    title: 'Pagamentos',
    route: '/payments-clinic',
    category: 'general',
    icon: <PriceCheckIcon />
  }
]
