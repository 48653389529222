import React, { useEffect, useState } from 'react'
import MainContainer from '../../../components/MainContainer'
import { fetchPrivacyPolicy } from '../../../../services/privacy-policy'
import * as S from './styled'
import Loading from '../../../components/layout/Loading'
import { useQuery } from '@apollo/client'

function PrivacyPolicy ({ list }) {
  const [content, setContent] = useState('')
  const { data, loading } = useQuery(fetchPrivacyPolicy())

  useEffect(() => {
    if (data?.legal_documents_by_pk?.privacy_policy) {
      setContent(data?.legal_documents_by_pk?.privacy_policy)
    }
  }, [data])

  return (
    <MainContainer container>

      {loading && (<Loading />)}

      {!loading && <S.Paragraph dangerouslySetInnerHTML={{ __html: content }} />}
    </MainContainer>
  )
}

export default PrivacyPolicy
