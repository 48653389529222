import { gql } from '@apollo/client'

export const paymentsByClinics = () => {
  return gql`
  fragment clinicPaymentFields on payments {
    description
    created_at
    id
    method
    status
    updated_at
    value_of_transaction
    user {
      id
      full_name
      email
      photo
    }
    schedule {
      day
      end_time
      start_time
      status
      type_of_medical_appointment
      doctors_appointment {
        doctor {
          full_name
          id
        }
      }
    }
  }
  
  query getPaymentsByClinic(
    $clinic_id: Int,
    $limit: Int,
    $offset: Int,
    $status: String
  ) {
    payments(
      where: {
        status: { _like: $status },
        schedule: {
          doctors_appointment: { clinic_id: { _eq: $clinic_id } }
        }
      },
      limit: $limit,
      offset: $offset,
      order_by: { updated_at: desc }
    ) {
      ...clinicPaymentFields
    }
  
    payments_aggregate(
      where: {
        status: { _like: $status },
        schedule: {
          doctors_appointment: { clinic_id: { _eq: $clinic_id } }
        }
      }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
  `
}
