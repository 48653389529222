import React, { useState } from 'react'
import { fetchClinicsByDoctorId, getClinicsCountByDoctorId } from '../../../../services/doctor'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import DataTable from '../../../components/DataTable'
import Header from '../../../components/Header'
import { useAlertBox } from '../../../../contexts/alert_box'
import { columns } from './columns'

function ClinicsByDoctor (props) {
  const id = props.user_id

  const alertBox = useAlertBox()
  const history = useHistory()

  const [offset, setOffset] = useState(0)
  const { data, loading } = useQuery(fetchClinicsByDoctorId(), {
    variables: {
      limit: 10,
      offset: offset * 10,
      doctor_id: id
    },
    onError: () => {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar os dados!',
        type: 'error',
        time: 3000
      })
    }
  })

  const { data: counts } = useQuery(getClinicsCountByDoctorId(), {
    variables: {
      doctor_id: id
    },
    onError: () => {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar os dados!',
        type: 'error',
        time: 3000
      })
    }
  })

  const onChangePage = (page) => {
    setOffset(page)
  }

  return (
    <div>
      <Header title="Clínicas" />

      <DataTable
        data={data?.clinics ?? []}
        loading={loading}
        onChangePage={onChangePage}
        columns={columns(history)}
        totalItems={counts?.clinics_aggregate?.aggregate?.count || 100}
      />
    </div>
  )
}

export default ClinicsByDoctor
