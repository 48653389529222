import React, { useState } from 'react'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { useQuery } from '@apollo/client'
import ApartmentIcon from '@mui/icons-material/Apartment'
import MoneyIcon from '@mui/icons-material/Money'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import { CustomStyle } from '../users/styled'
import GenerateForm from '../../components/layout/GenerateForm'
import { filterUsers } from '../users/columns'
import {
  fecthDataAllByfilter,
  fecthDataRequestsByfilter
} from '../../../services/dash'
import DashboardBox from '../../components/DashboardBox'
import Header from '../../components/Header'
import * as S from './styled'

function DashboardAdmin ({ data }) {
  const [filter, setFilter] = useState({ state: { value: '%' } })
  const allData = useQuery(fecthDataAllByfilter(), {
    variables: {
      limit: 10000000,
      offset: 0,
      state_code: filter?.state?.value,
      city: filter?.city?.label || '%'
    }
  })

  const requestCredit = useQuery(fecthDataRequestsByfilter(), {
    variables: {
      limit: 10000000,
      offset: 0,
      state_code: filter?.state?.value,
      city: filter?.city?.label || '%',
      distinct: false
    }
  })
  const requestCreditAccept = useQuery(fecthDataRequestsByfilter(), {
    variables: {
      limit: 10000000,
      offset: 0,
      state_code: filter?.state?.value,
      city: filter?.city?.label || '%',
      distinct: true
    }
  })

  return (
    <S.Container>
      <Header title="Dashboard" />

      <CustomStyle>
        <GenerateForm
          onSubmit={null}
          groupFields={filterUsers(filter, setFilter)}
          button={{
            submit: 'Adicionar médico existente na base de dados',
            submitting: 'Adicionando...'
          }}
        />
      </CustomStyle>

      <S.Grid>
        <DashboardBox
          title="Usuários cadastrados"
          number={allData?.data?.users_aggregate?.aggregate?.count || 0}
          color="#00ff00"
          icon={<PersonOutlineIcon />}
        />
        <DashboardBox
          title="Médicos credenciados"
          number={allData?.data?.doctors_aggregate?.aggregate?.count || 0}
          color="#c8b900"
          icon={<ApartmentIcon />}
        />
        <DashboardBox
          title="Clínicas credenciadas"
          number={allData?.data?.clinics_aggregate?.aggregate?.count || 0}
          color="#260e04"
          icon={<ApartmentIcon />}
        />
        <DashboardBox
          title="Hospitais"
          number={allData?.data?.hospitals_aggregate?.aggregate?.count || 0}
          color="#8e0000"
          icon={<ApartmentIcon />}
        />
        <DashboardBox
          title="SUS credenciadas"
          number={allData?.data?.sus_aggregate?.aggregate?.count || 0}
          color="#12005e"
          icon={<ApartmentIcon />}
        />
      </S.Grid>

      <h3 className="mb-3 mt-5">Solicitações de crédito</h3>

      <S.Grid>
        <DashboardBox
          title="Crédito Solicitado"
          number={requestCredit?.data?.credit_requests_aggregate?.aggregate?.count || 0}
          color="#006db3"
          icon={<CurrencyExchangeIcon />}
        />
        <DashboardBox
          title="Crédito Liberado"
          number={requestCreditAccept?.data?.credit_requests_aggregate?.aggregate?.count || 0}
          color="#c43e00"
          icon={<MoneyIcon />}
        />
      </S.Grid>
    </S.Container>
  )
}
export default DashboardAdmin
