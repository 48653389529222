import { gql } from '@apollo/client'

export const fecthDataAllByfilter = () => {
  return gql`
    query getAllByCityAndStateCode($city: String, $state_code: String) {
      clinics_aggregate(
        where: {
          adress: { city: { _like: $city }, state_code: { _like: $state_code } }
        }
      ) {
        aggregate {
          count
        }
      }
      doctors_aggregate(
        where: {
          adress: { city: { _like: $city }, state_code: { _like: $state_code } }
        }
      ) {
        aggregate {
          count
        }
      }
      hospitals_aggregate(
        where: {
          adress: { city: { _like: $city }, state_code: { _like: $state_code } }
        }
      ) {
        aggregate {
          count
        }
      }
      sus_aggregate(
        where: {
          adress: { city: { _like: $city }, state_code: { _like: $state_code } }
        }
      ) {
        aggregate {
          count
        }
      }
      users_aggregate(
        where: {
          adress: { city: { _like: $city }, state_code: { _like: $state_code } }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `
}

export const fecthDataRequestsByfilter = () => {
  return gql`
    query getCreditRequestsAggregateWithCity(
      $distinct: Boolean
      $city: String = ""
      $state_code: String = ""
    ) {
      credit_requests_aggregate(
        where: {
          user: {
            adress: {
              city: { _like: $city }
              state_code: { _like: $state_code }
            }
          }
        }
      ) {
        aggregate {
          count(columns: accepted, distinct: $distinct)
        }
      }
    }
  `
}
