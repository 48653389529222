import { gql } from '@apollo/client'

export const fetchAnamnese = () => {
  return gql`
    query getMedicalHistory($user_id: Int!, $limit: Int, $offset: Int) {
      medical_history(where: {user_id: {_eq: $user_id}}, order_by: {updated_at: desc}, limit: $limit, offset: $offset) {
        shedule_id
        updated_at
        user_id
        id
        doctor_id
        description
        created_at
        doctor {
          full_name
          email
          profile_image
        }
      }
    }
  `
}

export const fetchAnamneseById = () => {
  return gql``
}

export const addAnamnese = () => {
  return gql`
    mutation insertAmnese($description: String, $doctor_id: Int, $user_id: Int, $shedule_id: Int) {
      insert_medical_history_one(object: {description: $description, doctor_id: $doctor_id, user_id: $user_id, shedule_id: $shedule_id}) {
        created_at
        description
        doctor_id
        id
        shedule_id
        updated_at
        user_id
      }
    }
  `
}

export const updateAmamneses = () => {
  return gql`
  mutation updateMedicalHistory($id: Int!, $description: String) {
    update_medical_history_by_pk(pk_columns: {id: $id}, _set: {description: $description}) {
      description
      doctor_id
      id
      shedule_id
      updated_at
    }
  }
  `
}

export const removeAnamnese = () => {
  return gql``
}
