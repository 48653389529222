import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { useMutation, useQuery } from '@apollo/client'
import { columns, filterUsers } from './columns'
import { useModal } from '../../../../contexts/modal'
import { Link } from 'react-router-dom'
import ButtonPrimary from '../../../components/Button'
import { customTheme } from '../../../../styles/theme'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { fetchPetshop, deletePetshop, fetchPetshopByFilter } from '../../../../services/petshops'
import { useAlertBox } from '../../../../contexts/alert_box'
import { CustomStyle } from './styled'
import GenerateForm from '../../../components/layout/GenerateForm'

function PetshopPage ({ list }) {
  const [perPage, setPerPage] = useState(5)
  const [filter, setFilter] = useState({ state: { value: '%' } })
  const { loading, data, refetch } = useQuery(fetchPetshopByFilter(), {
    variables: {
      limit: 1000000,
      offset: 0,
      state_code: filter?.state?.value,
      city: filter?.city?.label || '%'
    }
  })
  const [mutateDelete] = useMutation(deletePetshop())
  const modal = useModal()
  const alertBox = useAlertBox()
  if (loading) return <span>Carregando...</span>

  return (
    <>
      <Row>
        <Col xs={12}>
          <h2>Clínicas Veterinárias</h2>
        </Col>
        <Col xs={12}>
          <Link to="/petshops/new">
            <ButtonPrimary
              style={{
                marginBottom: '15px',
                marginTop: '50px',
                boxShadow: 'none',
                fontSize: '12px',
                padding: '5px',
                backgroundColor: customTheme.colors.primary,
                lineHeight: '2',
                maxWidth: 300,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              Adicionar Clínica Veterinária
              <AddCircleIcon
                style={{ color: customTheme.colors.white, marginLeft: 5 }}
              />
            </ButtonPrimary>
          </Link>
        </Col>
        <Col xs={12} className="mt-4">
          <CustomStyle className="list">
            <GenerateForm
              onSubmit={null}
              groupFields={filterUsers(filter, setFilter)}
              button={{
                submit: 'Adicionar médico existente na base de dados',
                submitting: 'Adicionando...'
              }}
            />
          </CustomStyle>
        </Col>
      </Row>
      {loading
        ? (
          <span>Carregando...</span>
          )
        : (
          <Row className="mt-4">
            <Col xs={12}>
              {data?.pet_shops && (
                <div style={{ height: 400, width: '100%' }}>
                  <DataGrid
                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    rows={list || data.pet_shops}
                    columns={columns(modal, alertBox, mutateDelete, refetch)}
                    pageSize={perPage}
                    onPageSizeChange={(e) => {
                      setPerPage(e)
                    }}
                    rowsPerPageOptions={[10]}
                    pagination
                    disableSelectionOnClick
                  />
                </div>
              )}
            </Col>
          </Row>
          )}
    </>
  )
}

export default PetshopPage
