import styled from 'styled-components'

export const Avatar = styled.img`
  aspect-ratio: 1/1;
  max-width: 24px;
  border-radius: 50%;
  object-fit: cover;
`

export const WeekForm = styled.form`
  display: flex;
  justify-content: space-between;
`

export const WeekDayForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;

  h4 {
    font-size: 12px;
  }
`

export const WeekFormInput = styled.div`
  display: flex;
  flex-direction: column;

  input {
    width: 100%;
  }
`

export const CalendarContainer = styled.div`
  width: 100%;
  height: 600px;
  margin-top: 25px;

  .btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  .rbc-time-content {
    ::-webkit-scrollbar {
      width: 7px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }
  }

  .rbc-header,
  .rbc-toolbar,
  .rbc-time-header.rbc-overflowing,
  .rbc-time-header-content {
    display: none !important;
  }
  .rbc-today {
    background-color: unset;
  }
`

export const WeekDays = styled.div`
  display: flex;
  justify-content: space-between;
  background: #fefefe;
  width: 100%;
  border: 0.5px #ccc solid;

  div {
    text-align: center;
    width: 100%;
    padding: 3px 9px;
    border-right: 1px #ddd solid;
    border-collapse: collapse;

    &:first-child {
      width: auto;
    }

    &:last-child {
      border-right: 9px #fff solid;
    }
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ModalConfirm = styled.div`
  h3 {
    font-size: 20px;
    text-align: center;
  }

  .buttons-area {
    display: flex;
    margin-top: 15px;
    align-items: center;
    
    button {
      margin: 0 5px;
      padding: 10px;
    }
  }
`
