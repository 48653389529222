import React, { useState } from 'react'
import { customTheme } from '../../../../styles/theme'
import moment from 'moment'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import ButtonPrimary from '../../../components/Button'
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import country from '../../../../Utils/estados_cidades.json'
import { formatMoney } from '../../../../Utils/format'
import { cpfValidator } from '../../../../services/validators/cpf_validator'
import { cepValidator } from '../../../../services/validators/cep_validator'
import { phoneValidator } from '../../../../services/validators/phone_validator'

export const columns = () => [
  {
    field: 'observation',
    headerName: 'Observação',
    width: 300,
    editable: false,
    valueGetter: (params) => `${params?.row?.observation}`
  },
  {
    field: 'deuserbiusert_fees',
    headerName: 'Nome do Solicitante',
    width: 160,
    editable: false,
    valueGetter: (params) => `${params.row.user.full_name || ''}`
  },
  {
    field: 'clinic',
    headerName: 'Instituicão',
    width: 200,
    editable: false,
    valueGetter: (params) =>
      `${params?.row?.clinic ? params?.row?.clinic?.company_name : ''}`
  },
  {
    field: 'value_of_request_credit',
    headerName: 'Valor',
    width: 150,
    editable: false,
    valueGetter: (params) =>
      `${formatMoney(params.row.value_of_request_credit) || ''}`
  },
  {
    field: 'accepted',
    headerName: 'Status',
    width: 100,
    editable: false,
    valueGetter: (params) => `${params.row.accepted ? 'Aceito' : 'Não Aceito'}`
  },
  {
    field: 'created_at',
    headerName: 'Data de Cadastro',
    sortable: true,
    width: 150,
    valueGetter: (params) => `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
    valueFormatter: params => moment(params.value).format('DD/MM/YYYY - HH:MM')
  },
  {
    field: 'id',
    headerName: '',
    sortable: false,
    width: 60,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          <Link to={`/credit-request/${params.row.id}`}>
            <ButtonPrimary
              style={{
                width: '35px',
                height: '35px',
                padding: '5px',
                borderRadius: '50%'
              }}
              // onClick={() => {
              //   modal.open({
              //     open: true,
              //     component: (
              //       <CardModal
              //         title={params.row.company_name}
              //         avatar={params?.row?.photo}
              //         subtitle={`${
              //           moment(params.row.created_at).format(
              //             "DD/MM/YYYY HH:MM"
              //           ) || ""
              //         } `}
              //       >
              //         sdsd
              //       </CardModal>
              //     ),
              //   });
              // }}
            >
              <RemoveRedEyeIcon
                style={{ color: customTheme.colors.white }}
                edge="start"
                fontSize="small"
                sx={{
                  marginBottom: '2px'
                }}
              />
            </ButtonPrimary>
          </Link>
        </Col>
      )
    }
  }
]

export const groupFields = (content, setContent) => {
  const cities = []
  const states = []
  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e?.target?.value || e }))
    }
  }
  return [
    {
      name: 'Tarifa',
      fields: [
        {
          label: 'Observações:',
          type: 'textarea',
          required: true,
          disabled: true,
          hideField: false,
          currentValue: content?.observation,
          name: 'observation',
          onChange: (e) => {
            handleInputChange(e, 'observation')
          },
          columns: {
            xs: 12
          }
        },
        {
          label: 'Valor Solicitado',
          type: 'text',
          required: true,
          disabled: true,
          hideField: false,
          currentValue: formatMoney(content?.value_of_request_credit),
          name: 'value_of_request_credit',
          onChange: (e) => {
            handleInputChange(e, 'value_of_request_credit')
          },
          columns: {
            xs: 3
          }
        }
      ]
    },
    ...groupFieldsUser(content?.user),
    ...clinicFields(content?.clinic)
  ]
}

export const groupFieldsUser = (content, setContent) => {
  const cities = []
  const states = []
  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e?.target?.value || e }))
    }
  }

  return [
    {
      name: 'Informações do Solicitante',
      fields: [
        {
          label: 'Nome',
          type: 'text',
          required: true,
          disabled: true,
          hideField: false,
          currentValue: content?.full_name,
          name: 'full_name',
          onChange: (e) => {
            handleInputChange(e, 'full_name')
          },
          columns: {
            xs: 3
          }
        },
        {
          label: 'Data de Nascimento',
          type: 'date',
          required: true,
          disabled: true,
          hideField: false,
          currentValue: `${moment(content?.birth_date).format('YYYY-MM-DD')}`,
          name: 'birth_date',
          columns: {
            xs: 3
          }
        },
        {
          label: 'CNPJ',
          type: 'text',
          required: true,
          disabled: true,
          hideField: false,
          currentValue: `${content?.cnpj}`,
          name: 'cnpj',
          columns: {
            xs: 3
          }
        },
        {
          label: 'E-mail',
          type: 'text',
          required: true,
          disabled: true,
          hideField: false,
          currentValue: content?.email,
          name: 'emailUser',
          columns: {
            xs: 3
          }
        },
        {
          label: 'Tipo',
          type: 'text',
          required: true,
          disabled: true,
          hideField: false,
          currentValue: `${
            content?.kind_of_person === 'pf'
              ? 'Pessoa Física'
              : 'Pessoa Jurídica'
          }`,
          name: 'kind_of_person',
          columns: {
            xs: 3
          }
        },
        {
          label: 'Telefone',
          type: 'text',
          required: true,
          disabled: true,
          hideField: false,
          mask: '+55 (99) 99999-9999',
          currentValue: `${content?.phone}`,
          name: 'phone',
          columns: {
            xs: 3
          }
        },
        {
          label: 'Estado Civil',
          type: 'text',
          required: true,
          disabled: true,
          hideField: !content?.user_marital_status,
          currentValue: `${content?.user_marital_status || ''}`,
          name: 'cuser_marital_statuspf',
          columns: {
            xs: 3
          }
        }
      ]
    },
    {
      name: 'Endereço do solicitante',
      fields: [
        {
          label: 'Estado',
          type: 'autocomplete',
          required: true,
          disabled: true,
          currentValue: content
            ? {
                label: content?.adress?.state,
                value: content?.adress?.state_code
              }
            : null,
          hideField: false,
          name: 'state',
          options: states,
          columns: {
            xs: 6
          }
        },
        {
          label: 'Cidade',
          type: 'autocomplete',
          required: true,
          disabled: true,
          currentValue: content
            ? {
                label: content?.adress?.city,
                value: content?.adress?.city
              }
            : null,
          hideField: false,
          name: 'city',
          columns: {
            xs: 6
          },
          onChange: (data) => {
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  city: data.label
                }
              }))
            }
          },
          options: cities.filter(
            (item) => item.value === content?.adress?.value
          )
        },
        {
          label: 'Logradouro',
          type: 'text',
          required: true,
          disabled: true,
          currentValue: content?.adress?.address,
          hideField: false,
          name: 'address',
          columns: {
            xs: 12
          }
        },
        {
          label: 'CEP',
          type: 'text',
          required: true,
          disabled: true,
          currentValue: content?.adress?.zipcode,
          hideField: false,
          mask: '99999-999',
          name: 'zipcode',
          columns: {
            xs: 4
          }
        },
        {
          label: 'Bairro',
          type: 'text',
          required: true,
          disabled: true,
          currentValue: content?.adress?.neighborhood,
          hideField: false,
          name: 'neighborhood',
          columns: {
            xs: 4
          }
        },
        {
          label: 'Número',
          type: 'text',
          required: true,
          disabled: true,
          currentValue: content?.adress?.number,
          hideField: false,
          name: 'number',
          columns: {
            xs: 4
          }
        },
        {
          label: 'Complemento',
          type: 'text',
          required: true,
          disabled: true,
          currentValue: content?.adress?.complement || '',
          hideField: false,
          name: 'complement',
          columns: {
            xs: 6
          }
        }
      ]
    }
  ]
}

const clinicFields = (content, setContent) => {
  const cities = []
  const states = []
  const [selectdState, setSelectedState] = useState(null)
  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e.target.value }))
    }
  }
  const handleInputChangeAddress = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        adress: { ...prev.adress, [key]: e.target.value }
      }))
    }
  }

  return [
    {
      name: 'Sobre da instituição',
      fields: [
        {
          label: 'Nome da Clínica',
          type: 'text',
          required: true,
          disabled: true,
          hideField: false,
          currentValue: content?.company_name,
          name: 'company_name',
          onChange: (e) => {
            handleInputChange(e, 'company_name')
          },
          columns: {
            xs: 6
          }
        },
        {
          label: 'E-mail',
          type: 'email',
          required: true,
          disabled: true,
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'email')
          },
          currentValue: content?.email || '',
          name: 'email',
          columns: {
            xs: 6
          }
        },
        {
          label: 'CNPJ',
          type: 'text',
          required: true,
          disabled: true,
          onChange: (e) => {
            handleInputChange(e, 'cnpj')
          },
          currentValue: content?.cnpj,
          hideField: false,
          name: 'cnpj',
          mask: '99.999.999/9999-99',
          columns: {
            xs: 6
          }
        },
        {
          label: 'Descrição',
          type: 'textarea',
          required: true,
          disabled: true,
          onChange: (e) => {
            handleInputChange(e, 'description')
          },
          currentValue: content?.description,
          hideField: false,
          name: 'description',
          columns: {
            xs: 12
          }
        },
        {
          label: 'Telefone',
          type: 'text',
          required: true,
          disabled: true,
          onChange: (e) => {
            handleInputChange(e, 'phone_number')
          },
          currentValue: content?.phone_number,
          hideField: false,
          mask: '+ 99 (99) 9 9999-9999',
          name: 'phone_number',
          columns: {
            xs: 6
          }
        },
        {
          label: 'Facebook URL',
          type: 'text',
          required: true,
          disabled: true,
          onChange: (e) => {
            handleInputChange(e, 'facebook')
          },
          mask: 'https://aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
          currentValue: content?.facebook || '',
          hideField: false,
          name: 'facebook',
          columns: {
            xs: 6
          }
        },
        {
          label: 'Instagram URL',
          type: 'text',
          required: true,
          disabled: true,
          onChange: (e) => {
            handleInputChange(e, 'instagram')
          },
          mask: 'https://aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
          currentValue: content?.instagram || '',
          hideField: false,
          name: 'instagram',
          columns: {
            xs: 6
          }
        },
        {
          label: 'Linkedin URL',
          type: 'text',
          required: true,
          disabled: true,
          onChange: (e) => {
            handleInputChange(e, 'linkedin')
          },
          mask: 'https://aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
          currentValue: content?.linkedin || '',
          hideField: false,
          name: 'linkedin',
          columns: {
            xs: 6
          }
        }
      ]
    },
    {
      name: 'Endereço da instituição',
      fields: [
        {
          label: 'Estado',
          type: 'autocomplete',
          required: true,
          disabled: true,
          currentValue: content
            ? {
                label: content?.adress?.state,
                value: content?.adress?.state_code
              }
            : null,
          hideField: false,
          name: 'state',
          onChange: (data) => {
            setSelectedState(data)
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  state: data.label,
                  state_code: data.value
                }
              }))
            }
          },
          options: states,
          columns: {
            xs: 6
          }
        },
        {
          label: 'Cidade',
          type: 'autocomplete',
          required: true,
          disabled: true,
          currentValue: content
            ? {
                label: content?.adress?.city,
                value: content?.adress?.city
              }
            : null,
          hideField: false,
          name: 'city',
          columns: {
            xs: 6
          },
          onChange: (data) => {
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  city: data.label
                }
              }))
            }
          },
          options: cities.filter(
            (item) =>
              item.value === selectdState?.value ||
              item.value === content?.adress?.state_code
          )
        },
        {
          label: 'Logradouro',
          type: 'text',
          required: true,
          disabled: true,
          currentValue: content?.adress?.address,
          hideField: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'address')
          },
          name: 'address',
          columns: {
            xs: 12
          }
        },
        {
          label: 'CEP',
          type: 'text',
          required: true,
          disabled: true,
          onChange: (e) => {
            handleInputChangeAddress(e, 'zipcode')
          },
          currentValue: content?.adress?.zipcode,
          hideField: false,
          mask: '99999-999',
          name: 'zipcode',
          columns: {
            xs: 4
          }
        },
        {
          label: 'Bairro',
          type: 'text',
          required: true,
          disabled: true,
          onChange: (e) => {
            handleInputChangeAddress(e, 'neighborhood')
          },
          currentValue: content?.adress?.neighborhood,
          hideField: false,
          name: 'neighborhood',
          columns: {
            xs: 4
          }
        },
        {
          label: 'Número',
          type: 'text',
          required: true,
          disabled: true,
          onChange: (e) => {
            handleInputChangeAddress(e, 'number')
          },
          currentValue: content?.adress?.number,
          hideField: false,
          name: 'number',
          columns: {
            xs: 4
          }
        },
        {
          label: 'Complemento',
          type: 'text',
          required: true,
          disabled: true,
          onChange: (e) => {
            handleInputChangeAddress(e, 'complement')
          },
          currentValue: content?.adress?.complement || '',
          hideField: false,
          name: 'complement',
          columns: {
            xs: 6
          }
        }
      ]
    }
  ]
}
