import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`

export const Button = styled.button`
  width: 35px;
  aspect-ratio: 1;
  padding: 5px;
  border-radius: 50%;
  background-color: transparent;
  color: ${props => props.color || '#333'};
  cursor: pointer;

  &:hover {
    background-color: #0000000a;
  }
`
