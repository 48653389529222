import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { columns, filterForm } from './columns'
import { checktransfer, fetchPayments } from '../../../../services/payments'
import { useModal } from '../../../../contexts/modal'
import DataTable from '../../../components/DataTable'
import Header from '../../../components/Header'
import MainContainer from '../../../components/MainContainer'
import GenerateForm from '../../../components/layout/GenerateForm'
import { Col } from 'react-bootstrap'
import * as S from './styled'

function PaymentsList ({ goBack, initialFilter }) {
  const [filter, setFilter] = useState(initialFilter)
  const [checkTransferMutation] = useMutation(checktransfer())
  const modal = useModal()

  const { loading, data, refetch } = useQuery(fetchPayments(filter), {
    variables: {
      ...filter,
      status: `%${filter?.status && filter?.status?.value !== 'all'
        ? filter?.status?.value + '%'
        : ''
        }`
    },
    pollInterval: 300000
  })

  const handleFilter = (e, actions) => {
    actions.setSubmitting(true)
    setFilter({ ...filter, status: e.status })
    actions.setSubmitting(false)
  }

  const checkTransfer = async (id) => {
    await checkTransferMutation({
      variables: {
        _in: [id]
      }
    })
    await refetch()
  }

  return (
    <MainContainer>
      <Header title="Pagamentos" onGoBack={goBack} />

      <S.FilterContainer>
        <Col xs={12}>
          <div className="filter">
            <GenerateForm
              onSubmit={handleFilter}
              groupFields={filterForm(filter)}
              button={{
                submit: 'Filtrar',
                submitting: 'Filtrando...'
              }}
            />
          </div>

        </Col>
      </S.FilterContainer>

      <DataTable
        data={data?.payments || []}
        loading={loading}
        columns={columns(modal, checkTransfer)}
      />
    </MainContainer>
  )
}

export default PaymentsList
