import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlertBox } from '../../../../contexts/alert_box'
import InternalBody from '../../../components/InternalBody'
import GenerateForm from '../../../components/layout/GenerateForm'
import { groupFields } from './columns'
import {
  createPlan,
  fetchPlanById,
  updatePlan
} from '../../../../services/plans'
import { genericCreateFetch, genericFetch } from '../../../../main/usecases/generic'
import { useHistory } from 'react-router-dom'

function InternalPlan ({ match }) {
  const { data } = useQuery(fetchPlanById(), {
    variables: { limit: 10, offset: 0, id: match.params.id }
  })
  const [mutateFunction] = useMutation(updatePlan())
  const [createPlanMutation] = useMutation(createPlan())
  const [content, setContent] = useState(null)
  const alertBox = useAlertBox()
  const history = useHistory()
  useEffect(() => {
    if (data?.plan[0]) {
      setContent(data?.plan[0])
    }
  }, [data])

  const mutationCreateClinic = async (e, url, actions) => {
    return await genericCreateFetch(
      createPlanMutation,
      {
        credit_fees: e.credit_fees || 0,
        debit_fees: e?.debit_fees || 0,
        fixed_amount_of_transfer_to_manager:
          isNaN(parseFloat(e?.fixed_amount_of_transfer_to_manager))
            ? null
            : parseFloat(e.fixed_amount_of_transfer_to_manager),
        fixed_percentage_of_transfer_to_manager:
          isNaN(parseFloat(e?.fixed_percentage_of_transfer_to_manager))
            ? null
            : parseFloat(e.fixed_percentage_of_transfer_to_manager),
        pix_fees: e?.pix_fees || 0
      },
      {
        actions,
        alertBox
      }
    )
  }

  const mutationUpdateClinic = async (e, url, actions) => {
    await genericFetch(
      mutateFunction,
      {
        id: match.params.id,
        credit_fees: e.credit_fees || 0,
        debit_fees: e?.debit_fees || 0,
        fixed_amount_of_transfer_to_manager:
          e?.fixed_amount_of_transfer_to_manager || null,
        fixed_percentage_of_transfer_to_manager:
          e?.fixed_percentage_of_transfer_to_manager || null,
        pix_fees: e?.pix_fees || 0
      },
      {
        actions,
        alertBox
      }
    )
  }

  const onSubmit = async (e, actions) => {
    if (match.params.id === 'new') {
      const res = await mutationCreateClinic(e, null, actions)
      actions.setSubmitting(false)
      history.push(`/plans/${res.data.insert_plan_one.id}`)
    } else {
      await mutationUpdateClinic(e, null, actions)
      actions.setSubmitting(false)
    }
  }

  return (
    <>
      <InternalBody>
        <p>
          Criado em:{' '}
          {moment(content?.created_at).format('DD/MM/YYYY HH:MM') || ''}
        </p>

        <GenerateForm
          onSubmit={onSubmit}
          groupFields={groupFields(content, setContent)}
          button={{
            submit: match.params.id === 'new' ? 'Adicionar' : 'Atualizar',
            submitting:
              match.params.id === 'new' ? 'Adicionando...' : 'Atualizando...'
          }}
        />
      </InternalBody>
    </>
  )
}

export default InternalPlan
