import React from 'react'
import { customTheme } from '../../../../styles/theme'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import { formatMoney, phoneMask } from '../../../../Utils/format'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ButtonPrimary from '../../../components/Button'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Rating } from '@mui/material'
import country from '../../../../Utils/estados_cidades.json'
import genders from '../../../../Utils/genders.json'
import { phoneValidator } from '../../../../services/validators/phone_validator'
import { cepValidator } from '../../../../services/validators/cep_validator'
import { cpfValidator } from '../../../../services/validators/cpf_validator'
import { getDataByZipCode } from '../../../../services/coordinates'

export const especiality = (esp, especialities) => {
  especialities = especialities?.map((item) => ({
    ...item,
    value: item.id,
    label: item.name // Utilize a propriedade 'name' como rótulo
  }))
  const selectedSpecialty = especialities?.find(
    (el) => el.value === esp?.medical_specialty?.id
  )

  return [
    {
      name: 'Selecione uma especialidade',
      fields: [
        {
          label: 'Filtrar por especialidade',
          type: 'autocomplete',
          required: true,
          disabled: esp,
          currentValue: selectedSpecialty,
          hideField: false,

          name: 'doctors_with_specialties',
          options: especialities || [],
          getOptionLabel: (option) => option.label, // Use a propriedade 'label' como rótulo
          getOptionValue: (option) => option.value, // Use a propriedade 'value' como valor
          columns: {
            xs: 12
          },
          validation: Yup.object().required('Campo obrigatório')
        }
      ]
    }
  ]
}

export const columns = (_, especilities) => [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: 'photo',
    headerName: 'Foto',
    width: 60,
    editable: true,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          {params?.row?.profile_image
            ? (
              <Avatar src={params.row.profile_image} />
              )
            : (
              <Avatar>{params?.row?.full_name[0]}</Avatar>
              )}
        </Col>
      )
    }
  },
  {
    field: 'full_name',
    headerName: 'Nome',
    width: 200,
    editable: true
  },
  {
    field: 'phone_number',
    headerName: 'Telefone',
    width: 200,
    editable: true,
    valueGetter: (params) => `${phoneMask(params.row.created_at) || ''} `,
    validator: phoneValidator()
  },
  {
    field: 'average_cost_of_medical_consultation',
    headerName: 'Custo Médio',
    width: 100,
    type: 'number',
    editable: true,
    valueGetter: (params) =>
      `${formatMoney(params?.row?.average_cost_of_medical_consultation)} `
  },
  {
    field: 'average_of_evaluations',
    headerName: 'Avaliações',
    width: 150,
    editable: true,
    renderCell: ({ row }) => {
      return (
        <Rating name="read-only" value={row?.average_of_evaluations} readOnly />
      )
    }
  },
  {
    field: 'main_specialty',
    headerName: 'Especialidade Principal',
    width: 250,
    editable: true,
    valueGetter: (params) => {
      return `${especilities?.find((item) => item?.id === params?.row?.main_specialty)
          ?.name || '--'
        }`
    }
  },
  {
    field: 'created_at',
    headerName: 'Data de Cadastro',
    sortable: true,
    width: 150,
    valueGetter: (params) => `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
    valueFormatter: params => moment(params.value).format('DD/MM/YYYY - HH:MM')
  },
  {
    field: 'id',
    headerName: 'Ações',
    sortable: false,
    width: 80,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          <Link to={`/doctors/${params.row.id}`}>
            <ButtonPrimary
              style={{
                width: '35px',
                height: '35px',
                padding: '5px',
                borderRadius: '50%'
              }}
            >
              <EditOutlinedIcon
                style={{ color: customTheme.colors.white }}
                edge="start"
                fontSize="small"
                sx={{
                  marginBottom: '2px'
                }}
              />
            </ButtonPrimary>
          </Link>
        </Col>
      )
    }
  }
]

export const groupFields = (
  content,
  setContent,
  especialities,
  selectdState,
  setSelectedState
  // selectedSex,
  // setSelectedSex
) => {
  especialities = especialities?.map((item) => ({ ...item, value: item.id }))

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e.target.value }))
    }
  }

  const cities = []
  const states = []
  const sexes = []

  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })
  genders.genders.forEach((item) => {
    sexes.push({ label: item.text, value: item.name })
  })

  // const searchByCep = async (data) => {
  //   console.log(data)
  //   try {
  //     if (!data) {
  //       return { success: false }
  //     }

  //     const cepPattern = /^\d{5}-?\d{3}_?$/ // Permitindo um hífen opcional e um underscore opcional

  //     const zipcode = data

  //     if (zipcode && zipcode.match(cepPattern)) {
  //       // Remover o underscore (se presente) antes de fazer a consulta
  //       const cleanedZipcode = zipcode.replace(/_/g, '')

  //       const coords = await getDataByZipCode(cleanedZipcode)

  //       if (coords && coords.lat) {
  //         return {
  //           success: true,
  //           data: {
  //             neighborhood: coords.bairro || '',
  //             state: states.find(
  //               (item) => item.value.toLowerCase() === coords.uf.toLowerCase()
  //             )?.label || '',
  //             city: coords.localidade || '',
  //             state_code: coords.uf || '',
  //             address: coords.logradouro || '',
  //             latitude: coords.lat || 0,
  //             longitude: coords.lon || 0
  //           }
  //         }
  //       } else {
  //         return { success: false }
  //       }
  //     }
  //   } catch (error) {
  //     return { success: false }
  //   }
  // }

  const handleInputChangeAddress = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        address: { ...prev?.address, [key]: e.target.value }
      }))
    }
  }

  return [
    {
      name: '',
      fields: [
        {
          label: 'Avatar',
          type: 'file',
          required: true,
          disabled: false,
          hideField: false,
          name: 'profile_image',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido'),
          component: (data) => {
            return (
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <Avatar
                    src={data || content?.profile_image}
                    style={{ width: 100, height: 100 }}
                  />
                </Col>
              </Row>
            )
          }
        },
        {
          label: 'Nome',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'full_name')
          },
          currentValue: content?.full_name || '',
          name: 'full_name',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'E-mail',
          type: 'email',
          required: true,
          // disabled: !window.location.href.includes("new"),
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'email')
          },
          currentValue: content?.email || '',
          name: 'email',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Sexo',
          type: 'autocomplete',
          required: false,
          disabled: false,
          currentValue: content?.sex ? (content.sex === 'F' ? { label: 'Feminino', value: 'F' } : { label: 'Masculino', value: 'M' }) : null,
          hideField: false,
          name: 'sex',
          columns: {
            xs: 6
          },
          onChange: (data) => {
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                sex: data.value
              }))
            }
          },
          options: sexes,
          validation: Yup.object().required('Campo obrigatório')
        },
        {
          label: 'CPF',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'cpf')
          },
          currentValue: content?.cpf || '',
          hideField: false,
          name: 'cpf',
          mask: '999.999.999-99',
          columns: {
            xs: 6
          },
          validation: cpfValidator() // Passando o objeto Yup retornado por cpfValidator()
        },
        {
          label: 'CRM (Número/UF)',
          type: 'text',
          required: true,
          disabled: false,
          currentValue: content?.crm || '',
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'crm')
          },
          name: 'crm',
          // mask: "9999999999/aa",
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Telefone',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'phone_number')
          },
          currentValue: content?.phone_number,
          hideField: false,
          mask: '+99 (99) 99999-9999',
          name: 'phone_number',
          columns: {
            xs: 6
          },
          validation: phoneValidator()
        },
        {
          label: 'Sobre',
          type: 'about',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'about')
          },
          currentValue: content?.about || '',
          hideField: false,
          name: 'about',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },

        {
          label: 'Linkedin URL',
          type: 'text',
          required: true,
          disabled: false,
          currentValue: content?.linkedin,
          placeholder: 'https://',
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'linkedin')
          },
          name: 'linkedin',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Valor médio da consulta',
          type: 'float',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.average_cost_of_medical_consultation,
          name: 'average_cost_of_medical_consultation',
          onChange: (e) => {
            const t = {
              target: {
                value: e
              }
            }
            handleInputChange(t, 'average_cost_of_medical_consultation')
          },
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Data de Nascimento',
          type: 'date',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.birthdate,
          name: 'birthdate',
          onChange: (e) => {
            const date = moment(e.target?.value, 'YYYY-MM-DD')
              .format('YYYY-MM-DD')

            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                birthdate: date
              }))
            }
          },
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido').required('Campo obrigatório')
        }
        // {
        //   label: "Principal especialidade",
        //   type: "autocomplete",
        //   required: true,
        //   disabled: false,
        //   currentValue: content
        //     ? {
        //         label: especialities?.find(
        //           (el) => el.value === content?.main_specialty
        //         )?.name,
        //         value: especialities?.find(
        //           (el) => el.value === content?.main_specialty
        //         )?.id,
        //       }
        //     : null,
        //   hideField: false,
        //   name: "main_specialty",
        //   onChange: (data) => {
        //     if (content && setContent) {
        //       setContent((prev) => ({
        //         ...prev,
        //         main_specialty: data.value,
        //       }));
        //     }
        //   },
        //   options: especialities?.map((item) => ({
        //     label: item.name,
        //     value: item.value,
        //   })),
        //   columns: {
        //     xs: 6,
        //   },
        //   validation: Yup.object().required("Campo obrigatório").nullable(),
        // },
      ]
    },
    {
      name: 'Dados do RG',
      fields: [
        {
          label: 'UF',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'rg_uf')
          },
          currentValue: content?.rg_uf || '',
          hideField: false,
          name: 'rg_uf',
          columns: {
            xs: 6
          }
        },
        {
          label: 'Número',
          type: 'text',
          required: true,
          currentValue: content?.rg_number || '',
          onChange: (e) => {
            handleInputChange(e, 'rg_number')
          },
          name: 'rg_number',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        }
      ]
    },
    {
      name: 'Endereço',
      fields: [
        {
          label: 'Estado',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content
            ? {
                label: content?.adress?.state,
                value: content?.adress?.state_code
              }
            : undefined,
          hideField: false,
          name: 'state',
          onChange: (data) => {
            setSelectedState(data)
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                address: {
                  ...prev?.address,
                  state: data.label,
                  state_code: data.value
                }
              }))
            }
          },
          options: states,
          columns: {
            xs: 6
          },
          validation: Yup.object()
        },
        {
          label: 'Cidade',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content
            ? {
                label: content?.adress?.city,
                value: content?.adress?.city
              }
            : undefined,
          hideField: false,
          name: 'city',
          columns: {
            xs: 6
          },
          onChange: (data) => {
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                address: {
                  ...prev?.address,
                  city: data.label
                }
              }))
            }
          },
          options: cities.filter(
            (item) =>
              item.value === selectdState?.value ||
              item.value === content?.adress?.state_code
          ),
          validation: Yup.object()
        },
        {
          label: 'Logradouro',
          type: 'text',
          required: true,
          disabled: false,
          currentValue: content?.adress?.address,
          hideField: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'address')
          },
          name: 'address',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'CEP',
          type: 'text',
          required: true,
          disabled: false,
          onChange: async (e) => {
            const { value } = e.target

            setContent((prev) => ({
              ...prev,
              adress: { ...prev?.adress, zipcode: value }
            }))

            if (value.length === 9) {
              try {
                const res = await getDataByZipCode(value)

                if (res) {
                  setContent((prev) => ({
                    ...prev,
                    adress: {
                      ...prev?.adress,
                      neighborhood: res.bairro || '',
                      state: states.find(
                        (item) => item.value.toLowerCase() === res.uf.toLowerCase()
                      )?.label || '',
                      city: res.localidade || '',
                      state_code: res.uf || '',
                      address: res.logradouro || '',
                      latitude: res.lat || '',
                      longitude: res.lon || ''
                    }
                  }))
                }
              } catch (error) {
                console.error('Error fetching data by zipcode:', error)
              }
            }
          },
          currentValue: content?.adress?.zipcode,
          hideField: false,
          mask: '99999-999',
          name: 'zipcode',
          columns: {
            xs: 4
          },
          validation: cepValidator()
        },
        {
          label: 'Bairro',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'neighborhood')
          },
          currentValue: content?.adress?.neighborhood,
          hideField: false,
          name: 'neighborhood',
          columns: {
            xs: 4
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Número',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'number')
          },
          currentValue: content?.adress?.number,
          hideField: false,
          name: 'number',
          columns: {
            xs: 4
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Complemento',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'complement')
          },
          currentValue: content?.adress?.complement || '',
          hideField: false,
          name: 'complement',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        }
      ]
    }
  ]
}
