import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { columns, groupFields } from './columns'
import { useModal } from '../../../../contexts/modal'
import { useAlertBox } from '../../../../contexts/alert_box'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../services/firebase/firebase-config'
import GenerateForm from '../../../components/layout/GenerateForm'
import CardModal from '../../../components/CardModal'
import {
  fecthBanners,
  updateBanner,
  createBanner,
  deleteBanner
} from '../../../../services/banners'
import moment from 'moment'
import DataTable from '../../../components/DataTable'
import MainContainer from '../../../components/MainContainer'
import Header from '../../../components/Header'

function Banners () {
  const { loading, data, refetch } = useQuery(fecthBanners())
  const [mutateUpdateBanner] = useMutation(updateBanner())
  const [mutateCreateBanner] = useMutation(createBanner())
  const [mutateDeleteBanner] = useMutation(deleteBanner())
  const [content, setContent] = useState(null)
  const alertBox = useAlertBox()
  const modal = useModal()

  useEffect(() => {
    if (data?.banners) {
      setContent(data?.banners)
    }
  }, [data])

  const updateEsp = async (id, e, actions) => {
    if (e.file) {
      const storageRef = ref(storage, `especialties/${e.file.name}`)
      const uploadTask = uploadBytesResumable(storageRef, e.file)

      uploadTask.on('state_changed', null, console.error, () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          await modelMutation(mutateUpdateBanner, id, e, actions, url)
        })
      })
    } else {
      await modelMutation(mutateUpdateBanner, id, e, actions, null)
    }
  }

  const createEsp = async (id, e, actions) => {
    if (e.file) {
      const storageRef = ref(storage, `especialties/${new Date().getTime()}`)
      const uploadTask = uploadBytesResumable(storageRef, e.file)

      uploadTask.on('state_changed', null, console.error, () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
          await modelMutation(mutateCreateBanner, id, e, actions, url)
        })
      })
    }
  }

  const modelMutation = async (mutation, id, e, actions, url) => {
    try {
      await mutation({
        variables: {
          ...(id && { id: id }),
          link_url_android: e.link_url_android || '',
          link_url_iphone: e.link_url_iphone || '',
          photo_url: url || content.find((el) => el.id === id)?.photo_url || '',
          relevance: e.relevance,
          expiration_date: moment(e.expiration_date)
            .add(12, 'hour')
            .format('YYYY-MM-DD hh:mm:ss')
        }
      })
      await refetch()
      actions.setSubmitting(false)
      alertBox.setOptions({
        open: true,
        message: 'Excluido com Sucesso!',
        type: 'success',
        time: 3000
      })
      modal.setOptions({ open: false })
    } catch (error) {
      actions.setSubmitting(false)
      console.error(error)
      alertBox.setOptions({
        open: true,
        message: 'Erro!',
        type: 'error',
        time: 3000
      })
    }
  }
  const remove = async (id) => {
    try {
      await mutateDeleteBanner({
        variables: {
          id: id
        }
      })
      await refetch()
      alertBox.setOptions({
        open: true,
        message: 'Registrado com Sucesso!',
        type: 'success',
        time: 3000
      })
      modal.setOptions({ open: false })
    } catch (error) {
      console.error(error)
      alertBox.setOptions({
        open: true,
        message: 'Erro Ao Registrar!',
        type: 'error',
        time: 3000
      })
    }
  }

  const handleAdd = () => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal>
          <GenerateForm
            onSubmit={(e, actions) => createEsp(null, e, actions)}
            groupFields={groupFields(null)}
            button={{
              submit: window.location.pathname.includes('new')
                ? 'Adicionar'
                : 'Atualizar',
              submitting: window.location.pathname.includes('new')
                ? 'Adicionando'
                : 'Atualizando...'
            }}
          />
        </CardModal>
      )
    })
  }

  if (loading) return <span>Carregando...</span>
  return (
    <MainContainer>
      <Header title="Banners" labelAdd="Adicionar Banner" handleAdd={handleAdd} />

      {content && (
        <DataTable
          data={content}
          columns={columns(modal, updateEsp, remove)}
        />
      )}
    </MainContainer>
  )
}

export default Banners
