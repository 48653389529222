import React, { useContext, useState, createContext, useEffect } from 'react'
import * as Auth from 'firebase/auth'
import { getUserID, getUserType } from '../Utils/getUserInfo'
import { useLoading } from './loading'

const UserContext = createContext()
const auth = Auth.getAuth()

const UserProvider = (props) => {
  const { setActive } = useLoading()

  const [user, setUser] = useState(null)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // set user, id and type after load firebase user
    auth.onAuthStateChanged(usr => {
      if (usr) {
        setUser(usr)

        const id = getUserID(usr)
        const type = getUserType(usr)

        setData({
          id,
          type
        })
      } else {
        setUser(null)
        setData(null)
      }
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    setActive(loading)
  }, [loading])

  // only go forward when user is loaded
  return (
    <UserContext.Provider
      value={{
        setUser,
        user,
        id: data?.id,
        type: data?.type,
        token: user?.accessToken,
        loading
      }}
    >
      {!loading && props.children}
    </UserContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(UserContext)
  if (!context) { throw new Error('useModal must be used within a provider UserProvider') }

  return { ...context }
}

export default UserProvider
