import React from 'react'
import { customTheme } from '../../../../styles/theme'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import CancelIcon from '@mui/icons-material/Cancel'
import ButtonPrimary from '../../../components/Button'
import { Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'

export const columns = (mutationRemoveUnavailableDaysDocAppointment) => [
  { field: 'id', headerName: 'ID', width: 40 },
  {
    field: 'day-weed',
    headerName: 'Dia da semana',
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return (
        <spam style={{ textTransform: 'capitalize' }}>
          {moment(params.row.date).add(3, 'hours').format('dddd')}
        </spam>
      )
    }
  },
  {
    field: 'date',
    headerName: 'Data',
    sortable: false,
    width: 200,
    valueGetter: (params) =>
      `${moment(params.row.date).add(3, 'hours').format('LL')}`
  },
  {
    field: 'actions',
    headerName: 'Ações',
    sortable: false,
    width: 110,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          <ButtonPrimary
            onClick={async () => {
              confirm('Deseja excluir esta data indisponível?')
              await mutationRemoveUnavailableDaysDocAppointment({
                unavailable_day_id: params?.row?.id
              })
            }}
            style={{
              height: '35px',
              borderRadius: '2px',
              fontSize: 12,
              padding: '3px 0 0 0',
              background: customTheme.colors.error,
              boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`
            }}
          >
            <CancelIcon
              style={{ color: customTheme.colors.white }}
              edge="start"
              fontSize="small"
              sx={{
                marginBottom: '2px',
                marginRight: '5px'
              }}
            />
            Excluir
          </ButtonPrimary>
        </Col>
      )
    }
  }
]

export const groupFields = (content, setContent, especialities) => {
  especialities = especialities?.map((item) => ({ ...item, value: item.id }))

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e.target.value }))
    }
  }
  return [
    {
      name: '',
      fields: [
        {
          label: 'Avatar',
          type: 'file',
          required: true,
          disabled: false,
          hideField: false,
          name: 'profile_image',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido'),
          component: (data) => {
            return (
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <Avatar
                    src={data || content?.profile_image}
                    style={{ width: 100, height: 100 }}
                  />
                </Col>
              </Row>
            )
          }
        },
        {
          label: 'Nome',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'full_name')
          },
          currentValue: content?.full_name || '',
          name: 'full_name',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'E-mail',
          type: 'email',
          required: true,
          disabled: !window.location.href.includes('new'),
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'email')
          },
          currentValue: content?.email || '',
          name: 'email',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'CRM',
          type: 'text',
          required: true,
          disabled: false,
          currentValue: content?.crm || '',
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'crm')
          },
          name: 'crm',
          mask: '9999999999/aa',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Sobre',
          type: 'about',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'about')
          },
          currentValue: content?.about || '',
          hideField: false,
          name: 'about',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },

        {
          label: 'Linkedin URL',
          type: 'textarea',
          required: true,
          disabled: false,
          currentValue: content?.linkedin || '',
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'linkedin')
          },
          name: 'linkedin',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Principal especialidade',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content
            ? {
                label: especialities?.find(
                  (el) => el.value === content?.main_specialty
                )?.name,
                value: especialities?.find(
                  (el) => el.value === content?.main_specialty
                )?.id
              }
            : null,
          hideField: false,
          name: 'main_specialty',
          onChange: (data) => {
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                main_specialty: data.value
              }))
            }
          },
          options: especialities?.map((item) => ({
            label: item.name,
            value: item.value
          })),
          columns: {
            xs: 6
          },
          validation: Yup.object().required('Campo obrigatório').nullable()
        }
      ]
    }
  ]
}

export const unavailableDaysFields = () => {
  return [
    {
      name: 'Horário disponível (Médico)',
      fields: [
        {
          label: 'Data bloqueada:',
          type: 'date',
          required: true,
          disabled: false,
          hideField: false,
          name: 'date',
          columns: { xs: 6 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]
}
