import { AuthService } from '../../../services/auth/auth'

export const acceptSusRoom = async (
  { id },
  params,
  extras
) => {
  const auth = new AuthService()
  try {
    const data = {
      id: id
    }

    const res = await auth.acceptRoom(data)
    return res
  } catch (error) {
    console.error(error)
    return { sucess: false, message: 'erro ao aceitar sala' }
  }
}
