import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { columns } from './columns'
import { useModal } from '../../../../contexts/modal'
import { fecthPlans, removePlan } from '../../../../services/plans'
import { useAlertBox } from '../../../../contexts/alert_box'
import DataTable from '../../../components/DataTable'
import MainContainer from '../../../components/MainContainer'
import Header from '../../../components/Header'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

function Plans ({ list }) {
  const { loading, data, refetch } = useQuery(fecthPlans())
  const [removeCoopMutation] = useMutation(removePlan())
  const alertBox = useAlertBox()
  const modal = useModal()
  const history = useHistory()

  const handleAdd = () => {
    history.push('/plans/new')
  }

  if (loading) return <span>Carregando...</span>

  console.log(data)

  return (
    <MainContainer>
      <Header title="Tarifas" labelAdd="Adicionar Tarifa" handleAdd={handleAdd} />

      <DataTable
        data={list || data?.plan || []}
        columns={columns(modal, removeCoopMutation, refetch, alertBox, history)}
      />
    </MainContainer>
  )
}

export default Plans
