import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import { columns, filterUsers } from './columns'
import { CustomStyle } from './styled'
import { useModal } from '../../../../contexts/modal'
import { useAlertBox } from '../../../../contexts/alert_box'
import GenerateForm from '../../../components/layout/GenerateForm'
import { deleteOptics, fetchOpticsByFilter } from '../../../../services/optics'
import DataTable from '../../../components/DataTable'
import MainContainer from '../../../components/MainContainer'
import Header from '../../../components/Header'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

function OpticsPage () {
  const [filter, setFilter] = useState({ state: { value: '%' } })
  const modal = useModal()
  const alertBox = useAlertBox()
  const [mutateDelete] = useMutation(deleteOptics())
  const history = useHistory()
  const { loading, error, data, refetch } = useQuery(fetchOpticsByFilter(), {
    variables: {
      limit: 1000000,
      offset: 0,
      state_code: filter?.state?.value,
      city: filter?.city?.label || '%'
    }
  })

  const handleAdd = () => {
    history.push('/optics/new')
  }

  return (
    <MainContainer>
      <Header title="Óticas" labelAdd="Adicionar Ótica" handleAdd={handleAdd} />

      <Col xs={12}>
        <CustomStyle className="list">
          <GenerateForm
            onSubmit={null}
            groupFields={filterUsers(filter, setFilter)}
            button={{
              submit: 'Adicionar médico existente na base de dados',
              submitting: 'Adicionando...'
            }}
          />
        </CustomStyle>
      </Col>

      {loading && (<span>Carregando...</span>)}

      {(!loading && data?.optics) && (
        <DataTable
          data={data.optics}
          columns={columns(modal, alertBox, mutateDelete, refetch, history)}
        />
      )}
    </MainContainer>
  )
}

export default OpticsPage
