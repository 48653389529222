import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { columns } from './columns'
import {
  fecthCooperatives,
  removeCooperative
} from '../../../../services/cooperative'
import DataTable from '../../../components/DataTable'
import MainContainer from '../../../components/MainContainer'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Header from '../../../components/Header'

function Cooperatives ({ list }) {
  const { loading, data, refetch } = useQuery(fecthCooperatives())
  const [removeCoopMutation] = useMutation(removeCooperative())
  const history = useHistory()

  const handleAdd = () => {
    history.push('/cooperatives/new')
  }

  if (loading) return <span>Carregando...</span>

  return (
    <MainContainer>
      <Header title="Agência de crédito" labelAdd="Adicionar Agência de crédito" handleAdd={handleAdd} />

      {data?.cooperatives && (
        <DataTable
          data={list || data.cooperatives}
          columns={columns(removeCoopMutation, refetch)}
        />
      )}
    </MainContainer>
  )
}

export default Cooperatives
