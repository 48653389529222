export const addAnamneseUseCase = async (
  mutation,
  params,
  extras
) => {
  try {
    const res = await mutation({
      variables: params
    })
    extras.alertBox.setOptions({
      open: true,
      message: 'Criado com Sucesso!',
      type: 'success',
      time: 3000
    })

    return res.data.insert_medical_history_one
  } catch (error) {
    if (extras?.actions?.setSubmitting) {
      extras.actions.setSubmitting(false)
    }
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro Ao atualizar!',
      type: 'error',
      time: 3000
    })
  }
}

export const editAnamneseUseCase = async (
  mutation,
  params,
  extras
) => {
  try {
    const res = await mutation({
      variables: params
    })
    extras.alertBox.setOptions({
      open: true,
      message: 'Editado com Sucesso!',
      type: 'success',
      time: 3000
    })

    return { success: true, data: res.data.update_medical_history_by_pk }
  } catch (error) {
    if (extras?.actions?.setSubmitting) {
      extras.actions.setSubmitting(false)
    }
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro Ao Atualizar!',
      type: 'error',
      time: 3000
    })

    return { success: false, error }
  }
}
