import { gql } from '@apollo/client'
import { fragmentAddresses } from '../address'
import { fragmentMedicalSpecialty } from '../medicalSpecialty'

export const fragmentDoctorsWithSpecialties = gql`
  fragment doctorsWithSpecialtiesFields on doctors_with_specialties {
    id
    updated_at
    specialist_since
    description
    created_at
    medical_specialty {
      ...medicalSpecialtyFields
    }
  }
  ${fragmentMedicalSpecialty}
`

export const fragmentResumedClinicFields = gql`
  fragment resumedClinicFields on clinics {
    photo
    cnpj
    company_name
    created_at
    description
    facebook
    id
    instagram
    linkedin
    phone_number
    photo
    updated_at
    website
    personal_assistance
    online_service
    adress {
      ...addressFields
    }
  }
  ${fragmentAddresses}
`

export const removeDoctorSpecialt = () => {
  return gql`
    mutation deleteMedicalSpecialtyOnDoctor($id: Int) {
      delete_doctors_with_specialties(where: {id: {_eq: $id}}) {
        affected_rows
      }
    }
  `
}

export const createDoctorSpecialt = () => {
  return gql`
    ${fragmentDoctorsWithSpecialties}

    ${fragmentMedicalSpecialty}

    mutation addMedicalSpecialtyOnDoctor(
      $doctor_id: Int
      $description: String
      $medical_specialties_id: Int
      $specialist_since: date
    ) {
      insert_doctors_with_specialties_one(
        object: {
          doctor_id: $doctor_id
          description: $description
          medical_specialties_id: $medical_specialties_id
          specialist_since: $specialist_since
        }
      ) {
        ...doctorsWithSpecialtiesFields
      }
    }
  `
}

export const updateDoctorWithSpecialties = () => {
  return gql`
    ${fragmentDoctorsWithSpecialties}

    ${fragmentMedicalSpecialty}

    mutation updateMedicalSpecialtyOnDoctor(
      $doctor_id: Int
      $medical_specialties_id: Int
      $description: String
      $specialist_since: date
    ) {
      update_doctors_with_specialties(
        where: {
          doctor_id: { _eq: $doctor_id }
          medical_specialties_id: { _eq: $medical_specialties_id }
        }
        _set: { description: $description, specialist_since: $specialist_since }
      ) {
        affected_rows
        returning {
          ...doctorsWithSpecialtiesFields
        }
      }
    }
  `
}
