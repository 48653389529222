import React from 'react'
import InternalPetshop from '../../master/petshops/Internal'
import { useAuth } from '../../../../contexts/authenticator'

function PetshopProfile () {
  const { id } = useAuth()

  return <div>{id && <InternalPetshop match={{ params: { id: id } }} />}</div>
}

export default PetshopProfile
