import React, { useState } from 'react'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { customTheme } from '../../../../styles/theme'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import {
  formatNumber,
  phoneMask,
  refactorLabelPlan
} from '../../../../Utils/format'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import MailOutlineOutlined from '@mui/icons-material/MailOutlineOutlined'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import ButtonPrimary from '../../../components/Button'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import country from '../../../../Utils/estados_cidades.json'
import { fecthPlans } from '../../../../services/plans'
import { useQuery } from '@apollo/client'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import CardModal from '../../../components/CardModal'
import { ModalConfirm } from '../../hospital/schedules-hospital/styled'
import { AuthService } from '../../../../services/auth/auth'

export const especiality = (esp, especialities) => {
  especialities = especialities?.map((item) => ({ ...item, value: item.id }))

  return [
    {
      name: '',
      fields: [
        {
          label: 'Filtrar por especialidade',
          type: 'autocomplete',
          required: true,
          disabled: esp,
          ...(esp && {
            currentValue: {
              label: especialities?.find(
                (el) => el.value === esp?.medical_specialty?.id
              )?.name,
              value: especialities?.find(
                (el) => el.value === esp?.medical_specialty?.id
              )?.value
            }
          }),
          hideField: false,

          name: 'sus_with_medical_specialties',
          options: especialities?.map((item) => ({
            label: item.name,
            value: item.value
          })),
          columns: {
            xs: 12
          },
          validation: Yup.object().required('Campo obrigatório')
        }
      ]
    }
  ]
}

export const columns = (modal, alertBox, remove, refetch) => [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: 'photo',
    headerName: 'Foto',
    width: 60,
    editable: true,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          {params.row.photo ? (
            <Avatar src={params.row.photo} />
          ) : (
            <AccountBalanceIcon
              style={{ color: customTheme.colors.primary }}
              edge="start"
              sx={{
                marginRight: '36px'
                // ...(open && { display: "none" }),
              }}
            />
          )}
        </Col>
      )
    }
  },
  {
    field: 'phone_number',
    headerName: 'Telefone',
    width: 200,
    editable: false,
    valueGetter: (params) => `${phoneMask(params.row.phone_number) || ''} `
  },
  {
    field: 'company_name',
    headerName: 'Nome',
    width: 200,
    editable: false
  },
  {
    field: 'cidade',
    headerName: 'Cidade',
    width: 200,
    editable: false,
    valueGetter: (params) => `${params.row.adress.city || ''} `
  },
  {
    field: 'state',
    headerName: 'Cidade',
    width: 150,
    editable: false,
    valueGetter: (params) => `${params.row.adress.state || ''} `
  },
  {
    field: 'neighborhood',
    headerName: 'Bairro',
    width: 100,
    editable: false,
    valueGetter: (params) => `${params.row.adress.neighborhood || ''} `
  },
  {
    field: 'created_at',
    headerName: 'Data de Cadastro',
    sortable: true,
    width: 150,
    valueGetter: (params) => `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
    valueFormatter: params => moment(params.value).format('DD/MM/YYYY - HH:MM')
  },
  {
    field: 'id',
    headerName: '',
    sortable: false,
    width: 120,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          <Link to={`/petshops/${params.row.id}`}>
            <ButtonPrimary
              style={{
                width: '35px',
                height: '35px',
                padding: '5px',
                borderRadius: '50%'
              }}
              // onClick={() => {
              //   modal.open({
              //     open: true,
              //     component: (
              //       <CardModal
              //         title={params.row.company_name}
              //         avatar={params?.row?.photo}
              //         subtitle={`${
              //           moment(params.row.created_at).format(
              //             "DD/MM/YYYY HH:MM"
              //           ) || ""
              //         } `}
              //       >
              //         sdsd
              //       </CardModal>
              //     ),
              //   });
              // }}
            >
              <EditOutlinedIcon
                style={{ color: customTheme.colors.white }}
                edge="start"
                fontSize="small"
                sx={{
                  marginBottom: '2px'
                }}
              />
            </ButtonPrimary>
          </Link>
          <Link to={`/petshop-available-schedules/${params.row.id}`}>
            <ButtonPrimary
              onClick={() => {
                history.push(`/petshop-available-schedules/${params.row.id}`)
                modal.setOptions({
                  open: false
                })
              }}
              style={{
                width: '35px',
                height: '35px',
                padding: '5px',
                borderRadius: '50%',
                marginLeft: 15
              }}
            >
              <CalendarTodayIcon
                style={{ color: customTheme.colors.white }}
                edge="start"
                fontSize="small"
                sx={{
                  marginBottom: '2px'
                }}
              />
            </ButtonPrimary>
          </Link>
        </Col>
      )
    }
  },
  {
    field: 'remove',
    headerName: '',
    sortable: false,
    width: 60,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          <ButtonPrimary
            onClick={async () => {
              modal.setOptions({
                open: true,
                component: (
                  <CardModal style={{ width: '400px' }}>
                    <ModalConfirm>
                      <h3>Tem certeza que deseja apagar?</h3>
                      <div className="buttons-area">
                        <ButtonPrimary
                          id="delete-button"
                          onClick={async () => {
                            try {
                              document.getElementById(
                                'delete-button'
                              ).disabled = true
                              const auth = new AuthService()

                              await remove({
                                variables: {
                                  id: params.row.id
                                }
                              })
                              await auth.removeUser({
                                email: params.row.email
                              })
                              await refetch()
                              alertBox.setOptions({
                                open: true,
                                message: 'Sucesso ao apagar!',
                                type: 'success',
                                time: 3000
                              })
                              modal.setOptions({ open: false })
                            } catch (error) {
                              alertBox.setOptions({
                                open: true,
                                message: 'Erro ao apagar Sus',
                                type: 'error',
                                time: 3000
                              })
                              console.error(error)
                            }
                            modal.setOptions({ open: false })
                          }}
                        >
                          Sim
                        </ButtonPrimary>

                        <ButtonPrimary
                          onClick={() => modal.setOptions({ open: false })}
                          style={{
                            backgroundColor: customTheme.colors.error,
                            boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`
                          }}
                        >
                          Não
                        </ButtonPrimary>
                      </div>
                    </ModalConfirm>
                  </CardModal>
                )
              })
            }}
            style={{
              width: '35px',
              height: '35px',
              padding: '5px',
              borderRadius: '50%',
              backgroundColor: customTheme.colors.error
            }}
          >
            <DeleteOutlinedIcon
              style={{ color: customTheme.colors.white }}
              edge="start"
              fontSize="small"
              sx={{
                marginBottom: '2px'
              }}
            />
          </ButtonPrimary>
        </Col>
      )
    }
  },
  {
    field: 'send-email',
    headerName: 'Reenviar E-mail',
    sortable: false,
    width: 150,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          <ButtonPrimary
            style={{
              width: '35px',
              height: '35px',
              padding: '5px',
              borderRadius: '50%'
            }}
            onClick={async () => {
              try {
                const auth = new AuthService()
                await auth.recoveryPassword(params?.row?.email)
                alertBox.setOptions({
                  open: true,
                  message: 'Enviado com Sucesso!',
                  type: 'success',
                  time: 3000
                })
              } catch (error) {
                alertBox.setOptions({
                  open: true,
                  message: 'Erro ao enviar',
                  type: 'error',
                  time: 3000
                })
                console.error(error)
              }
            }}
          >
            <MailOutlineOutlined
              style={{ color: customTheme.colors.white }}
              edge="start"
              fontSize="small"
              sx={{
                marginBottom: '2px'
              }}
            />
          </ButtonPrimary>
        </Col>
      )
    }
  }
]

export const groupFields = (content, setContent) => {
  const cities = []
  const states = []
  const [selectdState, setSelectedState] = useState(null)
  const { data } = useQuery(fecthPlans())
  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e.target.value }))
    }
  }
  const handleInputChangeAddress = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        adress: { ...prev.adress, [key]: e.target.value }
      }))
    }
  }
  const handleInputChangeCompanyPlan = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        company_plan: { ...prev.company_plan, [key]: e.target.value }
      }))
    }
  }

  return [
    {
      name: 'Sobre a instituição',
      fields: [
        {
          label: 'Avatar',
          type: 'file',
          required: true,
          disabled: false,
          hideField: false,
          name: 'photo',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido'),
          component: (data) => {
            return (
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <Avatar
                    src={data || content?.photo}
                    style={{ width: 100, height: 100 }}
                  />
                </Col>
              </Row>
            )
          }
        },
        {
          label: 'Nome',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.company_name,
          name: 'company_name',
          onChange: (e) => {
            handleInputChange(e, 'company_name')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'E-mail',
          type: 'email',
          required: true,
          disabled: !window.location.pathname.includes('/new'),
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'email')
          },
          currentValue: content?.email || '',
          name: 'email',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Descrição',
          type: 'textarea',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'description')
          },
          currentValue: content?.description,
          hideField: false,
          name: 'description',
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Telefone',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'phone_number')
          },
          currentValue: content?.phone_number,
          hideField: false,
          mask: '+ 99 (99) 9 9999-9999',
          name: 'phone_number',
          columns: {
            xs: 6
          },
          validation:
          Yup.string('Valor inválido')
            .required('Campo obrigatório')
            .test('phoneComplete', 'O campo precisa ser preenchido totalmente!', (content) => {
              const phoneNumber = content?.replace(/[^\d]/g, '')
              return phoneNumber?.length === 13
            })
        },
        {
          label: 'Website URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'website')
          },

          currentValue: content?.website || 'https://',
          hideField: false,
          name: 'website',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    },
    {
      name: 'Dados bancários e Tarifa',
      fields: [
        {
          label: 'Conta',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeCompanyPlan(e, 'account')
          },
          currentValue: content?.company_plan?.account || '',
          hideField: false,
          name: 'account',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Agencia',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeCompanyPlan(e, 'agency')
          },
          currentValue: content?.company_plan?.agency || '',
          hideField: false,
          name: 'agency',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Chave PIX',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeCompanyPlan(e, 'chave_pix')
          },
          currentValue: content?.company_plan?.chave_pix || '',
          hideField: false,
          name: 'chave_pix',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'CPF',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeCompanyPlan(e, 'cpf')
          },
          currentValue: content?.company_plan?.cpf || '',
          hideField: false,
          name: 'cpf',
          mask: '999.999.999-99',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório').test('cpfNotComplete', 'O campo precisa ser preenchido totalmente!', () => !content?.company_plan?.cpf?.includes('_'))
        },
        // {
        //   label: "transferência de interesse para o cliente",
        //   type: "checkbox",
        //   required: true,
        //   disabled: false,
        //   onChange: (e) => {
        //     handleInputChangeCompanyPlan(
        //       e,
        //       "transfer_of_interest_to_the_customer"
        //     );
        //   },
        //   currentValue:
        //     content?.company_plan?.transfer_of_interest_to_the_customer ||
        //     false,
        //   hideField: false,
        //   name: "transfer_of_interest_to_the_customer",
        //   columns: {
        //     xs: 6,
        //   },
        //   validation:
        //     Yup.string("Valor inválido").required("Campo obrigatório"),
        // },
        {
          label: 'Código do Banco',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeCompanyPlan(e, 'bank_id')
          },
          currentValue: content?.company_plan?.bank_id || '',
          hideField: false,
          name: 'bank_id',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Código do Banco Split',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeCompanyPlan(e, 'codigo_split')
          },
          currentValue: content?.company_plan?.codigo_split || '',
          hideField: false,
          name: 'codigo_split',
          columns: {
            xs: 6
          }
        },
        {
          label: 'Tarifa',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content?.company_plan?.plan_id
            ? {
                value: content?.company_plan?.plan_id,
                label: data?.plan
                  ?.filter((el) => el.id === content?.company_plan?.plan_id)
                  .map((item) => ({
                    label: refactorLabelPlan(item),
                    value: item.id
                  }))[0]?.label
              }
            : undefined,
          hideField: false,
          name: 'plan_id',
          onChange: (data) => {
            // setSelectedState(data);
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                company_plan: {
                  ...prev?.company_plan,
                  plan_id: data.value
                }
              }))
            }
          },
          options: data?.plan?.map((item) => ({
            label: refactorLabelPlan(item),
            value: item.id
          })),
          columns: {
            xs: 12
          },
          validation: Yup.object().required('Campo Obrigatório')
        }
      ]
    },
    {
      name: 'Endereço',
      fields: [
        {
          label: 'CEP',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'zipcode')
          },
          currentValue: content?.adress?.zipcode,
          hideField: false,
          mask: '99999-999',
          name: 'zipcode',
          columns: {
            xs: 4
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório').test('cepNotComplete', 'O campo precisa ser preenchido totalmente!', () => !content?.adress?.zipcode?.includes('_'))
        },
        {
          label: 'Logradouro',
          type: 'text',
          required: true,
          disabled: false,
          currentValue: content?.adress?.address,
          hideField: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'address')
          },
          name: 'address',
          columns: {
            xs: 8
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Bairro',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'neighborhood')
          },
          currentValue: content?.adress?.neighborhood,
          hideField: false,
          name: 'neighborhood',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Número',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'number')
          },
          currentValue: content?.adress?.number,
          hideField: false,
          name: 'number',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Latitude',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'latitude')
          },
          currentValue: content?.adress?.latitude || '',
          hideField: false,
          name: 'latitude',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Longitude',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'longitude')
          },
          currentValue: content?.adress?.longitude || '',
          hideField: false,
          name: 'longitude',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Estado',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content?.adress?.state
            ? {
                label: content?.adress?.state,
                value: content?.adress?.state_code
              }
            : undefined,
          hideField: false,
          name: 'state',
          onChange: (data) => {
            setSelectedState(data)
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  state: data.label,
                  state_code: data.value
                }
              }))
            }
          },
          options: states,
          columns: {
            xs: 6
          },
          validation: Yup.object().required('Campo obrigatório')
        },
        {
          label: 'Cidade',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content?.adress?.city
            ? {
                label: content?.adress?.city,
                value: content?.adress?.city
              }
            : undefined,
          hideField: false,
          name: 'city',
          columns: {
            xs: 6
          },
          onChange: (data) => {
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  city: data.label
                }
              }))
            }
          },
          options: cities.filter(
            (item) =>
              item.value === selectdState?.value ||
              item.value === content?.adress?.state_code
          ),
          validation: Yup.object().required('Campo obrigatório')
        },

        {
          label: 'Complemento',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'complement')
          },
          currentValue: content?.adress?.complement || '',
          hideField: false,
          name: 'complement',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        }
      ]
    }
  ]
}

export const filterUsers = (filter, setFilter) => {
  const cities = []
  const states = []
  country.estados
    .filter((item) => item.sigla === filter.state.value)
    .forEach((item) => {
      item.cidades.forEach((city) =>
        cities.push({ label: city, value: item.sigla })
      )
    })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })
  return [
    {
      name: 'Filtrar os Resultados',
      fields: [
        {
          label: 'Estado',
          type: 'autocomplete',
          required: true,
          disabled: false,
          hideField: false,
          name: 'state',
          onChange: (data) => {
            setFilter((prev) => ({
              ...prev,
              state: data
            }))
          },
          options: states,
          columns: {
            xs: 3
          },
          validation: Yup.object()
        },
        {
          label: 'Cidade',
          type: 'autocomplete',
          required: true,
          disabled: false,
          hideField: false,
          name: 'city',
          onChange: (data) => {
            setFilter((prev) => ({
              ...prev,
              city: data
            }))
          },
          options: cities,
          columns: {
            xs: 3
          },
          validation: Yup.object()
        }
      ]
    }
  ]
}
