import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    margin-bottom: 20px;
`

export const FormContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`

export const FilterField = styled.div`
    display: flex;
    flex-direction: column;

    input {
        border: 1px solid #333;
        padding: 3px;
        padding-left: 5px;
        border-radius: 4px;
        width: 250px;
    }

    #state {
        width: 50px;
    }
`
