import { useModal } from '../../../contexts/modal'
import ButtonPrimary from '../Button'
import * as S from './styles'
import { customTheme } from '../../../styles/theme'
import Loading from '../layout/Loading'
import React, { useState } from 'react'

const ConfirmModal = ({ title, description, onConfirm, onCancel }) => {
  const modal = useModal()

  const closeModal = () => {
    modal.setOptions({
      open: false
    })
  }

  const [loading, setLoading] = useState(false)

  const handleConfirmAndClose = async () => {
    if (onConfirm) {
      try {
        setLoading(true)
        await onConfirm()
      } catch (error) {
        console.error('Erro ao confirmar:', error)
      } finally {
        setLoading(false)
        closeModal()
      }
    } else {
      closeModal()
    }
  }

  if (loading) return <Loading />

  return (
    <S.Container>
      <h2>{title}</h2>

      <p>{description}</p>

      <S.ButtonsContainer>
        <ButtonPrimary onClick={handleConfirmAndClose}>
          Confirmar
        </ButtonPrimary>

        <ButtonPrimary
          style={{ background: customTheme.colors.error }}
          onClick={() => {
            if (onCancel) {
              onCancel()
            }
            closeModal()
          }}
        >
          Cancelar
        </ButtonPrimary>
      </S.ButtonsContainer>
    </S.Container>
  )
}

export default ConfirmModal
