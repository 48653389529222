import React from 'react'
import { Wrapper, LoadingStyled } from './styled'

function Loading () {
  return (
    <Wrapper>
      <LoadingStyled />
    </Wrapper>
  )
}

export default Loading
