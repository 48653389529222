import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { columns, filterForm } from './columns'
import { checktransfer } from '../../../../services/payments'
import GenerateForm from '../../../components/layout/GenerateForm'
import { CustomStyle } from './styled'
import { useModal } from '../../../../contexts/modal'
import DataTable from '../../../components/DataTable'
import { useAlertBox } from '../../../../contexts/alert_box'
import Header from '../../../components/Header'
import MainContainer from '../../../components/MainContainer'
import { paymentsByClinics } from '../../../../services/clinics/clinics_payments'

function PaymentsClinics (props) {
  const [pageSize] = useState(10)
  const [myList, setMyList] = useState([])
  const [id] = useState(props.user_id)
  const [offset, setOffset] = useState(0)
  const [filter, setFilter] = useState({})
  const [checkTransferMutation] = useMutation(checktransfer())

  const modal = useModal()
  const alertBox = useAlertBox()

  const { data, loading, refetch } = useQuery(paymentsByClinics(), {
    variables: {
      status: `%${filter?.status && filter?.status?.value !== 'all'
        ? filter?.status?.value + '%'
        : ''
        }`,
      clinic_id: id,
      limit: pageSize,
      offset: (offset) * pageSize
    },
    skip: !id,
    onError: () => {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar os dados!',
        type: 'error',
        time: 3000
      })
    }
  })

  useEffect(() => {
    if (data?.payments) {
      setMyList(data.payments)
    }
  }, [data])

  useEffect(() => {
    if (id !== null) {
      setFilter((prev) => ({
        ...prev,
        clinic: { value: id }
      }))
      refetch()
    }
  }, [])

  const onChangePage = (page) => {
    setOffset(page)
  }

  const handleFilter = async (e, actions) => {
    actions.setSubmitting(true)
    setFilter((prev) => ({ ...prev, status: e.status }))
    actions.setSubmitting(false)

    await refetch()
  }

  const checkTransfer = async (id) => {
    await checkTransferMutation({
      variables: {
        _in: [id]
      }
    })
    await refetch()
  }

  return (
    <MainContainer>
      <Header
        title="Pagamentos"
      />

      <CustomStyle>
        <div className="filter">
          <GenerateForm
            onSubmit={handleFilter}
            groupFields={filterForm(filter)}
            button={{
              submit: 'Filtrar',
              submitting: 'Filtrando...'
            }}
          />
        </div>
      </CustomStyle>

      <DataTable
        data={myList}
        onChangePage={onChangePage}
        loading={loading}
        columns={columns(modal, checkTransfer)}
        totalItems={data?.payments_aggregate?.aggregate?.count || 0}
      />
    </MainContainer>
  )
}

export default PaymentsClinics
