import React from 'react'
import { useQuery } from '@apollo/client'
import { columns } from './columns'
import { fetchAssessmentCalls } from '../../../../services/assessmentCalls'
import DataTable from '../../../components/DataTable'
import MainContainer from '../../../components/MainContainer'
import Header from '../../../components/Header'

function AssessmentCalls ({ list }) {
  const { loading, data } = useQuery(fetchAssessmentCalls())

  if (loading) return <span>Carregando...</span>

  return (
    <MainContainer>
      <Header title="Relatório de Consultas" />

      {loading && (<span>Carregando...</span>)}

      {data?.schedules && (
        <DataTable
          data={list || data.schedules}
          columns={columns()}
        />
      )}
    </MainContainer>
  )
}

export default AssessmentCalls
