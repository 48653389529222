import DataTableActionColumn from '../../../../components/DataTableActionColumn'
import { fetchPacientSymptomRecord } from '../../../../../services/pacients'
import DataTable from '../../../../components/DataTable'
import { FlexContainer } from '../styled'
import React, { useState, useEffect, createRef } from 'react'
import { useQuery } from '@apollo/client'

export default function Screening ({ id }) {
  const [detailsPage, setDetailsPage] = useState(false)
  const [selectedTriagem, setSelectedTriagem] = useState({})
  const { data } = useQuery(fetchPacientSymptomRecord(), {
    variables: { user_id: id }
  })

  const contentRef = createRef()

  useEffect(() => {
    if (contentRef?.current) {
      const html = contentRef.current

      html.innerHTML = selectedTriagem.description
      for (let i = 0; i < html.children.length; i++) {
        html.children[i].innerHTML = html.children[i].innerHTML.replace('body', '')
      }
    }
  }, [contentRef, selectedTriagem])

  const handleTriagem = (record) => {
    setDetailsPage(true)
    setSelectedTriagem(record)
  }

  return (
    <FlexContainer>
      {data?.patient_symptom_record && !detailsPage && (
        <DataTable
          data={data?.patient_symptom_record || []}
          columns={[
            {
              field: 'created_at',
              headerName: 'Data de criação',
              flex: 1,
              renderCell: (props) => {
                return <span>{new Date(props.value).toLocaleDateString('pt-BR')}</span>
              }
            },
            {
              field: 'actions',
              headerName: 'Detalhes',
              renderCell: (props) => (
                <DataTableActionColumn
                  handleVisibility={() => handleTriagem(props.row)}
                />
              )
            }
          ]}
        />)}

      {data?.patient_symptom_record && detailsPage && (
        <div
          id="triagem"
          ref={contentRef}
        />
      )}
    </FlexContainer>
  )
}
