import './services/firebase/firebase-config'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'

export const App = ({ children }) => {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'development') { // Adds messages only in a dev environment
    loadDevMessages()
    loadErrorMessages()
  }

  return children
}

export default App
