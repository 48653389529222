export function phoneMask (phone) {
  if (phone.slice(0, 3) === '+55') {
    return `${phone.slice(0, 1)} ${phone.slice(1, 3)} ${phone.slice(3, 7)} ${phone[8]} ${phone.slice(8, 17)} `
  }
  if (phone.slice(0, 2).includes('55')) {
    return `+ ${phone.slice(0, 2)} (${phone.slice(2, 4)}) ${phone.slice(
      4,
      5
    )} ${phone.slice(5, 9)}-${phone.slice(9, 122)}`
  }
  if (phone.includes('+ ')) {
    return `${phone}`
  }
  return `+ 55 (${phone.slice(0, 2)}) ${phone.slice(2, 3)} ${phone.slice(
    3,
    7
  )}-${phone.slice(7, 11)}`
}

export function formatMoney (val) {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  })

  return formatter.format(val) /* $2,500.00 */
}

export function formatNumber (val) {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('pt-BR', {
    // style: "currency",
    currency: 'BRL'
  })

  return formatter.format(val) /* $2,500.00 */
}

export function stringToFloat (val) {
  return parseFloat(`${val}`.replaceAll('.', '').replaceAll(',', '.'))
}

export function getBase64 (file) {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    return reader.result
  }
  reader.onerror = function (error) {
    console.error('Error: ', error)
  }
}

export const getType = (type) => {
  if ('hospitals') return 'hospitals_by_pk'
  if ('sus') return 'sus_by_pk'

  return type
}

export const refactorLabelPlan = (item) => {
  // return `Valor fixo: ${formatNumber(
  //   item.fixed_amount_of_transfer_to_manager
  // )} | Percentual fixo de transferência: ${formatNumber(
  //   item.fixed_percentage_of_transfer_to_manager
  // )} | Taxa Debito: ${formatNumber(item.debit_fees)} | Taxa PIX: ${formatNumber(
  //   item.pix_fees
  // )} | Taxa Crédito: ${formatNumber(item.credit_fees)}`;
  if (item.fixed_amount_of_transfer_to_manager) {
    return `Valor fixo: ${formatNumber(
      item.fixed_amount_of_transfer_to_manager
    )}`
  }

  return `Valor percentual por tranferência: ${formatNumber(
    item.fixed_percentage_of_transfer_to_manager
  )}%`
}

export const sortByID = (a, b) => {
  if (a.id > b.id) {
    return 1
  }
  if (a.id < b.id) {
    return -1
  }
  // a must be equal to b
  return 0
}

// 16:30:00+00:00 -> 16:30
export const hourAndMinuteClear = (value) => {
  try {
    const [hour, minut] = value.split(':')
    return `${hour}:${minut}`
  } catch (err) {
    console.error(err)
  }
}
