import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlertBox } from '../../../../contexts/alert_box'
import InternalBody from '../../../components/InternalBody'
import GenerateForm from '../../../components/layout/GenerateForm'
import { groupFields } from './columns'
import { genericFetch } from '../../../../main/usecases/generic'
import {
  acceptRequestsCredit,
  fecthRequestsCreditById
} from '../../../../services/creditRequest'

function InternalCreditRequest ({ match }) {
  const { data, refetch } = useQuery(fecthRequestsCreditById(), {
    variables: { limit: 10, offset: 0, id: match.params.id }
  })
  const [mutateFunction] = useMutation(acceptRequestsCredit())
  const [content, setContent] = useState(null)
  const alertBox = useAlertBox()
  useEffect(() => {
    if (data?.credit_requests[0]) {
      setContent(data?.credit_requests[0])
    }
  }, [data])

  const mutationUpdateClinic = async (e, url, actions) => {
    await genericFetch(
      mutateFunction,
      {
        _in: [content.id]
      },
      {
        actions,
        alertBox
      }
    )
    await refetch()
  }

  const onSubmit = async (e, actions) => {
    await mutationUpdateClinic(e, null, actions)
  }

  return (
    <>
      <InternalBody>
        <p>
          Criado em:{' '}
          {moment(content?.created_at).format('DD/MM/YYYY HH:MM') || ''}
        </p>

        <GenerateForm
          onSubmit={onSubmit}
          groupFields={groupFields(content, setContent)}
          button={{
            submit: content
              ? 'Marcar Solicitação como aceita'
              : 'Cancelar Solicitação',
            submitting: 'Atualizando...'
          }}
        />
      </InternalBody>
    </>
  )
}

export default InternalCreditRequest
