import { gql } from '@apollo/client'

export const addSheduleFile = () => {
  return gql`
    mutation insertFile($description: String, $url: String, $shedule_id: Int) {
        insert_files_one(object: {description: $description, url: $url, shedule_id: $shedule_id}) {
          created_at
          description
          exam_id
          id
          updated_at
          uploaded_by_user
          shedule_id
          url
        }
      }
    `
}

export const removeSheduleFile = () => {
  return gql`
    mutation MyMutation($id: Int!) {
        delete_files_by_pk(id: $id) {
          url
          uploaded_by_user
          updated_at
          shedule_id
          id
          exam_id
          description
          created_at
        }
      }
    `
}
