import { Button } from '@mui/material'

export const columns = (fieldName, onSelect) => [
  {
    field: fieldName,
    headerName: 'Nome',
    flex: 1,
    editable: true
  },
  {
    field: 'id',
    headerName: '',
    sortable: false,
    width: 200,
    renderCell: (params) => {
      return (
        <Button onClick={() => onSelect(params.row)}>
          Escolher
        </Button>
      )
    }
  }
]
