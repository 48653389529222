/* eslint-disable camelcase */
/* eslint-disable no-undef */
import React, { useEffect } from 'react'
import getJitsiJWT from '../../../Utils/getJitsiJWT'
import { useQuery } from '@apollo/client'
import { fetchDoctorById } from '../../../services/doctor'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/authenticator'
import * as S from './styled'
import Loading from '../../components/layout/Loading'

function Room ({ match, location }) {
  const history = useHistory()
  const { id } = useAuth()
  const { data, loading } = useQuery(fetchDoctorById(), {
    variables: { limit: 10, offset: 0, id }
  })

  useEffect(() => {
    if (!loading) {
      initJitsi()
    }
  }, [loading])

  console.log(location)

  const initJitsi = async () => {
    const { full_name, email, profile_image } = data.doctors_by_pk

    const callID = match.params.callId
    const roomID = match.params.id
    const token = await getJitsiJWT(email, full_name || '', roomID)
    const options = {
      roomName: `${callID}/${roomID}`,
      parentNode: document.querySelector('#jaas-container'),
      jwt: token,
      lang: 'pt-br',
      userInfo: {
        email: email,
        displayName: full_name,
        avatarUrl: profile_image
      }
    }

    document.querySelector('#jaas-container').innerHTML = ''
    const api = new JitsiMeetExternalAPI('8x8.vc', options)
    api.addListener('videoConferenceLeft', handleCallLeft)
  }

  const handleCallLeft = () => {
    if (location.state?.scheduleId) {
      history.push(`/doctor-requests-schedules/${location.state.scheduleId}`)
    } else {
      history.push('/doctor-requests-schedules')
    }
  }

  if (loading) return <Loading />

  return (
    <S.Container>
      <div id="jaas-container" />
    </S.Container>
  )
}

export default Room
