import styled from 'styled-components'

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 4px;
    margin-left: 5px;
`
export const FieldBox = styled.div`
    display: flex;
    gap: 5px;

    p {
        color: #777;
    }
`
