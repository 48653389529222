import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { useQuery } from '@apollo/client'
import { columns } from './columns'
import { useModal } from '../../../../contexts/modal'
import { fetchDoctors } from '../../../../services/doctor'
import { fecthEspecialities } from '../../../../services/medicalSpecialty'
import Loading from '../../../components/layout/Loading'

function SchedulesDoctorsMaster () {
  const [perPage, setPerPage] = useState(5)
  const especialities = useQuery(fecthEspecialities(), {})
  const { loading, data } = useQuery(fetchDoctors(), {
    variables: {
      limit: 50,
      offset: 0
    }
  })
  const modal = useModal()

  if (loading) return <Loading />
  return (
    <>
      <Row>
        <Col xs={12}>
          <h2>Horários disponíveis - Médicos</h2>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          {data?.doctors && especialities?.data?.medical_specialties && (
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={data.doctors}
                columns={columns(
                  modal,
                  especialities?.data?.medical_specialties
                )}
                pageSize={perPage}
                onPageSizeChange={(e) => {
                  setPerPage(e)
                }}
                rowsPerPageOptions={[10]}
                pagination
                disableSelectionOnClick
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

export default SchedulesDoctorsMaster
