import styled from '@emotion/styled'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
`

export const Grid = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`

export const CounterContainer = styled.span`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  margin-bottom: 15px;
`
export const CounterHeader = styled.span`
  background-color: ${(props) => props.color};
  color: #fff;
  height: 40px;
  font-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const CounterBody = styled.span`
  height: 100px;
  padding: 15px 15px;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.color};
`
