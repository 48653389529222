import React from 'react'
import InternalLaboratories from '../../master/laboratories/Internal'
import { useAuth } from '../../../../contexts/authenticator'

function LaboratoriesProfile () {
  const { id } = useAuth()

  return (
    <div>{id && <InternalLaboratories match={{ params: { id: id } }} />}</div>
  )
}

export default LaboratoriesProfile
