import { gql } from '@apollo/client'

export const fecthEspecialities = () => {
  return gql`
    ${fragmentMedicalSpecialty}
    query getMedicalSpecialties {
      medical_specialties(order_by: {updated_at: desc}) {
        ...medicalSpecialtyFields
      }
    }
  `
}

export const createSpecialty = () => {
  return gql`
    ${fragmentMedicalSpecialty}

    mutation insertMedicalSpecialty(
      $description: String
      $icon: String
      $name: String
    ) {
      insert_medical_specialties_one(
        object: { description: $description, icon: $icon, name: $name }
      ) {
        ...medicalSpecialtyFields
      }
    }
  `
}
export const deleteSpecialty = () => {
  return gql`
    ${fragmentMedicalSpecialty}
    mutation deleteMedicalSpecialtyByPK($id: Int!) {
      delete_medical_specialties_by_pk(id: $id) {
        ...medicalSpecialtyFields
      }
    }
  `
}

export const updateSpecialty = () => {
  return gql`
    ${fragmentMedicalSpecialty}

    mutation updateMedicalSpecialty(
      $id: Int
      $icon: String
      $description: String = ""
      $name: String = ""
    ) {
      update_medical_specialties(
        where: { id: { _eq: $id } }
        _set: { icon: $icon, description: $description, name: $name }
      ) {
        affected_rows
        returning {
          ...medicalSpecialtyFields
        }
      }
    }
  `
}

export const fragmentMedicalSpecialty = gql`
  fragment medicalSpecialtyFields on medical_specialties {
    icon
    id
    name
    updated_at
    description
    created_at
  }
`
