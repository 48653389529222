import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { useMutation, useQuery } from '@apollo/client'
import { columns, confrimRequest, SendResultsFieldsForm } from './columns'
import Loading from '../../../components/layout/Loading'
import { useModal } from '../../../../contexts/modal'
import CardModal from '../../../components/CardModal'
import ButtonPrimary from '../../../components/Button'
import { ConfirmModal, FilterContainer } from './styled'
import { customTheme } from '../../../../styles/theme'
import { useAlertBox } from '../../../../contexts/alert_box'
import { updateScheduleUseCase } from '../../../../main/usecases/schedules'
import GenerateForm from '../../../components/layout/GenerateForm'
import { getScheduleStatus } from '../../../../Utils/schedules'
import moment from 'moment'
import { createNotificationFireFunction } from '../../../../services/Notifications'
// import {
//   cancelExameRequest,
//   confirmExameRequest,
//   fetchLaboratoriesExameRequest,
//   finishExameRequest,
// } from "../../../../services/laboratories";
import { stringToFloat } from '../../../../Utils/format'
import {
  finishExameRequest,
  cancelExameRequest,
  confirmExameRequest,
  fetchLaboratoriesDiagExameRequest
} from '../../../../services/diagnostic-images'
import { useAuth } from '../../../../contexts/authenticator'

function RequestDiagnosticImage () {
  const [perPage, setPerPage] = useState(5)
  const [selectedFilters, setSelectedFilter] = useState({
    status: ''
  })

  const { id } = useAuth()

  const { data, loading, refetch, error } = useQuery(
    fetchLaboratoriesDiagExameRequest(),
    {
      variables: {
        medical_imaging_diagnostic_id: id,
        status: '%',
        limit: 10,
        offset: 0
      },
      pollInterval: 300000
    }
  )
  const alertBox = useAlertBox()
  const modal = useModal()

  useEffect(() => {
    if (error) {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar buscar dados',
        type: 'error',
        time: 3000
      })
    }
  }, [error])

  const [cancelScheduleMutation] = useMutation(cancelExameRequest())
  const [confirmExamMutation] = useMutation(confirmExameRequest())
  const [finishMutation] = useMutation(finishExameRequest())

  const [dataValues, setDataValues] = useState(null)
  const [filter, setFilter] = useState({
    status: [
      '',
      'requested',
      'pending_payment',
      'confirmed',
      'canceled',
      'finished'
    ]
  })

  const mutationFinishRequest = async (params) => {
    return await updateScheduleUseCase(finishMutation, params, {
      alertBox
    })
  }

  const mutationConfirmSchedule = async (params, actions) => {
    return await updateScheduleUseCase(
      confirmExamMutation,
      {
        ...params
      },
      {
        alertBox,
        actions
      }
    )
  }

  const mutationCancelSchedule = async (data) => {
    return await updateScheduleUseCase(cancelScheduleMutation, data, {
      alertBox
    })
  }

  const handleCancelSchedule = (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '500px' }}>
          <ConfirmModal>
            <h2>Deseja cancelar esta solicitação?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  await mutationCancelSchedule({
                    id: id,
                    exam_description:
                      'Infelizmente não foi possível dar continuidade com seu exame'
                  })

                  await createNotificationFireFunction({
                    title: 'Sua solicitação de exame não foi aceita ❌',
                    description:
                      'Não foi possível aceitar sua solicitação de exame, por favor selecione outro horário',
                    user_id: params.user_id,
                    payload: {
                      route: '/exams/exam_id',
                      exam_id: id
                    }
                  })

                  modal.setOptions({ open: false })
                  refetch()
                }}
              >
                Cancelar Solicitação
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: customTheme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }
  const handleFinishRequest = (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '500px' }}>
          <ConfirmModal>
            <h2>Deseja cancelar esta solicitação?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  await mutationFinishRequest({
                    id: id
                  })

                  await createNotificationFireFunction({
                    title: 'Sua solicitação de exame não foi aceita ❌',
                    description:
                      'Não foi possível aceitar sua solicitação de exame, por favor selecione outro horário',
                    user_id: params.user_id,
                    payload: {
                      route: '/exams/exam_id',
                      exam_id: id
                    }
                  })

                  modal.setOptions({ open: false })
                  refetch()
                }}
              >
                Finalizar Exame
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: customTheme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const handleConfirmSchedule = (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '40%' }}>
          <ConfirmModal>
            <h2>Deseja confirmar este agendamento?</h2>
            <GenerateForm
              className="confirm-form"
              onSubmit={async (values, actions) => {
                await mutationConfirmSchedule(
                  {
                    id: id,
                    exam_description: values?.exam_description || '',
                    value: stringToFloat(values?.value),
                    start_time: values?.start_time,
                    date: values?.date
                  }
                  // actions
                )

                await createNotificationFireFunction({
                  title: 'Sua solicitação de exame foi aceita 🔔✅',
                  description:
                    'Entre no aplicativo e faça o pagamento para continuar',
                  user_id: params.user_id,
                  payload: {
                    route: '/exams/exam_id',
                    exam_id: id
                  }
                })
                modal.setOptions({ open: false })
                refetch()
                // actions.setSubmitting(false);
              }}
              groupFields={confrimRequest()}
              button={{
                submit: 'Confirmar Agendamento',
                submitting: 'Confimando'
              }}
            />
            <div className="buttons-container">
              <ButtonPrimary
                style={{ background: customTheme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }
  const sendResults = (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '40%' }}>
          <ConfirmModal>
            <h2>Enviar arquivos do exame para o paciente</h2>
            <SendResultsFieldsForm exam_id={id} modal={modal} params={params} />
            <div className="buttons-container">
              <ButtonPrimary
                style={{ background: customTheme.colors.error, width: '100%' }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const columnsActions = {
    handleCancelSchedule,
    handleConfirmSchedule,
    handleFinishRequest,
    sendResults
  }

  const handleDataFilter = (e) => {
    let filteredValues = data?.exams

    if (selectedFilters?.status) {
      filteredValues = filteredValues?.filter(
        (item) => item.status === selectedFilters?.status
      )
    }

    if (selectedFilters?.date) {
      filteredValues = filteredValues?.filter(
        (item) =>
          moment(item?.created_at).format('YYYYMMDD') ===
          moment(selectedFilters?.date).format('YYYYMMDD')
      )
    }

    setDataValues(filteredValues)
  }

  useEffect(() => {
    setDataValues(data?.exams)
  }, [data])

  if (loading) return <Loading />

  return (
    <>
      <Row>
        <Col xs={12}>
          <h2>Solicitações de exames</h2>
        </Col>
        <Col xs={12}>
          <FilterContainer>
            <GenerateForm
              onSubmit={handleDataFilter}
              groupFields={[
                {
                  fields: [
                    //     {
                    //       label: "Paciente:",
                    //       type: "autocomplete",
                    //       required: true,
                    //       hideField: false,
                    //       name: "patient",
                    //       onChange: (e) => {
                    //         setSelectedFilter((prev) => ({
                    //           ...prev,
                    //           patient: e?.value,
                    //         }));
                    //       },
                    //       currentValue: {
                    //         label: selectedFilters?.patient?.full_name,
                    //         value: selectedFilters?.patient?.id,
                    //       },
                    //       options: filter?.patient
                    //         ?.filter(onlyUnique)
                    //         ?.map((item) => ({
                    //           label: item.full_name,
                    //           value: item,
                    //         })),
                    //       columns: { xs: 3 },
                    //     },
                    {
                      label: 'Status:',
                      type: 'autocomplete',
                      required: true,
                      hideField: false,
                      name: 'status',
                      onChange: (e) => {
                        setSelectedFilter((prev) => ({
                          ...prev,
                          status: e?.value
                        }))
                      },
                      currentValue: {
                        label: getScheduleStatus(selectedFilters?.status),
                        value: selectedFilters?.status
                      },
                      options: filter?.status?.map((item) => ({
                        label: getScheduleStatus(item),
                        value: item
                      })),
                      columns: { xs: 3 }
                    },
                    {
                      label: 'Data da solicitação:',
                      type: 'date',
                      required: true,
                      hideField: false,
                      onChange: (e) => {
                        setSelectedFilter((prev) => ({
                          ...prev,
                          date: e.target.value
                        }))
                      },
                      currentValue: selectedFilters?.date,
                      name: 'date',
                      columns: { xs: 3 }
                    }
                  ]
                }
              ]}
              button={{ submit: 'Filtrar', submitting: 'Filtrando' }}
            />
            <ButtonPrimary
              style={{
                boxShadow: 'none',
                fontSize: '14px',
                padding: '5px',
                backgroundColor: customTheme.colors.secondary,
                lineHeight: '2',
                display: 'flex',
                maxWidth: 'calc(25% - 18px)',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                bottom: 0,
                left: 'calc(25% + 6px)'
              }}
              onClick={() => {
                setDataValues(data?.schedules)
                setSelectedFilter(null)
              }}
            >
              Limpar filtros
            </ButtonPrimary>
          </FilterContainer>
        </Col>

        <Col xs={12}>
          {dataValues && (
            <div style={{ height: 400, width: '100%', marginTop: '30px' }}>
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'created_at', sort: 'desc' }]
                  }
                }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                getRowClassName={(params) => `grid-row-status-${params.row.status.replace(/"/g, '')}`}
                rows={dataValues}
                columns={columns(modal, columnsActions)}
                pageSize={perPage}
                onPageSizeChange={(e) => {
                  setPerPage(e)
                }}
                rowsPerPageOptions={[10]}
                pagination
                disableSelectionOnClick
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

export default RequestDiagnosticImage
