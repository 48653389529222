import { createGlobalStyle } from 'styled-components'
import { customTheme } from './theme'

export const GlobalStyle = createGlobalStyle`
  *,
  ::after,
  ::before {
    box-sizing: border-box;
    border: 0;
    margin: 0;
    padding: 0;
    outline: none;
  }

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
  }

  h1 {
    font-size: 2em;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: silver; 
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa; 
  }
  
  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }
  
  body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 1.35;
  }
  
  body, input, textarea {
    font-family: ${customTheme.fonts.body};
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: ${customTheme.fonts.heading};
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
  }
  
  p {
    margin-bottom: 10px;
    margin-top: 0;
  }  
  a{
    text-decoration: none;
  }
  .MuiDataGrid-columnHeaders {
      background-color: #325ba5;
  }

  .MuiDataGrid-columnHeaderTitle {
      color: #fff;
  }

  .MuiDataGrid-main {
      border-radius: 6px;
  }

  .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
    z-index: 0;
  }

  form.confirm-form .position-relative.col-12:before {
    content: 'Caso não esteja claro pelo paciente os exames a serem realizados, descreva-os aqui';
    position: absolute;
    width: 100%;
    display: flex;
    font-size: 10px;
    line-height: 1;
    bottom: -3px;
    height: 15px;
    padding-left: 15px;
    color: #983f3f;
    left: 0;
}
  .MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows{
    margin-bottom: 0;
  }
  
  .grid-row-status-requested {
    color: red !important;
    font-weight: bold;
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: white;
    background-color: #0d6efd;
    border-color: none;
  }

  .nav-tabs .nav-item .nav-link {
    border: 0.5px solid #ddd;
  }

  .nav-tabs {
    border-bottom: none;
  }

  .pacient-form .label-float input{
    height: 40px;
    border-radius: 4px;
  }

  .pacient-form .label-float label{
    margin-top: 20px;
  }

  .pacient-form .button-submit-generate-form{
    max-width: 260px;
    height: 36px;
    padding: 0;
  }

  .pacient-form .custom-select .react-select__control{
    height: 40px;
    border-radius: 4px;
    padding: 0;
  }

  .pacient-form .custom-select .react-select__indicators{
    height: 40px;
  }

  .custom-select .react-select__indicators{
    height: 50px;
  }

  .custom-select .react-select__control{
    border-radius: 4px;
    padding: 0;
  }

  .custom-select .react-select__input-container {
    height: fit-content;
  }

  .custom-select .react-select__input-container input {
    height: fit-content;
  }

  .custom-multi-select .react-select__value-container {
    padding-top: 30px;
    margin-top: 20px;
    padding-bottom: 5px;
  }

  .custom-multi-select .react-select__input-container {
    height: 0;
  }
`
