import styled from 'styled-components'
import CardModal from '../../../../components/CardModal'
import { ToggleButton as MUIToggleButton, styled as MStyled } from '@mui/material'
import { customTheme } from '../../../../../styles/theme'

export const ToggleButton = MStyled(MUIToggleButton)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: customTheme.colors.primary
  }
})

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 10px;
  width: 100%;
`

export const DayContainer = styled.div`
    display: flex;
    align-items: center;
    strong, label {
      color: ${props => props.$color}
    }
`
export const DayBadge = styled.span`
    font-size: 14px;
    padding: 4px 8px;
    background-color: rgb(42 72 141);
    text-transform: uppercase;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    background-color: rgb(42 72 141);
    margin-left: 8px;
`

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 5px;
  gap: 5px;
`

// export const ButtonContainer = styled.div`
//   display: flex;
//   position: relative;
//   margin-top: 100px;

//   button {
//     z-index: 2;
//   }
// `

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  
  button {
    width: 100%;
    height: auto;
  }

  & > label {
    margin-top: 0.5rem;
    font-weight: bold;
    color: red;
  }
`

export const ContainerModal = styled(CardModal)`
  width: 350px;
  max-width: 95%;
  padding: 2rem;
`
