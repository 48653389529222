import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import EmailOutlined from '@mui/icons-material/EmailOutlined'
import CalendarToday from '@mui/icons-material/CalendarToday'
import VisibilityOutlinedIcon from '@mui/icons-material/Visibility'
import * as S from './styled'

function DataTableActionColumn ({
  handleEdit,
  handleDelete,
  handleSendEmail,
  handleSchedules,
  handleVisibility,
  children
}) {
  return (
    <S.Container>
      {children}
      {handleVisibility && (
        <S.Button
          title="Ver"
          onClick={handleVisibility}
          color="#0000cc"
        >
          <VisibilityOutlinedIcon />
        </S.Button>
      )}
      {handleEdit && (
        <S.Button title="Editar" onClick={handleEdit} color="#0000cc">
          <EditOutlinedIcon />
        </S.Button>
      )}
      {handleSchedules && (
        <S.Button
          title="Agendamentos"
          onClick={handleSchedules}
          color="#0000cc"
        >
          <CalendarToday />
        </S.Button>
      )}
      {handleSendEmail && (
        <S.Button
          title="Enviar E-mail"
          onClick={handleSendEmail}
          color="#0000cc"
        >
          <EmailOutlined />
        </S.Button>
      )}
      {handleDelete && (
        <S.Button title="Deletar" onClick={handleDelete} color="#ee0000">
          <DeleteOutlinedIcon />
        </S.Button>
      )}
    </S.Container>
  )
}

export default DataTableActionColumn
