import { useQuery } from '@apollo/client'
import DataTableActionColumn from '../../../../components/DataTableActionColumn'
import DataTable from '../../../../components/DataTable'
import { FlexContainer } from '../styled'
import { fetchSchedulesByUser } from '../../../../../services/schedules'
import { useHistory } from 'react-router-dom'

export default function Screening ({ id }) {
  const history = useHistory()

  const { loading, data } = useQuery(fetchSchedulesByUser(), {
    variables: { user_id: id }, fetchPolicy: 'no-cache'
  })

  if (loading) return <span>Carregando...</span>

  const handleSchedule = (schedule) => {
    history.push(`/doctor-requests-schedules/${schedule.id}`)
  }

  return (
    <FlexContainer style={{ width: '100%' }}>
      {data?.schedules && (
        <DataTable
          data={data?.schedules || []}
          columns={[
            {
              field: 'created_at',
              headerName: 'Data de criação',
              flex: 1,
              renderCell: (props) => {
                return (
                  <span>
                    {new Date(props.value).toLocaleDateString('pt-BR')}
                  </span>
                )
              }
            },
            {
              field: 'details',
              headerName: 'Detalhes',
              renderCell: (props) => (
                <DataTableActionColumn
                  handleVisibility={() => handleSchedule(props.row)}
                />
              )
            }
          ]}
        />
      )}
    </FlexContainer>
  )
}
