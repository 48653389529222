// import api from "../api/api";
import { gql } from '@apollo/client'
import { fragmentAddresses } from '../address'
import { fragmentCompanyPlan } from '../companyPlans'
import { fragmentMedicalSpecialty } from '../medicalSpecialty'
import { fragmentPaymentsExams } from '../payments'
import { fragmentSchedules } from '../schedules'
import { fragmentUser } from '../users'

export const fetchLaboratoriesDiagById = () => {
  return gql`
    query getLaboratoriesByPK($id: Int!) {
      medical_imaging_diagnostics_by_pk(id: $id) {
        ...diagImgFields
      }
    }

    ${fragmentLaboratoriesDiag}
  `
}
export const deleteLaboratoriesDiag = () => {
  return gql`
    mutation deleteDigImgByPk($id: Int!) {
      delete_medical_imaging_diagnostics_by_pk(id: $id) {
        id
      }
    }
  `
}
export const fetchLaboratoriesDiagExameRequest = () => {
  return gql`
    query getExamsByLaboratories(
      $limit: Int
      $offset: Int
      $status: String
      $medical_imaging_diagnostic_id: Int
    ) {
      exams(
        limit: $limit
        offset: $offset
        where: {
          status: { _like: $status }
          medical_imaging_diagnostic_id: { _eq: $medical_imaging_diagnostic_id }
        }
      ) {
        ...examFields
      }
    }
    ${fragmentExamFieldsLaboratoriesDiagImg}
  `
}

export const fetchOdontologyExameRequest = () => {
  return gql`
    query getExamsByDentalClinic(
      $limit: Int
      $offset: Int
      $status: String
      $dental_clinic_id: Int
    ) {
      exams(
        limit: $limit
        offset: $offset
        where: {
          status: { _like: $status }
          dental_clinic_id: { _eq: $dental_clinic_id }
        }
      ) {
        ...examFields
      }
    }
    ${fragmentExamFieldsOdontology}
  `
}

export const fetchLaboratoriesDiagAddFiles = () => {
  return gql`
    mutation addFiles($objects: [files_insert_input!]!) {
      insert_files(objects: $objects) {
        affected_rows
        returning {
          ...fileFields
        }
      }
    }
    ${fragmentFileFields}
  `
}

export const finishExameRequest = () => {
  return gql`
    mutation finishExam($id: Int!, $status: String = "finished") {
      update_exams_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
        ...examFields
      }
    }
    ${fragmentExamFieldsLaboratoriesDiagImg}
  `
}
export const cancelExameRequest = () => {
  return gql`
    mutation cancelExam(
      $id: Int!
      $status: String = "canceled"
      $exam_description: String
    ) {
      update_exams_by_pk(
        pk_columns: { id: $id }
        _set: { status: $status, exam_description: $exam_description }
      ) {
        ...examFields
      }
    }
    ${fragmentExamFieldsLaboratoriesDiagImg}
  `
}

export const confirmExameRequestOdontology = () => {
  return gql`
    mutation confirmExam(
      $id: Int!
      $status: String = "pending_payment"
      $exam_description: String
      $date: timestamptz
      $value: numeric
      $start_time: timetz
    ) {
      update_exams_by_pk(
        pk_columns: { id: $id }
        _set: {
          status: $status
          exam_description: $exam_description
          date: $date
          value: $value
          start_time: $start_time
        }
      ) {
        ...examFields
      }
    }
    ${fragmentExamFieldsOdontology}
  `
}

export const cancelExameRequestOdontology = () => {
  return gql`
    mutation cancelExam(
      $id: Int!
      $status: String = "canceled"
      $exam_description: String
    ) {
      update_exams_by_pk(
        pk_columns: { id: $id }
        _set: { status: $status, exam_description: $exam_description }
      ) {
        ...examFields
      }
    }
    ${fragmentExamFieldsOdontology}
  `
}

export const finishExameRequestOdontology = () => {
  return gql`
    mutation finishExam($id: Int!, $status: String = "finished") {
      update_exams_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
        ...examFields
      }
    }
    ${fragmentExamFieldsOdontology}
  `
}

export const confirmExameRequest = () => {
  return gql`
    mutation confirmExam(
      $id: Int!
      $status: String = "pending_payment"
      $exam_description: String
      $date: timestamptz
      $value: numeric
      $start_time: timetz
    ) {
      update_exams_by_pk(
        pk_columns: { id: $id }
        _set: {
          status: $status
          exam_description: $exam_description
          date: $date
          value: $value
          start_time: $start_time
        }
      ) {
        ...examFields
      }
    }
    ${fragmentExamFieldsLaboratoriesDiagImg}
  `
}
export const fetchLaboratoriesDiagByFilter = () => {
  return gql`
    query getDiagImgByCityAndState(
      $limit: Int
      $offset: Int
      $city: String
      $state_code: String
    ) {
      medical_imaging_diagnostics(
        order_by: {updated_at: desc},
        limit: $limit,
        offset: $offset,
        where: {
          adress: { city: { _like: $city }, state_code: { _like: $state_code } }
        }
      ) {
        ...diagImgFields
      }
    }
    ${fragmentLaboratoriesDiag}
  `
}
export const createLaboratoriesDiag = () => {
  return gql`
    mutation insertDiagImgOne(
      $company_name: String
      $description: String
      $email: String
      $facebook: String
      $instagram: String
      $linkedin: String
      $phone_number: String
      $photo: String
      $website: String
      $address: String
      $city: String
      $complement: String
      $latitude: numeric
      $longitude: numeric
      $neighborhood: String
      $number: String
      $state: String
      $state_code: String
      $zipcode: String
      $company_plan_id: Int
    ) {
      insert_medical_imaging_diagnostics_one(
        object: {
          company_name: $company_name
          description: $description
          email: $email
          facebook: $facebook
          instagram: $instagram
          linkedin: $linkedin
          phone_number: $phone_number
          photo: $photo
          website: $website
          adress: {
            data: {
              address: $address
              city: $city
              complement: $complement
              latitude: $latitude
              longitude: $longitude
              neighborhood: $neighborhood
              number: $number
              state: $state
              state_code: $state_code
              zipcode: $zipcode
            }
          }
          company_plan_id: $company_plan_id
        }
      ) {
        ...diagImgFields
      }
    }
    ${fragmentLaboratoriesDiag}
  `
}
export const updateLaboratoriesDiag = () => {
  return gql`
    mutation updateDiagImgOne(
      $id: Int!
      $company_name: String
      $description: String
      $email: String
      $facebook: String
      $instagram: String
      $linkedin: String
      $phone_number: String
      $photo: String
      $website: String
    ) {
      update_medical_imaging_diagnostics_by_pk(
        pk_columns: { id: $id }
        _set: {
          company_name: $company_name
          description: $description
          email: $email
          facebook: $facebook
          instagram: $instagram
          linkedin: $linkedin
          phone_number: $phone_number
          photo: $photo
          website: $website
        }
      ) {
        ...diagImgFields
      }
    }
    ${fragmentLaboratoriesDiag}
  `
}

/**
 * Retorna uma consulta GraphQL para recuperar informações detalhadas dos diagnósticos de imagem médica, opcionalmente filtradas por nome, cidade e estado.
 *
 * @param {Object} options - Opções para filtrar os resultados da consulta.
 * @param {string} [options.name=null] - Opcional. O nome do diagnóstico de imagem médica para filtrar os resultados.
 * @param {string} [options.city=null] - Opcional. O nome da cidade para filtrar os resultados.
 * @param {string} [options.stateCode=null] - Opcional. O código do estado para filtrar os resultados.
 * @param {number} [options.limit=null] - Opcional. O número máximo de diagnósticos de imagem médica a serem retornados.
 * @param {number} [options.offset=null] - Opcional. O deslocamento para paginação dos resultados.
 * @returns {string} - Uma consulta GraphQL para recuperar informações detalhadas dos diagnósticos de imagem médica.
 *
 * @example
 * // Exemplo de utilização:
 * fetchMedicalImagingDiagnostics({
 *   name: "%diagnóstico%",        // Nome do diagnóstico de imagem médica (filtro opcional)
 *   city: "Itajaí",               // Nome da cidade (filtro opcional)
 *   stateCode: "SC",              // Código do estado (filtro opcional)
 *   limit: 10,                    // Limite de diagnósticos de imagem médica a serem retornados (opcional)
 *   offset: 0                     // Deslocamento para paginação (opcional)
 * })
 */
export const fetchMedicalImagingDiagnostics = ({ name = null, city = null, stateCode = null, limit = null, offset = null }) => {
  let query

  if (!name) {
    if (city && stateCode) {
      query = gql`
        query getMedicalImagingDiagnostics($limit: Int, $offset: Int, $city: String, $state_code: String) {
          medical_imaging_diagnostics(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            ...diagImgFields
          }
          medical_imaging_diagnostics_aggregate(where: { adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            aggregate {
              count(columns: address_id)
            }
          }
        }
        ${fragmentDiagImgFields}
      `
    } else {
      query = gql`
        query getMedicalImagingDiagnostics($limit: Int, $offset: Int) {
          medical_imaging_diagnostics(limit: $limit, offset: $offset, order_by: { updated_at: desc }) {
            ...diagImgFields
          }
          medical_imaging_diagnostics_aggregate {
            aggregate {
              count(columns: address_id)
            }
          }
        }
        ${fragmentDiagImgFields}
      `
    }
  } else {
    if (city !== null && stateCode !== null) {
      query = gql`
        query getMedicalImagingDiagnostics($limit: Int, $offset: Int, $company_name: String, $city: String, $state_code: String) {
          medical_imaging_diagnostics(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { name: { _ilike: $name }, adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            ...diagImgFields
          }
          medical_imaging_diagnostics_aggregate(where: { company_name: { _ilike: $name }, adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            aggregate {
              count(columns: address_id)
            }
          }
        }
        ${fragmentDiagImgFields}
      `
    } else {
      query = gql`
        query getMedicalImagingDiagnostics($limit: Int, $offset: Int, $name: String) {
          medical_imaging_diagnostics(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { company_name: { _ilike: $name } }) {
            ...diagImgFields
          }
          medical_imaging_diagnostics_aggregate(where: { company_name: { _ilike: $name } }) {
            aggregate {
              count(columns: address_id)
            }
          }
        }
        ${fragmentDiagImgFields}
      `
    }
  }
  return query
}

export const fragmentLaboratoriesDiag = gql`
  fragment diagImgFields on medical_imaging_diagnostics {
    adress {
      ...addressFields
    }
    company_name
    created_at
    description
    email
    facebook
    id
    instagram
    linkedin
    phone_number
    photo
    updated_at
    website
    company_plan {
      ...companyPlanFields
    }
  }
  ${fragmentAddresses}
  ${fragmentCompanyPlan}
`

export const fragmentResumedUserFields = gql`
  fragment resumedUserFields on users {
    id
    full_name
    email
    phone
    photo
  }
`
export const fragmentResumedLaboratoriesFields = gql`
  ${fragmentAddresses}

  fragment resumedLaboratoriesFields on petshop {
    id
    name
    photo
    adress {
      ...addressFields
    }
  }
`
export const fragmentDiagImgFields = gql`
  fragment diagImgFields on medical_imaging_diagnostics {
    adress {
      ...addressFields
    }
    company_name
    created_at
    description
    email
    facebook
    id
    instagram
    linkedin
    phone_number
    photo
    updated_at
    website
  }
  ${fragmentAddresses}
`

export const fragmentLaboratoriesMedicalSpecialty = gql`
  ${fragmentMedicalSpecialty}

  fragment petshopMedicalSpecialty on petshop_with_medical_specialty {
    created_at
    id
    medical_specialty_id
    petshop_id
    updated_at
    medical_specialty {
      ...medicalSpecialtyFields
    }
  }
`
export const fragmentFileFields = gql`
  fragment fileFields on files {
    created_at
    description
    id
    updated_at
    uploaded_by_user
    url
  }
`

export const fragmentExamFieldsOdontology = gql`
  ${fragmentMedicalSpecialty}
  fragment examFields on exams {
    created_at
    date
    exam_description
    id
    laboratory_id
    medical_imaging_diagnostic_id
    payment_id
    start_time
    status
    updated_at
    user_description
    user_id
    value
    files {
      ...fileFields
    }
    payment {
      ...paymentFields
    }
    dental_clinic{
      company_name
    }
    user {
      ...userFields
    }
  }
  ${fragmentPaymentsExams}
  ${fragmentFileFields}
  ${fragmentUser}
`

export const fragmentExamFieldsLaboratoriesDiagImg = gql`
  ${fragmentMedicalSpecialty}
  fragment examFields on exams {
    created_at
    date
    exam_description
    id
    laboratory_id
    medical_imaging_diagnostic_id
    payment_id
    start_time
    status
    updated_at
    user_description
    user_id
    value
    files {
      ...fileFields
    }
    payment {
      ...paymentFields
    }
    medical_imaging_diagnostic {
      ...diagImgFields
    }
    user {
      ...userFields
    }
  }
  ${fragmentLaboratoriesDiag}
  ${fragmentPaymentsExams}
  ${fragmentFileFields}
  ${fragmentUser}
`

export const fragmentLaboratoriesAppointmentFields = gql`
  ${fragmentResumedLaboratoriesFields}
  ${fragmentLaboratoriesMedicalSpecialty}

  fragment petshopAppointmentFields on petshop_appointment {
    created_at
    days_of_week {
      id
      abbreviation
      full_text
    }
    end_time
    id
    personal_assistance
    cost_of_medical_consultation_personal_assistance
    start_time
    petshop_id
    updated_at
    number_of_vacancies
    unavailable_days {
      date
      id
    }
    pet_shop {
      ...resumedLaboratoriesFields
    }
  }
`
export const fetchLaboratoriesAppointment = () => {
  return gql`
    query getLaboratoriesAppointments($petshop_id: Int) {
      petshop_appointment(order_by: {updated_at: desc}, where: { petshop_id: { _eq: $petshop_id } }) {
        ...petshopAppointmentFields
      }
    }
    ${fragmentLaboratoriesAppointmentFields}
  `
}

export const createLaboratoriesAppointment = () => {
  return gql`
    ${fragmentLaboratoriesAppointmentFields}

    mutation insertLaboratoriesAppointments(
      $objects: [petshop_appointment_insert_input!]!
    ) {
      insert_petshop_appointment(objects: $objects) {
        affected_rows
        returning {
          ...petshopAppointmentFields
        }
      }
    }
  `
}

export const addUnavailableDaysLaboratoriesAppointment = () => {
  return gql`
    ${fragmentLaboratoriesAppointmentFields}
    mutation addUnavailableDays($objects: [unavailable_days_insert_input!]!) {
      insert_unavailable_days(objects: $objects) {
        affected_rows
        returning {
          petshop_appointment {
            ...petshopAppointmentFields
          }
        }
      }
    }
  `
}

export const removeLaboratoriesAppointment = () => {
  return gql`
    ${fragmentLaboratoriesAppointmentFields}

    mutation deletePetshoAppointmentByIds($_in: [Int!] = 10) {
      delete_petshop_appointment(where: { id: { _in: $_in } }) {
        affected_rows
        returning {
          ...petshopAppointmentFields
        }
      }
    }
  `
}

export const removeUnavailableDaysLaboratoriesAppointment = () => {
  return gql`
    mutation deleteUnavailableDays($unavalaible_days_id: [Int!]) {
      delete_unavailable_days(where: { id: { _in: $unavalaible_days_id } }) {
        affected_rows
        returning {
          id
          date
        }
      }
    }
  `
}

export const updateLaboratoriesAppointment = () => {
  return gql`
    ${fragmentLaboratoriesAppointmentFields}

    mutation updateLaboratoriesAppointment(
      $id: Int!
      $day_of_week_id: Int
      $start_time: time
      $end_time: timetz
      $number_of_vacancies: Int
      $personal_assistance: Boolean
      $cost_of_medical_consultation_personal_assistance: numeric
    ) {
      update_petshop_appointment_by_pk(
        pk_columns: { id: $id }
        _set: {
          day_of_week_id: $day_of_week_id
          start_time: $start_time
          end_time: $end_time
          personal_assistance: $personal_assistance
          number_of_vacancies: $number_of_vacancies
          cost_of_medical_consultation_personal_assistance: $cost_of_medical_consultation_personal_assistance
        }
      ) {
        ...petshopAppointmentFields
      }
    }
  `
}

export const fetchLaboratoriesSchedules = () => {
  return gql`
    query getShedulesByLaboratories($petshop_id: Int, $status: String) {
      schedules(
        order_by: {updated_at: desc},
        where: {
          petshop_appointment: { petshop_id: { _eq: $petshop_id } }
          status: { _like: $status }
        }
      ) {
        ...sheduleFields
      }
    }
    ${fragmentSchedules}
  `
}

export const confirmLaboratoriesShedule = () => {
  return gql`
    mutation confirmLaboratoriesShedule(
      $id: Int!
      $status: String = "confirmed"
      $description: String
      $room_id: uuid
    ) {
      update_schedules_by_pk(
        pk_columns: { id: $id }
        _set: { status: $status, description: $description, room_id: $room_id }
      ) {
        ...sheduleFields
      }
    }
    ${fragmentSchedules}
  `
}

export const cancelLaboratoriesShedule = () => {
  return gql`
    mutation cancelLaboratoriesShedule(
      $id: Int!
      $status: String = "cancelled"
      $description: String
    ) {
      update_schedules_by_pk(
        pk_columns: { id: $id }
        _set: { status: $status, description: $description }
      ) {
        ...sheduleFields
      }
    }
    ${fragmentSchedules}
  `
}
