import { gql } from '@apollo/client'
import { fragmentDoctors } from '../doctor'
import { fragmentResumedClinicFields } from '../doctorsWithSpecialties'
import { fragmentUser } from '../users'

export const fetchPaymentsByClinic = () => {
  return gql`
    ${fragmentPayments}
    query paymentsByClinic($clinic_id: Int) {
      payments(
        order_by: {updated_at: desc},
        where: {
          schedules: { doctors_appointment: { clinic_id: { _eq: $clinic_id } } }
        }
      ) {
        ...paymentFields
      }
    }
  `
}

/**
 * Esta função retorna uma query GraphQL para recuperar pagamentos com base em diferentes filtros,
 * incluindo IDs de hospital, clínica, clínica odontológica, laboratório e diagnóstico por imagem.
 *
 * @param {object} filter - Objeto contendo os filtros para a consulta.
 * @returns {string} - Query GraphQL para recuperar pagamentos com base nos filtros fornecidos.
 *
 * Os filtros disponíveis são: hospital_id, clinic_id, dental_clinic_id,
 * medical_imaging_diagnostic_id e laboratory_id.
 */
export const fetchPayments = (filter) => {
  if (filter?.hospital_id) {
    return gql`
      ${fragmentPaymentsMaster}
      query MyQuery($limit: Int, $offset: Int, $hospital_id: Int, $status: String,) {
        payments(limit: $limit, offset: $offset, order_by: {updated_at: desc}, where: {status: { _like: $status }, doctors_appointment: {hospital_id: {_eq: $hospital_id}}}) {
          ...paymentFields
        }
        payments_aggregate(where: {status: {_like: $status}, doctors_appointment: {hospital_id: {_eq: $hospital_id}}}) {
          aggregate {
            count(columns: id)
          }
        }
      }
    `
  }

  if (filter?.clinic_id) {
    return gql`
        ${fragmentPaymentsMaster}
        query MyQuery($limit: Int, $offset: Int, $clinic_id: Int, $status: String) {
          payments(limit: $limit, offset: $offset, order_by: {updated_at: desc}, where: {status: {_like: $status}, doctors_appointment: {clinic_id: {_eq: $clinic_id}}}) {
            ...paymentFields
          }
          payments_aggregate(where: {status: {_like: $status}, doctors_appointment: {clinic_id: {_eq: $clinic_id}}}) {
            aggregate {
              count(columns: id)
            }
          }
        }
      `
  }
  if (filter?.dental_clinic_id) {
    return gql`
      ${fragmentPaymentsMaster}
      query MyQuery($limit: Int, $offset: Int, $dental_clinic_id: Int, $status: String) {
        payments(limit: $limit, offset: $offset, order_by: {updated_at: desc}, where: {status: {_like: $status}, exam: {dental_clinic_id: {_eq: $dental_clinic_id}}}) {
          ...paymentFields
        }
        payments_aggregate(where: {status: {_like: $status},  exam: {dental_clinic_id: {_eq: $dental_clinic_id}}}) {
          aggregate {
            count(columns: id)
          }
        }
      }
    `
  }
  if (filter?.sus?.value) {
    return gql`
      ${fragmentPaymentsMaster}
      query paymentsByStatusAndClinic($status: String, $sus_id: Int) {
        payments(
          order_by: {updated_at: desc},
          where: {
            status: { _like: $status }
            doctors_appointment: { sus_id: { _eq: $sus_id } }
          }
        ) {
          ...paymentFields
        }
      }
    `
  }
  if (filter?.medical_imaging_diagnostic_id) {
    return gql`
      ${fragmentPaymentsMaster}
      query MyQuery($limit: Int, $offset: Int, $medical_imaging_diagnostic_id: Int, $status: String) {
        payments(limit: $limit, offset: $offset, order_by: {updated_at: desc}, where: {status: {_like: $status}, exam: {medical_imaging_diagnostic_id: {_eq: $medical_imaging_diagnostic_id}}}) {
          ...paymentFields
        }
        payments_aggregate(where: {status: {_like: $status},  exam: {medical_imaging_diagnostic_id: {_eq: $medical_imaging_diagnostic_id}}}) {
          aggregate {
            count(columns: id)
          }
        }
      }
    `
  }
  if (filter?.laboratory_id) {
    return gql`
      ${fragmentPaymentsResumed}
      query MyQuery($limit: Int, $offset: Int, $laboratory_id: Int, $status: String) {
        payments(limit: $limit, offset: $offset, order_by: {updated_at: desc}, where: {status: {_like: $status}, exam: {laboratory_id: {_eq: $laboratory_id}}}) {
          ...paymentFields
        }
        payments_aggregate(where: {status: {_like: $status},  exam: {laboratory_id: {_eq: $laboratory_id}}}) {
          aggregate {
            count(columns: id)
          }
        }
      }
    `
  }

  return ''
}

export const checktransfer = () => {
  return gql`
    ${fragmentPayments}
    mutation markTransferToMade($_in: [Int!]) {
      update_payments(
        where: { id: { _in: $_in } }
        _set: { transfer_made: true }
      ) {
        affected_rows
        returning {
          ...paymentFields
        }
      }
    }
  `
}

export const fragmentPaymentsExams = gql`
  fragment paymentFields on payments {
    description
    created_at
    error_description
    id
    method
    status
    updated_at
    value_of_transaction
    user {
      ...userFields
    }
  }
  ${fragmentUser}
`

export const fragmentPaymentsResumed = gql`
  fragment paymentFields on payments {
    description
    created_at
    error_description
    id
    method
    status
    updated_at
    value_of_transaction
    user {
      id
      created_at
      updated_at
      birth_date
      firebase_uid
      full_name
      email
      photo
      cnpj
      cpf
      kind_of_person
      phone
    }
  }
`

export const fragmentPayments = gql`
  fragment paymentFields on payments {
    description
    created_at
    error_description
    id
    method
    status
    updated_at
    value_of_transaction
    doctors_appointment {
      clinic {
        ...resumedClinicFields
      }
      doctor {
        ...doctorFields
      }
      start_time
      end_time
      number_of_vacancies
      personal_assistance
      online_service
      days_of_week {
        abbreviation
        full_text
      }
    }
    user {
      ...userFields
    }
  }
  ${fragmentUser}
  ${fragmentDoctors}
  ${fragmentResumedClinicFields}
`

export const fragmentPaymentsMaster = gql`
  fragment paymentFields on payments {
    description
    created_at
    error_description
    id
    method
    status
    updated_at
    value_of_transaction
    user {
      ...userFields
    }
  }
  ${fragmentUser}
`

export const fragmentPaymentsDiagImg = gql`
  fragment paymentFields on payments {
    description
    created_at
    error_description
    id
    method
    status
    updated_at
    value_of_transaction
    user {
      ...userFields
    }
  }
  ${fragmentUser}
`

export const fragmentPaymentsLaboratories = gql`
  fragment paymentFields on payments {
    description
    created_at
    error_description
    id
    method
    status
    updated_at
    value_of_transaction
    user {
      ...userFields
    }
  }
  ${fragmentUser}
`
