import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  split
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'
import { AuthService } from '../auth/auth'

const client = (token) => {
  const auth = new AuthService()

  const headers = {
    'Hasura-Client-Name': 'hasura-console',
    'Content-Type': 'application/json'
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
    headers: headers
  })

  const wsLink = new GraphQLWsLink(createClient({
    url: process.env.REACT_APP_API_URL_WS,
    lazy: true,
    connectionParams: async () => ({
      headers: headers
    })
  }))

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )

  const verifyToken = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      if (
        response?.errors?.find(
          (err) => err.message === 'Could not verify JWT: JWTExpired'
        ) ||
        response?.errors?.find(
          (err) =>
            err.message === 'Could not verify JWT: JWSError JWSInvalidSignature'
        )
      ) {
        auth.signOut()
      }
      return response
    })
  })

  return new ApolloClient({
    link: verifyToken.concat(splitLink),
    cache: new InMemoryCache()
  })
}

export default client
