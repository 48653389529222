import * as Yup from 'yup'

export const cepValidator = () => {
  return Yup.string('Valor inválido')
    .nullable() // Permitir valores nulos
    .transform((value, originalValue) => originalValue === '' ? null : value) // Transformar valores vazios em nulos
    .test('cepComplete', 'O CEP precisa ser preenchido corretamente!', (value) => {
      return value && value.replace(/[^\d]/g, '').length === 8
    })
}
