import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem;
  width: 95%;
  max-width: 350px;

  h2 {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    margin-bottom: 1rem;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
`
