import { gql } from '@apollo/client'
import { fragmentAddresses } from '../address'
import {
  fragmentDoctorsWithSpecialties,
  fragmentResumedClinicFields
} from '../doctorsWithSpecialties'
import { sheduleDoctorFields } from '../schedules'

export const fetchDoctorsByState = () => {
  return gql`
    query getDoctorsByStateCode($state_code: String) {
      doctors(order_by: {updated_at: desc}, where: { adress: { state_code: { _eq: $state_code } } }) {
        ...doctorFields
      }
    }

    ${fragmentDoctors}
  `
}

/**
 * Retorna uma consulta GraphQL para recuperar informações detalhadas dos médicos, opcionalmente filtradas por nome, cidade e estado.
 *
 * @param {Object} options - Opções para filtrar os resultados da consulta.
 * @param {string} [options.name=null] - Opcional. O nome do médico para filtrar os resultados.
 * @param {string} [options.city=null] - Opcional. O nome da cidade para filtrar os resultados.
 * @param {string} [options.stateCode=null] - Opcional. O código do estado para filtrar os resultados.
 * @returns {string} - Uma consulta GraphQL para recuperar informações detalhadas dos médicos.
 *
 * @example
 * // Exemplo de utilização:
 * fetchDoctors({
 *   name: "%médico%",            // Nome do médico (filtro opcional)
 *   city: "Itajaí",              // Nome da cidade (filtro opcional)
 *   stateCode: "SC",             // Código do estado (filtro opcional)
 *   limit: 10,                   // Limite de médicos a serem retornados (opcional)
 *   offset: 0                    // Deslocamento para paginação (opcional)
 * })
 */
export const fetchDoctors = (data) => {
  let query

  if (!data?.name) {
    if (data?.city && data?.stateCode) {
      query = gql`
        query getDoctors($limit: Int, $offset: Int, $city: String, $state_code: String) {
          doctors(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { address: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            ...doctorFields
          }
          doctors_aggregate(where: { address: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentDoctors}
      `
    } else {
      query = gql`
        query getDoctors($limit: Int, $offset: Int) {
          doctors(limit: $limit, offset: $offset, order_by: { updated_at: desc }) {
            ...doctorFields
          }
          doctors_aggregate {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentDoctors}
      `
    }
  } else {
    if (data?.city !== null && data?.stateCode !== null) {
      query = gql`
        query getDoctors($limit: Int, $offset: Int, $name: String, $city: String, $state_code: String) {
          doctors(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { full_name: { _ilike: $name }, address: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            ...doctorFields
          }
          doctors_aggregate(where: { full_name: { _ilike: $name }, address: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentDoctors}
      `
    } else {
      query = gql`
        query getDoctors($limit: Int, $offset: Int, $name: String) {
          doctors(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { full_name: { _ilike: $name } }) {
            ...doctorFields
          }
          doctors_aggregate(where: { full_name: { _ilike: $name } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentDoctors}
      `
    }
  }
  return query
}

export const fetchClinicsByDoctorId = () => {
  return gql`
  query getClinicsByDoctor($limit: Int, $offset: Int, $doctor_id: Int) {
    clinics(limit: $limit, offset: $offset, order_by: {updated_at: desc}, where: {doctors_working_in_clinics: {doctor_id: {_eq: $doctor_id}}}) {
      ...clinicFields
    }
  }
    ${resumedClinicByDoctor}
  `
}

export const fetchDoctorById = () => {
  return gql`
    query getDoctorByPk($id: Int!) {
      doctors_by_pk(id: $id) {
        ...doctorFields
      }
    }  
    ${fragmentDoctors}
  `
}

export const fragmentDoctors = gql`
  fragment doctorFields on doctors {
    phone_number
    full_name
    average_of_evaluations
    about
    average_cost_of_medical_consultation
    created_at
    crm
    id
    linkedin
    main_specialty
    profile_image
    updated_at
    email
    rg_uf
    rg_number
    sex
    cpf
    birthdate
    receita_digital_id
    receita_digital_infos
    doctors_with_specialties {
      ...doctorsWithSpecialtiesFields
    }
    doctors_working_in_clinics {
      clinic {
        ...resumedClinicFields
      }
    }
    doctors_working_in_sus{
      su{
        name
        email
        phone_number
        adress{
          state_code
          city
          complement
          number
          zipcode
          address
          neighborhood
        }
        receita_digital_id
        receita_digital_infos
      }
    }
    doctors_working_in_hospitals {
      hospital {
        id
        company_name
        photo
        adress {
          ...addressFields
        }
      }
    }
    adress {
      ...addressFields
    }
  }
  ${fragmentAddresses}
  ${fragmentDoctorsWithSpecialties}
  ${fragmentResumedClinicFields}
`

export const fragmentResumedDoctorFields = gql`
  fragment resumedDoctorFields on doctors {
    id
    full_name
    email
    profile_image
  }
`

export const doctorCounts = () => {
  return gql`
  query doctorData($doctor_id: Int) {
    confirmed: schedules_aggregate(where: { 
      _or: [
        { doctors_appointment: { doctor_id: { _eq: $doctor_id } } },
        { doctor_id: { _eq: $doctor_id } }
      ], 
      status: { _ilike: "%confirmed%" } 
    }) {
      aggregate {
        count(columns: created_at)
      }
    }
    requested: schedules_aggregate(where: { 
      _or: [
        { doctors_appointment: { doctor_id: { _eq: $doctor_id } } },
        { doctor_id: { _eq: $doctor_id } }
      ], 
      status: { _eq: "requested" } 
    }) {
      aggregate {
        count(columns: created_at)
      }
    }
    pendingPayment: schedules_aggregate(where: { 
      _or: [
        { doctors_appointment: { doctor_id: { _eq: $doctor_id } } },
        { doctor_id: { _eq: $doctor_id } }
      ], 
      status: { _eq: "pending_payment" } 
    }) {
      aggregate {
        count(columns: created_at)
      }
    }
    canceled: schedules_aggregate(where: { 
      _or: [
        { doctors_appointment: { doctor_id: { _eq: $doctor_id } } },
        { doctor_id: { _eq: $doctor_id } }
      ], 
      status: { _in: ["cancelled", "canceled"] } 
    }) {
      aggregate {
        count(columns: created_at)
      }
    }
    finished: schedules_aggregate(where: { 
      _or: [
        { doctors_appointment: { doctor_id: { _eq: $doctor_id } } },
        { doctor_id: { _eq: $doctor_id } }
      ], 
      status: { _eq: "finished" } 
    }) {
      aggregate {
        count(columns: created_at)
      }
    }
    by_sus: schedules_aggregate(where: { 
      _or: [
        { doctors_appointment: { doctor_id: { _eq: $doctor_id } } },
        { doctor_id: { _eq: $doctor_id } }
      ], 
      sus_id: { _is_null: false } 
    }) {
      aggregate {
        count(columns: created_at)
      }
    }
    by_clinics: schedules_aggregate(where: { 
      _or: [
        { doctors_appointment: { doctor_id: { _eq: $doctor_id } } },
        { doctor_id: { _eq: $doctor_id } }
      ], 
      doctors_appointment: { clinic_id: {_is_null:false} } 
    }) {
      aggregate {
        count(columns: created_at)
      }
    }
    by_hospitals: schedules_aggregate(where: { 
      _or: [
        { doctors_appointment: { doctor_id: { _eq: $doctor_id } } },
        { doctor_id: { _eq: $doctor_id } }
      ], 
      doctors_appointment: { hospital_id: {_is_null:false} } 
    }) {
      aggregate {
        count(columns: created_at)
      }
    }
    pacients: users_aggregate (where: { 
      _or: [
        { schedules:{ doctors_appointment: { doctor_id: { _eq: $doctor_id }} } },
        { schedules:{doctor_id: { _eq: $doctor_id }} }
      ], 
    }) {
      aggregate {
        count(columns: created_at)
      }
    }
  }
  `
}

export const shedulesByDoctorStatusClinicHospital = (data) => {
  console.log(data)
  if (!data.clinic_id && !data.hospital_id) {
    return gql`

    query getShedules(
      $limit: Int,
      $offset: Int,
      $doctor_id: Int,
      $status: String,
    ) {
      schedules(
        limit: $limit,
        offset: $offset,
        order_by: { updated_at: desc },
        where: {
          _or: [
            { doctor_id: { _eq: $doctor_id } },
            { doctors_appointment: { doctor_id: { _eq: $doctor_id } } },
          ],
          status: { _ilike: $status },
        }
      ) {
        ...sheduleFields
      }
    }
    ${sheduleDoctorFields}
    `
  }

  if (data.clinic_id && data.hospital_id) {
    return gql`
    query getShedules(
      $limit: Int,
      $offset: Int,
      $doctor_id: Int,
      $status: String,
      $clinic_id: Int,
      $hospital_id: Int,
    ) {
      schedules(
        limit: $limit,
        offset: $offset,
        order_by: { updated_at: desc },
        where: {
          _or:[
             {doctors_appointment: { doctor_id: { _eq: $doctor_id },clinic_id:{ _eq:$clinic_id }}},
            {doctors_appointment: { doctor_id: { _eq: $doctor_id },hospital_id:{ _eq:$hospital_id }}}
          ] ,
          status: { _ilike: $status },
        }
      ) {
        ...sheduleFields
      }
    }
    ${sheduleDoctorFields}
    `
  }

  if (data.hospital_id) {
    return gql`
    query getShedules(
      $limit: Int,
      $offset: Int,
      $doctor_id: Int,
      $status: String,
      $hospital_id: Int,
    ) {
      schedules(
        limit: $limit,
        offset: $offset,
        order_by: { updated_at: desc },
        where: {
           doctors_appointment: { doctor_id: { _eq: $doctor_id },hospital_id:{ _eq:$hospital_id }} ,
          status: { _ilike: $status },
        }
      ) {
        ...sheduleFields
      }
    }
    ${sheduleDoctorFields}
    `
  }

  if (data.clinic_id) {
    return gql`
    query getShedules(
      $limit: Int,
      $offset: Int,
      $doctor_id: Int,
      $status: String,
      $clinic_id: Int,
    ) {
      schedules(
        limit: $limit,
        offset: $offset,
        order_by: { updated_at: desc },
        where: {
           doctors_appointment: { doctor_id: { _eq: $doctor_id },clinic_id:{ _eq:$clinic_id }} ,
          status: { _ilike: $status },
        }
      ) {
        ...sheduleFields
      }
    }
    ${sheduleDoctorFields}
    `
  }
}

export const getHospitalsByDoctor = () => {
  return gql`
  query getHospitalsByDoctor($limit: Int, $offset: Int, $doctor_id: Int) {
    hospitals(limit: $limit, offset: $offset, order_by: {updated_at: desc}, where: {doctors_working_in_hospitals: {doctor_id: {_eq: $doctor_id}}}) {
      ...hospitalFields
    }
  }
  ${resumedHospitalByDoctor}`
}

export const getHospitalById = () => {
  return gql`
  query getHospitalsById( $id: Int!) {
    hospitals_by_pk(id: $id) {
      ...hospitalFields
    }
  }
  ${resumedHospitalByDoctor}`
}

export const resumedHospitalByDoctor = gql`
fragment addressFields on adresses {
  address
  id
  city
  state
  state_code
  complement
  created_at
  updated_at
  neighborhood
  number
  zipcode
  longitude
  latitude
}

fragment hospitalFields on hospitals {
  photo
  cnpj
  company_name
  created_at
  description
  facebook
  id
  instagram
  linkedin
  phone_number
  photo
  email
  updated_at
  website
  personal_assistance
  online_service
  adress {
    ...addressFields
  }
}
`

export const resumedClinicByDoctor = gql`
fragment addressFields on adresses {
  address
  id
  city
  state
  state_code
  complement
  created_at
  updated_at
  neighborhood
  number
  zipcode
  longitude
  latitude
}

fragment clinicFields on clinics {
  photo
  cnpj
  company_name
  created_at
  description
  facebook
  id
  instagram
  linkedin
  phone_number
  photo
  email
  updated_at
  website
  personal_assistance
  online_service
  adress {
    ...addressFields
  }
}
`

export const getClinicsCountByDoctorId = () => {
  return gql`
query getClinicsTotalCountByDoctor($doctor_id: Int) {
  clinics_aggregate(where: {doctors_working_in_clinics: {doctor_id: {_eq: $doctor_id}}}) {
    aggregate {
      count(columns: id)
    }
  }
} `
}
