import React, { useState, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import { columns, filterUsers } from './columns'
import {
  deletePharmacie,
  fetchPharmaciesByFilter
} from '../../../../services/pharmacies'
import { useModal } from '../../../../contexts/modal'
import { useAlertBox } from '../../../../contexts/alert_box'
import GenerateForm from '../../../components/layout/GenerateForm'
import DataTable from '../../../components/DataTable'
import MainContainer from '../../../components/MainContainer'
import Header from '../../../components/Header'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import * as S from './styled'

function PharmaciePage () {
  const [filter, setFilter] = useState({ state: { value: '%' } })
  const modal = useModal()
  const alertBox = useAlertBox()
  const [mutateDelete] = useMutation(deletePharmacie())
  const history = useHistory()
  const { loading, data, refetch } = useQuery(
    fetchPharmaciesByFilter(),
    {
      variables: {
        limit: 1000000,
        offset: 0,
        state_code: filter?.state?.value,
        city: filter?.city?.label || '%'
      }
    }
  )

  const handleAdd = () => {
    history.push('/pharmacies/new')
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <MainContainer>
      <Header title="Farmácias" labelAdd="Adicionar Farmácia" handleAdd={handleAdd} />

      <Col xs={12}>
        <S.CustomStyle className="list">
          <GenerateForm
            onSubmit={null}
            groupFields={filterUsers(filter, setFilter)}
            button={{
              submit: 'Adicionar médico existente na base de dados',
              submitting: 'Adicionando...'
            }}
          />
        </S.CustomStyle>
      </Col>

      {loading && (<span>Carregando...</span>)}

      {(!loading && data?.pharmacies) && (
        <DataTable
          data={data.pharmacies}
          columns={columns(modal, alertBox, mutateDelete, refetch, history)}
        />
      )}

    </MainContainer>
  )
}

export default PharmaciePage
