// import api from "../api/api";
import { gql } from '@apollo/client'
import { fragmentAddresses } from '../address'
import { fragmentMedicalSpecialty } from '../medicalSpecialty'
import { fragmentSchedules } from '../schedules'
import { fragmentDoctors } from '../doctor'

export const fragmentConsultationRoomUserRequestFields = gql`
  fragment consultationRoomUserRequestFields on consultation_room_user_request {
    schedule{
    id
    created_at
    updated_at
    day
    description
    status
    type_of_medical_appointment
    start_time
    end_time
    room{
    id
    created_at
    updated_at
    start_time
    end_time
    date
    description
    call_id
    }
    }
    user {
      birth_date
      cnpj
      email
      firebase_uid
      full_name
      phone
      photo
      genderByGender {
        value
      }
      sus_card
    }
  }
`

export const fragmentResumedSusFields = gql`
  ${fragmentAddresses}

  fragment resumedSusFields on sus {
    id
    name
    photo
    adress {
      ...addressFields
    }
  }
`

export const fragmentSusMedicalSpecialty = gql`
  ${fragmentMedicalSpecialty}

  fragment susMedicalSpecialty on sus_with_medical_specialty {
    created_at
    id
    medical_specialty_id
    sus_id
    updated_at
    medical_specialty {
      ...medicalSpecialtyFields
    }
  }
`

export const fragmentSusAppointmentFields = gql`
  ${fragmentResumedSusFields}
  ${fragmentSusMedicalSpecialty}

  fragment susAppointmentFields on sus_appointment {
    created_at
    days_of_week {
      id
      abbreviation
      full_text
    }
    end_time
    id
    online_service
    personal_assistance
    start_time
    sus_id
    updated_at
    number_of_vacancies
    unavailable_days {
      date
      id
    }
    su {
      ...resumedSusFields
      sus_with_medical_specialties {
        ...susMedicalSpecialty
      }
    }
  }
`
/**
 * Retorna uma consulta GraphQL para recuperar informações detalhadas dos SUS, opcionalmente filtradas por nome, cidade e estado.
 *
 * @param {Object} options - Opções para filtrar os resultados da consulta.
 * @param {string} [options.name=null] - Opcional. O nome do SUS para filtrar os resultados.
 * @param {string} [options.city=null] - Opcional. O nome da cidade para filtrar os resultados.
 * @param {string} [options.stateCode=null] - Opcional. O código do estado para filtrar os resultados.
 * @returns {string} - Uma consulta GraphQL para recuperar informações detalhadas dos SUS.
 *
 * @example
 * // Exemplo de utilização:
 * fetchSUS({
 *   name: "%sus%",            // Nome do SUS (filtro opcional)
 *   city: "Itajaí",           // Nome da cidade (filtro opcional)
 *   stateCode: "SC",          // Código do estado (filtro opcional)
 *   limit: 10,                // Limite de SUS a serem retornados (opcional)
 *   offset: 0                 // Deslocamento para paginação (opcional)
 * })
 */
export const fetchSUS = (data) => {
  let query

  if (!data?.name) {
    if (data?.city && data?.stateCode) {
      query = gql`
        query getSus($limit: Int, $offset: Int, $city: String, $state_code: String) {
          sus(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            ...susFields
          }
          sus_aggregate(where: { adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentSUSFields}
      `
    } else {
      query = gql`
        query getSus($limit: Int, $offset: Int) {
          sus(limit: $limit, offset: $offset, order_by: { updated_at: desc }) {
            ...susFields
          }
          sus_aggregate {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentSUSFields}
      `
    }
  } else {
    if (data?.city && data?.stateCode) {
      query = gql`
        query getSus($limit: Int, $offset: Int, $name: String, $city: String, $state_code: String) {
          sus(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { name: { _ilike: $name }, adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            ...susFields
          }
          sus_aggregate(where: { name: { _ilike: $name }, adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentSUSFields}
      `
    } else {
      query = gql`
        query getSus($limit: Int, $offset: Int, $name: String) {
          sus(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { name: { _ilike: $name } }) {
            ...susFields
          }
          sus_aggregate(where: { name: { _ilike: $name } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentSUSFields}
      `
    }
  }
  return query
}

export const fetchSUSById = () => {
  return gql`
    query getSusByPk($id: Int!) {
      sus_by_pk(id: $id) {
        ...susFields
      }
    }
    ${fragmentSUSFields}
  `
}

export const fetchSUSSchedules = () => {
  return gql`
    query getShedulesBySus($sus_id: Int, $status: String) {
      schedules(
        order_by: {updated_at: desc},
        where: {
          susAppoitments: { sus_id: { _eq: $sus_id } }
          status: { _like: $status }
        }
      ) {
        ...sheduleFields
      }
    }
    ${fragmentSchedules}
  `
}

export const fetchSUSAppointment = () => {
  return gql`
    query getSusAppointments($sus_id: Int) {
      sus_appointment(order_by: {updated_at: desc}, where: { sus_id: { _eq: $sus_id } }) {
        ...susAppointmentFields
      }
    }
    ${fragmentSusAppointmentFields}
  `
}

export const fetchMySUSList = () => {
  return gql`
    fragment susMedicalSpecialty on sus_with_medical_specialty {
      created_at
      id
      medical_specialty_id
      sus_id
      updated_at
      medical_specialty {
        ...medicalSpecialtyFields
      }
    }
    fragment medicalSpecialtyFields on medical_specialties {
      icon
      id
      name
      updated_at
      description
      created_at
    }
    fragment addressFields on adresses {
      address
      id
      city
      state
      state_code
      complement
      created_at
      updated_at
      neighborhood
      number
      zipcode
      longitude
      latitude
    }
    fragment susFields on sus {
      adress {
        ...addressFields
      }
      created_at
      email
      id
      name
      online_service
      personal_assistance
      phone_number
      updated_at
      photo
      website
      sus_with_medical_specialties {
        ...susMedicalSpecialty
      }
    }

    query getMySusList($limit: Int, $offset: Int, $doctor_id: Int) {
      sus(limit: $limit, offset: $offset, where: {doctors_working_in_sus: {doctor_id: {_eq: $doctor_id}}}) {
        ...susFields
      }
    }
  `
}

export const updateSUS = () => {
  return gql`
    ${fragmentSUSFields}
    mutation updateSusByPk(
      $id: Int!
      $email: String
      $name: String
      $online_service: Boolean
      $personal_assistance: Boolean
      $phone_number: String
      $website: String
      $photo: String
    ) {
      update_sus_by_pk(
        pk_columns: { id: $id }
        _set: {
          email: $email
          name: $name
          online_service: $online_service
          personal_assistance: $personal_assistance
          phone_number: $phone_number
          website: $website
          photo: $photo
        }
      ) {
        ...susFields
      }
    }
  `
}

export const createSUS = () => {
  return gql`
    ${fragmentSUSFields}
    mutation insertSusOne(
      $address: String
      $city: String
      $complement: String
      $latitude: numeric
      $longitude: numeric
      $neighborhood: String
      $number: String
      $state: String
      $state_code: String
      $zipcode: String
      $email: String
      $name: String
      $online_service: Boolean
      $personal_assistance: Boolean
      $phone_number: String
      $website: String
      $photo: String
    ) {
      insert_sus_one(
        object: {
          adress: {
            data: {
              address: $address
              city: $city
              complement: $complement
              latitude: $latitude
              longitude: $longitude
              neighborhood: $neighborhood
              number: $number
              state: $state
              state_code: $state_code
              zipcode: $zipcode
            }
          }
          email: $email
          name: $name
          online_service: $online_service
          personal_assistance: $personal_assistance
          phone_number: $phone_number
          website: $website
          photo: $photo
        }
      ) {
        ...susFields
      }
    }
  `
}

export const removeSUSSpecialties = () => {
  return gql`
    ${fragmentSUSFields}
    mutation removeMedicalSpecialtiesFromSus($_in: [Int!]) {
      delete_sus_with_medical_specialty(where: { id: { _in: $_in } }) {
        affected_rows
        returning {
          su {
            ...susFields
          }
        }
      }
    }
  `
}

export const addSUSSpecialties = () => {
  return gql`
    ${fragmentSUSFields}
    mutation addMedicalSpecialtiesToSus(
      $objects: [sus_with_medical_specialty_insert_input!]!
    ) {
      insert_sus_with_medical_specialty(objects: $objects) {
        affected_rows
        returning {
          su {
            ...susFields
          }
        }
      }
    }
  `
}

export const createSusAppointment = () => {
  return gql`
    ${fragmentSusAppointmentFields}

    mutation insertSusAppointments($objects: [sus_appointment_insert_input!]!) {
      insert_sus_appointment(objects: $objects) {
        affected_rows
        returning {
          ...susAppointmentFields
        }
      }
    }
  `
}

export const removeSusAppointment = () => {
  return gql`
    ${fragmentSusAppointmentFields}

    mutation deleteSusAppointmentByIds($_in: [Int!] = 10) {
      delete_sus_appointment(where: { id: { _in: $_in } }) {
        affected_rows
        returning {
          ...susAppointmentFields
        }
      }
    }
  `
}

export const updateSusAppointment = () => {
  return gql`
    ${fragmentSusAppointmentFields}

    mutation updateSusAppointment(
      $id: Int!
      $day_of_week_id: Int
      $end_time: timetz
      $start_time: timetz
      $personal_assistance: Boolean
      $online_service: Boolean
      $number_of_vacancies: Int
    ) {
      update_sus_appointment_by_pk(
        pk_columns: { id: $id }
        _set: {
          day_of_week_id: $day_of_week_id
          end_time: $end_time
          start_time: $start_time
          personal_assistance: $personal_assistance
          online_service: $online_service
          number_of_vacancies: $number_of_vacancies
        }
      ) {
        ...susAppointmentFields
      }
    }
  `
}

export const fragmentSUSFields = gql`
  ${fragmentAddresses}
  ${fragmentSusMedicalSpecialty}

  fragment susFields on sus {
    adress {
      ...addressFields
    }
    created_at
    email
    id
    name
    online_service
    personal_assistance
    phone_number
    updated_at
    photo
    website
    sus_with_medical_specialties {
      ...susMedicalSpecialty
    }
  }
`

export const addUnavailableDaysSusAppointment = () => {
  return gql`
    ${fragmentSusAppointmentFields}
    mutation addUnavailableDays($objects: [unavailable_days_insert_input!]!) {
      insert_unavailable_days(objects: $objects) {
        affected_rows
        returning {
          sus_appointment {
            ...susAppointmentFields
          }
        }
      }
    }
  `
}

export const fragmentRoom = gql`
  fragment roomFields on rooms {
    id
    created_at
    updated_at
    start_time
    end_time
    date
    description
    call_id
  }
`

export const createRoom = () => {
  return gql`
    mutation createRoom(
      $date: timestamptz
      $description: String
      $start_time: timetz
      $end_time: timetz
    ) {
      insert_rooms_one(
        object: {
          date: $date
          description: $description
          start_time: $start_time
          end_time: $end_time
        }
      ) {
        ...roomFields
      }
    }
    ${fragmentRoom}
  `
}

export const confirmSusShedule = () => {
  return gql`
    mutation confirmSusShedule(
      $id: Int!
      $status: String = "confirmed"
      $description: String
      $room_id: uuid
    ) {
      update_schedules_by_pk(
        pk_columns: { id: $id }
        _set: { status: $status, description: $description, room_id: $room_id }
      ) {
        ...sheduleFields
      }
    }
    ${fragmentSchedules}
  `
}

export const cancelSusShedule = () => {
  return gql`
    mutation cancelSusShedule(
      $id: Int!
      $status: String = "cancelled"
      $description: String
    ) {
      update_schedules_by_pk(
        pk_columns: { id: $id }
        _set: { status: $status, description: $description }
      ) {
        ...sheduleFields
      }
    }
    ${fragmentSchedules}
  `
}

export const removeUnavailableDaysSUSAppointment = () => {
  return gql`
    mutation deleteUnavailableDays($unavalaible_days_id: [Int!]) {
      delete_unavailable_days(where: { id: { _in: $unavalaible_days_id } }) {
        affected_rows
        returning {
          sus_appoitment_id
          id
          doctors_appointment_id
          date
        }
      }
    }
  `
}

export const fetchdDoctorsInSus = () => {
  return gql` 
    query doctorsWorkingInSus($id: Int) {
      doctors_working_in_sus(order_by: {updated_at: desc}, where: {sus_id: {_eq: $id}}) {
        doctor {
          ...doctorFields
        }
      }
    }
    ${fragmentDoctors}
  `
}

export const fetchSusWaitingRooms = () => {
  return gql`
    ${fragmentMedicalSpecialty}
    
    subscription getConsultationsRoom($sus_id: Int, $yesterday: timestamptz, $tomorrow: timestamptz, $offset: Int, $limit: Int) {
      consultation_room(where: {sus_id: {_eq: $sus_id}, created_at: {_gte: $yesterday, _lt: $tomorrow}}, order_by: {updated_at: desc}, limit: $limit, offset: $offset) {
        available_vacancies
        id
        end_time
        created_at
        sus_id
        updated_at
        tolerance_to_enter_the_room_in_minutes
        consultation_room_medical_specialties {
        id
          medical_specialty {
            ...medicalSpecialtyFields
          }
        }
        consultation_room_user_requests_aggregate {
          aggregate {
            count(columns: consultation_room_id)
          }
        }
      }
    }
  `
}

export const fetchSusWaitingRoomById = () => {
  return gql`
    ${fragmentMedicalSpecialty}
    query getConsultationsRoom($id: Int) {
      consultation_room(where: {id: {_eq: $id}}) {
        available_vacancies
        id
        end_time
        created_at
        sus_id
        updated_at
        tolerance_to_enter_the_room_in_minutes
        consultation_room_medical_specialties {
          id
          medical_specialty {
            ...medicalSpecialtyFields
          }
        }
        consultation_room_user_requests_aggregate {
          aggregate {
            count(columns: consultation_room_id)
          }
        }
   }
}
`
}

export const fetchSusWaitingRoomUsers = () => {
  return gql`
    subscription requestsByRoom($offset: Int, $limit: Int, $consultation_room_id: Int) {
      consultation_room_user_request(offset: $offset, limit: $limit, where: {consultation_room_id: {_eq: $consultation_room_id}, shedule_id: {_is_null: true}}, order_by: {created_at: asc}) {
        consultation_room_id
        created_at
        id
        patient_symptom_record {
          created_at
          description
          id
          updated_at
        }
        updated_at
        shedule_id
        user {
          birth_date
          address
          cpf
          created_at
          email
          full_name
          phone
          photo
          firebase_uid
          genderByGender {
            id
            value
          }
          kind_of_person
        }
      }
    }
  `
}

export const createSusWaitingRoom = () => {
  return gql`
    ${fragmentMedicalSpecialty}
    mutation insertConsutationRoom($available_vacancies: Int, $sus_id: Int, $end_time: timetz, $medical_specialties: [consultation_room_medical_specialty_insert_input!]!, $tolerance_to_enter_the_room_in_minutes: numeric) {
      insert_consultation_room_one(object: {available_vacancies: $available_vacancies, sus_id: $sus_id, end_time: $end_time, consultation_room_medical_specialties: {data: $medical_specialties}, tolerance_to_enter_the_room_in_minutes: $tolerance_to_enter_the_room_in_minutes}) {
        available_vacancies
        created_at
        end_time
        id
        sus_id
        updated_at
        tolerance_to_enter_the_room_in_minutes
        consultation_room_medical_specialties {
          medical_specialty {
            ...medicalSpecialtyFields
          }
        }
      }
    }
  `
}

export const updateConsutationRoom = () => {
  return gql`
  ${fragmentMedicalSpecialty}
  mutation updateConsutationRoom($available_vacancies: Int, $end_time: timetz,  $tolerance_to_enter_the_room_in_minutes: numeric, $id: Int!) {
    update_consultation_room_by_pk(_set: {available_vacancies: $available_vacancies, end_time: $end_time,  tolerance_to_enter_the_room_in_minutes: $tolerance_to_enter_the_room_in_minutes}, pk_columns: {id: $id}) {
      available_vacancies
      created_at
      end_time
      id
      sus_id
      updated_at
      tolerance_to_enter_the_room_in_minutes
      consultation_room_medical_specialties {
        id
        medical_specialty {
          ...medicalSpecialtyFields
        }
      }
    }
  }
`
}

export const insertMedicalSpecialty = () => {
  return gql`
  ${fragmentMedicalSpecialty}
  mutation addMecialSpecialty($consultation_room_id: Int, $medical_specialty_id: Int) {
    insert_consultation_room_medical_specialty_one(object: {consultation_room_id: $consultation_room_id, medical_specialty_id: $medical_specialty_id}) {
      consultation_room{
        available_vacancies
      created_at
      end_time
      id
      sus_id
      updated_at
      tolerance_to_enter_the_room_in_minutes
      consultation_room_medical_specialties {
        id
        medical_specialty {
          ...medicalSpecialtyFields
        }
      }
      }
    }
  }
  `
}

export const removeMedicalSpecialty = () => {
  return gql`
  ${fragmentMedicalSpecialty}
  mutation removeMediacalSpecialty($id: Int!) {
    delete_consultation_room_medical_specialty_by_pk(id: $id) {
      consultation_room {
        available_vacancies
        created_at
        end_time
        id
        sus_id
        updated_at
        tolerance_to_enter_the_room_in_minutes
        consultation_room_medical_specialties {
          id
          medical_specialty {
            ...medicalSpecialtyFields
          }
        }
      }
    }
  }
  `
}

export const removeSusWaitingRoom = () => {
  return gql`
    mutation deleteConsultationRoom($id: Int!) {
      delete_consultation_room_by_pk(id: $id) {
        created_at
      }
    }
  `
}

/* mutation deleteSusByPk($id: Int!) {
  delete_sus_by_pk(id: $id) {
    created_at
  }
} */

export const inserDoctorInSus = () => {
  return gql`
    mutation addDoctorToSus($doctor_id: Int!, $sus_id: Int!) {
      insert_doctors_working_in_sus_one(object: {doctor_id: $doctor_id, sus_id: $sus_id}) {
        created_at
        doctor_id
        id
        sus_id
        updated_at
        su{
          ...susFields
        }
        
      }
    }
    ${fragmentSUSFields}
  `
}
