import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { CounterBody, CounterContainer, CounterHeader } from './styled'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { useQuery } from '@apollo/client'
import { fetchSchedulesBySUS } from '../../../services/schedules'
import ScheduleIcon from '@mui/icons-material/Schedule'
import MoneyIcon from '@mui/icons-material/Money'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'
import { fecthRequestsCreditBySUS } from '../../../services/creditRequest'
import Header from '../../components/Header'
import * as S from './styled'
import DashboardBox from '../../components/DashboardBox'

function DashboardSus ({ data }) {
  const [countUser, setCountUser] = useState(0)
  const schedulesByClinic = useQuery(fetchSchedulesBySUS(), {
    variables: {
      status: '%',
      limit: 10000000,
      offset: 0,
      sus_id: data.id
    }
  })
  const requestCredit = useQuery(fecthRequestsCreditBySUS(), {
    variables: {
      limit: 10000000,
      offset: 0,
      clinic_id: data.id
    }
  })

  useEffect(() => {
    const list = []
    if (schedulesByClinic?.data?.schedules?.length) {
      schedulesByClinic.data.schedules.forEach((item) => {
        if (!list.find((el) => el.id === item.user.id)) {
          list.push(item.user)
        }
      })
      setCountUser(list.length)
    }
  }, [schedulesByClinic?.data?.schedules])

  return (
    <S.Container>
      <Header title="Dashboard" />

      <S.Grid>
        <DashboardBox
          title="Usuários cadastrados"
          number={countUser}
          color="#00ff00"
          icon={<PersonOutlineIcon />}
        />

        <DashboardBox
          title="Quantidade de consultas"
          number={schedulesByClinic?.data?.schedules?.length || 0}
          color="#12005e"
          icon={<PersonOutlineIcon />}
        />

        <DashboardBox
          title="Valor Crédito solicitado"
          number={requestCredit?.data?.credit_requests?.length || 0}
          color="#006db3"
          icon={<CurrencyExchangeIcon />}
        />

        <DashboardBox
          title="Valor Credito Liberado"
          number={requestCredit?.data?.credit_requests?.filter(
            (item) => item.accepted
          )?.length || 0}
          color="#c43e00"
          icon={<MoneyIcon />}
        />
      </S.Grid>
    </S.Container>
  )
}
export default DashboardSus
