import styled from 'styled-components'

export const CustomStyle = styled.div`
  width: 100%;
  .button-submit-generate-form {
    position: absolute;
    max-width: 100px;
    top: 9px;
    right: 60%;
  }
  .filter {
    position: relative;
  }
`
