import styled from 'styled-components'

export const Avatar = styled.img`
  aspect-ratio: 1/1;
  max-width: 24px;
  border-radius: 50%;
  object-fit: cover;
`
export const CustomLayout = styled.div`
  .button-submit-generate-form {
    bottom: 15px;
    max-width: 200px;
    right: 15px;
  }
  .MuiCardContent-root {
    position: relative;
  }
  .plan-container {
    height: 600px;
    padding-bottom: 150px;
  }
`
