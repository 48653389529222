import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import * as Yup from 'yup'
import { Col, Row } from 'react-bootstrap'
import InternalBody from '../../../components/InternalBody'
import Loading from '../../../components/layout/Loading'
import { fetchDoctorById } from '../../../../services/doctor'
import { fetchClinicsFull } from '../../../../services/clinics'
import GenerateForm from '../../../components/layout/GenerateForm'
import { useAlertBox } from '../../../../contexts/alert_box'
import { useModal } from '../../../../contexts/modal'
import ButtonPrimary from '../../../components/Button'
import { customTheme } from '../../../../styles/theme'
import { columns } from './columns'
import CardModal from '../../../components/CardModal'
import {
  addUnavailableDaysDocAppointment,
  createDoctorsAppointment,
  fetchDoctorAppointmentsFull,
  removeDoctorsAppointment,
  removeUnavailableDaysDocAppointment,
  updateDoctorsAppointment
} from '../../../../services/schedules'
import {
  createScheduleAvailableUseCase,
  deleteScheduleAvailableUseCase,
  updateScheduleAvailableUseCase
} from '../../../../main/usecases/schedules'
import {
  currentWeek,
  getIdDay,
  isAvailableDocSchedule,
  translateCalendar,
  verifyEventIsAvailable,
  weekDays
} from '../../../../Utils/dates'
import {
  CalendarContainer,
  EditModalContent,
  FilterContainer,
  WeekDays
} from './styled'
import { fetchHospitals } from '../../../../services/hospitals'
import AppointmentForm from './appointment-form'
import { useLoading } from '../../../../contexts/loading'
import { useAuth } from '../../../../contexts/authenticator'
require('react-big-calendar/lib/css/react-big-calendar.css')
const localizer = momentLocalizer(moment)

function InternalSchedulesDoctors ({ match }) {
  const [perPage, setPerPage] = useState(5)
  const { id } = useAuth()
  let docId = id

  if (match.params.id && match.params.clinic_id) {
    docId = match.params.id
  }

  const [clinicSchedulesAvailable, setClinicSchedulesAvailable] =
    useState(null)
  const [doctorSchedulesAvailable, setDoctorSchedulesAvailable] = useState([])
  const [createdSchedule, setCreatedSchedule] = useState(null)
  const [clinicId, setClinicId] = useState(null)
  const [unavailableDays, setUnavailableDays] = useState([])
  const [filter, setFilter] = useState([])

  const modal = useModal()
  const alertBox = useAlertBox()
  const { setActive } = useLoading()

  const docAvailableColor = customTheme.colors.primary

  const fetchClinics = useQuery(fetchClinicsFull({}))
  const getHospitals = useQuery(fetchHospitals({}))
  const fetchDoctor = useQuery(fetchDoctorById(), {
    variables: { id: docId }
  })
  const fetchDoctorAppointments = useQuery(fetchDoctorAppointmentsFull(), {
    variables: {
      doctor_id: docId
    }
  })

  const [createDoctorsAppointmentMutation] = useMutation(
    createDoctorsAppointment()
  )

  const mutationCreateDoctorAppointment = async (data) => {
    return await createScheduleAvailableUseCase(
      createDoctorsAppointmentMutation,
      data,
      { alertBox }
    )
  }

  const [removeDoctorsAppointmentMutation] = useMutation(
    removeDoctorsAppointment()
  )

  const mutationRemoveDoctorAppointment = async (data) => {
    return await deleteScheduleAvailableUseCase(
      removeDoctorsAppointmentMutation,
      data,
      { alertBox }
    )
  }

  const [updateDoctorsAppointmentMutation] = useMutation(
    updateDoctorsAppointment()
  )

  const mutationUpdateDoctorAppointment = async (data) => {
    return await updateScheduleAvailableUseCase(
      updateDoctorsAppointmentMutation,
      data,
      { alertBox }
    )
  }

  const [addUnavailableDaysDocAppointmentMutation] = useMutation(
    addUnavailableDaysDocAppointment()
  )

  const mutationAddUnavailableDaysDocAppointment = async (data) => {
    return await createScheduleAvailableUseCase(
      addUnavailableDaysDocAppointmentMutation,
      data,
      { alertBox }
    )
  }

  const [removeUnavailableDaysDocAppointmentMutation] = useMutation(
    removeUnavailableDaysDocAppointment()
  )

  const mutationRemoveUnavailableDaysDocAppointment = async (data) => {
    return await deleteScheduleAvailableUseCase(
      removeUnavailableDaysDocAppointmentMutation,
      data,
      { alertBox }
    )
  }

  const unavailableForm = (id) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '400px' }}>
          <GenerateForm
            onSubmit={async (e) => {
              setActive(true)
              await mutationAddUnavailableDaysDocAppointment({
                objects: [
                  {
                    doctors_appointment_id: id,
                    date: e?.date
                  }
                ]
              })
              modal.setOptions({ open: false })
              setActive(false)
            }}
            groupFields={[
              {
                name: 'Adicionar horário indisponível',
                fields: [
                  {
                    label: 'Data:',
                    type: 'date',
                    required: true,
                    disabled: false,
                    hideField: false,
                    name: 'date',
                    columns: { xs: 12 },
                    validation: Yup.string().required('Campo obrigatório')
                  }
                ]
              }
            ]}
            button={{
              submit: 'Adicionar',
              submitting: 'Adicionando'
            }}
          />
        </CardModal>
      )
    })
  }

  const handleCreateDoctorAppointment = async (
    e,
    createdSchedule,
    schedule
  ) => {
    try {
      setActive(true)

      const scheduleType =
        clinicId.type === 'clinic' ? 'clinic_id' : 'hospital_id'

      const resp = await mutationCreateDoctorAppointment({
        objects: [
          {
            ...e,
            [scheduleType]: clinicId?.id,
            doctor_id: Number(docId),
            day_of_week_id: createdSchedule.day_of_week_id,
            online_service: !!e.online_service,
            personal_assistance: !!e.personal_assistance
          }
        ]
      })

      await setDoctorSchedulesAvailable((prev) => [
        ...prev,
        {
          id: resp?.data?.insert_doctors_appointment?.returning[0].id,
          start: schedule.start,
          end: schedule.end,
          title: 'Disponível',
          color: docAvailableColor,
          full_data: resp?.data?.insert_doctors_appointment?.returning[0]
        }
      ])

      setCreatedSchedule(null)
      modal.setOptions({ open: false })
      setActive(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleUpdateDoctorAppointment = async (e, event) => {
    try {
      setActive(true)

      const resp = await mutationUpdateDoctorAppointment({
        id: e.id,
        number_of_vacancies: event?.number_of_vacancies,
        cost_of_medical_consultation_personal_assistance:
          event.cost_of_medical_consultation_personal_assistance,
        cost_of_medical_consultation_online_service:
          event?.cost_of_medical_consultation_online_service,
        start_time: event?.start_time,
        end_time: event?.end_time,
        online_service: !!event.online_service,
        personal_assistance: !!event.personal_assistance,
        day_of_week_id: getIdDay(e?.full_data?.days_of_week?.full_text)
      })

      const currentDay = moment(
        currentWeek()[
          getIdDay(
            resp?.data?.update_doctors_appointment_by_pk?.days_of_week
              ?.full_text
          ) - 1
        ]
      ).format('YYYY, M, D ')

      setDoctorSchedulesAvailable((prev) => prev.filter((i) => i.id !== e.id))
      setDoctorSchedulesAvailable((prev) => [
        ...prev,
        {
          id: resp?.data?.update_doctors_appointment_by_pk?.id,
          title: 'Disponível',
          color: docAvailableColor,
          start: new Date(
            currentDay +
              moment(
                `11/11/1111 ${resp?.data?.update_doctors_appointment_by_pk.start_time.replace(
                  '+00',
                  ''
                )}`
              ).format('HH:mm:ss')
          ),
          end: new Date(
            currentDay +
              moment(
                `11/11/1111 ${resp?.data?.update_doctors_appointment_by_pk.end_time.replace(
                  '+00',
                  ''
                )}`
              ).format('HH:mm:ss')
          ),
          full_data: resp?.data?.update_doctors_appointment_by_pk
        }
      ])
      modal.setOptions({
        open: false
      })
      setActive(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleRemoveDoctorAppointment = async (id) => {
    try {
      setActive(true)
      await mutationRemoveDoctorAppointment({ id: id })
      setDoctorSchedulesAvailable((prev) => prev.filter((i) => i.id !== id))
      modal.setOptions({ open: false })
      setActive(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleSelectClinic = (e, actions) => {
    setClinicId(e?.entity?.value)

    fetchDoctorAppointments.refetch()
    const evsClinic = []
    const evsDoctor = []

    const fieldType =
      e?.entity?.value?.type === 'clinic'
        ? 'clinic_hours_and_days_availables'
        : 'hospital_hours_and_days_availables'

    e?.entity?.value[fieldType].forEach((item) => {
      const currentDayCli = moment(
        currentWeek()[Number(item?.days_of_week?.id) - 1]
      ).format('YYYY, M, D ')

      evsClinic.push({
        id: 'clinic_' + item?.id,
        title: 'Disponível',
        color: '#b3b3b3',
        start: new Date(
          currentDayCli +
            moment(`11/11/1111 ${item.start_time.replace('+00', '')}`).format(
              'HH:mm:ss'
            )
        ),
        end: new Date(
          currentDayCli +
            moment(`11/11/1111 ${item.end_time.replace('+00', '')}`).format(
              'HH:mm:ss'
            )
        )
      })
    })

    if (e?.entity?.value?.type === 'clinic') {
      fetchDoctorAppointments?.data?.doctors_appointment
        .filter((i) => i.clinic_id === e?.entity?.value?.id)
        .forEach((item) => {
          const currentDay = moment(
            currentWeek()[getIdDay(item?.days_of_week?.full_text) - 1]
          ).format('YYYY, M, D ')

          evsDoctor.push({
            id: item?.id,
            title: 'Disponível',
            color: docAvailableColor,
            start: new Date(
              currentDay +
                moment(
                  `11/11/1111 ${item.start_time.replace('+00', '')}`
                ).format('HH:mm:ss')
            ),
            end: new Date(
              currentDay +
                moment(`11/11/1111 ${item.end_time.replace('+00', '')}`).format(
                  'HH:mm:ss'
                )
            ),
            full_data: item
          })
        })
    } else {
      fetchDoctorAppointments?.data?.doctors_appointment
        .filter((i) => i.hospital_id === e?.entity?.value?.id)
        .forEach((item) => {
          const currentDay = moment(
            currentWeek()[getIdDay(item?.days_of_week?.full_text) - 1]
          ).format('YYYY, M, D ')

          evsDoctor.push({
            id: item?.id,
            title: 'Disponível',
            color: docAvailableColor,
            start: new Date(
              currentDay +
                moment(
                  `11/11/1111 ${item.start_time.replace('+00', '')}`
                ).format('HH:mm:ss')
            ),
            end: new Date(
              currentDay +
                moment(`11/11/1111 ${item.end_time.replace('+00', '')}`).format(
                  'HH:mm:ss'
                )
            ),
            full_data: item
          })
        })
    }

    setClinicSchedulesAvailable(evsClinic)
    setDoctorSchedulesAvailable(evsDoctor)
    if (actions) actions.setSubmitting(false)
  }

  const handleSelectTime = ({ start, end }) => {
    if (!createdSchedule) {
      let clinicAvailable = true
      clinicSchedulesAvailable.forEach((event) => {
        if (clinicAvailable && isAvailableDocSchedule(start, end, event)) {
          clinicAvailable = false
        }
      })

      let doctorAvailable = true
      doctorSchedulesAvailable.forEach((value) => {
        if (doctorAvailable && verifyEventIsAvailable(start, end, value)) {
          doctorAvailable = false
        }
      })

      if (!createdSchedule && !clinicAvailable && doctorAvailable) {
        const createdScheduleTemp = {
          day_of_week_id: Number(moment(start).format('d')) + 1,
          start_time: moment(start).format('HH:mm:ss'),
          end_time: moment(end).format('HH:mm:ss')
        }

        setCreatedSchedule(createdScheduleTemp)
        const schedule = { start, end }

        modal.setOptions({
          open: true,
          component: (
            <CardModal style={{ width: '50%' }}>
              <AppointmentForm
                type="create"
                values={createdScheduleTemp}
                submitEvent={handleCreateDoctorAppointment}
                button={{
                  submit: 'Adicionar',
                  submitting: 'Adicionando'
                }}
                complements={[createdScheduleTemp, schedule]}
                closeEvent={() => {
                  modal.setOptions({ open: false })
                  setCreatedSchedule(null)
                }}
              />
            </CardModal>
          )
        })
      }
    }
  }

  useEffect(() => {
    const unvailableDaysTemp = []
    fetchDoctorAppointments?.data?.doctors_appointment
      ?.filter(
        (i) => i.clinic_id === clinicId?.id || i.hospital_id === clinicId?.id
      )
      .forEach((item) => {
        item?.unavailable_days.forEach((i) => {
          unvailableDaysTemp.push(i)
        })
      })
    setUnavailableDays(unvailableDaysTemp)
    if (fetchDoctor?.data?.doctors_by_pk) {
      let listFilter = []
      const actualDoctor = fetchDoctor?.data?.doctors_by_pk

      fetchClinics?.data?.clinics.forEach((i) => {
        if (
          actualDoctor?.doctors_working_in_clinics.find(
            (int) => int.clinic.id === i.id
          )
        ) {
          listFilter.push({
            ...i,
            label: i.company_name,
            type: 'clinic'
          })
        }
      })

      getHospitals?.data?.hospitals.forEach((i) => {
        if (
          actualDoctor?.doctors_working_in_hospitals.find(
            (int) => int.hospital.id === i.id
          )
        ) {
          listFilter.push({
            ...i,
            label: i.company_name,
            type: 'hospital'
          })
        }
      })

      if (match.params.id && match.params.clinic_id) {
        const result = listFilter.find(obj => obj.id === match.params.clinic_id)
        listFilter = result ? [result] : []
      }

      setFilter(listFilter)
    }
  }, [
    fetchDoctor?.data?.doctors_by_pk,
    fetchClinics?.data,
    getHospitals?.data,
    fetchDoctorAppointments?.data,
    clinicId
  ])

  if (
    fetchDoctor?.loading ||
    fetchDoctorAppointments?.loading ||
    fetchClinics?.loading
  ) { return <Loading /> }

  return (
    <InternalBody>
      <Row>
        <Col xs={12}>
          <h2>Horários disponíveis para agendamento (Médicos)</h2>
        </Col>
        <Col xs={4}>
          <FilterContainer>
            {!!filter?.length && (
              <GenerateForm
                onSubmit={handleSelectClinic}
                groupFields={[
                  {
                    fields: [
                      {
                        label: 'Selecione a entidade:',
                        type: 'autocomplete',
                        required: true,
                        hideField: false,
                        name: 'entity',
                        options: filter.map((item) => ({
                          label: item.label,
                          value: item
                        })),
                        columns: { xs: 12 }
                      }
                    ]
                  }
                ]}
                button={{ submit: 'Selecionar', submitting: 'Selecionar' }}
              />
            )}
          </FilterContainer>
        </Col>
        {!!clinicSchedulesAvailable && !!clinicSchedulesAvailable.length ? (
          <Col xs={12}>
            <CalendarContainer>
              <WeekDays>
                <div>Hora</div>
                {weekDays.map((item) => (
                  <div key={item.value}>{item.label}</div>
                ))}
              </WeekDays>
              <Calendar
                selectable
                localizer={localizer}
                events={[
                  ...clinicSchedulesAvailable,
                  ...doctorSchedulesAvailable
                ]}
                // style={{ height: 2000, paddingBottoms: 200 }}
                defaultView={Views.WEEK}
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                onSelectEvent={(e) => {
                  if (
                    moment(e.end).diff(e.start) >= 1799000 &&
                    moment(e.end).diff(e.start) <= 1800000 &&
                    e.color !== docAvailableColor
                  ) {
                    handleSelectTime({ start: e.start, end: e.end })
                  } else if (e.color === docAvailableColor) {
                    modal.setOptions({
                      open: true,
                      component: (
                        <CardModal style={{ width: '50%' }}>
                          <EditModalContent>
                            <ButtonPrimary
                              onClick={() => unavailableForm(e?.id)}
                              style={{
                                boxShadow: 'none',
                                fontSize: '12px',
                                padding: '5px 15px',
                                backgroundColor: customTheme.colors.primary,
                                lineHeight: '2',
                                display: 'flex',
                                width: 'fit-content',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '15px',
                                position: 'absolute',
                                right: 16
                              }}
                            >
                              Adicionar data indisponível
                              <AddCircleIcon
                                style={{
                                  color: customTheme.colors.white,
                                  marginLeft: 5
                                }}
                              />
                            </ButtonPrimary>

                            <AppointmentForm
                              type="update"
                              values={e.full_data}
                              submitEvent={handleUpdateDoctorAppointment}
                              button={{
                                submit: 'Salvar',
                                submitting: 'Salvando'
                              }}
                              complements={[e]}
                              closeEvent={() => {
                                modal.setOptions({ open: false })
                                setCreatedSchedule(null)
                              }}
                            />

                            <div style={{ display: 'flex', marginTop: 30 }}>
                              <ButtonPrimary
                                id="delete-button"
                                onClick={() => {
                                  document.getElementById(
                                    'delete-button'
                                  ).textContent = 'Apagando'
                                  handleRemoveDoctorAppointment(e.id)
                                }}
                                style={{
                                  backgroundColor: customTheme.colors.secondary,
                                  boxShadow: 'none',
                                  marginRight: 10
                                }}
                              >
                                Apagar
                              </ButtonPrimary>
                              <ButtonPrimary
                                onClick={() => {
                                  modal.setOptions({ open: false })
                                  setCreatedSchedule(null)
                                }}
                                style={{
                                  backgroundColor: customTheme.colors.error,
                                  boxShadow: 'none',
                                  marginLeft: 10
                                }}
                              >
                                Fechar
                              </ButtonPrimary>
                            </div>
                          </EditModalContent>
                        </CardModal>
                      )
                    })
                  }
                }}
                onSelectSlot={handleSelectTime}
                messages={translateCalendar}
                eventPropGetter={(event) => {
                  return {
                    style: {
                      fontSize: 12,
                      backgroundColor: event?.color,
                      border: 'none'
                    }
                  }
                }}
              />
            </CalendarContainer>
          </Col>
        ) : (
          clinicSchedulesAvailable !== null && <h5>Sem horários disponíveis</h5>
        )}
      </Row>
      {!!unavailableDays.length && (
        <>
          <Row>
            <Col xs={12} style={{ marginTop: 20 }}>
              <h4>Datas Indisponíveis</h4>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs={12}>
              <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                  localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                  rows={unavailableDays}
                  columns={columns(mutationRemoveUnavailableDaysDocAppointment)}
                  pageSize={perPage}
                  onPageSizeChange={(e) => {
                    setPerPage(e)
                  }}
                  rowsPerPageOptions={[10]}
                  pagination
                  disableSelectionOnClick
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </InternalBody>
  )
}

export default InternalSchedulesDoctors
