import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import ManageDoctors from '../../master/manageDoctors/ManageDoctors'
import { fetchClinicsById, inserDoctorInClinic, removeDoctorInClinic } from '../../../../services/clinics'
import { useUserClaims } from '../../../../hooks/user.hook'
import { genericFetch } from '../../../../main/usecases/generic'
import { useModal } from '../../../../contexts/modal'
import { useAlertBox } from '../../../../contexts/alert_box'
import { AuthService } from '../../../../services/auth/auth'
import { updateDoctorUseCase } from '../../../../main/usecases/doctors'

function MyDoctors (props) {
  const [id] = useState(props.user_id)
  const history = useHistory()
  const modal = useModal()
  const alertBox = useAlertBox()

  const claims = useUserClaims()

  const [mutationAddDoctor] = useMutation(inserDoctorInClinic())
  const [mutationRemoveDoctor] = useMutation(removeDoctorInClinic())

  const handleSubmit = async (e, actions) => {
    await genericFetch(
      mutationAddDoctor,
      {
        clinic_id: id,
        doctor_id: e.doctor.value
      },
      {
        alertBox,
        modal,
        actions
      }
    )
    await updateDoctorUseCase({ id: e.doctor.value })
  }

  const handleRemove = async (doctorId) => {
    await genericFetch(
      mutationRemoveDoctor,
      {
        clinic_id: id,
        doctor_id: doctorId
      },
      {
        alertBox,
        modal
      }
    )
  }

  const handleNewDoctor = () => {
    history.push('/doctors/new', { entity_id: id })
    modal.setOptions({
      open: false
    })
  }

  const handleEdit = (paramsRow) => {
    modal.setOptions({ open: false })
    history.push(`/doctors/${paramsRow.row.id}`)
  }

  const handleSchedules = (paramsRow) => {
    modal.setOptions({ open: false })
    history.push(
      `/doctor-available-schedules/${id}/${paramsRow.row.id}`
    )
  }

  const handleSendEmail = async (paramsRow) => {
    try {
      const auth = new AuthService()
      await auth.recoveryPassword(paramsRow?.row?.email)
      alertBox.setOptions({
        open: true,
        message: 'Enviado com Sucesso!',
        type: 'success',
        time: 3000
      })
    } catch (error) {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao enviar',
        type: 'error',
        time: 3000
      })
      console.error(error)
    }
  }

  const getResponse = (data) => {
    if (!data) return null

    if (!data?.clinics?.length) return null

    return data?.clinics[0].doctors_working_in_clinics
  }

  return (
    <div>
      {claims && id && (
        <ManageDoctors
          query={fetchClinicsById()}
          params={{
            clinic_id: id
          }}
          queryKey="id"
          objectKey="clinic_id"
          onSubmit={handleSubmit}
          onRemove={handleRemove}
          newDoctor={{
            title: 'Adicionar Novo Médico á essa clínica',
            action: handleNewDoctor
          }}
          actions={{
            handleEdit,
            handleSchedules,
            handleSendEmail
          }}
          getResponse={getResponse}
        />
      )}
    </div>
  )
}

export default MyDoctors
