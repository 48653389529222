import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'
import {
  LoadingWrapper,
  LoadingBackground
} from './styled'
import Loading from '../presentation/components/layout/Loading'
const LoadingContext = createContext(null)

export const LoadingProvider = ({ children }) => {
  const [active, setActive] = useState(false)

  return (
    <LoadingContext.Provider value={{
      active,
      setActive
    }}
    >
      {children}
      {active &&
        <LoadingWrapper>
          <LoadingBackground />
          <Loading />
        </LoadingWrapper>}
    </LoadingContext.Provider>
  )
}

LoadingProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export const useLoading = () => {
  const context = useContext(LoadingContext)

  if (!context) throw new Error('useLoading must be used within a provider LoadingProvider')
  return { ...context }
}
