import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import ButtonPrimary from '../../Button'
import MakeField from './MakeField'

import * as S from './styled'
import { Form, Formik } from 'formik'
import { mapInitialValues, mapValidations } from './utils/mapInitialValues'

function GenerateForm ({ groupFields, button, onSubmit, onPartialSubmit, className }) {
  const [file, setFile] = useState(null)

  const handleSubmit = (values, actions) => {
    if (file) {
      onSubmit({ ...values, file }, actions)
    } else {
      onSubmit(values, actions)
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={mapInitialValues(groupFields)}
      validationSchema={mapValidations(groupFields)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, touched, ...actions }) => (
        <Form className={className || ''}>
          <Row>
            <Col xs={12}>
              {groupFields.map((group, index) => (
                <React.Fragment key={index}>
                  {group?.name && <h5>{group?.name}</h5>}
                  {group?.subtitle && <S.GroupSubTitle>{group?.subtitle}</S.GroupSubTitle>}
                  <Row key={group?.name}>
                    {group.fields
                      .filter((field) => field && !field.hideField)
                      .map((field) => (
                        <Col
                          key={field.name}
                          {...field.columns}
                          className="position-relative"
                        >
                          <MakeField
                            {...field}
                            partialSubmit={field.partialSubmit && onPartialSubmit}
                            actions={actions}
                            fileAttr={{ file, setFile }}
                          />
                          <S.Error>{errors[field.name]}</S.Error>
                        </Col>
                      ))}
                  </Row>
                </React.Fragment>
              ))}
            </Col>
            {button && (
              <Col xs={12} className="mt-3 button-submit-generate-form-container">
                <ButtonPrimary
                  type="submit"
                  className="button-submit-generate-form"
                  disabled={Object.keys(errors).length || isSubmitting}
                >
                  {isSubmitting
                    ? button.submitting || 'Enviando'
                    : button.submit}
                </ButtonPrimary>
              </Col>
            )}
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default GenerateForm
