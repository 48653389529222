import md5 from 'md5'

export const IframeContainer = (props) => {
  const { payload, onLoad, hidden } = props
  const secret = process.env.REACT_APP_API_SECRET_RECEITA_DIGITAL
  const key = process.env.REACT_APP_API_KEY_RECEITA_DIGITAL

  // const b64 = Buffer.from(payloadJson).toString('base64')
  const b64 = btoa(payload)
  const token = md5(secret + md5(b64))

  return (
    <div>
      <iframe
        title="iframe"
        src={`${process.env.REACT_APP_RECEITA_DIGITAL_IFRAME}${key}-${token}?payload=${b64}`}
        width="1000"
        height="700"
        style={{ border: 0, opacity: hidden ? 0 : 1 }}
        allowFullScreen=""
        loading="lazy"
        onLoad={onLoad}
      />
    </div>
  )
}
