import { Avatar, IconButton } from '@mui/material'
import { Col } from 'react-bootstrap'
import moment from 'moment'
import { age } from '../../../../Utils/dates'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

export const columns = (handleAcceptRequest) => [
  {
    field: 'user.photo',
    headerName: 'Foto',
    headerAlign: 'center',
    align: 'center',
    flex: 0.3,
    minWidth: 50,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          {params?.row?.user?.photo
            ? (
              <Avatar src={params.row.user?.photo} />
              )
            : (
              <Avatar>{params?.row?.user?.full_name[0]}</Avatar>
              )}
        </Col>
      )
    }
  },
  {
    field: 'created_at',
    headerName: 'Horário',
    description: 'Horário da entrada do usuário nesta sala',
    flex: 0.8,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: params => moment(params.value).format('DD/MM/YYYY - HH:mm')
  },
  {
    field: 'user.full_name',
    headerName: 'Nome',
    description: 'Nome do paciente',
    flex: 1.5,
    headerAlign: 'center',
    align: 'center',
    valueGetter: (params) => `${params.row.user?.full_name || ''} `
  },
  {
    field: 'user',
    headerName: 'Idade',
    description: 'Idade do paciente',
    flex: 1,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: (value) => age(value.value.birth_date, true)
  },
  {
    field: 'acceptRequest',
    headerName: 'Aceitar Solicitação',
    headerAlign: 'center',
    align: 'center',
    flex: 0.5,
    renderCell: (params) => {
      return (
        <div
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => handleAcceptRequest(params.row.id)}
        >
          <IconButton
            color="primary"
            aria-label="Aceitar Solicitação"
          >
            <CheckCircleOutlineIcon />
          </IconButton>
          <span>Aceitar</span>
        </div>
      )
    }
  }
]
