import * as Yup from 'yup'

export const groupFields = (content, setContent) => {
  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e?.target?.value || e }))
    }
  }
  return [
    {
      name: 'Dados do Suporte',
      fields: [
        {
          label: 'E-mail do Suporte',
          type: 'email',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.email_suport,
          name: 'email_suport',
          onChange: (e) => {
            handleInputChange(e, 'email_suport')
          },
          columns: {
            xs: 3
          },
          validation: Yup.string('Valor inválido')
            .email()
            .required('Campo obrigatório')
        },
        {
          label: 'Link suporte Facebook',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.facebook_suport || 'https://',
          name: 'facebook_suport',
          onChange: (e) => {
            handleInputChange(e, 'facebook_suport')
          },
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Link da central de ajuda',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.help_center_link || 'https://',
          name: 'help_center_link',
          onChange: (e) => {
            handleInputChange(e, 'help_center_link')
          },
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Link do instagram do suporte',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.instagram_suport || 'https://',
          name: 'instagram_suport',
          onChange: (e) => {
            handleInputChange(e, 'instagram_suport')
          },
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Link Suporte Whatsapp',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.whatsapp_suport || 'https://',
          name: 'whatsapp_suport',
          onChange: (e) => {
            handleInputChange(e, 'whatsapp_suport')
          },
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]
}
