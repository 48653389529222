import { gql } from '@apollo/client'

export const fetchTermsOfUse = () => {
  return gql`
  query MyQuery($id: Int = 1) {
    legal_documents_by_pk(id: $id) {
      created_at
      id
      privacy_policy
      terms_of_use
      updated_at
    }
  }
  `
}

export const addTermsOfUse = () => {
  return gql`
  mutation MyMutation($terms_of_use: String) {
    update_legal_documents_by_pk(pk_columns: {id: 1}, _set: {terms_of_use: $terms_of_use}) {
      created_at
      id
      privacy_policy
      terms_of_use
      updated_at
    }
  }
  `
}
