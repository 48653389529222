import * as Yup from 'yup'

export const cpfValidator = () => {
  return Yup.string('Valor inválido')
    .nullable() // Permitir valores nulos
    .transform((value, originalValue) => originalValue === '' ? null : value) // Transformar valores vazios em nulos
    .required('Campo obrigatório')
    .test('cpfValid', 'CPF inválido', (value) => {
      if (!value) return false // Se o valor for nulo ou vazio, retornar falso

      // Remover caracteres não numéricos
      const cpf = value.replace(/[^\d]/g, '')

      // Verificar se o CPF tem 11 dígitos
      if (cpf.length !== 11) return false

      // Verificar se todos os dígitos do CPF são iguais (caso contrário, é inválido)
      if (/^(\d)\1{10}$/.test(cpf)) return false

      // Calcular os dígitos verificadores
      let sum = 0
      let remainder

      for (let i = 1; i <= 9; i++) sum += parseInt(cpf.substring(i - 1, i)) * (11 - i)
      remainder = (sum * 10) % 11

      if ((remainder === 10) || (remainder === 11)) remainder = 0
      if (remainder !== parseInt(cpf.substring(9, 10))) return false

      sum = 0
      for (let i = 1; i <= 10; i++) sum += parseInt(cpf.substring(i - 1, i)) * (12 - i)
      remainder = (sum * 10) % 11

      if ((remainder === 10) || (remainder === 11)) remainder = 0
      if (remainder !== parseInt(cpf.substring(10, 11))) return false

      return true
    })
}
