import { getDataByZipCode } from '../coordinates'

export const fetchAddressByZipCode = async (
  zipCode,
  content,
  setContent,
  states,
  alertBox
) => {
  try {
    const cepPattern = /^\d{5}-?\d{3}_?$/ // Permitindo um hífen opcional e um underscore opcional
    if (!zipCode && !zipCode.match(cepPattern)) {
      return
    }

    const cleanedZipcode = zipCode.replace(/_/g, '')
    const coords = await getDataByZipCode(cleanedZipcode)

    if (!coords) {
      return
    }

    const state = states.find(
      (item) => item.value.toLowerCase() === coords.uf.toLowerCase()
    )

    if (!state) {
      return
    }

    setContent((prev) => ({
      ...prev,
      adress: {
        number: '',
        id: content.adress.id,
        ...prev.adress,
        neighborhood: coords.bairro || '',
        state: state.label,
        city: coords.localidade || '',
        state_code: state.value,
        address: coords.logradouro || '',
        latitude: coords?.lat || '',
        longitude: coords?.lon || ''
      }
    }))
  } catch (error) {
    alertBox.setOptions({
      open: true,
      message: 'Erro ao buscar CEP',
      type: 'error',
      time: 3000
    })
  }
}
