import GenerateProfile from '../../../components/layout/GenerateProfile'
import { fetchSUSById } from '../../../../services/sus'
import country from '../../../../Utils/estados_cidades.json'
import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import Header from '../../../components/Header'

export default function InternalDoctorSUS ({ match }) {
  const [content, setContent] = useState({})
  const history = useHistory()

  const { data } = useQuery(fetchSUSById(), {
    variables: { limit: 10, offset: 0, id: match.params.id }
  })

  const states = []
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  useEffect(() => {
    if (data?.sus_by_pk) {
      setContent(data?.sus_by_pk)
    }
  }, [data])

  const handleBack = () => {
    if (history.length > 0) {
      history.goBack()
    } else {
      history.push('/my-list-sus')
    }
  }

  return (
    <div>
      <Header
        title="Detalhes"
        labelAdd="Voltar"
        handleAdd={handleBack}
        centralButton={{
          label: 'Ver salas de atendimento',
          onClick: () => {
            console.log('Ao ir para a salas')
            history.push(`/doctor/my-waiting-rooms/${data?.sus_by_pk.id}`)
          }
        }}
      />
      <GenerateProfile data={content} />
    </div>
  )
}
