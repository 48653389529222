import * as Auth from 'firebase/auth'

export const guardsRoutes = async (to, current, next) => {
  const auth = Auth.getAuth()
  const user = auth.currentUser

  if (!user && to.meta.auth) {
    const redirect = btoa(to.match.path)
    next.redirect(`/login?redirect=${redirect}`)
  }

  next()
}
