import * as S from './styled'
import { groupFields } from './columns'
import GenerateForm from '../layout/GenerateForm'

function Filter ({ setFilter }) {
  const onSubmit = (values, actions) => {
    actions.setSubmitting(true)

    const valueToSeach = `%${values.name?.trim().split(' ').join('%')}%`

    const data = {
      name: values.name?.trim() ? valueToSeach : null,
      city: values.city?.label || null,
      stateCode: values.state?.value || null
    }

    if (data.city === '-' || data.stateCode === '-') {
      delete data.city
      delete data.stateCode
    }

    setFilter(data)

    actions.setSubmitting(false)
  }

  const onPartialSubmit = (value) => {
    if (value && value.length >= 3) {
      const valueToSeach = `%${value.trim().split(' ').join('%')}%`
      setFilter({
        name: valueToSeach
      })
    } else {
      setFilter({
        name: null
      })
    }
  }

  return (
    <S.Container>
      <GenerateForm
        onSubmit={onSubmit}
        groupFields={groupFields()}
        onPartialSubmit={onPartialSubmit}
        button={{
          submit: 'Filtrar',
          submitting: 'Filtrando'
        }}
      />
    </S.Container>
  )
}

export default Filter
