import React from 'react'
import logo from '../../../../assets/images/logos/horizontal_branco_logo.png'
import * as S from './styled'

function PublicLayout ({ children }) {
  return (
    <S.Container>
      <S.Header>
        <img alt="smart-life" src={logo} />
      </S.Header>
      <S.Content>
        {children}
      </S.Content>
    </S.Container>
  )
}

export default PublicLayout
