import { gql } from '@apollo/client'

export const fetchAllPacients = () => {
  return gql`
  query getUsers($limit: Int, $offset: Int) {
    users(order_by: {updated_at: desc, id: asc}, distinct_on: [updated_at, id], limit: $limit, offset: $offset) {
      birth_date
      cpf
      created_at
      email
      full_name
      id
      phone
      photo
      updated_at
      adress {
        address
        id
        city
        state
        state_code
        complement
        created_at
        updated_at
        neighborhood
        number
        zipcode
        longitude
        latitude
      }
      user_marital_status {
        spouse_document
        marital_status {
          have_a_spouse
          label
          id
        }
      }
      sus_card
      genderByGender {
        value
        id
      }
    }
    users_aggregate {
      aggregate {
        count(columns: id)
      }
    }
  }
  `
}

export const fetchPacientsByDoctorId = () => {
  return gql`
  query getUsers($doctor_id: Int, $limit: Int, $offset: Int) {
    users(
      where: {
        schedules: {
          _or: [
            { doctors_appointment: { doctor_id: { _eq: $doctor_id } } },
            { doctor_id: { _eq: $doctor_id } }
          ]
        }
      },
      order_by: { updated_at: desc, id: asc },  # Adicione id: asc para order_by
      distinct_on: [updated_at,id],
      limit: $limit,
      offset: $offset
    ) {
      birth_date
      cpf
      created_at
      email
      full_name
      id
      phone
      photo
      updated_at
      adress {
      address
      id
      city
      state
      state_code
      complement
      created_at
      updated_at
      neighborhood
      number
      zipcode
      longitude
      latitude
    }
    user_marital_status {
      spouse_document
      marital_status {
        have_a_spouse
        label
        id
      }
    }
      sus_card
      genderByGender {
        value
        id
      }
    }
  }
  `
}

export const fetchPacientSymptomRecord = () => {
  return gql`
  query getPatientSymptomRecords($user_id: Int!) {
    patient_symptom_record(
      order_by: { created_at: desc }
      where: { user_id: { _eq: $user_id } }
    ) {
      created_at
      description
      id
      updated_at
      user_id
      schedules{
        id
      }
    }
  }
  `
}

export const fetchCurrentUserById = () => {
  return gql`
  query getUserById($id: Int!) {
    users_by_pk(id: $id){
        birth_date
        cpf
        created_at
        email
        full_name
        id
        phone
        photo
        updated_at
        adress {
          address
          id
          city
          state
          state_code
          complement
          created_at
          updated_at
          neighborhood
          number
          zipcode
          longitude
          latitude
        }
        user_marital_status {
          spouse_document
          marital_status {
            have_a_spouse
            label
            id
          }
        }
        sus_card
        genderByGender {
          value
          id
        }
      }
    }
  `
}

export const fetchShedulesByPacient = () => {
  return gql`
  fragment resumedUserFields on users {
    id
    created_at
    updated_at
    birth_date
    full_name
    email
    photo
    cnpj
    cpf
    kind_of_person
    phone
    genderByGender {
      id
      value
    }
    adress {
      address
      id
      city
      state
      state_code
      complement
      created_at
      updated_at
      neighborhood
      number
      zipcode
      longitude
      latitude
    }
    user_marital_status {
      spouse_document
      marital_status {
        have_a_spouse
        label
        id
      }
    }
  }
  
  fragment sheduleFields on schedules {
    id
    created_at
    updated_at
    day
    description
    status
    type_of_medical_appointment
    user_id
    room {
      id
      call_id
      start_time
      end_time
    }
    doctor_appointment_id
    start_time
    end_time
    user {
      ...resumedUserFields
    }
    start_time
    end_time
    day
    status
    evaluations {
      id
    }
    patient_symptom_record {
      description
      id
    }
    type_of_medical_appointment
  }
  
  query getShedulesByUser($limit: Int, $offset: Int, $user_id: Int, $doctor_id: Int) {
    schedules(order_by: {updated_at: desc}, limit: $limit, offset: $offset, where: {user_id: {_eq: $user_id},_or: [
              { doctors_appointment: { doctor_id: { _eq: $doctor_id } } },
              { doctor_id: { _eq: $doctor_id } }
            ]}) {
      ...sheduleFields
    }
  }
  
  `
}
