import { decodeToken } from 'react-jwt'

export function getUserID (user) {
  const decoded = decodeToken(
    user?.stsTokenManager?.accessToken || user?.accessToken
  )

  return decoded['https://hasura.io/jwt/claims'].entity_id ||
    decoded['https://hasura.io/jwt/claims']['entity-id']
}

export function getUserType (user) {
  const decoded = decodeToken(
    user?.stsTokenManager?.accessToken || user?.accessToken
  )

  return decoded['https://hasura.io/jwt/claims'].entity
}
