import { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { fetchClinicsFull, removeClinic } from '../../../../services/clinics'
import { columns } from './columns'
import { useModal } from '../../../../contexts/modal'
import { useAlertBox } from '../../../../contexts/alert_box'
import DataTable from '../../../components/DataTable'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import Header from '../../../components/Header'
import MainContainer from '../../../components/MainContainer'
import Filter from '../../../components/Filter'

function Clinics ({ list, isDoctorsPage }) {
  const [offset, setOffset] = useState(0)
  const [pageSize] = useState(10)
  const [myList, setMyList] = useState([])
  const [filter, setFilter] = useState()
  const { data, loading, refetch } = useQuery(fetchClinicsFull(filter), {
    variables: {
      limit: pageSize,
      offset: (offset) * pageSize,
      name: filter?.name,
      city: filter?.city,
      state_code: filter?.stateCode
    },
    onError: () => {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar os dados!',
        type: 'error',
        time: 3000
      })
    }
  })

  useEffect(() => {
    if (data?.clinics) {
      setMyList(data.clinics)
    }
  }, [data])

  useEffect(() => {
    refetch()
  }, [filter])

  const onChangePage = (page) => {
    setOffset(page)
  }

  const [mutateDelete] = useMutation(removeClinic())
  const modal = useModal()
  const alertBox = useAlertBox()
  const history = useHistory()

  const handleAddClinic = () => {
    history.push('/clinics/new')
  }

  return (
    <MainContainer>
      <Header
        title="Clínicas"
        labelAdd={isDoctorsPage ? false : 'Adicionar Clínica'}
        handleAdd={handleAddClinic}
      />

      <Filter setFilter={setFilter} />

      <DataTable
        totalItems={data?.clinics_aggregate?.aggregate?.count || 100}
        data={myList || list}
        loading={loading}
        onChangePage={onChangePage}
        columns={columns(modal, alertBox, mutateDelete, refetch, history)}
      />

    </MainContainer>
  )
}

export default Clinics
