import moment from 'moment'

export const currentWeek = () => {
  const date = new Date()
  const week = []

  date.setDate(date.getDate() - 1 - date.getDay() + 1)
  for (let i = 0; i < 7; i++) {
    week.push(new Date(date))
    date.setDate(date.getDate() + 1)
  }
  return week
}

export const verifyDates = (start, end, date) => {
  if (
    new Date(date).getTime() <= new Date(end).getTime() - 1 &&
    new Date(date).getTime() >= new Date(start).getTime() + 1
  ) {
    return true
  }
}

export const verifyEventIsAvailable = (start, end, event) => {
  if (
    verifyDates(start, end, event?.start) ||
    verifyDates(start, end, event?.end) ||
    verifyDates(event?.start, event?.end, start) ||
    verifyDates(event?.start, event?.end, end) ||
    String(event?.start) === String(start) ||
    String(event?.end) === String(end)
  ) {
    return true
  }
  return false
}

export const isAvailableDocSchedule = (start, end, event) => {
  if (
    new Date(event?.start).getTime() <= new Date(start).getTime() &&
    new Date(event?.end).getTime() >= new Date(end).getTime()
  ) {
    return true
  }
  return false
}

export const weekDays = [
  {
    label: 'Domingo',
    value: 1
  },
  {
    label: 'Segunda',
    value: 2
  },
  {
    label: 'Terça',
    value: 3
  },
  {
    label: 'Quarta',
    value: 4
  },
  {
    label: 'Quinta',
    value: 5
  },
  {
    label: 'Sexta',
    value: 6
  },
  {
    label: 'Sábado',
    value: 7
  }
]

export const getIdDay = (day) => {
  return weekDays?.find((item) => item.label === day?.replace('-feira', ''))
    .value
}

export const translateCalendar = {
  month: 'Mês',
  day: 'Dia',
  today: 'Hoje',
  week: 'Semana',
  next: 'Próximo',
  previous: 'Anterior',
  allDay: 'O dia todo',
  date: 'Data',
  time: 'Horário',
  event: 'Evento',
  showMore: (e) => `${e} mais`
}

export const dateAddSubDays = (day, date) => {
  const temp = date ? new Date(date) : new Date()

  const newDate = new Date(temp.setDate(temp.getDate() + day))

  return newDate
}

export const yesterdayDate = (format = 'en-CA') => {
  return dateAddSubDays(-2).toLocaleDateString(format)
}

export const tomorrowDate = (format = 'en-CA') => {
  return dateAddSubDays(2).toLocaleDateString(format)
}

export const age = (born, withMonth) => {
  const date = moment(new Date(born))
  const now = moment(new Date())

  const difDuration = moment.duration(now.diff(date))

  if (withMonth) {
    return `${difDuration.years()} anos e ${difDuration.months()} meses`
  }

  return `${difDuration.years()} anos`
}
