import styled from 'styled-components'
import { customTheme } from '../../../../styles/theme'

export const Container = styled.div`
  display: flex;
`

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 250px;
  height: 100vh;

  overflow-x: hidden;

  background-color: ${customTheme.colors.primary};
  padding: 15px 10px;

  gap: 5px;

  img {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
  }
`

export const SeparatorTitle = styled.div`
  font-size: 12px;
  color: #dbdbdb;
  margin-bottom: 5px;
  margin-top: 8px;
  height: 14px;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
`

export const MenuLink = styled.div`
  a, button {
    text-decoration: none;
    color: inherit;
    background-color: transparent;
    width: 100%;
    text-align: start;
  }
`

export const LinkItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  width: 100%;
  height: auto;
  padding: 5px 25px;
  border-radius: 4px;
  gap: 5px;

  background-color: transparent;
  color: #fff;

  p { 
    word-wrap: break-word;
    white-space: break-spaces;
    width: 100%;
    margin: 0;
    font-size: 14px;
  }

  ${props => props.$select && `
    & {
      background-color: #fff;
      color: #000;
      transition: 200ms;
    }
  `}

  &:hover {
    background-color: #fff;
    color: #000;
    transition: 200ms;
  }
`

export const IconBox = styled.div`
  width: 24px;
  height: fit-content;
  color: inherit;
`

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  padding: 2%;
  overflow: auto;
`
