import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import { columns, filterUsers } from './columns'
import { useModal } from '../../../../contexts/modal'
import { useAlertBox } from '../../../../contexts/alert_box'
import { CustomStyle } from './styled'
import GenerateForm from '../../../components/layout/GenerateForm'
import {
  deleteLaboratoriesDiag,
  fetchLaboratoriesDiagByFilter
} from '../../../../services/diagnostic-images'
import DataTable from '../../../components/DataTable'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import MainContainer from '../../../components/MainContainer'
import Header from '../../../components/Header'

function DianosticImagePage ({ list }) {
  const [filter, setFilter] = useState({ state: { value: '%' } })
  const { loading, data, refetch } = useQuery(fetchLaboratoriesDiagByFilter(), {
    variables: {
      limit: 50,
      offset: 0,
      state_code: filter?.state?.value || '',
      city: filter?.city?.label || '%'
    }
  })
  const [mutateDelete] = useMutation(deleteLaboratoriesDiag())
  const modal = useModal()
  const alertBox = useAlertBox()
  const history = useHistory()

  const handleAdd = () => {
    history.push('/diagnostic-images/new')
  }

  return (
    <MainContainer>
      <Header
        title="Laboratórios de diagnóstico de imagens"
        labelAdd="Adicionar Laboratório"
        handleAdd={handleAdd}
      />

      <Col xs={12}>
        <CustomStyle className="list">
          <GenerateForm
            onSubmit={null}
            groupFields={filterUsers(filter, setFilter)}
            button={{
              submit: 'Adicionar médico existente na base de dados',
              submitting: 'Adicionando...'
            }}
          />
        </CustomStyle>
      </Col>

      {loading && (<span>Carregando...</span>)}

      {(!loading && data?.medical_imaging_diagnostics) && (
        <DataTable
          data={list || data.medical_imaging_diagnostics}
          columns={columns(modal, alertBox, mutateDelete, refetch, history)}
        />
      )}
    </MainContainer>
  )
}

export default DianosticImagePage
