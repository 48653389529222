import { AuthService } from '../../../services/auth/auth'

export const updateClinicUseCase = async (mutation, params, extras) => {
  try {
    await mutation({
      variables: params
    })
    extras.actions.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: 'Atualizado com Sucesso!',
      type: 'success',
      time: 3000
    })
  } catch (error) {
    console.error(error)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro Ao atualizar!',
      type: 'error',
      time: 3000
    })
  }
}
export const createClinicUseCase = async (
  { email, ...params },
  extras
) => {
  const auth = new AuthService()

  try {
    const cepWithUnderscore = params.zipcode
    const formattedCep = cepWithUnderscore.replace(/_/g, '')

    const phoneNumber = params.phone_number
    const formattedPhoneNumber = phoneNumber.replace(/[_\s]/g, '')

    const data = {
      email: email,
      address: params.address,
      city: params.city,
      complement: params.complement,
      neighborhood: params.neighborhood,
      number: params.number,
      state: params.state,
      state_code: params.state_code,
      latitude: params.latitude,
      longitude: params.longitude,
      company_name: params.company_name,
      website: params.website,
      phone_number: formattedPhoneNumber,
      photo: params.photo,
      zipcode: formattedCep,
      type_of_account: 'clinics',
      online_service: params.online_service ?? false,
      personal_assistance: params.personal_assistance ?? false,
      cnpj: params.cnpj,
      description: params.description,
      facebook: params.facebook,
      instagram: params.instagram,
      linkedin: params.linkedin,
      company_plan_id: params.company_plan_id
    }

    function replaceUndefinedWithNull (obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === undefined) {
          obj[key] = null
        }
      }
    }

    replaceUndefinedWithNull(data)

    await auth.createAccount(data)
  } catch (error) {
    const message = error?.response?.data?.error ?? 'Erro ao criar sus!'
    extras.actions.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: message,
      type: 'error',
      time: 3000
    })
  } finally {
    await auth.recoveryPassword(email)
  }
}
