import styled from 'styled-components'
import ButtonPrimary from '../../../components/Button'
import CardModal from '../../../components/CardModal'

export const Wrapper = styled.div``

export const Button = styled(ButtonPrimary)`
  padding: 0.6rem;
  font-size: 0.875rem;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

export const ContainerModal = styled(CardModal)`
  width: 95%;
  padding: 2rem;

  @media  (min-width: 992px)  {
    width: 70%
  }

  @media  (min-width: 1200px)  {
    width: 55%
  }
`
