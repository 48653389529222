import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { useAlertBox } from '../../../contexts/alert_box'

function Alert () {
  function Alert (props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }
  const alertBox = useAlertBox()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    alertBox.setOptions({
      open: false,
      type: 'success',
      time: 1500
    })
  }
  if (!alertBox?.options?.open) return null
  return (
    <>
      <Snackbar
        open={alertBox.options.open}
        autoHideDuration={alertBox.options.time}
        onClose={handleClose}
      >
        <Alert severity={alertBox.options.type}>
          {alertBox.options.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Alert
