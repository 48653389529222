import ContactPageIcon from '@mui/icons-material/ContactPage'
import Screening from './screening'
import Detalhes from './details'
import Anamnese from './anamnese'
import GenerateProfile from '../../../../components/layout/GenerateProfile'
import { MedicalPrescription } from './MedicalPrescription'

export const tabsData = [
  {
    name: 'Dados Pessoais',
    key: 'personal-data',
    icon: <ContactPageIcon />,
    tabs: [
      {
        name: 'Detalhes do agendamento',
        component: ({ data }) => <Detalhes data={data} hideHeader typeScheduling />
      },
      {
        name: 'Dados Pessoais',
        component: ({ data }) => <GenerateProfile hideHeader data={data?.user} />
      },
      {
        name: 'Triagem',
        component: ({ data }) => <Screening data={data?.patient_symptom_record} />
      },
      {
        name: 'Anamnese',
        component: ({ data }) => <Anamnese data={data} />
      },
      {
        name: 'Prescrição',
        component: (props) => <MedicalPrescription {...props} />
      }
    ]
  }
]
