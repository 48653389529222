import React, { useState } from 'react'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { customTheme } from '../../../../styles/theme'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ButtonPrimary from '../../../components/Button'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import country from '../../../../Utils/estados_cidades.json'
import { weekDays } from '../../../../Utils/dates'

export const columns = () => [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: 'photo',
    headerName: 'Foto',
    width: 60,
    editable: true,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          {params.row.photo ? (
            <Avatar src={params.row.photo} />
          ) : (
            <AccountBalanceIcon
              style={{ color: customTheme.colors.primary }}
              edge="start"
              sx={{
                marginRight: '36px'
                // ...(open && { display: "none" }),
              }}
            />
          )}
        </Col>
      )
    }
  },
  {
    field: 'company_name',
    headerName: 'Nome',
    width: 200,
    editable: true
  },
  {
    field: 'cnpj',
    headerName: 'CNPJ',
    width: 250,
    editable: true
  },
  {
    field: 'created_at',
    headerName: 'Data de Cadastro',
    sortable: true,
    width: 150,
    valueGetter: (params) => `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
    valueFormatter: params => moment(params.value).format('DD/MM/YYYY - HH:MM')
  },
  {
    field: 'id',
    headerName: '',
    sortable: false,
    width: 60,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          <Link to={`/schedules-available/${params.row.id}`}>
            <ButtonPrimary
              style={{
                width: '35px',
                height: '35px',
                padding: '5px',
                borderRadius: '50%'
              }}
              // onClick={() => {
              //   modal.open({
              //     open: true,
              //     component: (
              //       <CardModal
              //         title={params.row.company_name}
              //         avatar={params?.row?.photo}
              //         subtitle={`${
              //           moment(params.row.created_at).format(
              //             "DD/MM/YYYY HH:MM"
              //           ) || ""
              //         } `}
              //       >
              //         sdsd
              //       </CardModal>
              //     ),
              //   });
              // }}
            >
              <EditOutlinedIcon
                style={{ color: customTheme.colors.white }}
                edge="start"
                fontSize="small"
                sx={{
                  marginBottom: '2px'
                }}
              />
            </ButtonPrimary>
          </Link>
        </Col>
      )
    }
  }
]

export const groupFields = (content, setContent) => {
  const cities = []
  const states = []
  const [selectdState, setSelectedState] = useState(null)
  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e.target.value }))
    }
  }
  const handleInputChangeAddress = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        adress: { ...prev.adress, [key]: e.target.value }
      }))
    }
  }
  return [
    {
      name: 'Sobre a Clínica',
      fields: [
        {
          label: 'Avatar',
          type: 'file',
          required: true,
          disabled: false,
          hideField: false,
          name: 'photo',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido'),
          component: (data) => {
            return (
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <Avatar
                    src={data || content?.photo}
                    style={{ width: 100, height: 100 }}
                  />
                </Col>
              </Row>
            )
          }
        },
        {
          label: 'Nome da Clínica',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.company_name,
          name: 'company_name',
          onChange: (e) => {
            handleInputChange(e, 'company_name')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'E-mail',
          type: 'email',
          required: true,
          disabled: false,
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'email')
          },
          currentValue: content?.email || '',
          name: 'email',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'CNPJ',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'cnpj')
          },
          currentValue: content?.cnpj,
          hideField: false,
          name: 'cnpj',
          mask: '99.999.999/9999-99',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
            .min(18, 'Fora do padrão')
            .max(18, 'Fora do padrão')
            .required('Campo obrigatório')
        },
        {
          label: 'Descrição',
          type: 'textarea',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'description')
          },
          currentValue: content?.description,
          hideField: false,
          name: 'description',
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Telefone',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'phone_number')
          },
          currentValue: content?.phone_number,
          hideField: false,
          mask: '+ 99 (99) 9 9999-9999',
          name: 'phone_number',
          columns: {
            xs: 6
          },
          validation:
          Yup.string('Valor inválido')
            .required('Campo obrigatório')
            .test('phoneComplete', 'O campo precisa ser preenchido totalmente!', (content) => {
              const phoneNumber = content?.replace(/[^\d]/g, '')
              return phoneNumber?.length === 13
            })
        },
        {
          label: 'Facebook URL',
          type: 'textarea',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'facebook')
          },
          mask: 'https://aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
          currentValue: content?.facebook || '',
          hideField: false,
          name: 'facebook',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Instagram URL',
          type: 'textarea',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'instagram')
          },
          mask: 'https://aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
          currentValue: content?.instagram || '',
          hideField: false,
          name: 'instagram',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Linkedin URL',
          type: 'textarea',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'linkedin')
          },
          mask: 'https://aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
          currentValue: content?.linkedin || '',
          hideField: false,
          name: 'linkedin',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    },
    {
      name: 'Endereço',
      fields: [
        {
          label: 'Estado',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content
            ? {
                label: content?.adress?.state,
                value: content?.adress?.state_code
              }
            : null,
          hideField: false,
          name: 'state',
          onChange: (data) => {
            setSelectedState(data)
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  state: data.label,
                  state_code: data.value
                }
              }))
            }
          },
          options: states,
          columns: {
            xs: 6
          },
          validation: Yup.object().required('Campo obrigatório')
        },
        {
          label: 'Cidade',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content
            ? {
                label: content?.adress?.city,
                value: content?.adress?.city
              }
            : null,
          hideField: false,
          name: 'city',
          columns: {
            xs: 6
          },
          onChange: (data) => {
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  city: data.label
                }
              }))
            }
          },
          options: cities.filter(
            (item) =>
              item.value === selectdState?.value ||
              item.value === content?.adress?.state_code
          ),
          validation: Yup.object().required('Campo obrigatório')
        },
        {
          label: 'Logradouro',
          type: 'text',
          required: true,
          disabled: false,
          currentValue: content?.adress?.address,
          hideField: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'address')
          },
          name: 'address',
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'CEP',
          type: 'textarea',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'zipcode')
          },
          currentValue: content?.adress?.zipcode,
          hideField: false,
          mask: '99999-999',
          name: 'zipcode',
          columns: {
            xs: 4
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Bairro',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'neighborhood')
          },
          currentValue: content?.adress?.neighborhood,
          hideField: false,
          name: 'neighborhood',
          columns: {
            xs: 4
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Número',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'number')
          },
          currentValue: content?.adress?.number,
          hideField: false,
          name: 'number',
          columns: {
            xs: 4
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Complemento',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'complement')
          },
          currentValue: content?.adress?.complement || '',
          hideField: false,
          name: 'complement',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        }
      ]
    }
  ]
}

export const editFields = (schedule, setSchedule) => {
  let daySelected = weekDays[schedule?.day_of_week_id - 1]

  return [
    {
      name: 'Horário disponível',
      fields: [
        {
          label: 'Dia da semana',
          type: 'autocomplete',
          required: true,
          disabled: false,
          hideField: false,
          name: 'day_of_week_id',
          options: weekDays,
          currentValue: daySelected,
          onChange: (data) => {
            // setSchedule((prev) => ({
            //   ...prev,
            //   day_of_week_id: data.value,
            // }));
            daySelected = data.value
          },
          columns: { xs: 12 },
          validation: Yup.object().required('Campo obrigatório').nullable()
        },
        {
          label: 'Início',
          type: 'time',
          required: true,
          disabled: false,
          currentValue: schedule?.start_time,
          hideField: false,
          name: 'start_time',
          columns: { xs: 12 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Final',
          type: 'time',
          required: true,
          disabled: false,
          currentValue: schedule?.end_time,
          hideField: false,
          name: 'end_time',
          columns: { xs: 12 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]
}
