import styled from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Header = styled.header`
  display: flex;
  padding: 1rem 0;
  justify-content: center;
  background-color: ${props => props.theme.colors.primary};

  img {
    max-width: 20vw;
    min-width: 150px;
  }
`

export const Content = styled.section`
  padding: 2rem 0;
  flex: 1;
  overflow: auto;
`

export const Footer = styled.header`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.colors.primary};

  img {
    max-width: 20vw;
    min-width: 150px;
  }
`
