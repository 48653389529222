import styled, { keyframes } from 'styled-components'
import { customTheme } from '../../../../styles/theme'

const spin = keyframes`
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 100%r;
`

export const LoadingStyled = styled.div`
  border-top: 6px solid ${customTheme.colors.primary};
  border-left: 6px solid ${customTheme.colors.primary};
  border-bottom: 6px solid ${customTheme.colors.primary};
  border-right: 6px solid transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  animation: ${spin} 800ms linear infinite;
`
