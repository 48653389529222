import * as Yup from 'yup'

export const mapInitialValues = (groupsFields) => {
  const values = {}

  groupsFields.forEach((group) => {
    if (!Array.isArray(group)) {
      group.fields.forEach((field) => {
        if (!field.hideField) {
          Object.assign(values, {
            [field.name]: field.currentValue
          })
        }
      })
    } else {
      group.forEach((groupField) => {
        groupField.fields.forEach((field) => {
          if (!field.hideField) {
            Object.assign(values, {
              [field.name]: field.value
            })
          }
        })
      })
    }
  })

  return values
}

export const mapValidations = (groupFields) => {
  const validations = {}
  groupFields.forEach((group) =>
    group.fields.forEach(
      (field) =>
        field.validation &&
        Object.assign(validations, { [field.name]: field.validation })
    )
  )

  const validationSchema = Yup.object().shape({
    ...validations
  })
  return validationSchema
}
