import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlertBox } from '../../../../contexts/alert_box'
import InternalBody from '../../../components/InternalBody'
import GenerateForm from '../../../components/layout/GenerateForm'
import { groupFields } from './columns'
import { updateAddress } from '../../../../services/address'
import { updateAddressUseCase } from '../../../../main/usecases/addressess'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../services/firebase/firebase-config'
import { Col } from 'react-bootstrap'
import { genericFetch } from '../../../../main/usecases/generic'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import mapPin from '../../../../assets/svg/pin.svg'
import Leaflet from 'leaflet'
import { CustomStyle } from './styled'
import country from '../../../../Utils/estados_cidades.json'
import { useHistory } from 'react-router-dom'
import {
  createOptic,
  fetchOpticsById,
  updateOptics
} from '../../../../services/optics'
import { coordIsValid } from '../../../../Utils/coordValidate'
import { fetchAddressByZipCode } from '../../../../services/searchZip/searchZipcode'

function InternalOptics ({ match }) {
  const { data } = useQuery(fetchOpticsById(), {
    variables: { limit: 10, offset: 0, id: match.params.id }
  })

  const [mutateFunction] = useMutation(updateOptics())
  const [createEntityMutation] = useMutation(createOptic())
  const [mutateFunctionAdress] = useMutation(updateAddress())
  const [content, setContent] = useState({})
  const alertBox = useAlertBox()
  const history = useHistory()
  const [isFirstRun, setIsFirtsRun] = useState(true)
  const states = []
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })
  useEffect(() => {
    if (data?.optics_by_pk) {
      setContent(data?.optics_by_pk)
    }
  }, [data])

  useEffect(() => {
    if (content?.adress?.zipcode && !isFirstRun) {
      fetchAddressByZipCode(
        content?.adress?.zipcode,
        content,
        setContent,
        states,
        alertBox
      ).then(() => { })
    }

    if (data?.optics_by_pk.adress?.zipecode !== content?.adress?.zipcode) {
      setIsFirtsRun(false)
    }
  }, [content?.adress?.zipcode])

  const mutationCreateEntity = async (e, url, actions) => {
    return await genericFetch(
      createEntityMutation,
      {
        email: e.email,
        address: e.address,
        city: e.city.label,
        complement: e.complement,
        neighborhood: e.neighborhood,
        number: e.number,
        state: e.state.label,
        state_code: content?.adress?.state_code,
        zipcode: e.zipcode,
        latitude: content.latitude,
        longitude: content.longitude,
        menu: e.menu,
        company_name: e.company_name,
        description: e.description,
        facebook: e.facebook,
        website: e.website,
        instagram: e.instagram,
        linkedin: e.linkedin,
        phone_number: e.phone_number,
        photo: url || ''
      },
      {
        actions,
        alertBox
      }
    )
  }

  const mutationUpdateEntity = async (e, url, actions) => {
    await updateAddressUseCase(
      mutateFunctionAdress,
      {
        id: content.adress.id,
        address: e.address,
        complement: e.complement,
        latitude: e.latitude,
        longitude: e.longitude,
        neighborhood: e.neighborhood,
        number: e.number,
        city: e.city.label,
        state: e.state.label,
        state_code: e.state.value,
        zipcode: e.zipcode
      },
      {
        actions,
        alertBox
      }
    )
    await genericFetch(
      mutateFunction,
      {
        id: match.params.id,
        email: e.email,
        menu: e.menu,
        company_name: e.company_name,
        description: e.description,
        facebook: e.facebook,
        website: e.website,
        instagram: e.instagram,
        linkedin: e.linkedin,
        phone_number: e.phone_number,
        photo: url || content.photo
      },
      {
        actions,
        alertBox
      }
    )
  }

  const mapPinIcon = Leaflet.icon({
    iconUrl: mapPin,
    iconSize: [58, 68],
    iconAnchor: [29, 68],
    popupAnchor: [170, 2]
  })

  const onSubmit = async (e, actions) => {
    if (window.location.href.includes('new')) {
      if (e.file) {
        const storageRef = ref(storage, `optics/${new Date().getTime()}`)
        const uploadTask = uploadBytesResumable(storageRef, e.file)

        uploadTask.on('state_changed', null, console.error, () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            const res = await mutationCreateEntity(e, url, actions)
            history.push(`/optics/${res.data.insert_optics_one.id}`)
          })
        })
      } else {
        const res = await mutationCreateEntity(e, null, actions)
        history.push(`/optics/${res.data.insert_optics_one.id}`)
      }
    } else {
      if (e.file) {
        const storageRef = ref(storage, `optics/${new Date().getTime()}`)
        const uploadTask = uploadBytesResumable(storageRef, e.file)

        uploadTask.on('state_changed', null, console.error, () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            await mutationUpdateEntity(e, url, actions)
          })
        })
      } else {
        await mutationUpdateEntity(e, null, actions)
      }
    }

    handleGoBack()
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <>
      <CustomStyle>
        <InternalBody>
          <p>
            Criado em:{' '}
            {moment(content?.created_at).format('DD/MM/YYYY HH:MM') || ''}
          </p>

          <GenerateForm
            onSubmit={onSubmit}
            groupFields={groupFields(content, setContent)}
            button={{
              submit: window.location.pathname.includes('new')
                ? 'Adicionar'
                : 'Atualizar',
              submitting: window.location.pathname.includes('new')
                ? 'Adicionando...'
                : 'Atualizando...'
            }}
          />

          {coordIsValid(content.adress?.latitude) && coordIsValid(content.adress?.longitude) && (
            <Col xs={12} style={{ height: '250px' }}>
              <MapContainer
                center={[content.adress.latitude, content.adress.longitude]}
                style={{ width: '100%', height: '100%' }}
                zoom={15}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  icon={mapPinIcon}
                  position={[
                    content.adress.latitude,
                    content.adress.longitude
                  ]}
                />
              </MapContainer>
            </Col>
          )}
        </InternalBody>
      </CustomStyle>
    </>
  )
}

export default InternalOptics
