import React from 'react'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { customTheme } from '../../../../styles/theme'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import { Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import CardModal from '.././../../components/CardModal'
import GenerateForm from '../../../components/layout/GenerateForm'
import ConfirmModal from '../../../components/ConfirmModal'
import DataTableActionColumn from '../../../components/DataTableActionColumn'
import { genericFetch } from '../../../../main/usecases/generic'

const handleEdit = (modal, updateEsp, params) => {
  modal.setOptions({
    open: true,
    component: (
      <CardModal>
        <GenerateForm
          onSubmit={(e, actions) =>
            updateEsp(params.row.id, e, actions)}
          groupFields={groupFields(params.row)}
          button={{
            submit: 'Atualizar',
            submitting: 'Atualizando'
          }}
        />
      </CardModal>
    )
  })
}

const handleDelete = async (modal, alertBox, mutation, refetch, row) => {
  const { id } = row

  modal.setOptions({
    open: true,
    component: (
      <CardModal style={{ width: '500px' }}>
        <ConfirmModal
          title="Deseja remover essa especialidade médica?"
          description="Fazendo isso ela não será mais usada"
          onCancel={async () => {

          }}
          onConfirm={async () => {
            await handleDeleteSpecialty(id, mutation, alertBox)
            refetch()
          }}
        />
      </CardModal>
    )
  })
}

const handleDeleteSpecialty = async (specialtiId, mutation, alertBox) => {
  await genericFetch(
    mutation,
    {
      id: specialtiId
    },
    {
      alertBox
    }
  )
}

export const columns = (modal, updateEsp, alertBox, remove, refetch) => [
  {
    field: 'icon',
    headerName: 'Ícone',
    width: 80,
    required: false,
    editable: true,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          {params.row.icon ? (
            <Avatar src={params.row.icon} />
          ) : (
            <AccountBalanceIcon
              style={{ color: customTheme.colors.primary }}
              edge="start"
              sx={{
                marginRight: '36px'
                // ...(open && { display: "none" }),
              }}
            />
          )}
        </Col>
      )
    }
  },
  {
    field: 'name',
    headerName: 'Nome',
    width: 250,
    editable: true
  },
  {
    field: 'description',
    headerName: 'Descrição',
    width: 340,
    required: false,
    editable: true
  },
  {
    field: 'created_at',
    headerName: 'Data de Cadastro',
    sortable: true,
    width: 200,
    valueGetter: (params) => `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
    valueFormatter: params => moment(params.value).format('DD/MM/YYYY - HH:MM')
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return (
        <DataTableActionColumn
          handleEdit={() => handleEdit(modal, updateEsp, params)}
          handleDelete={() => handleDelete(modal, alertBox, remove, refetch, params.row)}
        />
      )
    }
  }
]

export const groupFields = (content, setContent) => {
  return [
    {
      name: 'Gerenciar Especialidade',
      fields: [
        {
          label: 'Foto',
          type: 'file',
          required: false,
          disabled: false,
          hideField: false,
          name: 'icon',
          columns: {
            xs: 12
          },
          component: (data) => {
            return (
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <Avatar
                    src={data || content?.icon}
                    style={{ width: 100, height: 100 }}
                  />
                </Col>
              </Row>
            )
          }
        },
        {
          label: 'Nome',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.name || '',
          name: 'name',
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Descrição',
          type: 'textarea',
          required: false,
          disabled: false,
          currentValue: content?.description || '',
          hideField: false,
          name: 'description',
          columns: {
            xs: 12
          }
        }
      ]
    }
  ]
}
