import React, { useState } from 'react'
import Step1 from './steps/step-1'
import Step2 from './steps/step-2'
import PaymentsList from './paymentsList'

function Payments () {
  const [step, setStep] = useState(1)
  const [step1Data, setStep1Data] = useState()
  const [filter, setFilter] = useState()

  const onSubmitStep1 = (values) => {
    if (values.clinica) setStep1Data('clinica')
    else if (values.hospital) setStep1Data('hospital')
    else if (values.laboratorio) setStep1Data('laboratorio')
    else if (values.odontologia) setStep1Data('odontologia')

    setStep(2)
  }

  const onSubmitStep2 = (values) => {
    const filter = {}

    switch (values.__typename) {
      case 'laboratories':
        filter.laboratory_id = values.id
        break
      case 'dental_clinics':
        filter.dental_clinic_id = values.id
        break
      case 'hospitals':
        filter.hospital_id = values.id
        break
      default:
        filter.clinic_id = values.id
    }

    setFilter(filter)
    setStep(3)
  }

  switch (step) {
    case 1:
      return (
        <Step1 onSubmit={onSubmitStep1} />
      )
    case 2:
      return (
        <Step2 entity={step1Data} onSubmit={onSubmitStep2} onGoBack={() => setStep(1)} />
      )
    default:
      return (
        <PaymentsList initialFilter={filter} goBack={() => setStep(2)} />
      )
  }
}

export default Payments
