import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { useMutation, useQuery } from '@apollo/client'
import { columns, filterForm } from './columns'
import Loading from '../../../components/layout/Loading'
import { checktransfer, fetchPayments } from '../../../../services/payments'
import GenerateForm from '../../../components/layout/GenerateForm'
import { CustomStyle } from './styled'
import { useModal } from '../../../../contexts/modal'
import { useAuth } from '../../../../contexts/authenticator'

function PaymentsDiagnosticImage () {
  const [perPage, setPerPage] = useState(5)
  const [filter, setFilter] = useState({})
  const { id } = useAuth()

  const { loading, data, refetch } = useQuery(fetchPayments({
    medical_imaging_diagnostic_id: id
  }), {
    variables: {
      status: `%${
        filter?.status && filter?.status?.value !== 'all'
          ? filter?.status?.value + '%'
          : ''
      }`,
      medical_imaging_diagnostic_id: id
    },
    pollInterval: 300000
  })
  const [checkTransferMutation] = useMutation(checktransfer())
  const modal = useModal()

  const handleFilter = (e, actions) => {
    actions.setSubmitting(true)
    setFilter((prev) => ({ ...prev, status: e?.status }))
    actions.setSubmitting(false)
  }
  const checkTransfer = async (id) => {
    await checkTransferMutation({
      variables: {
        _in: [id]
      }
    })
    await refetch()
  }

  if (loading) return <Loading />
  return (
    <CustomStyle>
      <Row>
        <Col xs={12}>
          <h2>Pagamentos</h2>
          <div className="filter">
            <GenerateForm
              onSubmit={handleFilter}
              groupFields={filterForm(filter)}
              button={{
                submit: 'Filtrar',
                submitting: 'Filtrando...'
              }}
            />
          </div>
          {data?.payments && (
            <div style={{ height: 400, width: '100%', marginTop: '30px' }}>
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'created_at', sort: 'desc' }]
                  }
                }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={data.payments}
                columns={columns(modal, checkTransfer)}
                pageSize={perPage}
                onPageSizeChange={(e) => {
                  setPerPage(e)
                }}
                rowsPerPageOptions={[10]}
                pagination
                disableSelectionOnClick
              />
            </div>
          )}
        </Col>
      </Row>
    </CustomStyle>
  )
}

export default PaymentsDiagnosticImage
