// import api from "../api/api";
import { gql } from '@apollo/client'
import { fragmentAddresses } from '../address'

export const fetchOpticsById = () => {
  return gql`
    query getOpticByPk($id: Int!) {
      optics_by_pk(id: $id) {
        ...opticsFields
      }
    }

    ${fragmentOptics}
  `
}
export const deleteOptics = () => {
  return gql`
    mutation deleteOpticByPk($id: Int!) {
      delete_optics_by_pk(id: $id) {
        id
      }
    }

    ${fragmentOptics}
  `
}
export const fetchOpticsByFilter = () => {
  return gql`
    query getOptics(
      $limit: Int
      $offset: Int
      $city: String
      $state_code: String
    ) {
      optics(
        order_by: {updated_at: desc},
        limit: $limit,
        offset: $offset,
        where: {
          adress: { city: { _like: $city }, state_code: { _like: $state_code } }
        }
      ) {
        ...opticsFields
      }
    }
    ${fragmentOptics}
  `
}
export const createOptic = () => {
  return gql`
    mutation insertOpticOne(
      $company_name: String
      $description: String
      $email: String
      $facebook: String
      $instagram: String
      $linkedin: String
      $menu: String
      $phone_number: String
      $photo: String
      $website: String
      $address: String
      $city: String
      $complement: String
      $latitude: numeric
      $longitude: numeric
      $neighborhood: String
      $number: String
      $state: String
      $state_code: String
      $zipcode: String
    ) {
      insert_optics_one(
        object: {
          company_name: $company_name
          description: $description
          email: $email
          facebook: $facebook
          instagram: $instagram
          linkedin: $linkedin
          menu: $menu
          phone_number: $phone_number
          photo: $photo
          website: $website
          adress: {
            data: {
              address: $address
              city: $city
              complement: $complement
              latitude: $latitude
              longitude: $longitude
              neighborhood: $neighborhood
              number: $number
              state: $state
              state_code: $state_code
              zipcode: $zipcode
            }
          }
        }
      ) {
        ...opticsFields
      }
    }
    ${fragmentOptics}
  `
}
export const updateOptics = () => {
  return gql`
    mutation updateOpticByPk(
      $id: Int!
      $company_name: String
      $description: String
      $email: String
      $facebook: String
      $instagram: String
      $linkedin: String
      $menu: String
      $phone_number: String
      $photo: String
      $website: String
    ) {
      update_optics_by_pk(
        pk_columns: { id: $id }
        _set: {
          company_name: $company_name
          description: $description
          email: $email
          facebook: $facebook
          instagram: $instagram
          linkedin: $linkedin
          menu: $menu
          phone_number: $phone_number
          photo: $photo
          website: $website
        }
      ) {
        ...opticsFields
      }
    }
    ${fragmentOptics}
  `
}
export const fetchOptics = () => {
  return gql`
    query getPharmacies($limit: Int, $offset: Int) {
      pharmacies(order_by: {updated_at: desc}, limit: $limit, offset: $offset) {
        ...pharmacyFields
      }
    }
    ${fragmentOptics}
  `
}

export const fragmentOptics = gql`
  fragment opticsFields on optics {
    adress {
      ...addressFields
    }
    company_name
    created_at
    description
    email
    facebook
    id
    instagram
    linkedin
    menu
    phone_number
    photo
    updated_at
    website
  }
  ${fragmentAddresses}
`

export const fragmentResumedUserFields = gql`
  fragment resumedUserFields on users {
    id
    full_name
    email
    phone
    photo
  }
`
