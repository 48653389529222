import ContactPageIcon from '@mui/icons-material/ContactPage'
import Screening from './pacient-data/screening'
import AnamneseList from './anamnese-list'
import Schedules from './schedules'
import PacientData from './pacient-data'

export const tabsData = {
  doctor: [
    {
      name: 'Dados Pessoais',
      key: 'personal-data',
      icon: <ContactPageIcon />,
      tabs: [
        {
          name: 'Dados Pessoais',
          component: (data) => <PacientData data={data} />
        },
        {
          name: 'Triagem',
          component: (data) => <Screening id={data.id} />
        },
        {
          name: 'Agendamentos',
          component: (data) => <Schedules id={data.id} />
        },
        {
          name: 'Anamnese',
          component: () => <AnamneseList />
        }
      ]
    }
  ]
}
