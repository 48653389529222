import * as S from './styled'
import ButtonPrimary from '../../components/Button'

function PrescriptionSucceded ({ location }) {
  const handleGoToPrescriptions = () => {
    const scheduleId = location.pathname.split('/')[2]
    window.open(`/doctor-requests-schedules/${scheduleId || ''}`, '_blank')
  }

  return (
    <S.Container>
      <h1>Prescrição feita com sucesso!</h1>

      <ButtonPrimary onClick={handleGoToPrescriptions}>Ir para o agendamento</ButtonPrimary>
    </S.Container>
  )
}

export default PrescriptionSucceded
