import React, { useState } from 'react'
import InternalDoctor from '../../master/doctors/Internal'

function DoctorProfile (props) {
  const [id] = useState(props.user_id)

  return <div>{id && <InternalDoctor match={{ params: { id: id } }} />}</div>
}

export default DoctorProfile
