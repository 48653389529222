import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import MainContainer from '../../../components/MainContainer'
import Header from '../../../components/Header'
import DraftTextArea from '../../../components/DraftTextArea'
import Loading from '../../../components/layout/Loading'
import { addTermsOfUse, fetchTermsOfUse } from '../../../../services/terms-of-use'
import { useAlertBox } from '../../../../contexts/alert_box'
import { AuthService } from '../../../../services/auth/auth'
import * as S from './styled'

function TermsOfUse () {
  const alertBox = useAlertBox()
  const { data, loading } = useQuery(fetchTermsOfUse())
  const [mutateTermsOfUse] = useMutation(addTermsOfUse())
  const auth = new AuthService()
  const [content, setContent] = useState('')

  useEffect(() => {
    if (data?.legal_documents_by_pk?.terms_of_use) {
      setContent(data?.legal_documents_by_pk?.terms_of_use)
    }
  }, [data])

  const onSubmit = () => {
    mutateTermsOfUse({
      variables: {
        terms_of_use: content
      }
    })

    alertBox.setOptions({
      open: true,
      message: 'Salvo com sucesso!',
      type: 'success',
      time: 3000
    })
  }

  const onAddNotifications = () => {
    try {
      auth.createNotification({
        title: 'Atualizamos nossos termos de uso',
        description: 'Clique aqui para ver',
        to_all_users: true,
        payload: {
          route: '/web_view',
          title: 'Termos de Uso',
          url: 'Env.termsOfUse'
        }
      })
      alertBox.setOptions({
        open: true,
        message: 'Enviado com sucesso!',
        type: 'success',
        time: 3000
      })
    } catch (err) {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao enviar as notificações ao usuários',
        type: 'error',
        time: 3000
      })
    }
  }

  console.log('content', content)

  return (
    <MainContainer container>
      <Header title="Termos de uso" />

      {loading && (<Loading />)}

      {!loading && (
        <DraftTextArea
          style={{ height: '70vh' }}
          value={content}
          onChange={setContent}
        />
      )}

      <S.Actions>
        <S.Button onClick={onAddNotifications}>
          Notificar usuários
        </S.Button>
        <Link to="/terms-of-use" target="_blank">
          Visualizar
        </Link>
        <S.Button onClick={onSubmit}>
          Salvar
        </S.Button>
      </S.Actions>
    </MainContainer>
  )
}

export default TermsOfUse
