export const genericFetch = async (mutation, params, extras) => {
  try {
    const res = await mutation({
      variables: params
    })

    extras?.actions?.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: 'Atualizado com Sucesso!',
      type: 'success',
      time: 3000
    })
    return res
  } catch (error) {
    console.error(error)
    extras?.actions?.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro Ao atualizar!',
      type: 'error',
      time: 3000
    })
  }
}
export const genericCreateFetch = async (mutation, params, extras) => {
  try {
    const res = await mutation({
      variables: params
    })

    extras?.actions?.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: 'Criado com Sucesso!',
      type: 'success',
      time: 3000
    })
    return res
  } catch (error) {
    console.error(error)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro Ao Adicionar!',
      type: 'error',
      time: 3000
    })
  }
}

export const genericDeleteFetch = async (mutation, params, extras) => {
  try {
    const res = await mutation({
      variables: params
    })

    extras?.actions?.setSubmitting(false)
    extras.alertBox.setOptions({
      open: true,
      message: 'Deletado com Sucesso!',
      type: 'success',
      time: 3000
    })
    return res
  } catch (error) {
    console.error(error)
    extras.alertBox.setOptions({
      open: true,
      message: 'Erro Ao Deletar!',
      type: 'error',
      time: 3000
    })
  }
}
