import { getScheduleStatus } from '../../../../Utils/schedules'
import moment from 'moment'

function getCompanyName (params) {
  if (params.row.doctors_appointment) {
    if (params.row.doctors_appointment.clinic) {
      return params.row.doctors_appointment.clinic.company_name
    }
    if (params.row.doctors_appointment.hospital) {
      return params.row.doctors_appointment.hospital.company_name
    }
  }
  if (params.row.petshop_appointment) {
    if (params.row.petshop_appointment.pet_shop) {
      return params.row.petshop_appointment.pet_shop.company_name
    }
  }
  if (params.row.susAppoitments.length > 0) {
    if (params.row.susAppoitments[0].su) {
      return params.row.susAppoitments[0].su.name
    }
  }
  return 'Empresa não encontrada'
}

function getCompanyAddress (params) {
  if (params.row.doctors_appointment) {
    if (params.row.doctors_appointment.clinic) {
      return `${params.row.doctors_appointment.clinic.adress.city} - ${params.row.doctors_appointment.clinic.adress.state_code}`
    }
    if (params.row.doctors_appointment.hospital) {
      return `${params.row.doctors_appointment.hospital.adress.city} - ${params.row.doctors_appointment.hospital.adress.state_code}`
    }
  }
  if (params.row.petshop_appointment) {
    if (params.row.petshop_appointment.pet_shop) {
      return `${params.row.petshop_appointment.pet_shop.adress.city} - ${params.row.petshop_appointment.pet_shop.adress.state_code}`
    }
  }
  if (params.row.susAppoitments.length > 0) {
    if (params.row.susAppoitments[0].su) {
      return `${params.row.susAppoitments[0].su.adress.city} - ${params.row.susAppoitments[0].su.adress.state_code}`
    }
  }
  return ''
}

function getUserName (params) {
  if (params.row.user) {
    return params.row.user.full_name
  }
  return 'Usuário não encontrado'
}

function getDoctorName (params) {
  if (params.row.doctors_appointment) {
    if (params.row.doctors_appointment.doctor) {
      return params.row.doctors_appointment.doctor.full_name
    }
  }
  return 'Médico não encontrado'
}

function getStatus (params) {
  return getScheduleStatus(params.row.status)
}

export const columns = () => [
  {
    field: 'company_name',
    headerName: 'Empresa',
    width: 300,
    editable: true,
    valueGetter: getCompanyName
  },
  {
    field: 'company_address',
    headerName: 'Cidade/UF',
    width: 200,
    editable: true,
    valueGetter: getCompanyAddress
  },
  {
    field: 'user',
    headerName: 'Usuário',
    width: 300,
    editable: true,
    valueGetter: getUserName
  },
  {
    field: 'doctor',
    headerName: 'Médico',
    width: 250,
    editable: true,
    valueGetter: getDoctorName
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    editable: true,
    valueGetter: getStatus
  },
  {
    field: 'created_at',
    headerName: 'Data',
    sortable: true,
    width: 150,
    valueGetter: (params) => `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
    valueFormatter: params => moment(params.value).format('DD/MM/YYYY - HH:MM')
  }
]
