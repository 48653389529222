import styled from 'styled-components'

export const Wrapper = styled.div``

export const ConfirmModal = styled.div`
  h2 {
    font-size: 20px;
    text-align: center;
    line-height: 2;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    button {
      width: 46%;
      box-shadow: none;
      padding: 10px 0;
    }
  }
`
