import { useState } from 'react'
import * as Yup from 'yup'
import country from '../../../../Utils/estados_cidades.json'
import { fecthPlans } from '../../../../services/plans'
import { useQuery } from '@apollo/client'
import Avatar from '@material-ui/core/Avatar'
import { Col } from 'react-bootstrap'
import DataTableActionColumn from '../../../components/DataTableActionColumn'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { customTheme } from '../../../../styles/theme'
import Button from '@mui/material/Button'

export const especiality = (esp, especialities) => {
  especialities = especialities?.map((item) => ({ ...item, value: item.id }))
  return [
    {
      name: '',
      fields: [
        {
          label: 'Filtrar por especialidade',
          type: 'autocomplete',
          required: true,
          disabled: esp,
          ...(esp && {
            currentValue: {
              label: especialities?.find(
                (el) => el.value === esp?.medical_specialty?.id
              )?.name,
              value: especialities?.find(
                (el) => el.value === esp?.medical_specialty?.id
              )?.value
            }
          }),
          hideField: false,

          name: 'sus_with_medical_specialties',
          options: especialities?.map((item) => ({
            label: item.name,
            value: item.value
          })),
          columns: {
            xs: 12
          },
          validation: Yup.object().required('Campo obrigatório')
        }
      ]
    }
  ]
}

export const columns = (history, doctorId) => [
  {
    field: 'photo',
    headerName: 'Foto',
    width: 60,
    editable: true,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          {params.row.photo ? (
            <Avatar src={params.row.photo} />
          ) : (
            <AccountBalanceIcon
              style={{ color: customTheme.colors.primary }}
              edge="start"
              sx={{
                marginRight: '36px'
              }}
            />
          )}
        </Col>
      )
    }
  },
  {
    field: 'name',
    headerName: 'Nome',
    width: 250,
    sortable: true
  },
  {
    field: 'phone_number',
    headerName: 'Telefone',
    sortable: true,
    width: 200
  },
  {
    field: 'email',
    headerName: 'Email',
    sortable: true,
    width: 250
  },
  {
    field: 'id',
    headerName: 'Visualizar',
    sortable: false,
    width: 180,
    renderCell: (params) => {
      return (
        <DataTableActionColumn
          handleVisibility={() => history.push(`my-list-sus/${params.row.id}`)}
        />
      )
    }
  },
  {
    field: '',
    headerName: 'Salas de atendimento',
    sortable: false,
    width: 180,
    renderCell: (params) => {
      return (
        <Button variant="contained" onClick={() => history.push(`/doctor/my-waiting-rooms/${params.row.id}`, { doctorId })}>
          Ver
        </Button>
      )
    }
  }
]

export const groupFields = (content, setContent) => {
  const cities = []
  const states = []
  const [selectdState, setSelectedState] = useState(null)
  const { data } = useQuery(fecthPlans())
  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e.target.value }))
    }
  }
  const handleInputChangeAddress = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        adress: { ...prev.adress, [key]: e.target.value }
      }))
    }
  }
  const handleInputChangeCompanyPlan = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        company_plan: { ...prev.company_plan, [key]: e.target.value }
      }))
    }
  }

  return [
    {
      fields: [
        {
          label: 'Nome completo',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.company_name,
          name: 'company_name',
          onChange: (e) => {
            handleInputChange(e, 'company_name')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Gênero',
          type: 'autocomplete',
          required: true,
          hideField: false,
          options: [
            {
              value: 'masculine',
              label: 'Masculino'
            },
            {
              value: 'feminine',
              label: 'Feminino'
            },
            {
              value: 'other',
              label: 'Outro'
            }
          ],
          onChange: (e) => {
            handleInputChange(e, 'gender')
          },
          currentValue: content?.gender || '',
          name: 'email',
          columns: {
            sm: 4
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Razão civil',
          type: 'autocomplete',
          required: true,
          hideField: false,
          options: [
            {
              value: 'masculine',
              label: 'Masculino'
            },
            {
              value: 'feminine',
              label: 'Feminino'
            },
            {
              value: 'other',
              label: 'Outro'
            }
          ],
          onChange: (e) => {
            handleInputChange(e, 'gender')
          },
          currentValue: content?.gender || '',
          name: 'email',
          columns: {
            xs: 2
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Nascimento',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'birth_date')
          },
          currentValue: content?.birth_date || '',
          hideField: false,
          name: 'birth_date',
          mask: '99/99/9999',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'CPF / CNPJ',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'cnpj')
          },
          currentValue: content?.cnpj || content?.cpf,
          hideField: false,
          name: content?.cnpj ? 'cnpj' : 'CPF',
          mask: content?.cnpj ? '99.999.999/9999-99' : '999.999.999-99',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Telefone',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'phone_number')
          },
          currentValue: content?.phone_number,
          hideField: false,
          mask: '+ 99 (99) 9 9999-9999',
          name: 'phone_number',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido')
              .required('Campo obrigatório')
              .test('phoneComplete', 'O campo precisa ser preenchido totalmente!', (content) => {
                const phoneNumber = content?.replace(/[^\d]/g, '')
                return phoneNumber?.length === 13
              })
        },
        {
          label: 'Website URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'website')
          },
          currentValue: content?.website || 'https://',
          hideField: false,
          name: 'website',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Facebook URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'facebook')
          },
          currentValue: content?.facebook || 'https://',
          hideField: false,
          name: 'facebook',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Instagram URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'instagram')
          },
          currentValue: content?.instagram || 'https://',
          hideField: false,
          name: 'instagram',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Linkedin URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'linkedin')
          },
          currentValue: content?.linkedin || 'https://',
          hideField: false,
          name: 'linkedin',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Observação',
          type: 'textarea',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'description')
          },
          currentValue: content?.description,
          hideField: false,
          name: 'description',
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]
}
