import { gql } from '@apollo/client'

export const updateAddress = () => {
  return gql`
    ${fragmentAddresses}
    mutation updateAddress(
      $id: Int
      $address: String
      $city: String
      $complement: String
      $latitude: numeric
      $longitude: numeric
      $neighborhood: String
      $number: String
      $state: String
      $state_code: String
      $zipcode: String
    ) {
      update_adresses(
        where: { id: { _eq: $id } }
        _set: {
          address: $address
          city: $city
          complement: $complement
          latitude: $latitude
          longitude: $longitude
          neighborhood: $neighborhood
          number: $number
          state: $state
          state_code: $state_code
          zipcode: $zipcode
        }
      ) {
        affected_rows
        returning {
          ...addressFields
        }
      }
    }
  `
}

export const fragmentAddresses = gql`
  fragment addressFields on adresses {
    address
    id
    city
    state
    state_code
    complement
    created_at
    updated_at
    neighborhood
    number
    zipcode
    longitude
    latitude
  }
`
