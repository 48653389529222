import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import { Col } from 'react-bootstrap'
import * as Yup from 'yup'
import country from '../../../../Utils/estados_cidades.json'
import DataTableActionColumn from '../../../components/DataTableActionColumn'

export const columns = (modal, alertBox, remove, refetch, history) => [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: 'photo',
    headerName: 'Foto',
    width: 60,
    editable: true,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          {params?.row?.photo
            ? (
              <Avatar src={params.row.photo} />
              )
            : (
              <Avatar>{params?.row?.full_name[0]}</Avatar>
              )}
        </Col>
      )
    }
  },
  {
    field: 'full_name',
    headerName: 'Nome',
    width: 200,
    editable: true
  },
  {
    field: 'birth_date',
    headerName: 'Nascimento',
    sortable: true,
    width: 150,
    valueGetter: (params) => new Date(params.row.birth_date).toLocaleDateString('pt-BR')
  },
  {
    field: 'cpf',
    headerName: 'CPF',
    sortable: true,
    width: 150
  },
  {
    field: 'phone',
    headerName: 'Telefone',
    width: 200
  },
  {
    field: 'id',
    headerName: '',
    sortable: false,
    width: 180,
    renderCell: (params) => {
      return (
        <DataTableActionColumn
          handleVisibility={() => history.push(`my-pacients/${params.row.id}`)}
        />
      )
    }
  }
]

export const groupFields = (content, setContent) => {
  const cities = []
  const states = []
  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e.target.value }))
    }
  }

  return [
    {
      fields: [
        {
          label: 'Nome completo',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.company_name,
          name: 'company_name',
          onChange: (e) => {
            handleInputChange(e, 'company_name')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Gênero',
          type: 'autocomplete',
          required: true,
          hideField: false,
          options: [
            {
              value: 'masculine',
              label: 'Masculino'
            },
            {
              value: 'feminine',
              label: 'Feminino'
            },
            {
              value: 'other',
              label: 'Outro'
            }
          ],
          onChange: (e) => {
            handleInputChange(e, 'gender')
          },
          currentValue: content?.gender || '',
          name: 'email',
          columns: {
            sm: 4
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Razão civil',
          type: 'autocomplete',
          required: true,
          hideField: false,
          options: [
            {
              value: 'masculine',
              label: 'Masculino'
            },
            {
              value: 'feminine',
              label: 'Feminino'
            },
            {
              value: 'other',
              label: 'Outro'
            }
          ],
          onChange: (e) => {
            handleInputChange(e, 'gender')
          },
          currentValue: content?.gender || '',
          name: 'email',
          columns: {
            xs: 2
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Nascimento',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'birth_date')
          },
          currentValue: content?.birth_date || '',
          hideField: false,
          name: 'birth_date',
          mask: '99/99/9999',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'CPF / CNPJ',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'cnpj')
          },
          currentValue: content?.cnpj || content?.cpf,
          hideField: false,
          name: content?.cnpj ? 'cnpj' : 'CPF',
          mask: content?.cnpj ? '99.999.999/9999-99' : '999.999.999-99',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Telefone',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'phone_number')
          },
          currentValue: content?.phone_number,
          hideField: false,
          mask: '+ 99 (99) 9 9999-9999',
          name: 'phone_number',
          columns: {
            xs: 6
          },
          validation:
          Yup.string('Valor inválido')
            .required('Campo obrigatório')
            .test('phoneComplete', 'O campo precisa ser preenchido totalmente!', (content) => {
              const phoneNumber = content?.replace(/[^\d]/g, '')
              return phoneNumber?.length === 13
            })
        },
        {
          label: 'Website URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'website')
          },
          currentValue: content?.website || 'https://',
          hideField: false,
          name: 'website',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Facebook URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'facebook')
          },
          currentValue: content?.facebook || 'https://',
          hideField: false,
          name: 'facebook',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Instagram URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'instagram')
          },
          currentValue: content?.instagram || 'https://',
          hideField: false,
          name: 'instagram',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Linkedin URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'linkedin')
          },
          currentValue: content?.linkedin || 'https://',
          hideField: false,
          name: 'linkedin',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Observação',
          type: 'textarea',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'description')
          },
          currentValue: content?.description,
          hideField: false,
          name: 'description',
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]
}
