import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlertBox } from '../../../../contexts/alert_box'
import InternalBody from '../../../components/InternalBody'
import GenerateForm from '../../../components/layout/GenerateForm'
import { columnsPlans, groupFields, groupFieldsPlans } from './columns'
import { genericFetch } from '../../../../main/usecases/generic'
import { useHistory } from 'react-router-dom'
import {
  fetchCooperativeById,
  updateCooperative,
  createCooperative
} from '../../../../services/cooperative'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { CustomLayout } from './styled'
import CardModal from '../../../components/CardModal'
import { useModal } from '../../../../contexts/modal'
import {
  createCooperativeInstallmentPlan,
  removeCooperativeInstallmentPlan,
  updateCooperativeInstallmentPlan
} from '../../../../services/cooperativeInstallment'
import { stringToFloat } from '../../../../Utils/format'
import { Col } from 'react-bootstrap'
import ButtonPrimary from '../../../components/Button'
import { customTheme } from '../../../../styles/theme'
import AddCircleIcon from '@material-ui/icons/AddCircle'

function InternalCooperatives ({ match }) {
  const [perPage, setPerPage] = useState(5)
  const { data, refetch } = useQuery(fetchCooperativeById(), {
    variables: { id: match.params.id }
  })
  const [mutateFunction] = useMutation(updateCooperative())
  const [createPlanMutation] = useMutation(createCooperative())
  const [updatePlanMutations] = useMutation(updateCooperativeInstallmentPlan())
  const [createPlanMutations] = useMutation(createCooperativeInstallmentPlan())
  const [removePlanMutations] = useMutation(removeCooperativeInstallmentPlan())
  const [content, setContent] = useState(null)
  const alertBox = useAlertBox()
  const modal = useModal()
  const history = useHistory()
  useEffect(() => {
    if (data?.cooperatives[0]) {
      setContent(data?.cooperatives[0])
    }
  }, [data])

  const mutationCreateClinic = async (e, url, actions) => {
    return await genericFetch(
      createPlanMutation,
      {
        name: e.name,
        site: e.site,
        terms_of_use: e.terms_of_use,
        terms_of_use_url_site: e.terms_of_use_url_site
      },
      {
        actions,
        alertBox
      }
    )
  }

  const mutationUpdateClinic = async (e, url, actions) => {
    await genericFetch(
      mutateFunction,
      {
        id: match.params.id,
        name: e.name,
        site: e.site,
        terms_of_use: e.terms_of_use,
        terms_of_use_url_site: e.terms_of_use_url_site
      },
      {
        actions,
        alertBox
      }
    )
  }

  const onSubmit = async (e, actions) => {
    if (window.location.href.includes('new')) {
      const res = await mutationCreateClinic(e, null, actions)
      history.push(`/cooperatives/${res.data.insert_cooperatives_one.id}`)
    } else {
      await mutationUpdateClinic(e, null, actions)
    }
  }

  const openPlans = (plan) => {
    const onSubmitPlan = async (e, actions) => {
      if (plan?.id) {
        await genericFetch(
          updatePlanMutations,
          {
            id: plan.id,
            minimum_value: stringToFloat(e.minimum_value),
            number_of_installments: stringToFloat(e.number_of_installments),
            fees: stringToFloat(e.fees)
          },
          {
            actions,
            alertBox
          }
        )
        refetch()
        modal.setOptions({ open: false })
      } else {
        await genericFetch(
          createPlanMutations,
          {
            objects: [
              {
                cooperative_id: content?.id,
                minimum_value: stringToFloat(e.minimum_value),
                number_of_installments: stringToFloat(e.number_of_installments),
                fees: stringToFloat(e.fees)
              }
            ]
          },
          {
            actions,
            alertBox
          }
        )
        refetch()
        modal.setOptions({ open: false })
      }
    }

    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '90%' }}>
          <GenerateForm
            onSubmit={onSubmitPlan}
            groupFields={groupFieldsPlans(plan)}
            button={{
              submit: !plan?.id ? 'Adicionar' : 'Atualizar',
              submitting: !plan?.id ? 'Adicionando...' : 'Atualizando...'
            }}
          />
        </CardModal>
      )
    })
  }

  return (
    <>
      <CustomLayout>
        <InternalBody>
          <p>
            Criado em:{' '}
            {moment(content?.created_at).format('DD/MM/YYYY HH:MM') || ''}
          </p>

          <GenerateForm
            onSubmit={onSubmit}
            groupFields={groupFields(content, setContent)}
            button={{
              submit: match.params.id === 'new' ? 'Adicionar' : 'Atualizar',
              submitting:
                match.params.id === 'new' ? 'Adicionando...' : 'Atualizando...'
            }}
          />
          {content?.id && (
            <div className="plan-container mt-5">
              <h5 className="m-0">Tarifas</h5>
              <Col xs={12}>
                <ButtonPrimary
                  onClick={() => openPlans(null)}
                  style={{
                    marginBottom: '15px',
                    marginTop: '10px',
                    boxShadow: 'none',
                    fontSize: '12px',
                    padding: '5px',
                    backgroundColor: customTheme.colors.primary,
                    lineHeight: '2',
                    maxWidth: 150,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  Adicionar Tarifa
                  <AddCircleIcon
                    style={{ color: customTheme.colors.white, marginLeft: 5 }}
                  />
                </ButtonPrimary>
              </Col>
              <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={
                  content?.cooperative_installment_plans.map((item, index) => ({
                    ...item,
                    index
                  })) || []
                }
                columns={columnsPlans(openPlans, removePlanMutations, refetch)}
                pageSize={perPage}
                onPageSizeChange={(e) => {
                  setPerPage(e)
                }}
                rowsPerPageOptions={[10]}
                pagination
                disableSelectionOnClick
              />
            </div>
          )}
        </InternalBody>
      </CustomLayout>
    </>
  )
}

export default InternalCooperatives
