import React, { useEffect, useState } from 'react'
import InternalClinic from '../../master/clinics/Internal'

function ClinicProfile (props) {
  const [id] = useState(props.user_id)

  return <div>{id && <InternalClinic match={{ params: { id: id } }} />}</div>
}

export default ClinicProfile
