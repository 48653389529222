import React from 'react'
import { useQuery } from '@apollo/client'
import { columns } from './columns'
import { useModal } from '../../../../contexts/modal'
import { fecthRequestsCredit } from '../../../../services/creditRequest'
import DataTable from '../../../components/DataTable'
import Header from '../../../components/Header'
import MainContainer from '../../../components/MainContainer'

function CreditRequest ({ list }) {
  const { loading, data } = useQuery(fecthRequestsCredit(), {
    pollInterval: 300000
  })
  const modal = useModal()

  if (loading) return <span>Carregando...</span>

  return (
    <MainContainer>
      <Header title="Solicitações de Crédito" />

      {data?.credit_requests && (
        <DataTable
          data={list || data.credit_requests}
          columns={columns(modal)}
        />
      )}
    </MainContainer>
  )
}

export default CreditRequest
