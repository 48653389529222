import axios from 'axios'

async function getJitsiJWT (userEmail, userName, roomID) {
  try {
    const response = await axios
      .post(process.env.REACT_APP_FUNCTIONS_URL + '/getRoomJwtAuthenticator', {
        user_name: userName,
        call_id: process.env.REACT_APP_CALL_ID + roomID,
        user_email: userEmail,
        room_id: roomID
      })

    return response.data.acess_token
  } catch (err) {
    throw new Error(err)
  }
}

export default getJitsiJWT
