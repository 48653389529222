import styled from 'styled-components'
import ButtonPrimary from '../../../components/Button'

export const Wrapper = styled.div``

export const Button = styled(ButtonPrimary)`
  padding: 0.6rem;
  font-size: 0.875rem;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem
`
