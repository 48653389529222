import React, { useState } from 'react'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import { customTheme } from '../../../../styles/theme'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import {
  formatNumber,
  phoneMask,
  refactorLabelPlan
} from '../../../../Utils/format'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import MailOutlineOutlined from '@mui/icons-material/MailOutlineOutlined'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import ButtonPrimary from '../../../components/Button'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import country from '../../../../Utils/estados_cidades.json'
import { fecthPlans } from '../../../../services/plans'
import { useQuery } from '@apollo/client'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { ModalConfirm } from '../../hospital/schedules-hospital/styled'
import CardModal from '../../../components/CardModal'
import { AuthService } from '../../../../services/auth/auth'
import DataTableActionColumn from '../../../components/DataTableActionColumn'

const handleEdit = (modal, history, params) => {
  history.push(`clinics/${params.row.id}`)
}

const handleDelete = async (modal, alertBox, remove, refetch, params) => {
  modal.setOptions({
    open: true,
    component: (
      <CardModal style={{ width: '400px' }}>
        <ModalConfirm>
          <h3>Tem certeza que deseja apagar?</h3>
          <div className="buttons-area">
            <ButtonPrimary
              id="delete-button"
              onClick={async () => {
                try {
                  document.getElementById('delete-button').disabled = true
                  const auth = new AuthService()

                  await remove({
                    variables: {
                      id: params.row.id
                    }
                  })
                  await auth.removeUser({
                    email: params.row.email
                  })
                  await refetch()
                  alertBox.setOptions({
                    open: true,
                    message: 'Sucesso ao apagar!',
                    type: 'success',
                    time: 3000
                  })
                  modal.setOptions({ open: false })
                } catch (error) {
                  alertBox.setOptions({
                    open: true,
                    message: 'Erro Ao apagar Clinica',
                    type: 'error',
                    time: 3000
                  })
                  console.error(error)
                  modal.setOptions({ open: false })
                }
              }}
            >
              Sim
            </ButtonPrimary>

            <ButtonPrimary
              onClick={() => modal.setOptions({ open: false })}
              style={{
                backgroundColor: customTheme.colors.error,
                boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`
              }}
            >
              Não
            </ButtonPrimary>
          </div>
        </ModalConfirm>
      </CardModal>
    )
  })
}

const handleSendEmail = async (alertBox, params) => {
  try {
    const auth = new AuthService()
    await auth.recoveryPassword(params?.row?.email)
    alertBox.setOptions({
      open: true,
      message: 'Enviado com Sucesso!',
      type: 'success',
      time: 3000
    })
  } catch (error) {
    alertBox.setOptions({
      open: true,
      message: 'Erro ao enviar',
      type: 'error',
      time: 3000
    })
    console.error(error)
  }
}

export const columns = (modal, alertBox, remove, refetch, history) => [
  // { field: "id", headerName: "ID", width: 90 },
  {
    field: 'photo',
    headerName: 'Foto',
    width: 60,
    editable: true,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          {params?.row?.photo
            ? (
              <Avatar src={params.row.photo} />
              )
            : (
              <Avatar>{params?.row?.full_name[0]}</Avatar>
              )}
        </Col>
      )
    }
  },
  {
    field: 'full_name',
    headerName: 'Nome',
    width: 200,
    editable: true
  },
  {
    field: 'birth_date',
    headerName: 'Nascimento',
    sortable: true,
    width: 150,
    valueGetter: (params) => new Date(params.row.birth_date).toLocaleDateString('pt-BR')
  },
  {
    field: 'cpf',
    headerName: 'CPF',
    sortable: true,
    width: 150
  },
  {
    field: 'phone',
    headerName: 'Telefone',
    width: 200
  },
  {
    field: 'id',
    headerName: '',
    sortable: false,
    width: 180,
    renderCell: (params) => {
      return (
        <DataTableActionColumn
          handleVisibility={() => history.push(`pacients/${params.row.id}`)}
        />
      )
    }
  }
]

export const groupFields = (content, setContent) => {
  const cities = []
  const states = []
  const [selectdState, setSelectedState] = useState(null)
  const { data } = useQuery(fecthPlans())
  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e.target.value }))
    }
  }
  const handleInputChangeAddress = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        adress: { ...prev.adress, [key]: e.target.value }
      }))
    }
  }
  const handleInputChangeCompanyPlan = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        company_plan: { ...prev.company_plan, [key]: e.target.value }
      }))
    }
  }

  return [
    {
      fields: [
        {
          label: 'Nome completo',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.company_name,
          name: 'company_name',
          onChange: (e) => {
            handleInputChange(e, 'company_name')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Gênero',
          type: 'autocomplete',
          required: true,
          hideField: false,
          options: [
            {
              value: 'masculine',
              label: 'Masculino'
            },
            {
              value: 'feminine',
              label: 'Feminino'
            },
            {
              value: 'other',
              label: 'Outro'
            }
          ],
          onChange: (e) => {
            handleInputChange(e, 'gender')
          },
          currentValue: content?.gender || '',
          name: 'email',
          columns: {
            sm: 4
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Razão civil',
          type: 'autocomplete',
          required: true,
          hideField: false,
          options: [
            {
              value: 'masculine',
              label: 'Masculino'
            },
            {
              value: 'feminine',
              label: 'Feminino'
            },
            {
              value: 'other',
              label: 'Outro'
            }
          ],
          onChange: (e) => {
            handleInputChange(e, 'gender')
          },
          currentValue: content?.gender || '',
          name: 'email',
          columns: {
            xs: 2
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Nascimento',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'birth_date')
          },
          currentValue: content?.birth_date || '',
          hideField: false,
          name: 'birth_date',
          mask: '99/99/9999',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'CPF / CNPJ',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'cnpj')
          },
          currentValue: content?.cnpj || content?.cpf,
          hideField: false,
          name: content?.cnpj ? 'cnpj' : 'CPF',
          mask: content?.cnpj ? '99.999.999/9999-99' : '999.999.999-99',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Telefone',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'phone_number')
          },
          currentValue: content?.phone_number,
          hideField: false,
          mask: '+ 99 (99) 9 9999-9999',
          name: 'phone_number',
          columns: {
            xs: 6
          },
          validation:
          Yup.string('Valor inválido')
            .required('Campo obrigatório')
            .test('phoneComplete', 'O campo precisa ser preenchido totalmente!', (content) => {
              const phoneNumber = content?.replace(/[^\d]/g, '')
              return phoneNumber?.length === 13
            })
        },
        {
          label: 'Website URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'website')
          },
          currentValue: content?.website || 'https://',
          hideField: false,
          name: 'website',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Facebook URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'facebook')
          },
          currentValue: content?.facebook || 'https://',
          hideField: false,
          name: 'facebook',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Instagram URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'instagram')
          },
          currentValue: content?.instagram || 'https://',
          hideField: false,
          name: 'instagram',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Linkedin URL',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'linkedin')
          },
          currentValue: content?.linkedin || 'https://',
          hideField: false,
          name: 'linkedin',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        },
        {
          label: 'Observação',
          type: 'textarea',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'description')
          },
          currentValue: content?.description,
          hideField: false,
          name: 'description',
          columns: {
            xs: 12
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]
}
