import React, { useEffect, useState } from 'react'
import { FormGroup } from 'react-bootstrap'
import { CheckBokField, LabelFloat } from './styled'
import InputMask from 'react-input-mask'
import Select from 'react-select'
import { Field } from 'formik'
import NumberFormat from 'react-number-format'
import { MdAttachFile } from 'react-icons/md'
import * as S from './styled'

const MakeField = ({ type, partialSubmit, hideField, ...el }) => {
  const [tempValue, setTempValue] = useState(el?.currentValue || '')
  const { file, setFile } = el.fileAttr
  const [fileLocal, setFileLocal] = useState(null)
  const [options, setOptions] = useState([])

  useEffect(() => {
    if (el.currentValue !== undefined) {
      setTempValue(el.currentValue)
    }
  }, [el.currentValue])

  useEffect(() => {
    if (el?.options?.length) {
      setOptions(el?.options)
    }
  }, [el?.options])

  const onChange = (event) => {
    partialSubmit?.(event.target.value)
    setTempValue(event.target.value)
  }

  switch (type) {
    case 'component':
      return (
        <label htmlFor={el.name} className="d-block">
          {el.component()}
        </label>
      )
    case 'attachment':
      return (

        <Field name={el.name} element={el}>
          {({ field, form }) => {
            return (
              <>
                <S.LabelInput htmlFor={el.name}>
                  {file ? (
                    <>
                      <MdAttachFile />
                      {file.name}
                    </>
                  ) : el.label}
                </S.LabelInput>
                <input
                  {...field}
                  id={el.name}
                  disabled={el.disabled}
                  autoComplete="off"
                  className="form-control"
                  type="file"
                  onChange={(e) => {
                    if (el.onChange) {
                      el.onChange(e)
                    }
                    onChange(e)
                    if (e.target.files.length === 0) {
                      setFile(null)
                      return
                    }
                    setFile(e?.target?.files[0])
                  }}
                  style={{ marginBottom: 4, display: 'none' }}
                />
              </>
            )
          }}
        </Field>
      )
    case 'file':
      return (
        <label htmlFor={el.name} className="d-block">
          <Field name={el.name} element={el}>
            {({ field }) => {
              return (
                <>
                  {el.component(fileLocal || el.actions.values[el.name])}
                  <InputMask
                    {...field}
                    id={el.name}
                    hidden
                    disabled={el.disabled}
                    autoComplete="off"
                    className="form-control"
                    placeholder={el.hasPlaceholder ? el.placeholder : ''}
                    mask={el.mask}
                    type={type || 'text'}
                    onInput={(e) => {
                      if (el.onChange) {
                        el.onChange(e)
                      }
                      // el.actions.setFieldValue(
                      //   "file",
                      //   e.currentTarget.files[0]
                      // );
                      if (!file) {
                        setFile(e?.target?.files[0])
                      }
                      const reader = new FileReader()
                      reader.readAsDataURL(e.target.files[0])
                      reader.onload = function () {
                        setFileLocal(reader.result)
                        return reader.result
                      }
                      reader.onerror = function (error) {
                        console.error(error)
                      }
                    }}
                  />
                </>
              )
            }}
          </Field>
        </label>
      )
    case 'autocomplete':
      return (
        <FormGroup>
          <LabelFloat className="label-float">
            <Select
              styles={el?.styles || ''}
              className={el.multiple ? 'custom-multi-select' : 'custom-select'}
              name={el.name}
              classNamePrefix="react-select"
              options={options}
              isDisabled={el.disabled}
              placeholder=""
              onBlur={() =>
                el?.actions?.validateField &&
                el?.validation &&
                el.actions?.validateField(el?.name)}
              isMulti={el.multiple}
              onChange={(e, opt) => {
                if (el.onChange) {
                  el.onChange(e)
                }
                el.actions.setFieldValue(el.name, e)
              }}
              value={el.actions.values[el.name] || el.currentValue}
            />
            {el.label && <label className="active">{el.label}</label>}
          </LabelFloat>
        </FormGroup>
      )
    case 'float':
      return (
        <FormGroup>
          <LabelFloat>
            <Field name={el.name} element={el}>
              {({ field }) => {
                return (
                  <NumberFormat
                    {...field}
                    id={el.name}
                    autoComplete="off"
                    disabled={el.disabled}
                    thousandSeparator="."
                    decimalSeparator=","
                    value={tempValue}
                    onValueChange={(e) => {
                      setTempValue(e.formattedValue)
                      if (el.onChange) {
                        el.onChange(e.floatValue)
                      }
                    }}
                    style={{ width: '100%' }}
                    renderText={(value, props) => (
                      <input
                        {...props}
                        value={value}
                        style={{ width: '100%' }}
                      />
                    )}
                  />
                )
              }}
            </Field>
            {el.label && <label>{el.label}</label>}
          </LabelFloat>
        </FormGroup>
      )
    case 'checkbox':
      return (
        <FormGroup>
          <CheckBokField>
            <Field name={el.name} element={el}>
              {({ field }) => {
                return (
                  <InputMask
                    {...field}
                    id={el.name}
                    disabled={el.disabled}
                    // required={true}
                    type="checkbox"
                    checked={tempValue}
                    onInput={(e) => {
                      if (el.onChange) {
                        el.onChange(e)
                      }
                      setTempValue((prev) => !prev)
                    }}
                  />
                )
              }}
            </Field>
            {el.label && <label htmlFor={el.name}>{el.label}</label>}
          </CheckBokField>
        </FormGroup>
      )
    case 'textarea':
      return (
        <FormGroup>
          <LabelFloat>
            <Field name={el.name} element={el}>
              {({ field }) => {
                return (
                  <textarea
                    {...field}
                    id={el.name}
                    rows="4"
                    disabled={el.disabled}
                    autoComplete="off"
                    className="form-control"
                    placeholder={el.hasPlaceholder ? el.placeholder : ''}
                    style={{ paddingTop: '45px' }}
                    mask={el.mask}
                    type={type || 'text'}
                    onInput={(e) => {
                      if (el.onChange) {
                        el.onChange(e)
                      }
                    }}
                  />
                )
              }}
            </Field>
            {el.label && <label htmlFor={el.name}>{el.label}</label>}
          </LabelFloat>
        </FormGroup>
      )
    case 'radio':
      return (
        <FormGroup>
          <CheckBokField>
            <Field name={el.name} element={el}>
              {({ field }) => {
                return (
                  <InputMask
                    {...field}
                    id={el.name}
                    disabled={el.disabled}
                    // required={true}
                    type="radio"
                    checked={el.currentValue}
                    onChange={(e) => {
                      if (el.onChange) {
                        el.onChange(e)
                      }
                    }}
                  />
                )
              }}
            </Field>
            {el.label && <label htmlFor={el.name}>{el.label}</label>}
          </CheckBokField>
        </FormGroup>
      )

    default:
      return (
        <FormGroup>
          <LabelFloat className="label-float">
            <Field name={el.name} element={el}>
              {({ field }) => {
                if (el.mask) {
                  return (
                    <InputMask
                      {...field}
                      id={el.name}
                      disabled={el.disabled}
                      autoComplete="off"
                      value={tempValue}
                      className="form-control"
                      placeholder={el.hasPlaceholder ? el.placeholder : ''}
                      mask={el.mask}
                      type={type || 'text'}
                      onInput={(e) => {
                        if (el.onChange) {
                          el.onChange(e)
                        }
                        onChange(e)
                      }}
                    />
                  )
                } else {
                  return (
                    <input
                      {...field}
                      id={el.name}
                      disabled={el.disabled}
                      autoComplete="off"
                      value={tempValue}
                      className="form-control"
                      placeholder={el.hasPlaceholder ? el.placeholder : ''}
                      mask={el.mask}
                      type={type || 'text'}
                      onInput={(e) => {
                        if (el.onChange) {
                          el.onChange(e)
                        }
                        onChange(e)
                      }}
                    />
                  )
                }
              }}
            </Field>
            {el.label && <label>{el.label}</label>}
          </LabelFloat>
        </FormGroup>
      )
  }
}

export default MakeField
