import { useTheme } from 'styled-components'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import ButtonPrimary from '../../../../components/Button'
import { useModal } from '../../../../../contexts/modal'
import { useLoading } from '../../../../../contexts/loading'
import { finishSchedule, updateSchedule } from '../../../../../services/schedules'
import { updateScheduleUseCase } from '../../../../../main/usecases/schedules'
import { useAlertBox } from '../../../../../contexts/alert_box'
import CardModal from '../../../../components/CardModal'
import { createNotificationFireFunction } from '../../../../../services/Notifications'
import { ConfirmModal } from '../styled'
import * as S from './styles'

const Actions = ({ data, handleRefresh }) => {
  const statusPayment = data.status
  const theme = useTheme()
  const modal = useModal()
  const alertBox = useAlertBox()
  const history = useHistory()
  const { setActive } = useLoading()

  const [updateScheduleMutation] = useMutation(updateSchedule())
  const [finishScheduleMutation] = useMutation(finishSchedule())

  const mutationUpdateSchedule = async (data) => {
    try {
      setActive(true)
      await updateScheduleUseCase(updateScheduleMutation, data, { alertBox })
      setActive(false)
    } catch (error) {
      console.error('Error updating schedule:', error)
    } finally {
      handleGoBack()
    }
  }

  const mutationFinishSchedule = async (data) => {
    try {
      setActive(true)
      await updateScheduleUseCase(finishScheduleMutation, data, { alertBox })
      setActive(false)
    } catch (error) {
      console.error('Error finishing schedule:', error)
    } finally {
      handleGoBack()
    }
  }

  const handleCancelSchedule = async (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '500px' }}>
          <ConfirmModal>
            <h2>Deseja cancelar este agendamento?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  setActive(true)
                  await mutationUpdateSchedule({
                    id: id,
                    status: 'canceled',
                    description: 'Seu agendamento foi cancelado, compareça à clínica para ter seu reembolso'
                  })

                  await createNotificationFireFunction({
                    title: 'Seu agendamento não foi aceito ❌',
                    description: 'Por favor, confirme dia e hora com a recepção da clínica via WhatsApp',
                    user_id: params.user_id,
                    payload: {
                      route: '/my_shedules/my_shedule',
                      schedule_id: params.id
                    }
                  })

                  modal.setOptions({ open: false })
                  setActive(false)
                }}
              >
                Cancelar agendamento
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: theme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const handleConfirmSchedule = (data) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '40%' }}>
          <ConfirmModal>
            <h2>Deseja confirmar este agendamento?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  setActive(true)

                  await mutationUpdateSchedule({
                    id: data.id,
                    status: 'pending_payment',
                    description: 'Agendamento confirmado!'
                  })

                  await createNotificationFireFunction({
                    title: 'Seu agendamento foi aceito 🔔✅',
                    description: 'Entre no aplicativo e faça o pagamento para continuar',
                    user_id: data.user_id,
                    payload: {
                      route: '/my_shedules/my_shedule',
                      schedule_id: data.id
                    }
                  })

                  handleGoBack()
                  setActive(false)
                }}
              >
                Confirmar agendamento
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: theme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const handleFinishSchedule = (data) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '40%' }}>
          <ConfirmModal>
            <h2>Deseja finalizar este agendamento?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  setActive(true)

                  await mutationFinishSchedule({
                    id: data.id
                  })

                  await createNotificationFireFunction({
                    title: 'Sua solicitação foi finalizada com sucesso ✅',
                    description: 'Aproveite e nos conte como foi, faça uma avaliação da consulta ⭐',
                    user_id: data.user_id,
                    payload: {
                      route: '/my_shedules/my_shedule',
                      schedule_id: data.id
                    }
                  })

                  handleGoBack()
                  setActive(false)
                }}
              >
                Finalizar agendamento
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: theme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const handleGoBack = () => {
    modal.setOptions({ open: false })
    handleRefresh()
  }

  return (
    <S.ContainerButtons>
      {statusPayment === 'confirmed' && data?.type_of_medical_appointment === 'online' && data?.room && (
        <S.ButtonContainer>
          <ButtonPrimary
            onClick={() => {
              history.push(`/room/${data.room?.id}&callId=${data.room?.call_id.split('/')[0]}`, { scheduleId: data.id })
            }}
            style={{
              backgroundColor: theme.colors.primary,
              boxShadow: 'none'
            }}
            disabled={!data?.patient_symptom_record}
          >
            Fazer atendimento
          </ButtonPrimary>
          {!data?.patient_symptom_record && <label>Não fez a triagem</label>}
        </S.ButtonContainer>
      )}
      {statusPayment.includes('requested') && (
        <S.ButtonContainer>
          <ButtonPrimary
            onClick={() => handleConfirmSchedule(data)}
            disabled={statusPayment.includes('confirmed')}
            style={{
              backgroundColor: theme.colors.primary,
              boxShadow: 'none'
            }}
          >
            Confirmar agendamento
          </ButtonPrimary>
        </S.ButtonContainer>
      )}
      <S.ButtonContainer>
        <ButtonPrimary
          onClick={() => handleFinishSchedule(data)}
          disabled={statusPayment.includes('finished')}
          style={{
            backgroundColor: theme.colors.secondary,
            boxShadow: 'none'
          }}
        >
          Finalizar agendamento
        </ButtonPrimary>
      </S.ButtonContainer>
      <S.ButtonContainer>
        <ButtonPrimary
          onClick={handleGoBack}
          style={{
            backgroundColor: theme.colors.error,
            boxShadow: 'none'
          }}
        >
          Voltar
        </ButtonPrimary>
      </S.ButtonContainer>
    </S.ContainerButtons>
  )
}

export default Actions
