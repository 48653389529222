import React, { useState } from 'react'
import { customTheme } from '../../../../styles/theme'
import ButtonPrimary from '../../../components/Button'
import GenerateForm from '../../../components/layout/GenerateForm'
import * as Yup from 'yup'
import { FormModal } from './styled'

function AppointmentForm ({ type, values, submitEvent, button, complements, closeEvent }) {
  const [buttonText, setButtonText] = useState(button.submit)
  const [dataValues, setDataValues] = useState({
    cost_of_medical_consultation_personal_assistance: values?.cost_of_medical_consultation_personal_assistance || null,
    cost_of_medical_consultation_online_service: values?.cost_of_medical_consultation_online_service || null,
    start_time: values?.start_time.replace('+00', ''),
    end_time: values?.end_time.replace('+00', ''),
    number_of_vacancies: values?.number_of_vacancies || 1,
    personal_assistance: values?.personal_assistance || null,
    online_service: values?.online_service || null
  })

  const canSubmit = () => {
    return !!dataValues.start_time && !!dataValues.end_time && (!!dataValues.personal_assistance || !!dataValues.online_service)
  }

  const fields = {
    create: [
      {
        name: 'Horário disponível (SUS)',
        fields: [
          {
            label: 'Início',
            type: 'time',
            disabled: true,
            currentValue: dataValues?.start_time,
            hideField: false,
            name: 'start_time',
            columns: { xs: 6 }
          },
          {
            label: 'Final',
            type: 'time',
            disabled: true,
            currentValue: dataValues?.end_time,
            hideField: false,
            name: 'end_time',
            columns: { xs: 6 }
          },
          {
            label: 'Atendimento Presencial',
            type: 'checkbox',
            disabled: false,
            hideField: false,
            currentValue: dataValues?.personal_assistance,
            onChange: (e) => {
              setDataValues(prev => ({
                ...prev,
                personal_assistance: JSON.parse(e.target.value)
              }))
            },
            name: 'personal_assistance',
            columns: { xs: 12 }
          },
          {
            label: 'Atendimento Online',
            type: 'checkbox',
            disabled: false,
            hideField: false,
            currentValue: dataValues?.online_service,
            onChange: (e) => {
              setDataValues(prev => ({
                ...prev,
                online_service: JSON.parse(e.target.value)
              }))
            },
            name: 'online_service',
            columns: { xs: 12 }
          }
        ]
      }
    ],
    update: [
      {
        name: 'Horário disponível (SUS)',
        fields: [
          {
            label: 'Início',
            type: 'time',
            disabled: false,
            currentValue: dataValues?.start_time,
            onChange: (e) => {
              setDataValues(prev => ({
                ...prev,
                start_time: e.target.value
              }))
            },
            hideField: false,
            name: 'start_time',
            columns: { xs: 6 }
          },
          {
            label: 'Final',
            type: 'time',
            disabled: false,
            currentValue: dataValues?.end_time,
            onChange: (e) => {
              setDataValues(prev => ({
                ...prev,
                end_time: e.target.value
              }))
            },
            hideField: false,
            name: 'end_time',
            columns: { xs: 6 }
          },
          {
            label: 'Atendimento Presencial',
            type: 'checkbox',
            disabled: false,
            hideField: false,
            currentValue: dataValues?.personal_assistance,
            onChange: (e) => {
              setDataValues(prev => ({
                ...prev,
                personal_assistance: JSON.parse(e.target.value)
              }))
            },
            name: 'personal_assistance',
            columns: { xs: 12 }
          },
          {
            label: 'Atendimento Online',
            type: 'checkbox',
            disabled: false,
            hideField: false,
            currentValue: dataValues?.online_service,
            onChange: (e) => {
              setDataValues(prev => ({
                ...prev,
                online_service: JSON.parse(e.target.value)
              }))
            },
            name: 'online_service',
            columns: { xs: 12 }
          }
        ]
      }
    ]
  }

  return (
    <FormModal>
      <GenerateForm groupFields={fields[type]} />

      <ButtonPrimary
        disabled={!canSubmit()}
        onClick={(e) => {
          setButtonText(button.submitting)
          if (type === 'create') {
            submitEvent(
              dataValues,
              ...complements
            )
          } else {
            submitEvent(
              ...complements,
              dataValues
            )
          }
        }}
      >
        {buttonText}
      </ButtonPrimary>

      {type === 'create' && (
        <>
          <ButtonPrimary
            onClick={closeEvent}
            style={{
              marginTop: 15,
              backgroundColor: customTheme.colors.error,
              boxShadow: 'none'
            }}
          >
            Cancelar
          </ButtonPrimary>
        </>
      )}
    </FormModal>
  )
}

export default AppointmentForm
