import { gql } from '@apollo/client'

/**
 * Retorna uma consulta GraphQL para recuperar informações detalhadas das clínicas odontológicas, opcionalmente filtradas por nome, cidade e estado.
 *
 * @param {Object} options - Opções para filtrar os resultados da consulta.
 * @param {string} [options.name=null] - Opcional. O nome da clínica odontológica para filtrar os resultados.
 * @param {string} [options.city=null] - Opcional. O nome da cidade para filtrar os resultados.
 * @param {string} [options.stateCode=null] - Opcional. O código do estado para filtrar os resultados.
 * @returns {string} - Uma consulta GraphQL para recuperar informações detalhadas das clínicas odontológicas.
 *
 * @example
 * // Exemplo de utilização:
 * fetchDentailClinics({
 *   name: "%odontológica%",      // Nome da clínica odontológica (filtro opcional)
 *   city: "Itajaí",              // Nome da cidade (filtro opcional)
 *   stateCode: "SC",             // Código do estado (filtro opcional)
 *   limit: 10,                   // Limite de clínicas odontológicas a serem retornadas (opcional)
 *   offset: 0                    // Deslocamento para paginação (opcional)
 * })
 */
export const fetchDentailClinics = (data) => {
  let query

  if (!data?.name) {
    if (data?.city && data?.stateCode) {
      query = gql`
        query getDentailClinics($limit: Int, $offset: Int, $city: String, $state_code: String) {
          dental_clinics(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            ...dentalClinicFields
          }
          dental_clinics_aggregate(where: { adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentDentalClinicFields}
      `
    } else {
      query = gql`
        query getDentailClinics($limit: Int, $offset: Int) {
          dental_clinics(limit: $limit, offset: $offset, order_by: { updated_at: desc }) {
            ...dentalClinicFields
          }
          dental_clinics_aggregate {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentDentalClinicFields}
      `
    }
  } else {
    if (data?.city && data?.stateCode) {
      query = gql`
        query getDentailClinics($limit: Int, $offset: Int, $name: String, $city: String, $state_code: String) {
          dental_clinics(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { company_name: { _ilike: $name }, adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            ...dentalClinicFields
          }
          dental_clinics_aggregate(where: { company_name: { _ilike: $name }, adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentDentalClinicFields}
      `
    } else {
      query = gql`
        query getDentailClinics($limit: Int, $offset: Int, $name: String) {
          dental_clinics(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { company_name: { _ilike: $name } }) {
            ...dentalClinicFields
          }
          dental_clinics_aggregate(where: { company_name: { _ilike: $name } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentDentalClinicFields}
      `
    }
  }
  return query
}

export const fetchDentalClinicsById = () => {
  return gql`
    query getDataByPk($id: Int!) {
      dental_clinics_by_pk(id: $id) {
        ...dentalClinicFields
      }
    }
    ${fragmentDentalClinicFields}
  `
}

export const fragmentDentalClinicFields = gql`
  fragment dentalClinicFields on dental_clinics {
    adress {
      address
      city
      complement
      created_at
      latitude
      longitude
      id
      neighborhood
      number
      state
      state_code
      updated_at
      zipcode
    }
    company_name
    company_plan_id
    created_at
    description
    email
    facebook
    id
    instagram
    linkedin
    phone_number
    photo
    updated_at
    website
    company_plan {
      cpf
      cnpj_account
      chave_pix
      account
      agency
      transfer_of_interest_to_the_customer
      bank_id
      codigo_split
    }
  }
`

export const createDentalClinics = () => {
  return gql`
    mutation insertDentalClinicOne(
      $company_name: String
      $description: String
      $email: String
      $facebook: String
      $instagram: String
      $linkedin: String
      $phone_number: String
      $photo: String
      $website: String
      $address: String
      $city: String
      $complement: String
      $latitude: numeric
      $longitude: numeric
      $neighborhood: String
      $number: String
      $state: String
      $state_code: String
      $zipcode: String
      $company_plan_id: Int
    ) {
      insert_dental_clinics_one(
        object: {
          company_name: $company_name
          description: $description
          email: $email
          facebook: $facebook
          instagram: $instagram
          linkedin: $linkedin
          phone_number: $phone_number
          photo: $photo
          website: $website
          adress: {
            data: {
              address: $address
              city: $city
              complement: $complement
              latitude: $latitude
              longitude: $longitude
              neighborhood: $neighborhood
              number: $number
              state: $state
              state_code: $state_code
              zipcode: $zipcode
            }
          }
          company_plan_id: $company_plan_id
        }
      ) {
        ...dentalClinicFields
      }
    }
    ${fragmentDentalClinicFields}
  `
}
export const updateDentalClinics = () => {
  return gql`
    mutation updateDentalClinicsByPk(
      $id: Int!
      $company_name: String
      $description: String
      $email: String
      $facebook: String
      $instagram: String
      $linkedin: String
      $phone_number: String
      $photo: String
      $website: String
    ) {
      update_dental_clinics_by_pk(
        pk_columns: { id: $id }
        _set: {
          company_name: $company_name
          description: $description
          email: $email
          facebook: $facebook
          instagram: $instagram
          linkedin: $linkedin
          phone_number: $phone_number
          photo: $photo
          website: $website
        }
      ) {
        ...dentalClinicFields
      }
    }
    ${fragmentDentalClinicFields}
  `
}

export const addressDentalClinic = () => {
  return gql`
    mutation updateAddress(
      $address_id: Int!
      $address: String
      $city: String
      $complement: String
      $latitude: numeric
      $longitude: numeric
      $neighborhood: String
      $number: String
      $state: String
      $state_code: String
      $zipcode: String
    ) {
      update_adresses_by_pk(
        pk_columns: { id: $address_id }
        _set: {
          address: $address
          city: $city
          complement: $complement
          latitude: $latitude
          longitude: $longitude
          neighborhood: $neighborhood
          number: $number
          state: $state
          state_code: $state_code
          zipcode: $zipcode
        }
      ) {
        dental_clinics {
          ...dentalClinicFields
        }
      }
    }
    ${fragmentDentalClinicFields}
  `
}
