import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlertBox } from '../../../../contexts/alert_box'
import InternalBody from '../../../components/InternalBody'
import GenerateForm from '../../../components/layout/GenerateForm'
import { groupFields } from './columns'

import { genericFetch } from '../../../../main/usecases/generic'
import { useHistory } from 'react-router-dom'
import { stringToFloat } from '../../../../Utils/format'
import { fecthSuport, updateSuport } from '../../../../services/suport'

function Suport ({ match }) {
  const { data } = useQuery(fecthSuport(), {
    variables: { limit: 10, offset: 0 }
  })
  const [mutateFunction] = useMutation(updateSuport())
  const [content, setContent] = useState(null)
  const alertBox = useAlertBox()
  const history = useHistory()
  useEffect(() => {
    if (data?.suport[0]) {
      setContent(data?.suport[0])
    }
  }, [data])

  const mutationUpdateSuport = async (e, url, actions) => {
    await genericFetch(
      mutateFunction,
      {
        id: content.id,
        email_suport: e.email_suport,
        facebook_suport: e.facebook_suport,
        help_center_link: e.help_center_link,
        instagram_suport: e.instagram_suport,
        whatsapp_suport: e.whatsapp_suport
      },
      {
        actions,
        alertBox
      }
    )
  }

  const onSubmit = async (e, actions) => {
    if (match.params.id === 'new') {
      // create suport
      /*  const res = await mutationCreateClinic(e, null, actions)
      history.push(`/plans/${res.data.insert_plan_one.id}`) */
    } else {
      await mutationUpdateSuport(e, null, actions)
    }
  }

  return (
    <>
      <InternalBody>
        <GenerateForm
          onSubmit={onSubmit}
          groupFields={groupFields(content, setContent)}
          button={{
            submit: 'Atualizar',
            submitting: 'Atualizando...'
          }}
        />
      </InternalBody>
    </>
  )
}

export default Suport
