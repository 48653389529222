import styled from 'styled-components'
import ButtonPrimary from '../../../components/Button'

export const Button = styled(ButtonPrimary)`
  display: block;
  width: 20rem;
  max-width: 100%;
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
  margin-top: 2rem;

  a {
    background-color: ${props => props.theme.colors.success};
    color:  ${props => props.theme.colors.white};
    padding: 15px;
    border-radius: 6px;
    font-weight: 600;
    text-align: center;
    
    
    display: block;
    width: 20rem;
    max-width: 100%;

    border: none;
    cursor: pointer;
    box-shadow: 0 8px 15px -2px #325ba5;

    &:hover {
      opacity: 0.90;
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
`
