import GenerateProfile from '../../../components/layout/GenerateProfile'
import { getHospitalById } from '../../../../services/doctor'
import country from '../../../../Utils/estados_cidades.json'
import { useEffect, useState } from 'react'
import Header from '../../../components/Header'
import { useQuery } from '@apollo/client'
import { useHistory } from 'react-router-dom'

export default function InternalMyHospitals ({ match }) {
  const [content, setContent] = useState({})
  const history = useHistory()

  const { data } = useQuery(getHospitalById(), {
    variables: { id: match.params.id }
  })

  const states = []
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  useEffect(() => {
    if (data?.hospitals_by_pk) {
      console.log(data)
      setContent(data.hospitals_by_pk)
    }
  }, [data])

  const handleBack = () => {
    if (history.length > 0) {
      history.goBack()
    } else {
      history.push('/my-hospitals')
    }
  }

  return (
    <div>
      <Header
        title="Detalhes"
        labelAdd="Voltar"
        handleAdd={handleBack}
      />
      <GenerateProfile data={content} />
    </div>
  )
}
