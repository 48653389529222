import { useState } from 'react'
import GenerateForm from '../../../../components/layout/GenerateForm'
import * as S from './styled'
import MainContainer from '../../../../components/MainContainer'
import Header from '../../../../components/Header'
import * as Yup from 'yup'

export function StepContainer ({ title, children, onSubmit }) {
  return (
    <MainContainer>
      <Header title="Selecione o tipo de entidade" />

      <S.Container>
        {children}
        <GenerateForm
          onSubmit={onSubmit}
          groupFields={formGroup(title)}
          button={{
            submit: 'Continuar',
            submitting: 'Continuar'
          }}
        />
      </S.Container>
    </MainContainer>

  )
}

const formGroup = (title) => {
  const entityList = [
    { label: 'Clínica', value: 'clinica' },
    { label: 'Odontologia', value: 'odontologia' },
    { label: 'Hospital', value: 'hospital' },
    { label: 'Laboratório', value: 'laboratorio' }
  ]
  const [entitySelected, setEntitySelected] = useState(entityList[0].value)

  return [
    {
      name: title,
      fields: entityList.map(item => ({
        label: item.label,
        type: 'radio',
        required: true,
        disabled: false,
        hideField: false,
        currentValue: entitySelected === item.value,
        onChange: () => setEntitySelected(item.value),
        name: item.value,
        columns: {
          xs: 3
        },
        validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
      }))
    }
  ]
}
