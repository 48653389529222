export const groupFields = (values) => {
  return [
    {
      fields: [
        {
          label: 'Email',
          type: 'text',
          disabled: true,
          currentValue: values?.email || values?.user?.email,
          columns: {
            xs: 4
          }
        },
        {
          label: 'Telefone',
          type: 'text',
          disabled: true,
          currentValue: values?.phone_number || values?.user?.phone_number,
          columns: {
            xs: 4
          }
        }
      ]
    }
  ]
}

export const groupAddressFields = (values) => {
  return [
    {
      fields: [
        {
          label: 'Rua',
          type: 'text',
          disabled: true,
          currentValue: values.address,
          columns: {
            xs: 4
          }
        },
        {
          label: 'Número',
          type: 'text',
          disabled: true,
          currentValue: values.number,
          columns: {
            xs: 4
          }
        },
        {
          label: 'Bairro',
          type: 'text',
          disabled: true,
          currentValue: values.neighborhood,
          columns: {
            xs: 4
          }
        },
        {
          label: 'Complemento',
          type: 'text',
          disabled: true,
          currentValue: values.complement,
          columns: {
            xs: 4
          }
        },
        {
          label: 'Cidade',
          type: 'text',
          disabled: true,
          currentValue: `${values.city} - ${values.state_code}`,
          columns: {
            xs: 4
          }
        },
        {
          label: 'CEP',
          type: 'text',
          disabled: true,
          currentValue: values.zipcode,
          columns: {
            xs: 4
          }
        }
      ]
    }
  ]
}
