import styled from 'styled-components'
import { customTheme } from '../../../styles/theme'

export const ButtonStyled = styled.button`
  background: ${customTheme.colors.primaryButton};
  color: ${customTheme.colors.white};
  padding: 15px;
  border-radius: 6px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  border: none;
  cursor: pointer;
  box-shadow: 0 8px 15px -2px #325ba5;

  &:hover {
    background: ${customTheme.colors.secondary};
    color: ${customTheme.colors.white};
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`
