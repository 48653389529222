import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { useMutation, useQuery } from '@apollo/client'
import { columns } from './columns'
import Loading from '../../../components/layout/Loading'
import {
  updateSchedule,
  fetchSchedulesByClinic,
  finishSchedule,
  updateScheduleAvailable
} from '../../../../services/schedules'
import { useModal } from '../../../../contexts/modal'
import CardModal from '../../../components/CardModal'
import ButtonPrimary from '../../../components/Button'
import { ConfirmModal, FilterContainer } from './styled'
import { customTheme } from '../../../../styles/theme'
import { useAlertBox } from '../../../../contexts/alert_box'
import { updateScheduleUseCase } from '../../../../main/usecases/schedules'
import GenerateForm from '../../../components/layout/GenerateForm'
import { getScheduleStatus, onlyUnique } from '../../../../Utils/schedules'
import moment from 'moment'
import {
  cancelSusShedule,
  confirmSusShedule,
  createRoom,
  fetchSUSSchedules
} from '../../../../services/sus'
import { createNotificationFireFunction } from '../../../../services/Notifications'
import { useAuth } from '../../../../contexts/authenticator'

function SchedulesBySUS () {
  const { id } = useAuth()
  const [perPage, setPerPage] = useState(5)
  const [selectedFilters, setSelectedFilter] = useState(null)

  const { data, loading, refetch } = useQuery(fetchSUSSchedules(), {
    variables: {
      status: '%',
      sus_id: id
    },
    pollInterval: 300000
  })

  const alertBox = useAlertBox()
  const modal = useModal()
  const [confirmScheduleMutation] = useMutation(confirmSusShedule())
  const [cancelScheduleMutation] = useMutation(cancelSusShedule())
  const [createRoomMutation] = useMutation(createRoom())
  const [updateSchedule] = useMutation(updateScheduleAvailable())

  const [filterDoctors, setFilterDoctors] = useState([])
  const [dataValues, setDataValues] = useState([null])
  const [filter, setFilter] = useState({
    patient: [],
    status: ['pending', 'confirmed', 'canceled', 'finished']
  })

  const mutationConfirmSchedule = async ({ params, ...data }) => {
    const res = await createRoomMutation({
      variables: {
        date: params.day,
        description: `Atendimento ${params?.user?.full_name}`,
        start_time: params?.susAppoitments[0]?.start_time,
        end_time: params?.susAppoitments[0]?.end_time
      }
    })

    return await updateScheduleUseCase(
      confirmScheduleMutation,
      {
        ...data,
        room_id:
          params?.type_of_medical_appointment === 'online'
            ? res?.data?.insert_rooms_one?.id
            : null
      },
      {
        alertBox
      }
    )
  }

  const mutationCancelSchedule = async (data) => {
    return await updateScheduleUseCase(cancelScheduleMutation, data, {
      alertBox
    })
  }

  const handleCancelSchedule = (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '500px' }}>
          <ConfirmModal>
            <h2>Deseja cancelar este agendamento?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  await mutationCancelSchedule({
                    id: id,
                    description:
                      'Infelizmente seu agendamento não foi confirmado'
                  })

                  await createNotificationFireFunction({
                    title: 'Seu agendamento não foi aceito ❌',
                    description:
                      'Por favor, confirmar dia e hora com a recepção via WhatsApp',
                    user_id: params.user_id,
                    payload: {
                      route: '/my_shedules/my_shedule',
                      schedule_id: params.id
                    }
                  })

                  modal.setOptions({ open: false })
                  refetch()
                }}
              >
                Cancelar agendamento
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: customTheme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const handleConfirmSchedule = (id, params) => {
    modal.setOptions({
      open: true,
      component: (
        <CardModal style={{ width: '40%' }}>
          <ConfirmModal>
            <h2>Deseja confirmar este agendamento?</h2>

            <div className="buttons-container">
              <ButtonPrimary
                onClick={async () => {
                  await mutationConfirmSchedule({
                    id: id,
                    description: 'Seu agendamento foi confirmado',
                    params
                  })

                  modal.setOptions({ open: false })

                  await createNotificationFireFunction({
                    title: 'Seu agendamento foi aceito 🔔✅',
                    description:
                      'Entre no aplicativo e confira seu agendamento',
                    user_id: params.user_id,
                    payload: {
                      route: '/my_shedules/my_shedule',
                      schedule_id: params.id
                    }
                  })
                  refetch()
                }}
              >
                Confirmar agendamento
              </ButtonPrimary>

              <ButtonPrimary
                style={{ background: customTheme.colors.error }}
                onClick={() => modal.setOptions({ open: false })}
              >
                Fechar
              </ButtonPrimary>
            </div>
          </ConfirmModal>
        </CardModal>
      )
    })
  }

  const columnsActions = {
    handleCancelSchedule,
    handleConfirmSchedule
  }

  const insertFilter = (param, value) => {
    if (param === 'specialty') {
      if (!filterDoctors.find((item) => item.id === value.id)) {
        setFilterDoctors((prev) => [...prev, value])
      }
    }

    if (param === 'patient') {
      if (!filter[param].find((item) => item.id === value.id)) {
        setFilter((prev) => ({
          ...prev,
          [param]: [...prev[param], value]
        }))
      }
    }
  }

  const handleDataFilter = (e) => {
    let filteredValues = data?.schedules

    if (selectedFilters?.patient) {
      filteredValues = filteredValues.filter(
        (item) => item.user.id === selectedFilters?.patient.id
      )
    }

    if (selectedFilters?.status) {
      filteredValues = filteredValues.filter(
        (item) => item.status === selectedFilters?.status
      )
    }

    if (selectedFilters?.doctor) {
      filteredValues = filteredValues.filter(
        (item) =>
          item?.doctors_appointment?.doctor?.id === selectedFilters?.doctor.id
      )
    }

    if (selectedFilters?.date) {
      filteredValues = filteredValues.filter(
        (item) =>
          moment(item?.day).add(3, 'hours').format('YYYYMMDD') ===
          moment(selectedFilters?.date).format('YYYYMMDD')
      )
    }

    setDataValues(filteredValues)
  }

  useEffect(() => {
    data?.schedules.forEach((item) => {
      insertFilter('patient', item?.user)
      insertFilter(
        'specialty',
        item?.sus_with_medical_specialty?.medical_specialty
      )
    })
  }, [data])

  if (loading) return <Loading />

  return (
    <>
      <Row>
        <Col xs={12}>
          <h2>Agendamentos solicitados (SUS)</h2>
        </Col>
        <Col xs={12}>
          <FilterContainer>
            <GenerateForm
              onSubmit={handleDataFilter}
              groupFields={[
                {
                  fields: [
                    {
                      label: 'Paciente:',
                      type: 'autocomplete',
                      required: true,
                      hideField: false,
                      name: 'patient',
                      onChange: (e) => {
                        setSelectedFilter((prev) => ({
                          ...prev,
                          patient: e?.value
                        }))
                      },
                      currentValue: {
                        label: selectedFilters?.patient?.full_name,
                        value: selectedFilters?.patient?.id
                      },
                      options: filter?.patient
                        ?.filter(onlyUnique)
                        ?.map((item) => ({
                          label: item.full_name,
                          value: item
                        })),
                      columns: { xs: 3 }
                    },
                    {
                      label: 'Status:',
                      type: 'autocomplete',
                      required: true,
                      hideField: false,
                      name: 'status',
                      onChange: (e) => {
                        setSelectedFilter((prev) => ({
                          ...prev,
                          status: e?.value
                        }))
                      },
                      currentValue: {
                        label: getScheduleStatus(selectedFilters?.status),
                        value: selectedFilters?.status
                      },
                      options: filter?.status?.map((item) => ({
                        label: getScheduleStatus(item),
                        value: item
                      })),
                      columns: { xs: 3 }
                    },
                    {
                      label: 'Data da consulta:',
                      type: 'date',
                      required: true,
                      hideField: false,
                      onChange: (e) => {
                        setSelectedFilter((prev) => ({
                          ...prev,
                          date: e.target.value
                        }))
                      },
                      currentValue: selectedFilters?.date,
                      name: 'date',
                      columns: { xs: 3 }
                    },
                    {
                      label: 'Especialidade::',
                      type: 'autocomplete',
                      required: true,
                      hideField: false,
                      name: 'doctor',
                      onChange: (e) => {
                        setSelectedFilter((prev) => ({
                          ...prev,
                          doctor: e?.value
                        }))
                      },
                      currentValue: {
                        label: selectedFilters?.specialty?.name,
                        value: selectedFilters?.specialty?.id
                      },
                      options: filterDoctors
                        .filter(onlyUnique)
                        ?.map((item) => ({
                          label: item.name,
                          value: item
                        })),
                      columns: { xs: 3 }
                    }
                  ]
                }
              ]}
              button={{ submit: 'Filtrar', submitting: 'Filtrando' }}
            />
            <ButtonPrimary
              style={{
                boxShadow: 'none',
                fontSize: '14px',
                padding: '5px',
                backgroundColor: customTheme.colors.secondary,
                lineHeight: '2',
                display: 'flex',
                maxWidth: 'calc(25% - 18px)',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                bottom: 0,
                left: 'calc(25% + 6px)'
              }}
              onClick={() => {
                setDataValues(data?.schedules)
                setSelectedFilter(null)
              }}
            >
              Limpar filtros
            </ButtonPrimary>
          </FilterContainer>
        </Col>

        <Col xs={12}>
          {data?.schedules && (
            <div style={{ height: 400, width: '100%', marginTop: '30px' }}>
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'created_at', sort: 'desc' }]
                  }
                }}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                getRowClassName={(params) => `grid-row-status-${params.row.status.replace(/"/g, '')}`}
                rows={[]}
                columns={columns(modal, columnsActions)}
                pageSize={perPage}
                onPageSizeChange={(e) => {
                  setPerPage(e)
                }}
                rowsPerPageOptions={[10]}
                pagination
                disableSelectionOnClick
              />
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

export default SchedulesBySUS
