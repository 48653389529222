import { gql } from '@apollo/client'
import { fragmentAddresses } from '../address'
import {
  fragmentClinicHoursAndDaysAvailables,
  fragmentClinics
} from '../clinics'
import { fragmentDoctors, fragmentResumedDoctorFields } from '../doctor'
import {
  fragmentDoctorsWithSpecialties,
  fragmentResumedClinicFields
} from '../doctorsWithSpecialties'
import { fragmentResumedHospitalFields } from '../hospitals'
import { fragmentMedicalSpecialty } from '../medicalSpecialty'
import { fragmentPayments } from '../payments'
import {
  fragmentPetshopAppointmentFields,
  fragmentResumedPetshopFields
} from '../petshops'
import { fragmentResumedUserFields } from '../users'

export const fragmentDoctorAppointmentFields = gql`
  fragment doctorAppointmentFields on doctors_appointment {
    id
    clinic_id
    doctor_id
    hospital_id
    cost_of_medical_consultation_online_service
    cost_of_medical_consultation_personal_assistance
    start_time
    end_time
    number_of_vacancies
    personal_assistance
    online_service
    days_of_week {
      id
      abbreviation
      full_text
    }
    unavailable_days {
      date
      id
    }
  }
`

export const createScheduleAvailable = () => {
  return gql`
    ${fragmentClinics}
    mutation insertClinicHoursAndDaysAvailable(
      $objects: [clinic_hours_and_days_available_insert_input!] = {}
    ) {
      insert_clinic_hours_and_days_available(objects: $objects) {
        affected_rows
        returning {
          clinic {
            ...clinicFields
          }
        }
      }
    }
  `
}

export const deleteScheduleAvailable = () => {
  return gql`
    ${fragmentClinics}
    mutation deleteClinicHoursAndDaysAvailable($id: Int) {
      delete_clinic_hours_and_days_available(where: { id: { _eq: $id } }) {
        affected_rows
        returning {
          clinic {
            ...clinicFields
          }
        }
      }
    }
  `
}

export const updateScheduleAvailable = () => {
  return gql`
    ${fragmentClinics}
    mutation updateClinicHoursAndDaysAvailable(
      $id: Int
      $day_of_week_id: Int
      $start_time: timetz
      $end_time: timetz
    ) {
      update_clinic_hours_and_days_available(
        where: { id: { _eq: $id } }
        _set: {
          day_of_week_id: $day_of_week_id
          start_time: $start_time
          end_time: $end_time
        }
      ) {
        affected_rows
        returning {
          clinic {
            ...clinicFields
          }
        }
      }
    }
  `
}

export const createDoctorsAppointment = () => {
  return gql`
    ${fragmentAddresses}
    ${fragmentMedicalSpecialty}
    ${fragmentDoctorsWithSpecialties}
    ${fragmentResumedClinicFields}
    ${fragmentDoctors}
    ${fragmentClinics}
    ${fragmentClinicHoursAndDaysAvailables}
    ${fragmentDoctorAppointmentFields}

    mutation insertDoctorsAppointment(
      $objects: [doctors_appointment_insert_input!] = {}
    ) {
      insert_doctors_appointment(objects: $objects) {
        affected_rows
        returning {
          ...doctorAppointmentFields
        }
      }
    }
  `
}

export const removeDoctorsAppointment = () => {
  return gql`
    ${fragmentDoctorAppointmentFields}

    mutation deleteDoctorsAppointmentByPk($id: Int!) {
      delete_doctors_appointment_by_pk(id: $id) {
        ...doctorAppointmentFields
      }
    }
  `
}

export const updateDoctorsAppointment = () => {
  return gql`
    ${fragmentDoctorAppointmentFields}

    mutation updateDoctorsAppointmentByPk(
      $id: Int!
      $number_of_vacancies: Int
      $start_time: timetz
      $end_time: timetz
      $online_service: Boolean
      $personal_assistance: Boolean
      $cost_of_medical_consultation_online_service: numeric = ""
      $cost_of_medical_consultation_personal_assistance: numeric = ""
      $day_of_week_id: Int
    ) {
      update_doctors_appointment_by_pk(
        pk_columns: { id: $id }
        _set: {
          number_of_vacancies: $number_of_vacancies
          start_time: $start_time
          end_time: $end_time
          online_service: $online_service
          personal_assistance: $personal_assistance
          cost_of_medical_consultation_online_service: $cost_of_medical_consultation_online_service
          cost_of_medical_consultation_personal_assistance: $cost_of_medical_consultation_personal_assistance
          day_of_week_id: $day_of_week_id
        }
      ) {
        ...doctorAppointmentFields
      }
    }
  `
}

export const fetchDoctorAppointmentsFull = () => {
  return gql`
    ${fragmentAddresses}
    ${fragmentMedicalSpecialty}
    ${fragmentDoctorsWithSpecialties}
    ${fragmentResumedClinicFields}
    ${fragmentDoctors}
    ${fragmentClinics}
    ${fragmentClinicHoursAndDaysAvailables}
    ${fragmentDoctorAppointmentFields}

    query doctorAppointments($doctor_id: Int) {
      doctors_appointment(order_by: {updated_at: desc}, where: { doctor_id: { _eq: $doctor_id } }) {
        ...doctorAppointmentFields
      }
    }
  `
}
export const fragmentSchedules = gql`
  ${fragmentResumedUserFields}
  ${fragmentResumedDoctorFields}
  ${fragmentResumedClinicFields}
  ${fragmentPetshopAppointmentFields}
  ${fragmentResumedPetshopFields}
  ${fragmentDoctorAppointmentFields}
  ${fragmentPayments}
  ${fragmentMedicalSpecialty}
  fragment resumedSusFields on sus {
    id
    name
    photo
    adress {
      ...addressFields
    }
  }
  fragment susMedicalSpecialty on sus_with_medical_specialty {
    created_at
    id
    medical_specialty_id
    sus_id
    updated_at
    medical_specialty {
      ...medicalSpecialtyFields
    }
  }
  fragment susAppointmentFields on sus_appointment {
    created_at
    end_time
    id
    online_service
    personal_assistance
    start_time
    sus_id
    updated_at
    days_of_week {
      id
      abbreviation
      full_text
    }
    unavailable_days {
      date
      id
    }
    su {
      ...resumedSusFields
      sus_with_medical_specialties {
        ...susMedicalSpecialty
      }
    }
  }
  ${fragmentResumedHospitalFields}

  fragment sheduleFields on schedules {
    id
    created_at
    updated_at
    day
    description
    status
    type_of_medical_appointment
    user_id
    room_id
    doctor_appointment_id
    start_time
    end_time
    user {
      ...resumedUserFields
    }
    susAppoitments {
      ...susAppointmentFields
    }
    petshop_appointment {
      ...petshopAppointmentFields
      pet_shop {
        ...resumedPetshopFields
      }
    }
    doctors_appointment {
      doctor {
        ...resumedDoctorFields
      }
      clinic {
        ...resumedClinicFields
      }
      hospital {
        ...resumedHospitalFields
      }
      ...doctorAppointmentFields
    }
    payment {
      ...paymentFields
    }
    evaluations {
      id
    }
    sus_with_medical_specialty {
      medical_specialty {
        ...medicalSpecialtyFields
      }
    }
  }
`

export const fetchSchedulesByUser = () => {
  return gql`
    query getShedulesByStatus(
      $user_id: Int
      $status: String
      $limit: Int
      $offset: Int
    ) {
      schedules(
        order_by: {updated_at: desc},
        where: { user: { id: { _eq: $user_id } } }
        limit: $limit
        offset: $offset
      ) {
        ...sheduleFields
      }
    }
    ${sheduleDoctorFields}
  `
}
export const fetchSchedulesBySUS = () => {
  return gql`
    query getShedulesByClinicAndStatus(
      $sus_id: Int
      $status: String
      $limit: Int
      $offset: Int
    ) {
      schedules(
        order_by: {updated_at: desc},
        where: {
          doctors_appointment: { sus_id: { _eq: $sus_id } }
          status: { _like: $status }
        },
        limit: $limit,
        offset: $offset
      ) {
        ...sheduleFields
      }
    }
    ${fragmentSchedules}
  `
}
export const fetchSchedulesByClinic = () => {
  return gql`
    query getShedulesByClinicAndStatus(
      $clinic_id: Int
      $status: String
      $limit: Int
      $offset: Int
    ) {
      schedules(
        order_by: {updated_at: desc},
        where: {
          doctors_appointment: { clinic_id: { _eq: $clinic_id } }
          status: { _like: $status }
        },
        limit: $limit,
        offset: $offset
      ) {
        ...sheduleFields
      }
    }
    ${fragmentSchedules}
  `
}

export const fetchSheduleById = () => {
  return gql`
  query getShedulesById($id: Int!) {
    schedules_by_pk(id: $id) {
      ...sheduleFields
    }
  }
  ${sheduleDoctorFields}
  `
}

export const fetchSchedulesByDoctor = () => {
  return gql`
    query getShedulesByStatus(
      $doctor_id: Int
      $status: String
      $limit: Int
      $offset: Int
    ) {
      schedules(
        order_by: {updated_at: desc},
        where: {
          doctors_appointment: {
            doctor_id: { _eq: $doctor_id }
          }
          status: { _like: $status }
        },
        limit: $limit,
        offset: $offset
      ) {
        ...sheduleFields
      }
    }
    ${sheduleDoctorFields}
  `
}

export const fetchSchedulesByHospital = () => {
  return gql`
    query getShedulesByStatus(
      $hospital_id: Int
      $status: String
      $limit: Int
      $offset: Int
    ) {
      schedules(
        order_by: {updated_at: desc},
        where: {
          doctors_appointment: { hospital_id: { _eq: $hospital_id } }
          status: { _like: $status }
        },
        limit: $limit,
        offset: $offset
      ) {
        ...sheduleFields
      }
    }
    ${sheduleDoctorFields}
  `
}

export const updateSchedule = () => {
  return gql`
    ${fragmentSchedules}
    mutation updateShedule($id: Int, $status: String, $description: String) {
      update_schedules(
        where: { id: { _eq: $id } }
        _set: { status: $status, description: $description }
      ) {
        affected_rows
        returning {
          ...sheduleFields
        }
      }
    }
  `
}

export const finishSchedule = () => {
  return gql`
    ${fragmentSchedules}
    mutation finishShedule($id: Int!) {
      update_schedules_by_pk(
        pk_columns: { id: $id }
        _set: { status: "finished" }
      ) {
        ...sheduleFields
      }
    }
  `
}

export const addUnavailableDaysDocAppointment = () => {
  return gql`
    ${fragmentDoctorAppointmentFields}
    mutation addUnavailableDays(
      $objects: [unavailable_days_insert_input!] = {}
    ) {
      insert_unavailable_days(objects: $objects) {
        affected_rows
        returning {
          doctors_appointment {
            ...doctorAppointmentFields
          }
        }
      }
    }
  `
}

export const removeUnavailableDaysDocAppointment = () => {
  return gql`
    ${fragmentDoctorAppointmentFields}
    mutation deleteUnavailableDaysByPk($unavailable_day_id: Int!) {
      delete_unavailable_days_by_pk(id: $unavailable_day_id) {
        doctors_appointment {
          ...doctorAppointmentFields
        }
      }
    }
  `
}

export const createNotification = () => {
  return gql`
    ${fragmentSchedules}

    fragment notificationFields on notifications {
      id
      created_at
      updated_at
      title
      description
      read
      schedule {
        ...sheduleFields
      }
    }

    mutation createNotification(
      $title: String
      $description: String
      $user_id: Int
      $read: Boolean = false
      $schedule_id: Int
    ) {
      insert_notifications_one(
        object: {
          title: $title
          description: $description
          user_id: $user_id
          read: $read
          schedule_id: $schedule_id
        }
      ) {
        ...notificationFields
      }
    }
  `
}

export const sheduleDoctorFields = gql`
fragment resumedUserFields on users {
  receita_digital_id
  id
  created_at
  updated_at
  birth_date
  full_name
  email
  photo
  cnpj
  cpf
  kind_of_person
  phone
  genderByGender {
    id
    value
  }
  adress {
    address
    id
    city
    state
    state_code
    complement
    created_at
    updated_at
    neighborhood
    number
    zipcode
    longitude
    latitude
  }
  user_marital_status {
    spouse_document
    marital_status {
      have_a_spouse
      label
      id
    }
  }
}

fragment sheduleFields on schedules {
  su{
    name
    email
    id
  }
  files{
    description
    url
    id
    created_at
    updated_at
  }
  doctor{
    full_name
    receita_digital_id
    receita_digital_infos
    cpf
  }
  doctors_appointment {
    clinic {
      id
      company_name
      email
    }
    hospital {
      id
      company_name
      email
    }
    doctor {
      id
      full_name
      receita_digital_id
      cpf
      receita_digital_infos
      email
    }
    cost_of_medical_consultation_online_service
    cost_of_medical_consultation_personal_assistance
  }
  id
  created_at
  updated_at
  day
  description
  status
  type_of_medical_appointment
  user_id
  room {
    id
    call_id
    start_time
    end_time
  }
  doctor_appointment_id
  start_time
  end_time
  user {
    ...resumedUserFields
  }
  start_time
  end_time
  day
  status
  evaluations {
    id
  }
  patient_symptom_record {
    description
    id
  }
  type_of_medical_appointment
}
`
