import { FaSync } from 'react-icons/fa'
import * as S from './styled' // Suponha que o arquivo de estilos seja importado dessa forma
import { MdArrowBack } from 'react-icons/md'

function Header ({ title, labelAdd, handleAdd, handleRefresh, onGoBack, centralButton }) {
  return (
    <S.HeaderContainer>
      <S.HeaderBox>
        {onGoBack && (
          <S.GoBackButton onClick={onGoBack}>
            <MdArrowBack />
          </S.GoBackButton>
        )}
        <h1>{title}</h1>
      </S.HeaderBox>
      <S.HeaderBox>
        {handleRefresh && <FaSync style={{ marginRight: '16px', cursor: 'pointer', fontSize: '1.5rem' }} onClick={handleRefresh} />}
        {centralButton && (
          <S.CenterButton onClick={centralButton?.onClick}>
            <S.ViewIcon /> {centralButton?.label}
          </S.CenterButton>
        )}
        {labelAdd && <S.AddButton onClick={handleAdd || undefined}>{labelAdd}</S.AddButton>}
      </S.HeaderBox>
    </S.HeaderContainer>
  )
}

export default Header
