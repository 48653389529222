import { gql } from '@apollo/client'
import { fragmentClinics } from '../clinics'
import { fragmentCooperative } from '../cooperative'
import { fragmentUser } from '../users'

export const fecthRequestsCredit = () => {
  return gql`
    ${fragmentCreditRequest}

    query getCreditRequests($limit: Int, $offset: Int) {
      credit_requests(
        order_by: { updated_at: desc },
        limit: $limit,
        offset: $offset
      ) {
        ...creditRequestFields
      }
    }
  `
}
export const fecthRequestsCreditByHospital = () => {
  return gql`
    ${fragmentCreditRequest}
    query getCreditRequests($limit: Int, $offset: Int, $hospital_id: Int) {
      credit_requests(
        order_by: { updated_at: desc },
        limit: $limit,
        offset: $offset,
        where: { hospital_id: { _eq: $hospital_id } }
      ) {
        ...creditRequestFields
      }
    }
  `
}
export const fecthRequestsCreditBySUS = () => {
  return gql`
    ${fragmentCreditRequest}
    query getCreditRequests($limit: Int, $offset: Int, $sus_id: Int) {
      credit_requests(
        order_by: { updated_at: desc },
        limit: $limit,
        offset: $offset,
        where: { sus_id: { _eq: $sus_id } }
      ) {
        ...creditRequestFields
      }
    }
  `
}
export const fecthRequestsCreditByClinic = () => {
  return gql`
    ${fragmentCreditRequest}
    query getCreditRequests($limit: Int, $offset: Int, $clinic_id: Int) {
      credit_requests(
        order_by: { updated_at: desc },
        limit: $limit,
        offset: $offset,
        where: { clinic_id: { _eq: $clinic_id } }
      ) {
        ...creditRequestFields
      }
    }
  `
}

export const fecthRequestsCreditById = () => {
  return gql`
    ${fragmentCreditRequest}
    query getCreditRequests($limit: Int, $offset: Int, $id: Int) {
      credit_requests(
        order_by: { updated_at: desc },
        limit: $limit,
        offset: $offset,
        where: { id: { _eq: $id } }
      ) {
        ...creditRequestFields
      }
    }
  `
}
export const acceptRequestsCredit = () => {
  return gql`
    ${fragmentCreditRequest}
    mutation updateCreditRequests($_in: [Int!]!) {
      update_credit_requests(
        where: { id: { _in: $_in } }
        _set: { accepted: true }
      ) {
        affected_rows
        returning {
          ...creditRequestFields
        }
      }
    }
  `
}

export const fragmentCreditRequest = gql`
  fragment creditRequestFields on credit_requests {
    accepted
    cooperative_installment_plan_id
    created_at
    id
    observation
    updated_at
    value_of_request_credit
    user {
      ...userFields
    }
    clinic {
      ...clinicFields
    }
    cooperative_installment_plan {
      cooperative {
        ...cooperativeFields
      }
    }
  }
  ${fragmentClinics}
  ${fragmentCooperative}
  ${fragmentUser}
`
