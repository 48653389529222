import styled from 'styled-components'
import { customTheme } from '../../../styles/theme'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
`

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
`

export const GoBackButton = styled.button`
  padding: 8px;
  background-color: transparent;
  font-size: 28px;
  margin-right: 4px;

  &:hover {
    opacity: 0.7;
    transition: 200ms;
  }
`

export const AddButton = styled.button`
  height: 36px;
  padding: 6px 16px;
  font-size: 0.875rem;
  background-color: ${customTheme.colors.primary};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 4px;

  &:hover {
    opacity: 0.85;
    transition: 200ms;
  }
`

export const CenterButton = styled.button`
  height: 36px;
  padding: 6px 24px; /* Aumenta a largura do botão */
  font-size: 0.875rem;
  background-color: ${customTheme.colors.secondary}; /* Altere a cor conforme necessário */
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-radius: 4px;
  margin-right: 16px; /* Ajuste conforme necessário */

  &:hover {
    opacity: 0.85;
    transition: 200ms;
  }
`

export const ViewIcon = styled(VisibilityOutlinedIcon)`
  margin-right: 8px; /* Adiciona espaço entre o ícone e o texto */
`
