import React from 'react'
import { customTheme } from '../../../../styles/theme'
import moment from 'moment'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import ButtonPrimary from '../../../components/Button'
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { formatMoney, formatNumber } from '../../../../Utils/format'

export const columns = (remove, refetch) => [
  {
    field: 'name',
    headerName: 'Nome',
    width: 300,
    editable: false
  },
  {
    field: 'site',
    headerName: 'Site',
    width: 250,
    editable: false
  },
  {
    field: 'terms_of_use',
    headerName: 'Termos de Uso',
    width: 250,
    editable: false
  },
  {
    field: 'terms_of_use_url_site',
    headerName: 'URL dos Termos de Uso',
    width: 150,
    editable: false
  },
  {
    field: 'created_at',
    headerName: 'Data de Cadastro',
    sortable: true,
    width: 150,
    valueGetter: (params) => `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
    valueFormatter: params => moment(params.value).format('DD/MM/YYYY - HH:MM')
  },
  {
    field: 'id',
    headerName: '',
    sortable: false,
    width: 60,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          <Link to={`/cooperatives/${params.row.id}`}>
            <ButtonPrimary
              style={{
                width: '35px',
                height: '35px',
                padding: '5px',
                borderRadius: '50%'
              }}
            >
              <EditOutlinedIcon
                style={{ color: customTheme.colors.white }}
                edge="start"
                fontSize="small"
                sx={{
                  marginBottom: '2px'
                }}
              />
            </ButtonPrimary>
          </Link>
        </Col>
      )
    }
  },
  {
    field: 'remove',
    headerName: '',
    sortable: false,
    width: 60,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          <ButtonPrimary
            onClick={async () => {
              await remove({
                variables: {
                  id: params.row.id
                }
              })
              await refetch()
            }}
            style={{
              width: '35px',
              height: '35px',
              padding: '5px',
              borderRadius: '50%',
              backgroundColor: customTheme.colors.error
            }}
          >
            <DeleteOutlinedIcon
              style={{ color: customTheme.colors.white }}
              edge="start"
              fontSize="small"
              sx={{
                marginBottom: '2px'
              }}
            />
          </ButtonPrimary>
        </Col>
      )
    }
  }
]

export const columnsPlans = (openPlans, remove, refetch) => [
  {
    field: 'minimum_value',
    headerName: 'Valor Mínimo',
    width: 350,
    editable: false,
    renderCell: (params) => {
      return formatMoney(params.row.minimum_value)
    }
  },
  {
    field: 'number_of_installments',
    headerName: 'Número de parcelas',
    width: 250,
    editable: false
  },
  {
    field: 'fees',
    headerName: 'Juros (%) - por parcela sobre o valor total do crédito',
    width: 400,
    editable: false,
    renderCell: (params) => {
      return `${formatNumber(params.row.fees)}%`
    }
  },
  {
    field: 'id',
    headerName: '',
    sortable: false,
    width: 60,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          <ButtonPrimary
            onClick={() => openPlans(params.row)}
            style={{
              width: '35px',
              height: '35px',
              padding: '5px',
              borderRadius: '50%'
            }}
          >
            <EditOutlinedIcon
              style={{ color: customTheme.colors.white }}
              edge="start"
              fontSize="small"
              sx={{
                marginBottom: '2px'
              }}
            />
          </ButtonPrimary>
        </Col>
      )
    }
  },
  {
    field: 'remove',
    headerName: '',
    sortable: false,
    width: 60,
    renderCell: (params) => {
      return (
        <Col className="d-flex justify-content-center">
          <ButtonPrimary
            onClick={async () => {
              await remove({
                variables: {
                  _in: [params.row.id]
                }
              })
              await refetch()
            }}
            style={{
              width: '35px',
              height: '35px',
              padding: '5px',
              borderRadius: '50%',
              backgroundColor: customTheme.colors.error
            }}
          >
            <DeleteOutlinedIcon
              style={{ color: customTheme.colors.white }}
              edge="start"
              fontSize="small"
              sx={{
                marginBottom: '2px'
              }}
            />
          </ButtonPrimary>
        </Col>
      )
    }
  }
]

export const groupFieldsPlans = (content) => {
  const handleInputChange = (e, key) => {
    if (content) {
      content = { ...content, [key]: e?.target?.value || e }
      // setContent((prev) => ({ ...prev, [key]: e?.target?.value || e }));
    }
  }

  return [
    {
      name: 'Tarifa de parcelamento de crédito',
      fields: [
        {
          label: 'Valor Mínimo',
          type: 'float',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.minimum_value,
          name: 'minimum_value',
          onChange: (e) => {
            handleInputChange(e, 'minimum_value')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Número de parcelas',
          type: 'number',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.number_of_installments,
          name: 'number_of_installments',
          onChange: (e) => {
            handleInputChange(e, 'number_of_installments')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Juros (%) - por parcela sobre o valor total do crédito',
          type: 'float',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.fees,
          name: 'fees',
          onChange: (e) => {
            handleInputChange(e, 'fees')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]
}

export const groupFields = (content, setContent) => {
  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e?.target?.value || e }))
    }
  }

  return [
    {
      name: 'Dados da Agência',
      fields: [
        {
          label: 'Nome',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.name,
          name: 'name',
          onChange: (e) => {
            handleInputChange(e, 'name')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'URL do Site',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.site || 'https://',
          name: 'site',
          onChange: (e) => {
            handleInputChange(e, 'site')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Termos de Uso',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.terms_of_use || 'https://',
          name: 'terms_of_use',
          onChange: (e) => {
            handleInputChange(e, 'terms_of_use')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'URL dos Termos de Uso',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.terms_of_use_url_site || 'https://',
          name: 'terms_of_use_url_site',
          onChange: (e) => {
            handleInputChange(e, 'terms_of_use_url_site')
          },
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]
}
