function PrescriptionView () {
  console.log(location)

  return (
    <div>
      Prescripção
    </div>
  )
}

export default PrescriptionView
