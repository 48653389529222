import React, { useState } from 'react'
import { customTheme } from '../../../../styles/theme'
import moment from 'moment'
import ButtonPrimary from '../../../components/Button'
import * as Yup from 'yup'
import { formatMoney } from '../../../../Utils/format'
import CardModal from '../../../components/CardModal'
import { ModalConfirm } from '../../hospital/schedules-hospital/styled'
import DataTableActionColumn from '../../../components/DataTableActionColumn'

const handleDelete = (modal, alertBox, remove, refetch, params) => {
  modal.setOptions({
    open: true,
    component: (
      <CardModal style={{ width: '400px' }}>
        <ModalConfirm>
          <h3>Tem certeza que deseja apagar?</h3>
          <div className="buttons-area">
            <ButtonPrimary
              id="delete-button"
              onClick={async () => {
                try {
                  await remove({
                    variables: {
                      id: params.row.id
                    }
                  })
                  await refetch()
                  alertBox.setOptions({
                    open: true,
                    message: 'Sucesso ao apagar!',
                    type: 'success',
                    time: 3000
                  })
                  modal.setOptions({ open: false })
                } catch (error) {
                  alertBox.setOptions({
                    open: true,
                    message:
                      'Não pode apagar tarifa, provavelmente está sendo utilizada em algum cadastro de Clínica!',
                    type: 'error',
                    time: 3000
                  })
                  console.error(error)
                }
                modal.setOptions({ open: false })
              }}
            >
              Sim
            </ButtonPrimary>

            <ButtonPrimary
              onClick={() => modal.setOptions({ open: false })}
              style={{
                backgroundColor: customTheme.colors.error,
                boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`
              }}
            >
              Não
            </ButtonPrimary>
          </div>
        </ModalConfirm>
      </CardModal>
    )
  })
}

export const columns = (modal, remove, refetch, alertBox, history) => [
  {
    field: 'fixed_amount_of_transfer_to_manager',
    headerName: 'Valor fixo de transferência',
    width: 250,
    editable: false,
    valueGetter: (params) =>
      `${formatMoney(params?.row?.fixed_amount_of_transfer_to_manager)} `
  },
  // {
  //   field: "fixed_percentage_of_transfer_to_manager",
  //   headerName: "Percentual fixo de transferência",
  //   width: 250,
  //   editable: false,
  //   valueGetter: (params) =>
  //     `${
  //       formatNumber(params.row.fixed_percentage_of_transfer_to_manager) || ""
  //     }%`,
  // },
  // {
  //   field: "debit_fees",
  //   headerName: "Taxa Débito",
  //   width: 150,
  //   editable: false,
  //   valueGetter: (params) => `${formatNumber(params.row.debit_fees) || ""}%`,
  // },
  // {
  //   field: "pix_fees",
  //   headerName: "Taxa PIX",
  //   width: 150,
  //   editable: false,
  //   valueGetter: (params) => `${formatNumber(params.row.pix_fees) || ""}%`,
  // },
  // {
  //   field: "credit_fees",
  //   headerName: "Taxa Crédito",
  //   width: 150,
  //   editable: false,
  //   valueGetter: (params) => `${formatNumber(params.row.credit_fees) || ""}%`,
  // },
  {
    field: 'created_at',
    headerName: 'Data de Cadastro',
    sortable: true,
    width: 150,
    valueGetter: (params) =>
      `${moment(params.row.created_at).format('YYYY-MM-DD HH:MM') || ''} `,
    valueFormatter: (params) =>
      moment(params.value).format('DD/MM/YYYY - HH:MM')
  },
  {
    field: 'id',
    headerName: '',
    sortable: false,
    width: 100,
    renderCell: (params) => {
      return (
        <DataTableActionColumn
          handleEdit={() => history.push(`/plans/${params.row.id}`)}
          handleDelete={() =>
            handleDelete(modal, alertBox, remove, refetch, params)}
        />
      )
    }
  }
]

export const groupFields = (content, setContent) => {
  const [value, setValue] = useState(true)

  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e?.target?.value || e }))
    }
  }

  return [
    {
      name: 'Tarifa',
      fields: [
        {
          label: 'Valor fixo de transferência',
          type: 'radio',
          required: false,
          disabled: false,
          name: 'vft',
          currentValue: value,
          onChange: (data) => {
            setValue(true)
          },
          columns: {
            xs: 2
          }
        },
        {
          label: 'Percentual fixo de transferência',
          type: 'radio',
          required: false,
          disabled: false,
          name: 'pft',
          currentValue: !value,
          onChange: (data) => {
            setValue(false)
          },
          columns: {
            xs: 2
          }
        },
        {
          label: 'Valor fixo de transferência',
          type: 'float',
          required: true,
          disabled: !value,
          hideField: !value,
          currentValue: content?.fixed_amount_of_transfer_to_manager,
          name: 'fixed_amount_of_transfer_to_manager',
          onChange: (e) => {
            handleInputChange(e, 'fixed_amount_of_transfer_to_manager')
          },
          columns: {
            xs: 3
          }
        },
        {
          label: 'Percentual fixo de transferência (%)',
          type: 'float',
          required: true,
          disabled: value,
          hideField: value,
          currentValue: content?.fixed_percentage_of_transfer_to_manager,
          name: 'fixed_percentage_of_transfer_to_manager',
          onChange: (e) => {
            handleInputChange(e, 'fixed_percentage_of_transfer_to_manager')
          },
          columns: {
            xs: 3
          }
        }
        // {
        //   label: "Taxa Debito (%)",
        //   type: "float",
        //   required: true,
        //   disabled: false,
        //   hideField: false,
        //   currentValue: content?.debit_fees,
        //   name: "debit_fees",
        //   onChange: (e) => {
        //     handleInputChange(e, "debit_fees");
        //   },
        //   columns: {
        //     xs: 3,
        //   },
        //   validation:
        //     Yup.string("Valor inválido").required("Campo obrigatório"),
        // },
        // {
        //   label: "Taxa PIX (%)",
        //   type: "float",
        //   required: true,
        //   disabled: false,
        //   hideField: false,
        //   currentValue: content?.pix_fees,
        //   name: "pix_fees",
        //   onChange: (e) => {
        //     handleInputChange(e, "pix_fees");
        //   },
        //   columns: {
        //     xs: 3,
        //   },
        //   validation:
        //     Yup.string("Valor inválido").required("Campo obrigatório"),
        // },
        // {
        //   label: "Taxa Crédito (%)",
        //   type: "float",
        //   required: true,
        //   disabled: false,
        //   hideField: false,
        //   currentValue: content?.credit_fees,
        //   name: "credit_fees",
        //   onChange: (e) => {
        //     handleInputChange(e, "credit_fees");
        //   },
        //   columns: {
        //     xs: 3,
        //   },
        //   validation:
        //     Yup.string("Valor inválido").required("Campo obrigatório"),
        // },
      ]
    }
  ]
}
