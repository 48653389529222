import { ToggleButton as MUIToggleButton, styled as MStyled } from '@mui/material'
import { customTheme } from '../../../../../styles/theme'
import styled from 'styled-components'

export const ToggleButton = MStyled(MUIToggleButton)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: 'white',
    backgroundColor: customTheme.colors.primary
  }
})

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 10px;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  
  button {
    width: 100%;
    height: auto;
  }

  & > label {
    margin-top: 0.5rem;
    font-weight: bold;
    color: red;
  }
`
