import { DataGrid, ptBR } from '@mui/x-data-grid'
import * as S from './styled'

function DataTable ({ data, columns, itemPerPage = 10, totalItems = 100, onChangePage, loading }) {
  return (
    <S.Container>
      <DataGrid
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        rows={data}
        columns={columns}
        pageSize={itemPerPage}
        rowsPerPageOptions={[10]}
        pagination
        disableSelectionOnClick
        rowCount={totalItems}
        onPageChange={onChangePage}
        loading={loading}
        paginationMode="server"
      />
    </S.Container>
  )
}

export default DataTable
