import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlertBox } from '../../../../contexts/alert_box'
import InternalBody from '../../../components/InternalBody'
import GenerateForm from '../../../components/layout/GenerateForm'
import { groupFields, especiality } from './columns'
import { updateAddress } from '../../../../services/address'
import { updateAddressUseCase } from '../../../../main/usecases/addressess'
import { Col, Row } from 'react-bootstrap'
import ButtonPrimary from '../../../components/Button'
import { customTheme } from '../../../../styles/theme'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CardModal from '../../../components/CardModal'
import { useModal } from '../../../../contexts/modal'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import mapPin from '../../../../assets/svg/pin.svg'
import Leaflet from 'leaflet'
import { CustomStyle, buttonPrimaryStyle } from './styled'
import country from '../../../../Utils/estados_cidades.json'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import {
  createSUSUseCase,
  updateSUSUseCase
} from '../../../../main/usecases/sus'
import {
  addSUSSpecialties,
  createSUS,
  fetchSUSById,
  removeSUSSpecialties,
  updateSUS
} from '../../../../services/sus'
import { fecthEspecialities } from '../../../../services/medicalSpecialty'
import {
  genericCreateFetch,
  genericFetch
} from '../../../../main/usecases/generic'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../services/firebase/firebase-config'
import { coordIsValid } from '../../../../Utils/coordValidate'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Avatar from '@material-ui/core/Avatar'
import { fetchAddressByZipCode } from '../../../../services/searchZip/searchZipcode'
import EditIcon from '@mui/icons-material/Edit'
import ManageDoctorsSUS from '../manageDoctorsSUS/ManageDoctorsSus'
import * as S from './styled'

function InternalSus ({ match }) {
  const { data, refetch } = useQuery(fetchSUSById(), {
    variables: { limit: 10, offset: 0, id: match.params.id }
  })
  const especialities = useQuery(fecthEspecialities(), {})
  const [mutateAddSpecialtyFunction] = useMutation(addSUSSpecialties())
  const [mutateRemoveSpecialtyFunction] = useMutation(removeSUSSpecialties())
  const [mutateFunction] = useMutation(updateSUS())
  const [createSUSMutation] = useMutation(createSUS())
  const [mutateFunctionAdress] = useMutation(updateAddress())
  const [content, setContent] = useState({})
  const alertBox = useAlertBox()
  const history = useHistory()
  const modal = useModal()
  const [isFirstRun, setIsFirtsRun] = useState(true)
  const states = []
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })

  useEffect(() => {
    if (data?.sus_by_pk) {
      setContent(data?.sus_by_pk)
    }
  }, [data])

  useEffect(() => {
    if (content?.adress?.zipcode && !isFirstRun) {
      fetchAddressByZipCode(
        content?.adress?.zipcode,
        content,
        setContent,
        states,
        alertBox
      ).then(() => { })
    }

    if (data?.sus_by_pk?.adress?.zipecode !== content?.adress?.zipcode) {
      setIsFirtsRun(false)
    }
  }, [content?.adress?.zipcode])

  const mutationCreateSUS = async (e, url, actions) => {
    await createSUSUseCase(
      createSUSMutation,
      {
        email: e.email,
        address: e.address,
        city: e.city.label,
        complement: e.complement,
        neighborhood: e.neighborhood,
        number: e.number,
        state: e.state.label,
        state_code: content?.adress?.state_code,
        zipcode: e.zipcode,
        latitude: e.latitude || content.latitude,
        longitude: e.longitude || content.longitude,
        name: e.name,
        website: e.website,
        online_service: e.online_service,
        personal_assistance: e.personal_assistance,
        phone_number: e.phone_number,
        photo: url || ''
      },
      {
        actions,
        alertBox
      }
    )
  }

  const mutationUpdateSUS = async (e, url, actions) => {
    await updateAddressUseCase(
      mutateFunctionAdress,
      {
        id: content.adress.id,
        address: e.address,
        complement: e.complement,
        latitude: e.latitude || content.latitude,
        longitude: e.longitude || content.longitude,
        neighborhood: e.neighborhood,
        number: e.number,
        city: e.city.label,
        state: e.state.label,
        state_code: e.state.value,
        zipcode: e.zipcode
      },
      {
        actions,
        alertBox
      }
    )
    await updateSUSUseCase(
      mutateFunction,
      {
        id: match.params.id,
        email: e.email,
        name: e.name,
        website: e.website,
        phone_number: e.phone_number,
        online_service: e.online_service,
        personal_assistance: e.personal_assistance,
        photo: url || content.photo
      },
      {
        actions,
        alertBox
      }
    )
  }

  const mapPinIcon = Leaflet.icon({
    iconUrl: mapPin,
    iconSize: [58, 68],
    iconAnchor: [29, 68],
    popupAnchor: [170, 2]
  })

  const onSubmit = async (e, actions) => {
    if (window.location.pathname.includes('new')) {
      if (e.file) {
        const storageRef = ref(storage, `sus/${new Date().getTime()}`)
        const uploadTask = uploadBytesResumable(storageRef, e.file)

        uploadTask.on('state_changed', null, console.error, () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            await mutationCreateSUS(e, url, actions)
          })
        })
      } else {
        await mutationCreateSUS(e, null, actions)
      }
    } else {
      console.warn(e)
      if (e.file) {
        const storageRef = ref(storage, `sus/${new Date().getTime()}`)
        const uploadTask = uploadBytesResumable(storageRef, e.file)

        uploadTask.on('state_changed', null, console.error, () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            await mutationUpdateSUS(e, url, actions)
          })
        })
      } else {
        await mutationUpdateSUS(e, null, actions)
      }
    }

    handleGoBack()
  }

  const handleGoBack = () => {
    history.goBack()
  }

  const filterEspecialities = (all, used) => {
    return all.filter(
      (item) => !used.find((el) => el.medical_specialty.id === item.id)
    )
  }

  const viewEspecialisties = async (esp) => {
    const submitEspecialty = async (e, actions) => {
      if (!esp) {
        await genericCreateFetch(
          mutateAddSpecialtyFunction,
          {
            objects: [
              {
                medical_specialty_id: e?.sus_with_medical_specialties?.value,
                sus_id: content.id
              }
            ]
          },
          {
            alertBox,
            modal,
            actions
          }
        )
        await refetch()
        modal.setOptions({ open: false })
      } else {
        modal.setOptions({ open: false })
      }
    }
    const removeEspecialty = async (val) => {
      await genericFetch(
        mutateRemoveSpecialtyFunction,
        {
          _in: [val.id]
        },
        {
          alertBox,
          modal,
          actions: null
        }
      )
      await refetch()
      modal.setOptions({ open: false })
    }

    modal.setOptions({
      open: true,
      component: (
        <CardModal
          style={{ width: 500, position: 'relative', height: '90vh' }}
          title="Especialidades"
          avatar={false}
          subtitle="Gerencie todas as especialidades desse médico"
        >
          {esp && (
            <DeleteOutlinedIcon
              style={{
                position: 'absolute',
                right: 20,
                top: 20,
                backgroundColor: '#fff',
                cursor: 'pointer'
              }}
              onClick={async () => await removeEspecialty(esp)}
            />
          )}
          <GenerateForm
            onSubmit={submitEspecialty}
            groupFields={especiality(
              esp,
              esp
                ? especialities?.data?.medical_specialties
                : filterEspecialities(
                  especialities?.data?.medical_specialties,
                  content.sus_with_medical_specialties
                )
            )}
            button={{
              submit: !esp ? 'Adicionar Especialidade' : 'Fechar',
              submitting: esp ? 'Fechar' : 'Fechando'
            }}
          />
        </CardModal>
      )
    })
  }
  return (
    <>
      <CustomStyle>
        <InternalBody>
          {content?.created_at && (
            <>
              <p>
                Criado em:{' '}
                {moment(content?.created_at).format('DD/MM/YYYY HH:MM') || ''}
              </p>
              <Row>
                <h5 className="mb-2 mt-5">Especialidades</h5>
                <S.SpecialitiesBox>
                  {content?.sus_with_medical_specialties?.map((item, index, array) => (
                    <S.ButtonSpeciality
                      key={item.id}
                      onClick={() => viewEspecialisties(item)}
                    >
                      <Avatar
                        alt={item.medical_specialty.name}
                        src={item.medical_specialty.icon}
                      />
                      {item.medical_specialty.name}
                    </S.ButtonSpeciality>
                  ))}
                </S.SpecialitiesBox>
                <Col xs={2}>
                  <ButtonPrimary
                    onClick={() => viewEspecialisties(null)}
                    style={{
                      marginBottom: '30px',
                      boxShadow: 'none',
                      fontSize: '12px',
                      padding: '5px',
                      backgroundColor: 'transparent',
                      lineHeight: '2',
                      width: 30,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <AddCircleIcon style={{ color: customTheme.colors.primary }} />
                  </ButtonPrimary>
                </Col>
              </Row>
              {!window.location.pathname.includes('new') && (
                <Row>
                  <Col xs={12}>
                    <ButtonPrimary
                      onClick={() => {
                        modal.setOptions({
                          open: true,
                          component: (
                            <CardModal style={{ width: '90%' }}>
                              <ManageDoctorsSUS
                                sus_id={match.params.id}
                              />
                            </CardModal>
                          )
                        })
                      }}
                      style={buttonPrimaryStyle}
                    >
                      Gerenciar médicos
                      <EditIcon
                        style={{ color: customTheme.colors.white, marginLeft: 5 }}
                      />
                    </ButtonPrimary>
                  </Col>
                </Row>
              )}
            </>
          )}
          <GenerateForm
            onSubmit={onSubmit}
            groupFields={groupFields(content, setContent)}
            button={{
              submit: window.location.pathname.includes('new')
                ? 'Adicionar'
                : 'Atualizar',
              submitting: window.location.pathname.includes('new')
                ? 'Adicionando...'
                : 'Atualizando...'
            }}
          />

          {coordIsValid(content.adress?.latitude) && coordIsValid(content.adress?.longitude) && (
            <Col xs={12} style={{ height: '250px' }}>
              <MapContainer
                center={[content.adress.latitude, content.adress.longitude]}
                style={{ width: '100%', height: '100%' }}
                zoom={15}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  icon={mapPinIcon}
                  position={[
                    content.adress.latitude,
                    content.adress.longitude
                  ]}
                />
              </MapContainer>
            </Col>
          )}
        </InternalBody>
      </CustomStyle>
    </>
  )
}

export default InternalSus
