import React from 'react'
import { Col } from 'react-bootstrap'

import Leaflet from 'leaflet'
// import { coordIsValid } from '../../../Utils/coordValidate'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import mapPin from '../../../assets/svg/pin.svg'

export const Map = (props) => {
  const { content } = props

  const mapPinIcon = Leaflet.icon({
    iconUrl: mapPin,
    iconSize: [58, 68],
    iconAnchor: [29, 68],
    popupAnchor: [170, 2]
  })

  return (
    <>
      {content && (
        <Col xs={12} style={{ height: '250px' }}>
          <MapContainer
            center={[content.adress?.latitude, content.adress?.longitude]}
            style={{ width: '100%', height: '100%', maxHeight: '250px' }}
            zoom={15}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              icon={mapPinIcon}
              position={[
                content.adress?.latitude || -14.8567487,
                content.adress?.longitude || -40.8414804
              ]}
            >
              <Popup>
                A pretty CSS3 popup. <br /> Easily customizable.
              </Popup>
            </Marker>
          </MapContainer>
        </Col>
      )}
    </>
  )
}
