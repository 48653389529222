// import api from "../api/api";
import { gql } from '@apollo/client'
import { fragmentAddresses } from '../address'
import { fragmentCompanyPlan } from '../companyPlans'
import { fragmentDoctors } from '../doctor'

export const fetchClinics = () => {
  return gql`
    query GetUsers {
      clinics {
        address_id
        cnpj
        company_name
        created_at
        description
        facebook
        id
        instagram
        linkedin
        phone_number
        photo
        updated_at
      }
    }
  `
}

export const updateClinics = () => {
  return gql`
    ${fragmentClinics}
    mutation updateClinic(
      $cnpj: String = ""
      $company_name: String = ""
      $description: String = ""
      $facebook: String = ""
      $website: String = ""
      $instagram: String = ""
      $linkedin: String = ""
      $phone_number: String = ""
      $email: String = ""
      $photo: String = ""
      $company_plan_id: Int = ""
      $id: Int = 10
    ) {
      update_clinics(
        where: { id: { _eq: $id } }
        _set: {
          cnpj: $cnpj
          company_name: $company_name
          description: $description
          facebook: $facebook
          website: $website
          instagram: $instagram
          email: $email
          linkedin: $linkedin
          phone_number: $phone_number
          photo: $photo
          company_plan_id: $company_plan_id
        }
      ) {
        affected_rows
        returning {
          ...clinicFields
        }
      }
    }
  `
}

export const fetchClinicsById = () => {
  return gql`
    query getClinics($limit: Int, $offset: Int, $id: Int) {
      clinics(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { id: { _eq: $id } }) {
        ...clinicFields
      }
    }
    ${fragmentClinics}
  `
}

export const removeClinic = () => {
  return gql`
    mutation deleteClinicByPk($id: Int!) {
      delete_clinics_by_pk(id: $id) {
        created_at
      }
    }
  `
}

/**
 * Retorna uma consulta GraphQL para recuperar informações detalhadas das clínicas, opcionalmente filtradas por nome, cidade e estado.
 *
 * @param {Object} options - Opções para filtrar os resultados da consulta.
 * @param {string} [options.name=null] - Opcional. O nome da clínica para filtrar os resultados.
 * @param {string} [options.city=null] - Opcional. O nome da cidade para filtrar os resultados.
 * @param {string} [options.stateCode=null] - Opcional. O código do estado para filtrar os resultados.
 * @returns {string} - Uma consulta GraphQL para recuperar informações detalhadas das clínicas.
 *
 * @example
 * // Exemplo de utilização:
 * fetchClinicsFull({
 *   name: "%clinica de%",         // Nome da clínica (filtro opcional)
 *   city: "Itajaí",                // Nome da cidade (filtro opcional)
 *   stateCode: "SC"                // Código do estado (filtro opcional)
 * })
 */
export const fetchClinicsFull = (data) => {
  let query
  if (!data?.name) {
    if (data?.city && data?.stateCode) {
      query = gql`
        query getClinics($limit: Int, $offset: Int, $city: String, $state_code: String) {
          clinics(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            ...clinicFields
          }
          clinics_aggregate(where: { adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentClinics}
      `
    } else {
      query = gql`
        query getClinics($limit: Int, $offset: Int) {
          clinics(limit: $limit, offset: $offset, order_by: { updated_at: desc }) {
            ...clinicFields
          }
          clinics_aggregate {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentClinics}
      `
    }
  } else {
    if (data?.city && data?.stateCode) {
      query = gql`
        query getClinics($limit: Int, $offset: Int, $name: String, $city: String, $state_code: String) {
          clinics(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { company_name: { _ilike: $name }, adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            ...clinicFields
          }
          clinics_aggregate(where: { company_name: { _ilike: $name }, adress: { city: { _eq: $city }, state_code: { _eq: $state_code } } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentClinics}
      `
    } else {
      query = gql`
        query getClinics($limit: Int, $offset: Int, $name: String) {
          clinics(limit: $limit, offset: $offset, order_by: { updated_at: desc }, where: { company_name: { _ilike: $name } }) {
            ...clinicFields
          }
          clinics_aggregate(where: { company_name: { _ilike: $name } }) {
            aggregate {
              count(columns: id)
            }
          }
        }
        ${fragmentClinics}
      `
    }
  }

  return query
}

export const inserDoctorInClinic = () => {
  return gql`
    ${fragmentClinics}
    mutation insertDoctorsWorkingInClinics($clinic_id: Int, $doctor_id: Int) {
      insert_doctors_working_in_clinics_one(
        object: { clinic_id: $clinic_id, doctor_id: $doctor_id }
      ) {
        clinic {
          ...clinicFields
        }
      }
    }
  `
}

export const removeDoctorInClinic = () => {
  return gql`
    mutation deleteDoctorWorkingInClinic($clinic_id: Int, $doctor_id: Int) {
      delete_doctors_working_in_clinics(
        where: {
          clinic_id: { _eq: $clinic_id }
          doctor_id: { _eq: $doctor_id }
        }
      ) {
        affected_rows
      }
    }
  `
}

export const fragmentClinicHoursAndDaysAvailables = gql`
  fragment clinicHoursAndDaysAvailables on clinic_hours_and_days_available {
    id
    days_of_week {
      id
      full_text
      abbreviation
    }
    start_time
    end_time
  }
`

export const fragmentClinics = gql`
  fragment clinicFields on clinics {
    photo
    cnpj
    company_name
    created_at
    description
    facebook
    id
    instagram
    linkedin
    phone_number
    photo
    email
    updated_at
    website
    personal_assistance
    online_service
    adress {
      ...addressFields
    }
    doctors_working_in_clinics {
      doctor {
        ...doctorFields
      }
    }
    clinic_hours_and_days_availables {
      ...clinicHoursAndDaysAvailables
    }
    company_plan {
      ...companyPlanFields
    }
  }
  ${fragmentAddresses}
  ${fragmentDoctors}
  ${fragmentClinicHoursAndDaysAvailables}
  ${fragmentCompanyPlan}
`

export const fragmentResumedClinicFields = gql`
  fragment resumedClinicFields on clinics {
    id
    company_name
    photo
    adress {
      ...addressFields
    }
  }
`
