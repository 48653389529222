import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import * as Yup from 'yup'
import Avatar from '@material-ui/core/Avatar'
import country from '../../../Utils/estados_cidades.json'
import { getScheduleStatus } from '../../../Utils/schedules'
import moment from 'moment'
import { formatMoney } from '../../../Utils/format'
import CardModal from '../../components/CardModal'
import { ModalContainer } from '../doctor/schedules/styled'
import GenerateForm from '../../components/layout/GenerateForm'
import { schedulesFieldsView } from '../doctor/schedules/columns'
import DataTableActionColumn from '../../components/DataTableActionColumn'

export const groupFields = (content, setContent) => {
  const cities = []
  const states = []
  const [selectdState, setSelectedState] = useState(null)
  country.estados.forEach((item) => {
    item.cidades.forEach((city) =>
      cities.push({ label: city, value: item.sigla })
    )
  })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })
  const handleInputChange = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({ ...prev, [key]: e?.target?.value || e }))
    }
  }

  const handleInputChangeAddress = (e, key) => {
    if (content && setContent) {
      setContent((prev) => ({
        ...prev,
        adress: { ...prev.adress, [key]: e.target.value }
      }))
    }
  }

  return [
    {
      name: 'Usuário',
      fields: [
        {
          label: 'Avatar',
          type: 'file',
          required: true,
          disabled: false,
          hideField: false,
          name: 'photo',
          columns: {
            xs: 12
          },
          validation: Yup.string('Valor inválido'),
          component: (data) => {
            return (
              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <Avatar
                    src={data || content?.photo}
                    style={{ width: 100, height: 100 }}
                  />
                </Col>
              </Row>
            )
          }
        },
        {
          label: 'Nome',
          type: 'text',
          required: true,
          disabled: false,
          hideField: false,
          currentValue: content?.full_name,
          name: 'full_name',
          onChange: (e) => {
            handleInputChange(e, 'full_name')
          },
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'CPF / CNPJ',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'cnpj')
          },
          currentValue: content?.cnpj || content?.cpf,
          hideField: false,
          name: content?.cnpj ? 'cnpj' : 'CPF',
          mask: content?.cnpj ? '99.999.999/9999-99' : '999.999.999-99',
          columns: {
            xs: 3
          },
          validation: Yup.string('Valor inválido')
            .min(14, 'Fora do padrão')
            .max(18, 'Fora do padrão')
            .required('Campo obrigatório')
        },
        {
          label: 'Telefone',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChange(e, 'phone')
          },
          currentValue: content?.phone,
          hideField: false,
          mask: '+ 99 (99) 9 9999-9999',
          name: 'phone',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'E-mail',
          type: 'email',
          required: true,
          disabled: false,
          hideField: false,
          onChange: (e) => {
            handleInputChange(e, 'email')
          },
          currentValue: content?.email || '',
          name: 'email',
          columns: {
            xs: 6
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Gênero',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content?.genderByGender?.value
            ? {
                label: content?.genderByGender.value,
                value: content?.genderByGender?.id
              }
            : undefined,
          hideField: false,
          name: 'genderByGender',
          onChange: (data) => {},
          options: [
            { label: 'Masculino', value: 1 },
            { label: 'Feminino', value: 2 },
            { label: 'Outro', value: 3 }
          ],
          columns: {
            xs: 6
          },
          validation: Yup.object().required('Campo obrigatório')
        }
      ]
    },
    {
      name: 'Endereço',
      fields: [
        {
          label: 'CEP',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'zipcode')
          },
          currentValue: content?.adress?.zipcode,
          hideField: false,
          mask: '99999-999',
          name: 'zipcode',
          columns: {
            xs: 4
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Logradouro',
          type: 'text',
          required: true,
          disabled: false,
          currentValue: content?.adress?.address,
          hideField: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'address')
          },
          name: 'address',
          columns: {
            xs: 8
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Bairro',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'neighborhood')
          },
          currentValue: content?.adress?.neighborhood,
          hideField: false,
          name: 'neighborhood',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Número',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'number')
          },
          currentValue: content?.adress?.number,
          hideField: false,
          name: 'number',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Latitude',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'latitude')
          },
          currentValue: content?.adress?.latitude || '',
          hideField: false,
          name: 'latitude',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Longitude',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'longitude')
          },
          currentValue: content?.adress?.longitude || '',
          hideField: false,
          name: 'longitude',
          columns: {
            xs: 3
          },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Estado',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content?.adress?.state
            ? {
                label: content?.adress?.state,
                value: content?.adress?.state_code
              }
            : undefined,
          hideField: false,
          name: 'state',
          onChange: (data) => {
            setSelectedState(data)
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  state: data.label,
                  state_code: data.value
                }
              }))
            }
          },
          options: states,
          columns: {
            xs: 6
          },
          validation: Yup.object().required('Campo obrigatório')
        },
        {
          label: 'Cidade',
          type: 'autocomplete',
          required: true,
          disabled: false,
          currentValue: content?.adress?.city
            ? {
                label: content?.adress?.city,
                value: content?.adress?.city
              }
            : undefined,
          hideField: false,
          name: 'city',
          columns: {
            xs: 6
          },
          onChange: (data) => {
            if (content && setContent) {
              setContent((prev) => ({
                ...prev,
                adress: {
                  ...prev?.adress,
                  city: data.label
                }
              }))
            }
          },
          options: cities.filter(
            (item) =>
              item.value === selectdState?.value ||
              item.value === content?.adress?.state_code
          ),
          validation: Yup.object().required('Campo obrigatório')
        },

        {
          label: 'Complemento',
          type: 'text',
          required: true,
          disabled: false,
          onChange: (e) => {
            handleInputChangeAddress(e, 'complement')
          },
          currentValue: content?.adress?.complement || '',
          hideField: false,
          name: 'complement',
          columns: {
            xs: 6
          },
          validation: Yup.string('Valor inválido')
        }
      ]
    }
  ]
}

export const columns = (modal) => {
  return [
    // { field: "id", headerName: "ID", width: 90 },
    {
      field: 'photo',
      headerName: 'Foto',
      width: 60,
      editable: false,
      renderCell: (params) => {
        return (
          <Col className="d-flex justify-content-center">
            {params?.row?.user?.profile_image
              ? (
                <Avatar src={params.row.user?.photo} />
                )
              : (
                <Avatar>{params?.row?.user?.full_name ? params.row.user.full_name[0] : ''}</Avatar>
                )}
          </Col>
        )
      }
    },
    {
      field: 'type_of_medical_appointment',
      headerName: 'Tipo de Consulta',
      width: 140,
      editable: false,
      valueGetter: (params) =>
        `${
          params.row.type_of_medical_appointment === 'online'
            ? 'Online'
            : 'Presencial'
        } `
    },
    {
      field: 'full_name',
      headerName: 'Nome do paciente',
      width: 170,
      editable: false,
      valueGetter: (params) => `${params.row.user?.full_name || ''} `
    },
    {
      field: 'description',
      headerName: 'Descrição',
      width: 200,
      editable: false,
      valueGetter: (params) => `${params.row.description || ''} `
    },
    {
      field: 'status',
      headerName: 'Status',
      editable: false,
      valueGetter: (params) => `${getScheduleStatus(params.row.status) || ''} `
    },
    {
      field: 'average_cost_of_medical_consultation',
      headerName: 'Custo',
      width: 100,
      type: 'number',
      editable: false,
      valueGetter: (params) => {
        const value =
          params?.row?.type_of_medical_appointment === 'online'
            ? params?.row?.doctors_appointment
              ?.cost_of_medical_consultation_online_service
            : params?.row?.doctors_appointment
              ?.cost_of_medical_consultation_personal_assistance
        return `${formatMoney(value || 0)} `
      }
    },
    {
      field: 'fullname_doctor',
      headerName: 'Nome do Médico',
      width: 150,
      editable: false,
      valueGetter: (params) =>
      `${params?.row?.doctor?.full_name ?? params?.row?.doctors_appointment?.doctor?.full_name} `
    },

    {
      field: 'created_at',
      headerName: 'Horário',
      sortable: true,
      width: 150,
      valueGetter: (params) => {
        const day =
          moment(params.row.day).add(3, 'hours').format('YYYY-MM-DD ') || ''
        const startTime = params.row.doctors_appointment.start_time.replace(
          '+00',
          '-03:00'
        )
        return `${moment(day + startTime).format('YYYY-MM-DD HH:mm') || ''}`
      },
      valueFormatter: (params) =>
        moment(params.value).format('DD/MM/YYYY - HH:mm')
    },
    {
      field: 'id',
      headerName: '',
      sortable: false,
      width: 60,
      renderCell: (params) => {
        return (
          <DataTableActionColumn
            handleEdit={() => {
              modal.setOptions({
                open: true,
                component: (
                  <CardModal style={{ width: '60%' }}>
                    <ModalContainer>
                      <GenerateForm
                        groupFields={schedulesFieldsView(params?.row)}
                      />
                    </ModalContainer>
                  </CardModal>
                )
              })
            }}
          />
        )
      }
    }
  ]
}

export const filterUsers = (filter, setFilter) => {
  const cities = []
  const states = []
  country.estados
    .filter((item) => item.sigla === filter.state.value)
    .forEach((item) => {
      item.cidades.forEach((city) =>
        cities.push({ label: city, value: city })
      )
    })
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })
  return [
    {
      name: 'Filtrar os Resultados',
      fields: [
        {
          label: 'Estado',
          type: 'autocomplete',
          required: true,
          disabled: false,
          hideField: false,
          name: 'state',
          onChange: (data) => {
            setFilter((prev) => ({
              ...prev,
              state: data
            }))
          },
          options: states,
          columns: {
            xs: 3
          },
          validation: Yup.object()
        },
        {
          label: 'Cidade',
          type: 'autocomplete',
          required: true,
          disabled: false,
          hideField: false,
          name: 'city',
          onChange: (data) => {
            setFilter((prev) => ({
              ...prev,
              city: data
            }))
          },
          options: cities,
          columns: {
            xs: 3
          },
          validation: Yup.object()
        }
      ]
    }
  ]
}
