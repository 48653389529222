import { useState, useEffect } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import {
  adminMenu,
  clinicMenu,
  diagnosticImageMenus,
  doctorMenu,
  hospitalMenu,
  laboratoriesMenus,
  odontologyMenus,
  petShopsMenus,
  susMenu
} from './menu'
import { Link, useLocation } from 'react-router-dom'
import { AuthService } from '../../../../services/auth/auth'
import * as S from './styled'
import { useAuth } from '../../../../contexts/authenticator'
import { decodeToken } from 'react-jwt'
import LogoutIcon from '@mui/icons-material/Logout'

export default function BaseLayout ({ children }) {
  const { user } = useAuth()
  const [menu, setMenu] = useState([])
  const [tempUser, setTempUser] = useState()
  const auth = new AuthService()
  const { pathname } = useLocation()

  useEffect(() => {
    const decoded = decodeToken(
      (user?.stsTokenManager?.accessToken || user?.accessToken) || ''
    )
    if (decoded) {
      const type = decoded['https://hasura.io/jwt/claims'].entity
      if (
        decoded['https://hasura.io/jwt/claims']['x-hasura-default-role'] ===
        'user'
      ) {
        auth.signOut()
        return null
      }
      switch (type) {
        case 'doctors':
          setMenu(doctorMenu)
          break
        case 'doctor':
          setMenu(doctorMenu)
          break
        case 'clinics':
          setMenu(clinicMenu)
          break
        case 'hospitals':
          setMenu(hospitalMenu)
          break
        case 'hospital':
          setMenu(hospitalMenu)
          break
        case 'sus':
          setMenu(susMenu)
          break
        case 'petshops':
          setMenu(petShopsMenus)
          break
        case 'laboratory':
          setMenu(laboratoriesMenus)
          break
        case 'dental_clinic':
          setMenu(odontologyMenus)
          break
        case 'medical_imaging_diagnostic':
          setMenu(diagnosticImageMenus)
          break
        case 'admin':
          setMenu(adminMenu)
          break

        default:
          break
      }

      setTempUser({
        ...user,
        type,
        id: decoded['https://hasura.io/jwt/claims'].entity_id || decoded['https://hasura.io/jwt/claims']['entity-id']
      })
    }
  }, [user])

  const renderMenuList = (item) => {
    const currentURLPaths = pathname.split('/')

    return (
      <S.MenuLink key={item.title}>
        <Link to={item.route}>
          <S.LinkItem $select={item.route === '/' + currentURLPaths[1]}>
            <S.IconBox>{item.icon}</S.IconBox>
            <p>{item.title}</p>
          </S.LinkItem>
        </Link>
      </S.MenuLink>
    )
  }

  const generalMenu = menu
    .filter((item) => item.category === 'general')
    .map(renderMenuList)
  const settingsMenu = menu
    .filter((item) => item.category === 'settings')
    .map(renderMenuList)
  const servicesMenu = menu
    .filter((item) => item.category === 'services')
    .map(renderMenuList)

  return (
    <S.Container>
      <CssBaseline />
      {/* <AppBar position="fixed" open={open}>
        <Toolbar style={{ background: customTheme.colors.primary }}>
          <Row className="d-flex flex-row justify-content-between w-100">
            <Col xs={3} className="flex-row d-flex align-items-center">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: "36px",
                  // ...(open && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                <img
                  alt="logo"
                  style={{ maxWidth: 150 }}
                  src={require("../../../../assets/images/logos/horizontal_branco_logo.png")}
                />
              </Typography>
            </Col>
            <Col
              xs={9}
              className="justify-content-end d-flex align-items-center"
            >
              <ProfileMenu user={tempUser} />
            </Col>
          </Row>
        </Toolbar>
      </AppBar> */}

      <S.ListContainer>
        <img
          alt="logo"
          src="https://firebasestorage.googleapis.com/v0/b/medcare-57908.appspot.com/o/adm%2F0WCmMFC8xOIwKp7kB7d7%2Flogo?alt=media&token=378a9fa0-c9c1-4624-b369-3bdc207e66b4"
        />

        {generalMenu.length > 0 && (
          <>
            <S.SeparatorTitle>geral</S.SeparatorTitle>
            {generalMenu}
          </>
        )}

        {settingsMenu.length > 0 && (
          <>
            <S.SeparatorTitle>configurações</S.SeparatorTitle>
            {settingsMenu}
          </>
        )}

        {servicesMenu.length > 0 && (
          <>
            <S.SeparatorTitle>serviços</S.SeparatorTitle>
            {servicesMenu}
          </>
        )}

        <br />

        <S.MenuLink>
          <button onClick={() => auth.signOut()}>
            <S.LinkItem>
              <S.IconBox>
                <LogoutIcon />
              </S.IconBox>
              <p>Sair</p>
            </S.LinkItem>
          </button>
        </S.MenuLink>
      </S.ListContainer>

      <S.MainContainer>{children}</S.MainContainer>
    </S.Container>
  )
}
