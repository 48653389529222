import axios from 'axios'

export const getDataByZipCode = async (cep) => {
  try {
    const viaCepInfo = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
    const getNominatim = await axios.get(
      //   `https://nominatim.openstreetmap.org/search?country=Brazil&q=${viaCepInfo.data.logradouro}&format=json&limit=1`
      // .openstreetmap.org/search/${viaCepInfo.data.localidade}, Boa Vista, 45027-530?format=json&addressdetails=1&limit=1&polygon_svg=1`
      `https://nominatim.openstreetmap.org/search?q=${
        viaCepInfo.data.logradouro ? `${viaCepInfo.data.logradouro} ,` : ''
      }${viaCepInfo.data.bairro ? `${viaCepInfo.data.bairro} ,` : ''}${
        viaCepInfo.data.localidade ? `${viaCepInfo.data.localidade}` : ''
      }&format=json`
      //   `https://nominatim.openstreetmap.org/search?q=Travessa Boa Nova, Guarani, ${viaCepInfo.data.localidade}&format=json&polygon=1&addressdetails=1`
    )
    return { ...getNominatim.data[0], ...viaCepInfo?.data }
  } catch (error) {
    console.error(error)
  }
}
