import { useEffect, useState } from 'react'
import { useQuery, useSubscription } from '@apollo/client'
import { Col, Row } from 'react-bootstrap'
import { DataGrid, ptBR } from '@mui/x-data-grid'
import { fetchSusWaitingRooms } from '../../../../services/sus'
import { tomorrowDate, yesterdayDate } from '../../../../Utils/dates'
import { columns } from './columns'
import * as S from './styled'
import { fetchDoctorById } from '../../../../services/doctor'
import { useAlertBox } from '../../../../contexts/alert_box'

const ListWaitingRooms = ({ susId, doctorId, actions, ...rest }) => {
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(5)
  const [listRooms, setListRooms] = useState([])
  const [doctorSpecialitiesId, setDoctorSpecialitiesId] = useState([])
  const alertBox = useAlertBox()

  const { data: doctorData } = useQuery(fetchDoctorById(), {
    variables: { id: doctorId }
  })

  const { loading, data } = useSubscription(fetchSusWaitingRooms(), {
    fetchPolicy: 'no-cache',
    variables: {
      limit: perPage,
      offset: (page * perPage) ?? 0,
      sus_id: susId,
      yesterday: yesterdayDate(),
      tomorrow: tomorrowDate()
    }
  })

  useEffect(() => {
    setDoctorSpecialitiesId(doctorData?.doctors_by_pk.doctors_with_specialties
      .map(speciality => speciality.medical_specialty.id))
  }, [doctorData])

  useEffect(() => {
    setListRooms(data?.consultation_room || [])
  }, [data, doctorSpecialitiesId])

  const listValues = data?.consultation_room.map(item => ({
    count: item.consultation_room_user_requests_aggregate.aggregate.count,
    id: item.id
  }))

  return (
    <S.Wrapper>
      <Row>
        <Col xs={12}>
          <div style={{ minHeight: 400, width: '100%' }}>
            <DataGrid
              localeText={{
                ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: 'Sem salas de atendimento disponíveis'
              }}
              disableColumnSelector
              rows={listRooms}
              columns={columns(alertBox, actions, listValues, doctorSpecialitiesId)}
              pageSize={perPage}
              onPageChange={e => setPage(e)}
              onPageSizeChange={(e) => setPerPage(e)}
              rowsPerPageOptions={[10]}
              pagination
              disableSelectionOnClick
              page={page}
              loading={loading}
            />
          </div>
        </Col>
      </Row>
    </S.Wrapper>
  )
}

export default ListWaitingRooms
