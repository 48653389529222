import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Avatar from '@material-ui/core/Avatar'
// import IconButton from "@material-ui/core/IconButton";
import { red } from '@material-ui/core/colors'
// import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Col } from 'react-bootstrap'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  avatar: {
    backgroundColor: red[500],
    width: 100,
    height: 100
  }
}))

export default function InternalBody ({ title, subtitle, avatar, children }) {
  const classes = useStyles()
  return (
    <Col className={classes.root} xs={12}>
      {title && (
        <CardHeader
          avatar={
            <Avatar
              src={avatar}
              aria-label="recipe"
              className={classes.avatar}
              sx={{ width: 56, height: 56 }}
            >
              {title[0]}
            </Avatar>
          }
          // action={
          //   <IconButton aria-label="settings">
          //     <MoreVertIcon />
          //   </IconButton>
          // }
          title={title}
          subheader={subtitle}
        />
      )}
      <CardContent>
        {children}
      </CardContent>
    </Col>
  )
}
