import { gql } from '@apollo/client'

export const fecthSuport = () => {
  return gql`
    ${fragmentSuport}
    query getSuport {
      suport(order_by: { id: desc }) {
        ...suportFields
      }
    }
  `
}

export const updateSuport = () => {
  return gql`
    ${fragmentSuport}
    mutation updateSuport(
      $email_suport: String
      $facebook_suport: String
      $help_center_link: String
      $instagram_suport: String
      $whatsapp_suport: String
      $id: Int!
    ) {
      update_suport_by_pk(
        pk_columns: { id: $id }
        _set: {
          email_suport: $email_suport
          facebook_suport: $facebook_suport
          help_center_link: $help_center_link
          instagram_suport: $instagram_suport
          whatsapp_suport: $whatsapp_suport
        }
      ) {
        ...suportFields
      }
    }
  `
}

export const fragmentSuport = gql`
  fragment suportFields on suport {
    created_at
    email_suport
    facebook_suport
    help_center_link
    id
    instagram_suport
    updated_at
    whatsapp_suport
  }
`
