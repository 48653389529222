import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import { columns, filterForm } from './columns'
import { useAlertBox } from '../../../../contexts/alert_box'
import { checktransfer } from '../../../../services/payments'
import GenerateForm from '../../../components/layout/GenerateForm'
import { CustomStyle } from './styled'
import { useModal } from '../../../../contexts/modal'
import Header from '../../../components/Header'
import DataTable from '../../../components/DataTable'
import MainContainer from '../../../components/MainContainer'
import { paymentsByHospitals } from '../../../../services/hospitals/hospital_payments'

function PaymentsHospitals (props) {
  console.log(props)
  const [id] = useState(props.user_id)
  const [offset, setOffset] = useState(0)
  const [filter, setFilter] = useState({})
  const [checkTransferMutation] = useMutation(checktransfer())

  const alertBox = useAlertBox()
  const modal = useModal()

  const { loading, data, refetch } = useQuery(paymentsByHospitals(), {
    variables: {
      status: `%${
        filter?.status && filter?.status?.value !== 'all'
          ? filter?.status?.value + '%'
          : ''
      }`,
      offset: offset * 10,
      limit: 10,
      hospital_id: id
    },
    skip: !id,
    onError: () => {
      alertBox.setOptions({
        open: true,
        message: 'Erro ao buscar os dados!',
        type: 'error',
        time: 3000
      })
    }
  })

  useEffect(() => {
    if (id !== null) {
      setFilter((prev) => ({
        ...prev,
        hospital: { value: id }
      }))
      refetch()
    }
  }, [])

  useEffect(() => {
    console.log(data)
  }, [])

  const onChangePage = (page) => {
    setOffset(page)
  }

  const handleFilter = async (e, actions) => {
    actions.setSubmitting(true)

    setFilter((prev) => ({ ...prev, status: e.status }))
    actions.setSubmitting(false)

    await refetch()
  }

  const checkTransfer = async (id) => {
    await checkTransferMutation({
      variables: {
        _in: [id]
      }
    })
    await refetch()
  }

  return (
    <MainContainer>
      <Header
        title="Pagamentos"
      />

      <CustomStyle>
        <div className="filter">
          <GenerateForm
            onSubmit={handleFilter}
            groupFields={filterForm(filter)}
            button={{
              submit: 'Filtrar',
              submitting: 'Filtrando...'
            }}
          />
        </div>
      </CustomStyle>
      <DataTable
        data={data?.payments || []}
        onChangePage={onChangePage}
        loading={loading}
        columns={columns(modal, checkTransfer)}
        totalItems={data?.payments_aggregate?.aggregate?.count || 100}
      />
    </MainContainer>
  )
}

export default PaymentsHospitals
