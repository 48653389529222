import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import 'moment/locale/pt-br'
import React, { useEffect, useState } from 'react'
import { useAlertBox } from '../../../../contexts/alert_box'
import { fetchClinicsById } from '../../../../services/clinics'
import InternalBody from '../../../components/InternalBody'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import {
  createScheduleAvailable,
  deleteScheduleAvailable,
  updateScheduleAvailable
} from '../../../../services/schedules'
import {
  createScheduleAvailableUseCase,
  deleteScheduleAvailableUseCase,
  updateScheduleAvailableUseCase
} from '../../../../main/usecases/schedules'
import {
  currentWeek,
  translateCalendar,
  verifyEventIsAvailable,
  weekDays
} from '../../../../Utils/dates'
import { CalendarContainer, WeekDays } from './styled'
import Loading from '../../../components/layout/Loading'
import { customTheme } from '../../../../styles/theme'
import ButtonPrimary from '../../../components/Button'
import GenerateForm from '../../../components/layout/GenerateForm'
import * as Yup from 'yup'
import { useModal } from '../../../../contexts/modal'
import CardModal from '../../../components/CardModal'
import { CloseButton } from 'react-bootstrap'
import { editFields } from './columns'
import { useLoading } from '../../../../contexts/loading'
import { useAuth } from '../../../../contexts/authenticator'
require('react-big-calendar/lib/css/react-big-calendar.css')

const localizer = momentLocalizer(moment)
function InternalSchedulesClinic ({ match }) {
  const { id } = useAuth()

  let docId = id
  if (match.params.id) {
    docId = match.params.id
  }

  const { data, loading } = useQuery(fetchClinicsById(), {
    variables: { limit: 10, offset: 0, id: docId }
  })
  const alertBox = useAlertBox()
  const modal = useModal()
  const { setActive } = useLoading()

  const [createScheduleAvailableMutation] = useMutation(
    createScheduleAvailable()
  )

  const [deleteScheduleAvailableMutation] = useMutation(
    deleteScheduleAvailable()
  )

  const [updateScheduleAvailableMutation] = useMutation(
    updateScheduleAvailable()
  )

  const [content, setContent] = useState(null)
  const [events, setEvents] = useState([])
  const [schedule, setSchedule] = useState(null)
  const [scheduleEdit, setScheduleEdit] = useState(false)
  const [values, setValues] = useState([])

  const mutationCreateScheduleAvailable = async (data) => {
    return await createScheduleAvailableUseCase(
      createScheduleAvailableMutation,
      data,
      { alertBox }
    )
  }

  const mutationDeleteScheduleAvailable = async (data) => {
    return await deleteScheduleAvailableUseCase(
      deleteScheduleAvailableMutation,
      data,
      { alertBox }
    )
  }

  const mutationUpdateScheduleAvailable = async (data) => {
    return await updateScheduleAvailableUseCase(
      updateScheduleAvailableMutation,
      data,
      { alertBox }
    )
  }

  const EditFields = [
    {
      name: 'Horário disponível',
      fields: [
        {
          label: 'Dia da semana',
          type: 'autocomplete',
          required: true,
          disabled: !scheduleEdit,
          hideField: false,
          name: 'day_of_week_id',
          options: weekDays,
          currentValue: weekDays[schedule?.day_of_week_id - 1],
          onChange: (data) => {
            setSchedule((prev) => ({
              ...prev,
              day_of_week_id: data?.value
            }))
          },
          columns: { xs: 12 },
          validation: Yup.object().required('Campo obrigatório').nullable()
        },
        {
          label: 'Início',
          type: 'time',
          required: true,
          disabled: !scheduleEdit,
          currentValue: schedule?.start_time,
          hideField: false,
          name: 'start_time',
          columns: { xs: 12 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        },
        {
          label: 'Final',
          type: 'time',
          required: true,
          disabled: !scheduleEdit,
          currentValue: schedule?.end_time,
          hideField: false,
          name: 'end_time',
          columns: { xs: 12 },
          validation:
            Yup.string('Valor inválido').required('Campo obrigatório')
        }
      ]
    }
  ]

  const handleSelect = ({ start, end }) => {
    const title = 'Disponível'

    let canAddEvent = true
    events.forEach((event) => {
      if (verifyEventIsAvailable(start, end, event)) {
        canAddEvent = false
      }
    })

    values.forEach((value) => {
      if (verifyEventIsAvailable(start, end, value)) {
        canAddEvent = false
      }
    })

    if (canAddEvent) {
      modal.setOptions({
        open: true,
        component: (
          <CardModal style={{ width: '500px' }}>
            <CloseButton
              style={{
                position: 'absolute',
                right: 13,
                top: 13
              }}
              onClick={() => modal.setOptions({ open: false })}
            />
            <GenerateForm
              onSubmit={(e) => handleSave(e)}
              groupFields={editFields({
                day_of_week_id: Number(moment(start).format('d')) + 1,
                start_time: moment(start).format('HH:mm:ss'),
                end_time: moment(end).format('HH:mm:ss')
              })}
              but
              button={{
                submit: 'Salvar',
                submitting: 'Salvando'
              }}
            />
            <ButtonPrimary
              onClick={() => {
                modal.setOptions({ open: false })
              }}
              style={{
                backgroundColor: customTheme.colors.error,
                boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`,
                marginBottom: 15
              }}
            >
              Cancelar
            </ButtonPrimary>
          </CardModal>
        )
      })
    }
  }

  const handleSave = async (e) => {
    setActive(true)
    try {
      const body = [
        {
          clinic_id: content.id,
          day_of_week_id: e.day_of_week_id?.value,
          start_time: e.start_time,
          end_time: e.end_time
        }
      ]

      await mutationCreateScheduleAvailable({
        objects: body
      })
      setValues([])
    } catch (error) {
      console.error(error)
    }
    modal.setOptions({ open: false })
    setActive(false)
  }

  const handleUpdateRequest = async (event, e) => {
    setActive(true)
    try {
      await mutationUpdateScheduleAvailable({
        id: event.id,
        day_of_week_id: e.day_of_week_id?.value,
        start_time: e.start_time,
        end_time: e.end_time
      })
    } catch (error) {
      console.error(error)
    }
    modal.setOptions({ open: false })
    setActive(false)
  }

  const handleDeleteRequest = async (id) => {
    setActive(true)
    try {
      await mutationDeleteScheduleAvailable({
        id: id
      })
      setSchedule(null)
    } catch (error) {
      console.error(error)
    }
    modal.setOptions({ open: false })
    setActive(false)
  }

  const handleCancelSchedule = () => {
    setEvents((prev) => prev.filter((item) => !isNaN(item.id)))
    setSchedule(null)
    setValues([])
  }

  useEffect(() => {
    if (data?.clinics[0]) {
      setContent(data?.clinics[0])

      const evs = []
      data?.clinics[0].clinic_hours_and_days_availables.forEach((item) =>
        evs.push({
          id: item?.id,
          title: 'Disponível',
          color: '#3daf7d',
          start: new Date(
            moment(currentWeek()[Number(item?.days_of_week?.id) - 1]).format(
              'YYYY, M, D '
            ) + moment(`11/11/1111 ${item.start_time.replace('+00', '')}`).format(
              'HH:mm:ss'
            )
          ),
          end: new Date(
            moment(currentWeek()[Number(item?.days_of_week?.id) - 1]).format(
              'YYYY, M, D '
            ) +
              moment(`11/11/1111 ${item.end_time.replace('+00', '')}`).format(
                'HH:mm:ss'
              )
          )
        })
      )

      setEvents(evs)
      setSchedule(null)
    }
  }, [data])

  if (loading) return <Loading />

  return (
    <InternalBody>
      <h2>Horários disponíveis para atendimento (Clínica)</h2>
      <CalendarContainer>
        <WeekDays>
          <div>Hora</div>
          {weekDays.map((item) => (
            <div key={item?.value}>{item.label}</div>
          ))}
        </WeekDays>
        <Calendar
          selectable
          localizer={localizer}
          events={events}
          style={{ height: 650, paddingBottoms: 200 }}
          defaultView={Views.WEEK}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}
          onSelectEvent={(event) => {
            modal.setOptions({
              open: true,
              component: (
                <CardModal style={{ width: '500px' }}>
                  <CloseButton
                    style={{
                      position: 'absolute',
                      right: 13,
                      top: 13
                    }}
                    onClick={() => modal.setOptions({ open: false })}
                  />
                  <GenerateForm
                    onSubmit={(e) => handleUpdateRequest(event, e)}
                    groupFields={editFields({
                      id: event.id,
                      day_of_week_id:
                        Number(moment(event?.start).format('d')) + 1,
                      start_time: moment(event?.start).format('HH:mm:ss'),
                      end_time: moment(event?.end).format('HH:mm:ss')
                    })}
                    // groupFields={editFields(schedule, setSchedule)}
                    but
                    button={{
                      submit: 'Salvar',
                      submitting: 'Salvando'
                    }}
                  />
                  <ButtonPrimary
                    onClick={() => {
                      document.getElementById('delete-button').textContent =
                        'Apagando'
                      handleDeleteRequest(event.id)
                    }}
                    id="delete-button"
                    style={{
                      backgroundColor: customTheme.colors.error,
                      boxShadow: `0 8px 15px -2px ${customTheme.colors.error}`,
                      marginBottom: 15
                    }}
                  >
                    Apagar
                  </ButtonPrimary>
                </CardModal>
              )
            })
          }}
          onSelectSlot={handleSelect}
          messages={translateCalendar}
          eventPropGetter={(event) => {
            return {
              style: { backgroundColor: event?.color, border: 'none' }
            }
          }}
        />
        {/* {!!values.length === 22 && (
          <ButtonsContainer>
            <ButtonPrimary
              onClick={() => {
                document.getElementById("save-button").textContent = "Salvando";
                handleSave();
              }}
              id="save-button"
              style={{
                boxShadow: "none",
                fontSize: "16px",
                padding: "7px 5px",
                backgroundColor: customTheme.colors.primary,
                maxWidth: 150,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "15px 5px",
              }}
            >
              Salvar Horário
            </ButtonPrimary>

            <ButtonPrimary
              onClick={handleCancelSchedule}
              style={{
                boxShadow: "none",
                fontSize: "16px",
                padding: "7px 5px",
                backgroundColor: customTheme.colors.error,
                maxWidth: 150,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "15px 5px",
                marginRight: 0,
              }}
            >
              Cancelar
            </ButtonPrimary>
          </ButtonsContainer>
        )} */}
      </CalendarContainer>
    </InternalBody>
  )
}

export default InternalSchedulesClinic
