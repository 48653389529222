import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlertBox } from '../../../../contexts/alert_box'
import InternalBody from '../../../components/InternalBody'
import GenerateForm from '../../../components/layout/GenerateForm'
import { groupFields } from './columns'
import { updateAddress } from '../../../../services/address'
import EditIcon from '@mui/icons-material/Edit'
import { updateAddressUseCase } from '../../../../main/usecases/addressess'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../../../services/firebase/firebase-config'
import { Col, Row } from 'react-bootstrap'
import ButtonPrimary from '../../../components/Button'
import { customTheme } from '../../../../styles/theme'
import CardModal from '../../../components/CardModal'
import { useModal } from '../../../../contexts/modal'
import {
  createCompanyPlan,
  updateCompanyPlan
} from '../../../../services/companyPlans'
import { genericFetch } from '../../../../main/usecases/generic'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import mapPin from '../../../../assets/svg/pin.svg'
import Leaflet from 'leaflet'
import { CustomStyle } from './styled'
import country from '../../../../Utils/estados_cidades.json'
import {
  createHospital,
  fetchHospitalsById,
  updateHospitals
} from '../../../../services/hospitals'
import ManageDoctorsHospitals from '../manageDoctorsHospitals/ManageDoctors'
import {
  createHospitalUseCase,
  updateHospitalUseCase
} from '../../../../main/usecases/hospital'
import { coordIsValid } from '../../../../Utils/coordValidate'
import { fetchAddressByZipCode } from '../../../../services/searchZip/searchZipcode'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

function InternalHospital ({ match }) {
  const { data } = useQuery(fetchHospitalsById(), {
    variables: { limit: 10, offset: 0, id: match.params.id }
  })

  const [mutateFunction] = useMutation(updateHospitals())
  const [createHospitalMutation] = useMutation(createHospital())
  const [createCompanymutation] = useMutation(createCompanyPlan())
  const [updateCompanymutation] = useMutation(updateCompanyPlan())
  const [mutateFunctionAdress] = useMutation(updateAddress())
  const [content, setContent] = useState({})
  const [isAdmin, setIsAdmin] = useState(match.path === '/hospital/:id')
  const alertBox = useAlertBox()
  const modal = useModal()
  const history = useHistory()
  const states = []
  country.estados.forEach((item) => {
    states.push({ label: item.nome, value: item.sigla })
  })
  const [isFirstRun, setIsFirtsRun] = useState(true)

  useEffect(() => {
    setIsAdmin(match.path === '/hospital/:id')
  }, [match])

  useEffect(() => {
    if (data?.hospitals_by_pk) {
      setContent(data?.hospitals_by_pk)
    }
  }, [data])

  const buttonPrimaryStyle = {
    marginBottom: '15px',
    marginTop: '10px',
    boxShadow: 'none',
    fontSize: '12px',
    padding: '5px',
    backgroundColor: customTheme.colors.primary,
    lineHeight: '2',
    maxWidth: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  useEffect(() => {
    if (content?.adress?.zipcode && !isFirstRun) {
      fetchAddressByZipCode(
        content?.adress?.zipcode,
        content,
        setContent,
        states,
        alertBox
      ).then(() => { })
    }

    if (data?.hospitals_by_pk?.adress?.zipecode !== content?.adress?.zipcode) {
      setIsFirtsRun(false)
    }
  }, [content?.adress?.zipcode])

  const mutationCreateHospital = async (e, url, actions) => {
    const res = await createCompanymutation({
      variables: {
        account: '',
        agency: '',
        chave_pix: '',
        cpf: '',
        transfer_of_interest_to_the_customer:
          e?.transfer_of_interest_to_the_customer || false,
        plan_id: e.plan_id.value,
        bank_id: e.bank_id,
        codigo_split: e.codigo_split
      }
    })

    await createHospitalUseCase(
      createHospitalMutation,
      {
        email: e.email,
        address: e.address,
        city: e.city.label,
        state: e.state.label,
        state_code: e.state.value,
        complement: e.complement,
        neighborhood: e.neighborhood,
        number: e.number,
        zipcode: e.zipcode,
        latitude: content.latitude,
        longitude: content.longitude,
        cnpj: e.cnpj,
        company_name: e.company_name,
        description: e.description,
        facebook: e.facebook,
        website: e.website,
        instagram: e.instagram,
        linkedin: e.linkedin,
        online_service: e.online_service,
        personal_assistance: e.personal_assistance,
        phone_number: e.phone_number,
        photo: url || '',
        company_plan_id: res.data.insert_company_plan_one.id
      },
      {
        actions,
        alertBox
      }
    )
  }

  const mutationUpdateHospital = async (e, url, actions) => {
    if (isAdmin) {
      await genericFetch(
        updateCompanymutation,
        {
          id: content.company_plan.id,
          account: '',
          agency: '',
          chave_pix: '',
          cpf: '',
          transfer_of_interest_to_the_customer:
            e?.transfer_of_interest_to_the_customer || false,
          plan_id: e.plan_id.value,
          bank_id: e.bank_id,
          codigo_split: e.codigo_split
        },
        {
          actions,
          alertBox
        }
      )
    }
    await updateAddressUseCase(
      mutateFunctionAdress,
      {
        id: content.adress.id,
        address: e.address,
        city: e.city.value,
        complement: e.complement,
        latitude: e.latitude || content.latitude,
        longitude: e.longitude || content.longitude,
        neighborhood: e.neighborhood,
        number: e.number,
        state: e.state.label,
        state_code: content?.adress?.state_code,
        zipcode: e.zipcode
      },
      {
        actions,
        alertBox
      }
    )
    await updateHospitalUseCase(
      mutateFunction,
      {
        id: match.params.id,
        cnpj: e.cnpj,
        email: e.email,
        company_name: e.company_name,
        description: e.description,
        facebook: e.facebook,
        website: e.website,
        instagram: e.instagram,
        linkedin: e.linkedin,
        phone_number: e.phone_number,
        photo: url || content.photo,
        company_plan_id: content.company_plan.id,
        online_service: e.online_service,
        personal_assistance: e.personal_assistance
      },
      {
        actions,
        alertBox
      }
    )
  }

  const mapPinIcon = Leaflet.icon({
    iconUrl: mapPin,
    iconSize: [58, 68],
    iconAnchor: [29, 68],
    popupAnchor: [170, 2]
  })

  const handleGoBack = () => {
    history.goBack()
  }

  const onSubmit = async (e, actions) => {
    if (window.location.pathname.includes('new')) {
      if (e.file) {
        const storageRef = ref(storage, `hospitals/${new Date().getTime()}`)
        const uploadTask = uploadBytesResumable(storageRef, e.file)

        uploadTask.on('state_changed', null, console.error, () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            await mutationCreateHospital(e, url, actions)
          })
        })
      } else {
        await mutationCreateHospital(e, null, actions)
      }
    } else {
      console.warn(e)
      if (e.file) {
        const storageRef = ref(storage, `hospitals/${new Date().getTime()}`)
        const uploadTask = uploadBytesResumable(storageRef, e.file)

        uploadTask.on('state_changed', null, console.error, () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            await mutationUpdateHospital(e, url, actions)
          })
        })
      } else {
        await mutationUpdateHospital(e, null, actions)
      }
    }

    handleGoBack()
  }

  return (
    <>
      <CustomStyle>
        <InternalBody>
          <p>
            Criado em:{' '}
            {moment(content?.created_at).format('DD/MM/YYYY HH:MM') || ''}
          </p>
          {!window.location.pathname.includes('new') && (
            <Row>
              <Col xs={12}>
                <ButtonPrimary
                  onClick={() => {
                    modal.setOptions({
                      open: true,
                      component: (
                        <CardModal style={{ width: '90%' }}>
                          <ManageDoctorsHospitals
                            hospital_id={match.params.id}
                          />
                        </CardModal>
                      )
                    })
                  }}
                  style={buttonPrimaryStyle}
                >
                  Gerenciar médicos
                  <EditIcon
                    style={{ color: customTheme.colors.white, marginLeft: 5 }}
                  />
                </ButtonPrimary>
              </Col>
            </Row>
          )}
          <GenerateForm
            onSubmit={onSubmit}
            groupFields={groupFields(content, setContent, isAdmin)}
            button={{
              submit: window.location.pathname.includes('new')
                ? 'Adicionar'
                : 'Atualizar',
              submitting: window.location.pathname.includes('new')
                ? 'Adicionando...'
                : 'Atualizando...'
            }}
          />
          {coordIsValid(content.adress?.latitude) && coordIsValid(content.adress?.longitude) && (
            <Col xs={12} style={{ height: '250px' }}>
              <MapContainer
                center={[content.adress.latitude, content.adress.longitude]}
                style={{ width: '100%', height: '100%' }}
                zoom={15}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  icon={mapPinIcon}
                  position={[
                    content.adress.latitude,
                    content.adress.longitude
                  ]}
                />
              </MapContainer>
            </Col>
          )}
        </InternalBody>
      </CustomStyle>
    </>
  )
}

export default InternalHospital
